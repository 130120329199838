
<template>
<v-app>
    <notifications/>
    <v-main>

    <template v-if="codeUser==1 || 3">
                <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale1()">Gestion des appels</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Détail</a></li>
        <input type="hidden" v-model="StrucureCharger">
        <input type="hidden" v-model="nombrepersonneimplique">

			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion  des appels</h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<!-- <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a> -->
					</div>
					<h4 class="panel-title">Détails  </h4>
				</div>
				<div class="panel-body">
                     <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
              <button type="button"  @click="print()" class="btn btn-primary">Imprimer</button>
                	<button type="button"  @click="gotoModale1()" class="btn btn-inverse">RETOUR</button>
              </div>
          </div>

<div id="printMe" >


          <h5 style="text-align:center;text-decoration:underline">Information(s) sur la requête</h5>
           <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Numéro de la requête</th>
                  <th>Date de constitution</th>
                  <th>Structure chargé du dossier</th>
                  <th>Avocat chargé du dossier</th>
                  <th>Juriste chargé du dossier</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {{formData.numrequete_appel}} </td>
                  <td> {{formData.dateconstitution}} </td>
                  <td> {{formData.strucuturecharger}} </td>
                  <td> {{persCharger}} </td>
                  <td> {{data_clinique.nomprenomassocier}} </td>
                </tr>
              </tbody>
           </table> <br>


           <div>
             <h5 style="text-align:center;text-decoration:underline">Information sur la (les) partie(s) civile(s)</h5>
             <table class="table table-striped m-b-0" >
									<thead>
										<tr>
											<th>#</th>
											<th>Nom</th>
											<th>Prénom(s)</th>
                                            <th>Téléphone</th>
                                         
										</tr>
									</thead>
									<tbody v-if="datapartiecivile.length">
										<tr v-for="(item,index) in datapartiecivile" :key="'='+index">
											<td >{{index + 1}}</td>
                      <td>{{item.nom}}</td>
                      <td>{{item.prenom}}</td>
                      <td>{{item.telephone}}</td>
										</tr>
									</tbody>
              </table>
           </div> <br>

           <div>
              <h5 style="text-align:center;text-decoration:underline">Information sur l'audience</h5>
           <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Niveau</th>
                  <th>Juridiction</th>
                  <th>Type de dossier</th>
                  <th>Nombre de personnes impliquées</th>
                  <th>Date de mandat du dépôt</th>
                  <th>N° Registre du parquet</th>
                  <th>Président</th>
                  <th>Ministère Public</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {{formData.niveau_audience}} </td>
                  <td> {{formData.juridictioncompetente}} </td>
                  <td> {{formData.typedossier}} </td>
                  <td> {{data_clinique.nbre_personne_implique}} </td>
                  <td> {{formData.date_mandatdepot}} </td>
                  <td> {{data_clinique.numeroParquet}} </td>
                  <td> {{formData.president}} </td>
                  <td> {{data_clinique.ministere_public}} </td>
                </tr>
              </tbody>
           </table> <br>


            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Rappel des faits</th>
                  <th>Déroulé de l’audience</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {{formData.rappel_fait}} </td>
                  <td> {{formData.deroulement_audience}} </td>
               
                </tr>
              </tbody>
           </table> <br>

           </div>


           <div>
              <h5 style="text-align:center;text-decoration:underline">Liste des renvoi(s)</h5>
             <table class="table table-striped m-b-0" >
									<thead>
										<tr>
											<th>#</th>
											
											<th>Date renvoi</th>
                      <th>Motif renvoi</th>
                                          
								  		</tr>
							    		</thead>
								    	<tbody v-if="datarenvoi.length">
									    	<tr v-for="(item,index) in datarenvoi" :key="'='+index">
										      	<td >{{index + 1}}</td>
                                            <td>{{item.date}}</td>
                                            <td>{{item.motif}}</td>
                                           
											
                     
                      
								    		</tr>
										
								    	</tbody>
                  </table>
           
           </div>


            <div>
              <h5 style="text-align:center;text-decoration:underline">Information sur la décision</h5>
           <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Date de la décision</th>
                  <th>Décision(peine)</th>
                  <th>Personne chargée du suivi de la peine</th>
                  <th>Décision</th>
                  <th>Réquisitions du procureur</th>
                  <th>Ligne de défense de l’avocat</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {{formData.date_decision}} </td>
                  <td v-if="formData.decision_peine==1">Condamné </td>
                  <td v-else-if="formData.decision_peine==2">Condamné avec sursis </td>
                  <td v-else-if="formData.decision_peine==3">Acquitté relaxé </td>
                  <td> {{formData.persone_charger_peine}} </td>
                  <td> {{data_clinique.decision}} </td>
                  <td> {{formData.requisition_procurreur}} </td>
                  <td> {{formData.ligne_defense_avocat}} </td>
                </tr>
              </tbody>
           </table> <br>

           </div>




</div>
          </div>
		</div>
		




    
		


<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </template>

    <!-- //****** fin gestion des dossiers maison de justice */ -->
{{nouveaunumero}}
    </v-main>
    </v-app>
</template>



<script>
import ThemifyIcon from "vue-themify-icons";
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect,
     Multiselect,
     FormWizard,
    TabContent,
    ThemifyIcon,
    },
    props: {
    source: String,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nextButtonText: {
      type: String,
      default: "Suivant",
    },
    backButtonText: {
      type: String,
      default: "Précedent",
    },
    finishButtonText: {
      type: String,
      default: "Enregistrer",
    },

    validateOnBack: Boolean,

    color: {
      type: String,
      default: "black",
    },

    errorColor: {
      type: String,
      default: "#8b0000",
    },

    shape: {
      type: String,
      default: "circle",
    },

    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },

    startIndex: {
      type: Number,
      default: 0,
    },

    title1: {
    type: String,
    default: ''
  },
  /***
   * Icon name for the upper circle corresponding to the tab
   * Supports themify icons only for now.
   */
  icon: {
    type: String,
    default: ''
  },
  /***
   * Only render the content when the tab is active
   */
  lazy: {
    type: Boolean,
    default: false
  },
  /***
   * Function to execute before tab switch. Return value must be boolean
   * If the return result is false, tab switch is restricted
   */
  beforeChange: {
    type: Function
  }
  },
        data() {
            return {
               dialog:false,
               test:true,
               datatrue:false,
   //       lastformData:"",
               datashow_renvoi:0,
               infractionvalue:[],
               article_code_penalvalue:[],

               lastformData:{
                 decision:"",
                 ligne_defense_avocat:"",
                 requisition_procurreur:"",
                 nbre_audience:"",
                 date_decision:"",
                 decision_peine:"",
                 pers_charger_peine:"",

               },
               numrequete:"",
               renvoiformData:{
                 date:"",
                 motif:"",
                // dossier_clinique_id:""
               },
               formData:{
                   numrequete_appel:'',
                   dateconstitution:"",
                   dossier_clinique_id:"",
                   acteur_juridique_id:"",
                   fiche_entretien_id:"",
                   strucuturecharger:"",
                   personneCharger:"",
                   user_id:"",
                   structure_id:''
                 
               },

              data_clinique:{
                ministere_public:"",
                president:"",
                numeroParquet:"",
                date_mandatdepot:"",
                nbre_personne_implique:"",
                typedossier:"",
                juridictioncompetente:"",
                niveau_audience:"",
              },

              PartieCivileformData:{
                nom:"",
                prenom:"",
                telephone:"",
               // dossier_clinique_id:""
              },
               partie_requerante:[],
               datapartiecivile:[],
               datarenvoi:[],
               partie:{
                   naturecompromis:"",
                   datesignature:"",
                   suivicompromis:"",
               },
               edite:"",
                editepartiecivile:'',
                editeRenvoi:'',



     dialogEdite: false,
      dialogDelete: false,
      decisions_peine:[
          {id:'1',libelle:"Condamné"},
          {id:'2',libelle:"Condamné avec sursis"},
          {id:'3',libelle:"Acquitté relaxé"},
          {id:'4',libelle:"Pas de décision"}
          ],

          listtypedossier:[
          {libelle:"Criminel"},
          {libelle:"Correctionnel"}
          ],
         
          listeniveau_audience:[
            {id:1,libelle:"Première instance"},
            {id:1,libelle:"Appel"}
          ],


          situationfamiliale:[
          {id:1,libelle:"Marié(e) civilement"},
          {id:2,libelle:"Marié(e) traditionnellement"},
          {id:3,libelle:"Veuf/ veuve"},
          {id:4,libelle:"Divorcé(e)"},
          {id:5,libelle:"Père/mère de "},
          {id:6,libelle:"Nombre d'épouses : "}
          ],

      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },
      id_data:"",
      

            }
        },
        created(){
            let vm=this
          this.id_data=this.$route.params.id
          this.formData=this.getterAppel.find(item=>item.id==this.id_data);
          this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.id==this.formData.dossier_clinique_id);

          this.editepartiecivile=this.getterPartieCivil.filter(item=>item.appel_id==this.id_data)
            this.editepartiecivile.forEach(item2=>{
                vm.datapartiecivile.push(item2)
            });

            this.editeRenvoi=this.getterRenvoi.filter(item=>item.appel_id==this.id_data)
            this.editeRenvoi.forEach(item2=>{
                vm.datarenvoi.push(item2)
            });

        },

      
        computed:{
            ...mapGetters('Application', ["getterErrorDossiercliniquejuridique",
            "getterLoadingDossiercliniquejuridique",'getterPartieCivil','getterRenvoi',
            "getterDossiercliniquejuridique",'getterAudience',
            "getterInfraction","getterAppel","getterLoadingAppel",'getterErrorAppel',
            "getterCodePenal",
            "getterPartieRequerant",
            "getterActeurJuridique",
            "getterMotifPartie",
            "getterVictimeprevenu",
            'getterModeSaisine',
            "getterFicheEntretien",
            'getterLocaliteSaisine',]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },

            idStructure(){
             if(this.getterUserStore){
               return this.getterUserStore.structure_id
             }
             return ""
           },
           listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

           nouveaunumero(){
             let Objet = this.getterFicheEntretien.find(item=>item.id==this.numrequete)
             let taille =this.getterAppel.length+1
             if(Objet){
                 return this.formData.numrequete_appel =Objet.numrequete+'/A'+taille
             }
           },

            listeAnalyse1(){
             let collect=[];

             this.getterFicheEntretien.filter((item)=>{
              this.getterAudience.filter((item1)=>{
                   if(item1.fiche_entretien_id==item.id){
                        collect.push(item);
                   }
               })
             });
            return collect;

           },

           listeAnalyse2(){
             let collect=[];

             this.getterDossiercliniquejuridique.filter((item)=>{
              this.getterAudience.filter((item1)=>{
                   if(item1.dossier_clinique_id==item.id ){
                        collect.push(item);
                   }
               })
              //  if(val!=undefined){
              //    collect.push(item);
              //    return item
              //  }
             });
            return collect;

           },
           
           listegetterInfraction(){
              return this.getterInfraction.filter((item)=>item.etat==0)
           },

           listegetterCodePenal(){
              return this.getterCodePenal.filter((item)=>item.etat==0)
           },

           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },

    StrucureCharger(){
     
            let Objet= this.getterActeurJuridique.find(item=>item.id==this.formData.acteur_juridique_id)
           if(Objet){
             return this.formData.strucuturecharger = Objet.libelle;
             }return ""
        
        
      },

      persCharger(){
        
          let Objet= this.getterUser.find(item=>item.id==this.formData.user_id)
           if(Objet){
             return this.formData.personneCharger = Objet.name;
             }return ""
        
        
      },

        nombrepersonneimplique(){
          let Objet=this.getterVictimeprevenu.filter(item=>item.dossier_clinique_juridique_id==this.data_clinique.id)
         
         if(Objet){
         
           return this.data_clinique.nbre_personne_implique =this.partie_requerante.length + Objet.length;
         }
         
      },

      motifparti(){
        let Objet= this.getterMotifPartie.find(item=>item.id==this.edite.motif_partie_id)
           if(Objet){
             return  Objet.libelle;
             }return ""
      },
           
        },
        validations: {
          infractionvalue:{required},
          article_code_penalvalue:{required},

      data_clinique:{
                ministere_public:'',
                president:'',
                numeroParquet:'',
                date_mandatdepot:'',
                nbre_personne_implique:'',
                typedossier:'',
                juridictioncompetente:'',
                niveau_audience:'',
                deroulement_audience:'',
                rappel_fait:'',
              },

        
            formData: {
                   dateconstitution:{ required },
                   strucuturecharger:{ required },
            },
             renvoiformData:{
                 date:{ required },
                 motif:{ required },
                // dossier_clinique_id:""
               },

           lastformData:{
                 decision:{ required },
                 ligne_defense_avocat:{ required },
                 requisition_procurreur:{ required },
                //  deroulement_audience:{ required },
                //  rappel_fait:{ required },
                  nbre_audience:'',
                 date_decision:{ required },
                 decision_peine:'',
                 pers_charger_peine:{ required },
               },

                PartieCivileformData:{
                nom:{ required },
                prenom:{ required },
                telephone:{ required },
              },
            // partie:{
            //        naturecompromis:{ required },
            //        datesignature:{ required },
            //        suivicompromis:{ required },
            //    }
        },
        methods:{
             ...mapActions('Application', ['modificationPartieRequerant',
             'getTypeActeurJuridique','modificationAppel','ajouterAppel',
             "getAudience",
             "ajouterAudience",
             "ajouterFicheEntretien",
             "getDossierCliniqueJur",
             ]),
            1(){
              if(this.datashow_renvoi==1){
                 return this.datashow_renvoi=0;
              }
              return this.datashow_renvoi=1;
            },

              gotoModale1(){
              
                this.$router.go(-1)
            },

            addInfraction(newTag){
            this.infractionvalue.push(newTag)
          },

          addArticle_Code_penal(newTag){
            this.article_code_penalvalue.push(newTag)
          },
  //            handleValidation: function(isValid, tabIndex){
  //     console.log('Tab: '+tabIndex+ ' valid: '+isValid)
  //  },

   Testvalidate() {
    // this.numrequete.$touch()
      this.$v.formData.$touch();
     var isValid = !this.$v.formData.$error
      //var isValid1 = !this.$v.numrequete.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba3(){
      this.$v.data_clinique.$touch();
      var isValid = !this.$v.data_clinique.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba4(){
      this.$v.lastformData.$touch();
     // console.log(this.$v.lastformData.$error)
      var isValid = !this.$v.lastformData.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba2() {
      if(this.motifparti=='Détenu Prévenu'){
          this.$v.detenuprevenuformData.$touch();
          var isValid = !this.$v.detenuprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else if(this.motifparti=='Prévenu' || this.motifparti=='Victime'){
        this.$v.victimeprevenuformData.$touch();
          var isValid = !this.$v.victimeprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else{
        return true;
      }
      
    },

    // onComplete: function () {
    //    alert("Submitting Form ! Rock On");
    //   },


    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
          
            ajouterpartiecivile(){
            this.$v.PartieCivileformData.$touch();
                 if(this.$v.PartieCivileformData.$error) {
                     return
                 }
                this.datapartiecivile.push(this.PartieCivileformData)
   this.$v.PartieCivileformData.$reset();
                this.PartieCivileformData={
                nom:"",
                prenom:"",
                telephone:"",
              }

            },

             ajouterdaterenvoi(){
            this.$v.renvoiformData.$touch();
                 if(this.$v.renvoiformData.$error) {
                     return
                 }
                this.datarenvoi.push(this.renvoiformData)
   this.$v.renvoiformData.$reset();
                this.renvoiformData={
                date:"",
                motif:"",
              }

            },

            deletePartieRequerante(item){
            if (item > -1) {
              this.datapartiecivile.splice(item, 1);
            }
            },

            deleteDatarenvoi(item){
            if (item > -1) {
              this.datarenvoi.splice(item, 1);
            }
            },
            enregistrement(){
          
                 // this.formData.fiche_entretien_id=this.numrequete
                 // this.formData.dossier_clinique_id=this.data_clinique.id  
                 // this.formData.acteur_juridique_id=this.idActeurJuridique
                //  this.formData.structure_id=this.idStructure
               //   this.formData.user_id=this.getterUserStore.id

                 

                      let objet={
                   ...this.formData,
                  //  ...this.lastformData,
                  // ...this.data_clinique,
                   renvoiformData:this.datarenvoi,
                    PartieCivileformData:this.datapartiecivile
                //   ...this.instructionformData,
                  // tableau_infraction:this.infractionvalue,
                  // tableau_article_code:this.article_code_penalvalue
                   }
                  console.log(objet)
                  this.modificationAppel(objet)
                   this.$router.go(-1)
  
              
            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},
      // editItem (item) {
      //  window.$("#myModal").modal({
      //   backdrop: "static",
      //   keyboard: false,
      // });
      // //this.datatrue=true

      //  this.PatiRequerantformData=this.getterPartieRequerant.find(element=>element.id==item)
      // },

      detailItem (item) {
       this.$router.push({ name: 'DetailDossierCliniqueJuridique', params: { id: item } })
      },

      formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },


            async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

      

    

      
        },
           watch: {
        
         
             numrequete: function (value) {
      console.log(value);
      let vm=this
      vm.partie_requerante=[]
        if(value!=""){
    this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.fiche_analyse_cj_id==value)
    this.formData.dateconstitution=this.data_clinique.dateconstitution
    this.data_clinique.niveau_audience="Appel"
    this.edite=this.getterFicheEntretien.find(item=>item.id==value && item.status!=0)
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        }
          
   
    },
       
      },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        color: black;
    }
    .finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
