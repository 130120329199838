
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content content-full-width">
        <div class="profile">
          <div class="profile-header">
            <!-- BEGIN profile-header-cover -->
            <div class="profile-header-cover"></div>
            <!-- END profile-header-cover -->
            <!-- BEGIN profile-header-content -->
            <div class="profile-header-content">
              <!-- BEGIN profile-header-img -->
              <div class="profile-header-img">
                <img src="/assets/img/user/avatar.png" alt="" />
              </div>
              <!-- END profile-header-img -->
              <!-- BEGIN profile-header-info -->
              <div class="profile-header-info">
                <h4 class="m-t-10 m-b-5">{{ nameUser }}</h4>
                <p class="m-b-10">{{ liberole(nameRole) }}</p>
                <a href="#" class="btn btn-xs btn-yellow">Mon profil </a>
              </div>
              <input type="hidden" v-model="ListeUser" />
            </div>
            <!-- END profile-header-content fa fa-info-circle' -->
            <!-- BEGIN profile-header-tab -->
            <ul class="profile-header-tab nav nav-tabs">
              <li class="nav-item">
                <a
                  href="#profile-post"
                  class="nav-link active"
                  data-toggle="tab"
                  ><i class="fa fa-info-circle"></i> INFOS</a
                >
              </li>
              <li class="nav-item">
                <a href="#profile-about" class="nav-link" data-toggle="tab"
                  ><i class="fas fa-edit"></i> MODIFIER MES INFORMATIONS</a
                >
              </li>
              <li class="nav-item">
                <a href="#profile-photos" class="nav-link" data-toggle="tab"
                  ><i class="fas fa-edit"></i>MODIFIER LE MOT DE PASSE</a
                >
              </li>
              <!--<li class="nav-item"><a href="#profile-videos" class="nav-link" data-toggle="tab">VIDEOS</a></li>
						<li class="nav-item"><a href="#profile-friends" class="nav-link" data-toggle="tab">FRIENDS</a></li> -->
            </ul>
            <!-- END profile-header-tab -->
          </div>
        </div>

        <div class="profile-content">
          <!-- begin tab-content -->
          <div class="tab-content p-0">
            <!-- begin #profile-post tab -->
            <div class="tab-pane fade show active" id="profile-post">
              <!-- begin timeline -->
              <ul class="timeline">
                <li>
                  <!-- begin timeline-time -->

                  <!-- end timeline-time -->
                  <!-- begin timeline-icon -->
                  <div class="timeline-icon">
                    <a href="javascript:;">&nbsp;</a>
                  </div>

                  <div class="timeline-body">
                    <div class="timeline-footer">
                      <a
                        style="color: gray; font-size: 14px"
                        class="m-r-15 text-inverse-lighter"
                        ><i
                          class="fas fa-user"
                          style="color: gray; font-size: 18px"
                        ></i
                        >&nbsp; Nom et prénoms: {{ nameUser }}</a
                      ><br />
                      <br />
                      <a
                        style="color: gray; font-size: 14px"
                        class="m-r-15 text-inverse-lighter"
                        ><i
                          class="fas fa-user-tag"
                          style="color: gray; font-size: 18px"
                        ></i
                        >&nbsp; Rôle: {{ liberole(nameRole) }}</a
                      >
                      <br />
                      <br />
                      <a
                        style="color: gray; font-size: 14px"
                        class="m-r-15 text-inverse-lighter"
                        ><i
                          class="fas fa-envelope-square"
                          style="color: gray; font-size: 18px"
                        ></i
                        >&nbsp; Email: {{ EmailUser }}</a
                      >
                      <br />
                      <br />
                      <a
                        style="color: gray; font-size: 14px"
                        class="m-r-15 text-inverse-lighter"
                        ><i
                          class="fas fa-phone"
                          style="
                            color: gray;
                            font-size: 18px;
                            font-weight: bold;
                          "
                        ></i
                        >&nbsp; Téléphone: {{ PhoneUser }}</a
                      >
                      <br />
                      <br />
                      <a
                        style="color: gray; font-size: 14px"
                        class="m-r-15 text-inverse-lighter"
                        ><i
                          class="fas fa-align-justify"
                          style="
                            color: gray;
                            font-size: 18px;
                            font-weight: bold;
                          "
                        ></i>
                        &nbsp; Acteur Juridique:
                        {{ libelle_acteur_jur(ActeurjurUser) }}</a
                      >
                      <br />
                      <br />
                    </div>
                  </div>
                  <hr style="color: gray; font-size: 14px" />
                  <!-- end timeline-body -->
                </li>
              </ul>
              <!-- end timeline -->
            </div>

            <div class="tab-pane fade" id="profile-about">
              <!-- begin timeline -->
              <ul class="timeline">
                <li>
                  <!-- begin timeline-time -->

                  <!-- end timeline-time -->
                  <!-- begin timeline-icon -->
                  <div class="timeline-icon">
                    <a href="javascript:;">&nbsp;</a>
                  </div>

                  <div class="timeline-body">
                    <div class="timeline-content">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Nom et prénom(s)
                            </label>
                            <input
                              type="text"
                              v-model="formData1.name"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Entrez le nom et le(s) prénom(s)"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Email
                            </label>
                            <input
                              type="text"
                              v-model="formData1.email"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Entrez votre email"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Téléphone
                            </label>
                            <input
                              type="number"
                              v-model="formData1.telephone"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Entez le numéro de téléphone"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold">Sexe </label>
                            <model-list-select
                              class="form-control"
                              option-value="libelle"
                              option-text="libelle"
                              v-model="formData1.sexe"
                              :list="Liste_sexe"
                              placeholder="Sélectionner le sexe"
                            >
                            </model-list-select>
                          </div>
                          <!-- <div class="form-group">
                                                    <label class="form-label semibold" for="code">Sexe </label>
                                                    <input type="password"  v-model="formData1.sexe" name="code" class="form-control" id="code" placeholder="Entrez le nom et le(s) prénom(s)">
                                                
                                                </div> -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Profession
                            </label>
                            <input
                              type="text"
                              v-model="formData1.profession"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Entrez votre profession"
                            />
                          </div>
                        </div>

                        <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label semibold" for="code">Retapez le nouveau mot de passe </label>
                                                    <input type="password"  v-model="formData1.photo " name="code" class="form-control" id="code" placeholder="Entrez le nom et le(s) prénom(s)">
                                                
                                                </div>
                                             </div> -->
                      </div>
                    </div>

                    <div class="timeline-footer">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group"></div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group"></div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <button
                              type="submit"
                              @click.prevent="modificationProfile"
                              class="btn btn-primary width-150"
                            >
                              Modifier
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style="color: gray; font-size: 14px" />
                  <!-- end timeline-body -->
                </li>
              </ul>
              <!-- end timeline -->
            </div>

            <div class="tab-pane fade" id="profile-photos">
              <!-- begin timeline -->
              <ul class="timeline">
                <li>
                  <!-- begin timeline-time -->

                  <!-- end timeline-time -->
                  <!-- begin timeline-icon -->
                  <div class="timeline-icon">
                    <a href="javascript:;">&nbsp;</a>
                  </div>

                  <div class="timeline-body">
                    <div class="timeline-content">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Mot de passe actuel
                            </label>
                            <input
                              type="password"
                              v-model="formData2.ancien_password"
                              :class="{
                                hasErroInpu:
                                  $v.formData2.ancien_password.$error,
                              }"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Mot de passe actuel"
                            />
                            <div
                              style="color: red"
                              class=""
                              v-if="$v.formData2.ancien_password.$error"
                            >
                              Ce champs est obligatoire
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Nouveau mot de passe
                            </label>
                            <input
                              type="password"
                              v-model.trim="
                                $v.formData2.nouveau_password.$model
                              "
                              :class="{
                                hasErroInpu:
                                  $v.formData2.nouveau_password.$error,
                              }"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Nouveau mot de passe"
                            />
                            <div
                              style="color: red"
                              class=""
                              v-if="
                                $v.formData2.nouveau_password.$error &&
                                !$v.formData2.nouveau_password.required
                              "
                            >
                              Ce champs est obligatoire
                            </div>

                            <div
                              style="color: red"
                              class=""
                              v-if="!$v.formData2.nouveau_password.minLength"
                            >
                              Le nouveau mot de passe doit contenir au moins 6
                              Caractères
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold" for="code"
                              >Retapez le nouveau mot de passe
                            </label>
                            <input
                              type="password"
                              v-model="formData2.retapez_nouveau_password"
                              :class="{
                                hasErroInpu:
                                  $v.formData2.retapez_nouveau_password.$error,
                              }"
                              name="code"
                              class="form-control"
                              id="code"
                              placeholder="Retapez le mot de passe "
                            />
                            <div
                              style="color: red"
                              class=""
                              v-if="
                                $v.formData2.retapez_nouveau_password.$error &&
                                !$v.formData2.retapez_nouveau_password.required
                              "
                            >
                              Ce champs est obligatoire
                            </div>
                            <div
                              style="color: red"
                              class=""
                              v-if="
                                !$v.formData2.retapez_nouveau_password
                                  .sameAsPassword &&
                                formData2.retapez_nouveau_password != ''
                              "
                            >
                              Les deux mots de passe ne sont pas identique
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group"></div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <br />
                            <br />
                            <button
                              type="submit"
                              @click.prevent="modificationPassword"
                              class="btn btn-primary width-150"
                            >
                              Modifier
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- end timeline -->
            </div>
          </div>
          <!-- end tab-content -->
        </div>

        <v-dialog max-width="750" v-model="dialog12" persistent>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar dark>Enregistrement des utilisateurs</v-toolbar>
              <v-card-text>
                <br />
                <div class="form-group">
                  <label class="form-label semibold">Acteur Juridique</label>
                  <model-list-select
                    class="form-control"
                    :class="{
                      hasErroInpu: $v.formData.acteur_juridique_id.$error,
                    }"
                    option-value="id"
                    option-text="libelle"
                    v-model="formData.acteur_juridique_id"
                    :list="getterActeurJuridique"
                    placeholder="Sélectionner l'acteur juridique"
                  >
                  </model-list-select>
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.acteur_juridique_id.$error"
                  >
                    veuillez choisir l'acteur Juridique
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Rôle </label>
                  <model-list-select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.role_id.$error }"
                    option-value="id"
                    option-text="libelle"
                    v-model="formData.role_id"
                    :list="Liste_role"
                    placeholder="Sélectionner le role"
                  >
                  </model-list-select>
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.role_id.$error"
                  >
                    veuillez choisir le rôle
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="code"
                    >Structure</label
                  >
                  <input
                    type="text"
                    readonly
                    :value="recup_libelle_structure"
                    name="code"
                    class="form-control"
                    id="code"
                    placeholder="Structure"
                  />
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label semibold" for="code"
                        >Nom et prénom(s)
                      </label>
                      <input
                        type="text"
                        :class="{ hasErroInpu: $v.formData.name.$error }"
                        v-model="formData.name"
                        name="code"
                        class="form-control"
                        id="code"
                        placeholder="Entrez le nom et le(s) prénom(s)"
                      />
                      <div
                        style="color: red"
                        class=""
                        v-if="$v.formData.name.$error"
                      >
                        veuillez entrer le nom et prénom(s)
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label semibold" for="libelle"
                        >Email</label
                      >
                      <input
                        type="text"
                        :class="{ hasErroInpu: $v.formData.email.$error }"
                        v-model="formData.email"
                        name="libelle"
                        class="form-control"
                        id="libelle"
                        placeholder="Libelle"
                      />
                      <div
                        style="color: red"
                        class=""
                        v-if="$v.formData.email.$error"
                      >
                        veuillez entrer l'email
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="telephone"
                    >Téléphone</label
                  >
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.telephone.$error }"
                    v-model="formData.telephone"
                    name="telephone"
                    class="form-control"
                    id="telephone"
                    placeholder="Entrer le téléphone"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.telephone.$error"
                  >
                    veuillez entrer le téléphone
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement()"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-text>Le mot de passe actuel est inccorect</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="nePasContinueAnalyse()"
              >
                Non
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!---DIALOGUE DE SUPPRESION-->

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="headline"> Suppression !! </v-card-title>

            <v-card-text>
              Voulez-vous réellement effectuer cette action ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green" text @click="dialogDelete = false">
                Annuler
              </v-btn>

              <v-btn color="red" text @click="deleteItemConfirm(edite)">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogEdite">
          <template v-slot:default="dialogEdite">
            <v-card>
              <v-toolbar dark>Modification des utilisateurs</v-toolbar>
              <v-card-text>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label semibold" for="code"
                        >Nom et prénom(s)
                      </label>
                      <input
                        type="text"
                        v-model="edite.name"
                        name="code"
                        class="form-control"
                        id="code"
                        placeholder="Code"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label semibold" for="libelle"
                        >Email</label
                      >
                      <input
                        type="text"
                        v-model="edite.email"
                        name="libelle"
                        class="form-control"
                        id="libelle"
                        placeholder="Libelle"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="telephone"
                    >Téléphone</label
                  >
                  <input
                    type="text"
                    v-model="edite.telephone"
                    name="telephone"
                    class="form-control"
                    id="telephone"
                    placeholder="téléphone"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Acteur Juridique</label>
                  <select
                    class="form-control"
                    v-model="edite.acteur_juridique_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterActeurJuridique"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Structure</label>
                  <select class="form-control" v-model="edite.structure_id">
                    <option></option>
                    <option
                      v-for="item in liste_structure"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Rôle</label>
                  <select class="form-control" v-model="edite.role_id">
                    <option></option>
                    <option
                      v-for="item in Liste_role"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <!-- <div class="form-group">
                         <label class="form-label semibold">Rôle </label>
                            <model-list-select class="form-control" option-value="id"
                                    option-text="libelle" v-model="edite.role_id" :list="Liste_role"
                                                placeholder="Selectionner Acteur juridique">
                        </model-list-select>
                 </div> -->
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdite.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="modification(edite)"
                >
                  Modifier
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { ModelListSelect } from "vue-search-select";
import bcrypt from "bcryptjs";
export default {
  name: "Utilisateur",
  components: {
    ModelListSelect,
  },
  data() {
    return {
      dialog: false,
      test: true,
      formData1: {
        name: "",
        email: "",
        telephone: "",
        sexe: "",
        profession: "",
        photo: "",
      },

      formData2: {
        id: "",
        ancien_password: "",
        nouveau_password: "",
        retapez_nouveau_password: "",
      },
      formData: {
        name: "",
        email: "",
        acteur_juridique_id: "",
        telephone: "",
        role_id: "",
        password: "default2021",
        status_acteur_juridique: "",
        structure_id: "",
      },
      edite: "",
      dialogEdite: false,
      dialog12: false,
      dialogDelete: false,
      urlBase: process.env.VUE_APP_BASE,
      headers: [
        { text: "Photo", value: "profile_photo_path" },
        { text: "Structure", value: "structure.libelle" },
        { text: "Nom et prénom(s)", value: "name" },
        { text: "Email", value: "email" },
        { text: "Téléphone", value: "telephone" },
        { text: "Rôle", value: "role_id" },
        //  { text: 'Acteur Juridique', value: 'acteur_juridique.libelle' },
        { text: "Actions", value: "actions", sortable: false },
      ],
      Liste_role: [
        { id: 1, libelle: "Médiateur" },
        { id: 2, libelle: "Conciliateur" },
        { id: 3, libelle: "Avocat senior" },
        { id: 4, libelle: "juriste" },
        { id: 5, libelle: "Coordinateur" },
        { id: 6, libelle: "Administrateur" },
      ],

      Liste_sexe: [
        { id: 1, libelle: "Homme" },
        { id: 2, libelle: "Femme" },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters("Application", [
      "getterStructure",
      "getterLocalisationGeographique",
      "getterActeurJuridique",
      "getterLoadingActeurJurique",
      "getterErrorActeurJuridique",
      "getterTypeActeurJuridique",
    ]),

    ...mapGetters("Utilisateurs", [
      "getterErrorUser",
      "getterUserStore",
      "getterLoadingUser",
      "getterUser",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingUser;
    },
    liste_structure() {
      return this.getterStructure.reverse();
    },

    ListeUser() {
      if (!this.getterUserStore) return "JUSTICE";

      this.formData1 = this.getterUserStore;
      return this.formData1.name;
    },

    nameUser() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.name;
    },
    EmailUser() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.email;
    },

    PasswordUser() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.password;
    },

    ActeurjurUser() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.acteur_juridique_id;
    },

    PhoneUser() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.telephone;
    },

    nameRole() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.role_id;
    },

    liberole() {
      return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet = this.Liste_role.find((item) => item.id == id);
          if (Objet) {
            return Objet.libelle;
          }
          return "";
        }
      };
    },

    imageUsers() {
      if (this.getterUserStore.profile_photo_path) {
        let id_user = this.getterUserStore.id;
        return (
          this.urlBase +
          "/users/" +
          id_user +
          "/" +
          this.getterUserStore.profile_photo_path
        );
      }
      return this.urlBase + "/users/default/1.jpg";
    },

    recup_id_acteur() {
      const data = this.getterActeurJuridique.find(
        (item) => item.id == this.formData.acteur_juridique_id
      );

      if (data) {
        return data.structure_id;
      }
    },

    libelle_acteur_jur() {
      return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet = this.getterActeurJuridique.find(
            (item) => item.id == id
          );
          if (Objet) {
            return Objet.libelle;
          }
          return "";
        }
      };
    },

    recup_libelle_structure() {
      const Objet = this.getterStructure.find(
        (item) => item.id == this.recup_id_acteur
      );
      if (Objet) {
        return Objet.libelle;
      }
      return "";
    },

    recup_id_structure() {
      const Objet = this.getterStructure.find(
        (item) => item.id == this.recup_id_acteur
      );
      if (Objet) {
        return Objet.id;
      }
      return "";
    },

    typeActeurJu() {
      return this.getterTypeActeurJuridique.reverse();
    },
    utilisateurPasActeurJuridique() {
      return (acteur_juridique) => {
        if (!acteur_juridique) return [];
        return this.getterUser.filter(
          (item) => item.acteur_juridique_id == acteur_juridique
        );
      };
    },
    regionActeurJuridique() {
      return (localisation_geo_graphique_id) => {
        if (!localisation_geo_graphique_id) return "";

        let objet = this.getterLocalisationGeographique.find(
          (item) => item.id == localisation_geo_graphique_id
        );
        if (objet == undefined) return "";
        return objet.libelle;
      };
    },

    libelle_role() {
      return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet = this.Liste_role.find((item) => item.id == id);
          if (Objet) {
            return Objet.libelle;
          }
          return "";
        }
      };
    },

    //  libelle_role(){
    //    return id =>{
    //      let Objet=this.Liste_role.find(item=>item.id==id)
    //      if(Objet){
    //        return Objet.libelle;
    //      }return "";
    //    }
    //  },
    imageUsers() {
      return (image, id) => {
        //  console.log(id)
        if (!image) return this.urlBase + "/users/default/1.jpg";

        return this.urlBase + "/users/" + id + "/" + image;
      };
    },
  },
  validations: {
    formData2: {
      ancien_password: { required },
      nouveau_password: { required, minLength: minLength(6) },
      retapez_nouveau_password: {
        sameAsPassword: sameAs("nouveau_password"),
        required,
      },
    },
    formData: {
      name: { required },
      email: { required, email },
      acteur_juridique_id: { required },
      telephone: { required },
      password: { required },
      role_id: { required },
      status_acteur_juridique: "",
      structure_id: { required },
    },
  },
  methods: {
    ...mapActions("Application", [
      "getActeurJuridique",
      "ajouterActeurJuridique",
      "supprimerActeurJuridique",
      "modificationActeurJuridique",
    ]),

    ...mapActions("Utilisateurs", [
      "getUsers",
      "ajouterUser",
      "modificationUserPassword",
      "supprimerUser",
      "modificationUser",
      "modificationUserProfile",
    ]),

    gotoModale() {
      this.dialog = true;
    },
    enregistrement() {
      this.formData.structure_id = this.recup_id_structure;
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }

      let objet = this.getterActeurJuridique.find(
        (item) => item.id == this.formData.acteur_juridique_id
      );

      let ob = this.getterTypeActeurJuridique.find(
        (item) => item.id == objet.type_acteur_id
      );
      this.formData.status_acteur_juridique = ob.code;

      console.log(this.formData);
      this.ajouterUser(this.formData);
      if (this.getterErrorUser == false) {
        this.$v.formData.$reset();
        this.formData = {
          name: "",
          email: "",
          acteur_juridique_id: "",
          telephone: "",
          role_id: "",
          password: "default2021",
          status_acteur_juridique: "",
          structure_id: "",
        };
      }
    },

    modification(item) {
      console.log(item);
      let objet = this.getterActeurJuridique.find(
        (it) => it.id == item.acteur_juridique_id
      );

      let ob = this.getterTypeActeurJuridique.find(
        (ite) => ite.id == objet.type_acteur_id
      );
      item.status_acteur_juridique = ob.code;
      this.modificationUser(item);
    },

    modificationProfile() {
      let OBjet = {
        ...this.formData1,
      };

      this.modificationUserProfile(OBjet);
    },

    modificationPassword() {
      this.formData2.id = this.getterUserStore.id;
      this.$v.formData2.$touch();
      if (this.$v.formData2.$error) {
        return;
      }
      let OBjet = {
        ...this.formData2,
      };

      console.log(OBjet);

      this.modificationUserPassword(OBjet);
      this.$v.formData2.$reset();
      this.formData2 = {
        ancien_password: "",
        nouveau_password: "",
        retapez_nouveau_password: "",
      };
    },

    nePasContinueAnalyse() {
      this.dialog = false;
      //this.$router.go(-1);
    },

    editItem(item) {
      this.edite = item;
      this.dialogEdite = true;
    },

    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerUser(item.id);
    },
  },
  watch: {},
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
.icon:hover {
  cursor: pointer;
}
.muted {
  color: gray;
  font-size: 80%;
}
</style>



<style>
.vtl .vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}
.vtl .vtl-disabled {
  background-color: #d0cfcf;
}
</style> 
 
