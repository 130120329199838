
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Analyse</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h5 class="page-header">
          Gestion des analyses de la recevabilité
        </h5>
        <!-- end page-header -->
        <!-- begin panel -->
        <div class="panel panel-inverse">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-default"
                data-click="panel-expand"
                ><i class="fa fa-expand"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-success"
                data-click="panel-reload"
                ><i class="fa fa-redo"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-warning"
                data-click="panel-collapse"
                ><i class="fa fa-minus"></i
              ></a>
            </div>
            <h4 class="panel-title">Liste des requêtes analysées</h4>
          </div>
          <div class="panel-body">
             <div class="row">

             <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold">Numéro de la requête</label>
                  <model-list-select option-value="id"
                    option-text="numrequete"
                    v-model="recherche"
                    :list="listeAnalyse"
                    placeholder="Entrer le numéro de la requête"
                  >
                  </model-list-select>
                 
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de réception
                  </label>
                  <input  type="date" v-model="datareception"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>

               <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold">Statut </label>
                  <model-list-select option-value="code"
                    option-text="libelle"
                    v-model="statut_etat"
                    :list="ListeStatut"
                    placeholder="Sélectionner"
                  >
                  </model-list-select>
                 
                </div>
              </div>

              <div class="col-md-1">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
                  <button @click.prevent="resetdata" class="btn btn-primary">Tous</button>
                </div>
              </div>

               <div class="col-md-3" style="text-align: right">
                	<button type="button"  @click="gotoModale()" class="btn btn-inverse">NOUVEAU</button>
              </div>

           
          </div>

            <v-data-table
              :headers="headers"
              :items="listeAnalyse"
              sort-by="numrequete"
              class="elevation-1"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="numrequete"
              show-expand
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  {{ item.fiche_partie_requerant }}
                </td>
              </template>
              
               <template v-slot:item.datareception="{ item }">
               {{ formatagedate(item.datareception) }}
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip :color="getColor(item.status)" dark>
                  {{ getStatus(item.status) }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="btn-group btn-group-justified">
                  <a class="btn btn-default" @click="detailItem(item)"
                    ><i class="fas fa-list-alt"></i
                  ></a>
                  <a v-if="item.status!=4" class="btn btn-default" @click="editItem(item)"
                    ><i class="fas fa-edit"></i
                  ></a>
                  <a style="color:red" class="btn btn-default" @click="deleteItem(item)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>

        <v-dialog max-width="600" v-model="dialog" persistent>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar dark>Formulaire d'enregistrement</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libellé</label
                  >
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.libelle.$error }"
                    v-model="formData.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Libelle"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.libelle.$error"
                  >
                    Veuillez entrer le libellé
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement()"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!---DIALOGUE DE SUPPRESION-->

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="headline"> Suppression !! </v-card-title>

            <v-card-text>
             Voulez-vous réellement effectuer cette action ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green" text @click="dialogDelete = false">
                Annuler
              </v-btn>

              <v-btn color="red" text @click="deleteItemConfirm(edite)">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogEdite">
          <template v-slot:default="dialogEdite">
            <v-card>
              <v-toolbar dark>Modification</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libelle</label
                  >
                  <input
                    type="text"
                    v-model="edite.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Libelle"
                  />
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdite.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="modification(edite)"
                >
                  Modification
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment'
import { required, minLength } from "vuelidate/lib/validators";
import { ModelListSelect } from "vue-search-select";
export default {
  name: "ListeRequeteAnalyse",
           components: {
    ModelListSelect,
  },

  data() {
    return {
      dialog: false,
      test: true,
      formData: {
        libelle: "",
      },
      edite: "",

      singleExpand: false,
       datareception:'',
       statut_etat:'',
       recherche:'',
      expanded: [],
      dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: "Numéro de la requête dans la base ", value: "numrequete" },
        { text: "Date de réception de la requête ", value: "datareception" },
        { text: "Mode de saisine ", value: "mode_saisine.libelle" },
        {
          text: "Localité de la saisine initiale ",
          value: "localite_saisine.libelle",
        },
        { text: "Statut partie requérante", value: "motif_partie.libelle" },
        { text: "Statut", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },

          ListeStatut: [
        // { code:0,libelle: "En attente"},
        { code:1,libelle: "En cours"},
        { code:2,libelle: "Acceptée"},
        { code:3,libelle: "traitée"},
        { code:4,libelle: "Orientée"},
        { code:5,libelle: "Rejetée"},
      
      ],
    };
  },
  created() {},
  computed: {
    ...mapGetters("Application", [
      "getterTypeActeurJuridique",
      "getterLoadingTypeActeurJuridique",
      "getterErrorTypeActeurJuridique",
      "getterFicheEntretien",'getterMotifPartie',
      "getterLoadingFicheEntretien",
      "getterErrorFicheEntretien",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingTypeActeurJuridique;
    },
    listeAnalyse(){
              if(this.recherche!='' && this.datareception=="" && this.statut_etat==""){
                return this.getterFicheEntretien.filter(item=>item.status!=0 && item.id==this.recherche)

              }else if(this.recherche=='' && this.datareception!="" && this.statut_etat==""){
                 return this.getterFicheEntretien.filter(item=>item.status!=0 && item.datareception==this.datareception)
              
               }else if(this.recherche!='' && this.datareception!="" && this.statut_etat!=""){
                 return this.getterFicheEntretien.filter(item=>item.status!=0 && 
                 item.datareception==this.datareception && item.id==this.recherche
                  && item.status==this.statut_etat)

              }else if(this.recherche=='' && this.datareception=="" && this.statut_etat!=""){
                 return this.getterFicheEntretien.filter(item=>item.status!=0 && 
                  item.status==this.statut_etat)
              
               }else if(this.recherche!='' && this.datareception!="" && this.statut_etat==""){
                 return this.getterFicheEntretien.filter(item=>item.status!=0 && 
                 item.datareception==this.datareception && item.id==this.recherche)

              }else if(this.recherche!='' && this.datareception=="" && this.statut_etat!=""){
                 return this.getterFicheEntretien.filter(item=>item.status!=0 && 
                 item.status==this.statut_etat && item.id==this.recherche)

              }else if(this.recherche=='' && this.datareception!="" && this.statut_etat!=""){
                 return this.getterFicheEntretien.filter(item=>item.status!=0 && 
                 item.status==this.statut_etat && item.datareception==this.datareception)
              }else{
                return this.getterFicheEntretien.filter(item=>item.status!=0)
              }
             
           },
    getStatus() {
      return (status) => {
        if (status == 0) return "En attente";
        if (status == 1) return "En cours";
        if (status == 2) return "Acceptée";
        if (status == 3) return "Traitée";
        if (status == 4) return "Orientée";
        return "Rejetée";
      };
    },
  },
  validations: {
    formData: {
      libelle: { required },
    },
  },
  methods: {
    ...mapActions("Application", [
      "getTypeActeurJuridique",
      "ajouterTypeActeurJurique",
      "supprimerTypeActeurJuridique",
      "modificationTypeActeurJuridique",
      "supprimerFicheEntretien",
    ]),
    getColor(status) {
      if (status == 0) return "#f59c1a";
      else if (status == 1) return "#348fe2";
      else if (status == 2) return "#00acac";
      else if (status == 3) return "#00BFFF";
      else if (status == 4) return "#a05c31";
      else return "red";
    },
    gotoModale() {
      this.$router.push({
        name: "NouvelAnalyse",
      });
    },
    enregistrement() {
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }

      this.ajouterTypeActeurJurique(this.formData);
      if (this.getterErrorTypeActeurJuridique == false) {
        this.$v.formData.$reset();
        this.formData = {
          libelle: "",
        };
      }
    },

    resetdata(){
               this.datareception='',
               this.statut_etat='',
               this.recherche=''
              return 1
             },

    

    editItem(item) {
      this.$router.push({
        name: "ModificationRecevabilite",
        params: { id: item.id },
      });
    },
    detailItem(item) {
      this.$router.push({ name: "DetailAnalyse", params: { id: item.id } });
    },
    formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },
    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerFicheEntretien(item.id);
    },
  },
  watch: {},
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
</style>
