
<template>
<div>

    <DossierCliniqueJuridique v-if="codeUser==1"></DossierCliniqueJuridique>

    <Dossier v-if="codeUser==2"></Dossier>
</div>

</template>



<script>
import { mapGetters} from 'vuex'
import Dossier from "@/views/gestionDesDossiers/DossierMaisonJustice/Dossier";
import DossierCliniqueJuridique from "@/views/gestionDesDossiers/dossierCliniqueJuridique/DossierCliniqueJuridique";

export default {
  name: "TypeActeur",
  components: {
    DossierCliniqueJuridique,
    Dossier,
  },
  data() {
    return {
      dialog: false,
      test: true,
      formData: {
        libelle: "",
      },
      edite: "",

      singleExpand: false,
      expanded: [],
      dialogEdite: false,
      dialogDelete: false,

      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
    };
  },
  created() {},

   computed:{
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),
             
          
        
           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },
          
        },
};
</script>

<style scoped>
</style>
