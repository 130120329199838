
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale()">Recevabilité de la requête</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Détails</a></li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion des analyses de la recevabilité</h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Détail </h4>
				</div>
				<div class="panel-body">
          
          <div class="row">
         <div class="col-md-2">
<address>
  <strong>Numéro de la requête </strong><br />
 {{edite.numrequete}}<br />

</address>
         </div>
          <div class="col-md-2">
<address>
  <strong>Date de réception </strong><br />
  {{edite.datareception}}<br />
 
  
</address>
         </div>

          <div class="col-md-3">
<address>
  <strong>Mode de saisine </strong><br />
  <p v-if="edite.mode_saisine">{{edite.mode_saisine.libelle}}</p><br />

</address>
         </div>
          <div class="col-md-3">
<address>
  <strong>Localité de la saisine initiale </strong><br />
   <p v-if="edite.localite_saisine">{{edite.localite_saisine.libelle}}</p><br />
  
</address>
         </div>
          <div class="col-md-2">
<address>
  <strong>Statut partie requérante </strong><br />
 <p v-if="edite.motif_partie">{{edite.motif_partie.libelle}}</p><br />

</address>
         </div>
    
            </div>
		
<div class="table-responsive">
								
                                  <h5>Partie requérante</h5>
      <table class="table  m-b-0" v-if="partie_requerante.length">
									<thead>
										<tr>
											<th>#</th>
											<th>Nom</th>
											<th>Prénom(s)</th>
                                          <th>Contact</th>
                                             <th>Age</th>
                                            <th>Genre</th>
                      
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item,index) in partie_requerante" :key="'='+index">
											<td >{{index + 1}}</td>
											<td><input disabled type="text" v-model="item.nom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
											<td><input disabled type="text" v-model="item.prenom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                      	<td><input disabled type="text" v-model="item.contact" name="nom" class="form-control" id="nom" placeholder="Nom ">
                          </td>
											<td>
                                                <input disabled type="text" v-model="item.age" name="nom" class="form-control" id="nom" placeholder="Nom ">
                                               </td>
                      <td>
                          <select disabled class="form-control"  v-model="item.genre">
                           <option></option>
                           <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                         </select>
                      </td>
                     
                      
										</tr>
										
									</tbody>
			</table>
	</div>


<hr>


<table class="table table-bordered m-b-0">
  <tr>
    <td style="background:#d5dce4;text-align:center !important"  colspan="3">I.Analyse de recevabilité pour l’assistance juridique et judiciaire des Cliniques juridiques</td>
  </tr>
  <tr v-if="detail_analyse">
    <td style="background:#fbe4d5">Critères de vulnérabilité</td>
    <td style="background:#fbe4d5">Conclusion</td>
    <td style="background:#fbe4d5">Justifications</td>
  </tr>
  <tr v-if="detail_analyse">
    <td>1. Critère d’indigence(cf justificatifs)</td>
    <td>{{conclusion(detail_analyse.critere_indigence)}}</td>
    <td>
     
        
    <div class="box" v-if="detail_analyse">
    <ul class="df">
    <li v-for="item in detail_analyse.indigence_analyse" :key="item.id">
      {{critereIndigence(item.criter_indigence_id)}}</li>
    
    </ul> 
  </div>


    </td>
  </tr>
  <tr v-if="detail_analyse">
    <td>2. Critère de victimologie (cf certificats médicaux)</td>
    <td>{{conclusion(detail_analyse.critere_victimologie)}}</td>
    <td><li v-for="item in detail_analyse.victimologie_analyse" :key="item.id">
      {{critereVictimologie(item.critere_victimologie_id)}}</li></td>
  </tr>
  <tr v-if="detail_analyse">
    <td>3. Critère de non prise en charge par une autre structure d’accompagnement juridique/judiciaire</td>
    <td>{{conclusion(detail_analyse.criterede_non_prise_encharge_par_sturcture)}}</td>
    <td>{{detail_analyse.justification_autre_structure}}</td>
  </tr>
  <tr v-if="detail_analyse">
    <td>Conclusion  pour l’AJJ des CJ</td>
    <td>{{conclusionAnalyse(detail_analyse.conclusion_pourAJJ_desCJ)}}</td>
    <td>{{detail_analyse.justification_conclusion}}</td>
  </tr>
</table>
				</div>

			</div>


          


{{numeroRequete}}
    



<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "DetailAnalyse",
        props:["ficheRenseignement"],
        components: {
     ModelListSelect
    },
        data() {
            return {
               dialog:false,
               test:true,
               formData:{
                 
                   numrequete:"",
                   datareception:"",
                   mode_saisine_id:"",
                    localite_saisine_id:"",
                    motif_partie_id:"",
                    user_id:"",
                    acteur_juridique_id:""
                 
               },

               partie_requerante:[],
               partie:{
                   nom:"",
                   prenom:"",
                   contact:"",
                   age:"",
                    genre:"",
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,
      headers: [
      
        { text: 'Libelle', value: 'libelle' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      detail_analyse:"",
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 this.edite=this.getterFicheEntretien.find(item=>item.id==this.ficheRenseignement)
this.detail_analyse=this.getterAnalyseCJ.find(item=>{
    if(item.fiche_entretien_id==this.ficheRenseignement && item.acteur_juridique_id==this.idActeurJuridique){
        return item
    }
})
 console.log(this.detail_analyse)
 let vm=this
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        },
        computed:{
            ...mapGetters('Application', ["getterMotifPartie",'getterModeSaisine', 
            'getterLocaliteSaisine',"getterErrorTypeActeurJuridique",
            "getterLoadingFicheEntretien","getterErrorFicheEntretien",
            "getterFicheEntretien","getterAnalyseCJ","getterCriteIndigence","getterCritereVictimologie"]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           critereIndigence(){
             return id=>{
               if(!id) return ""
               let objet=this.getterCriteIndigence.find(item=>item.id==id)
               return objet.libelle
             }
           },
            critereVictimologie(){
             return id=>{
               if(!id) return ""
               let objet=this.getterCritereVictimologie.find(item=>item.id==id)
               return objet.libelle
             }
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },
           numeroRequete(){
            if(this.getterUserStore){
              let vm=this
              const date = new Date();
              let moi=date.getMonth()
              
              let annee=date.getFullYear()
              let jour=date.getDay()
              let heure=date.getHours()
              let mili=date.getMilliseconds()
              moi=moi+1
              let code=+mili+""+heure+""+jour+""+moi+""+annee
              console.log(code)
                if(this.getterUserStore.acteur_juridique){
                  vm.formData.numrequete=this.getterUserStore.acteur_juridique.code+"/"+code
                   return ""
                }
                return ""
            }
            return ""
           },
           conclusion(){
               return critere=>{
                   if(!critere) return ""
                   if(critere==0) return "Non rempli"
                   if(critere==1) return "rempli"
               }
           },
            conclusionAnalyse(){
               return critere=>{
                   if(!critere) return ""
                   if(critere==2) return "Recevable"
                   if(critere==3) return "Irrecevable"
               }
           },

        },
        validations: {
            formData: {
                numrequete:{ required },
                   datareception:{ required },
                   mode_saisine_id:{ required },
                    localite_saisine_id:{ required },
                   motif_partie_id:{ required },
                   user_id:"",
                    acteur_juridique_id:""
            },
            partie:{
                   nom:{ required },
                   prenom:{ required },
                   contact:{ required },
                   age:{ required },
                    genre:{ required },
               }
        },
        methods:{
             ...mapActions('Application', ['getTypeActeurJuridique',"ajouterTypeActeurJurique",
             "supprimerTypeActeurJuridique","modificationTypeActeurJuridique","ajouterFicheEntretien","modificationFicheEntretien"]),
            gotoModale(){
              
                this.$router.go(-1)
            }
            ,
            ajouterPartieRequerante(){
             this.$v.partie.$touch();
                if(this.$v.partie.$error) {
                    return
                }
                this.partie_requerante.push(this.partie)
   this.$v.partie.$reset();
                this.partie={
                   nom:"",
                   prenom:"",
                   contact:"",
                   age:"",
                    genre:"",
               }

            },
            deletePartieRequerante(item){
            if (item > -1) {
              this.partie_requerante.splice(item, 1);
            }
            },
            enregistrement () {

                
                 let objet={
                   ...this.edite,
                   partie:this.partie_requerante
                 }
                 console.log(objet)
                this.modificationFicheEntretien(objet)
                
               

            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
