
<template>
<v-app>
    <notifications/>
    <v-main>

    <template v-if="codeUser==1 || 3">
                <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale1()">Gestion des appels</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Ajouter</a></li>
        <input type="hidden" v-model="StrucureCharger">
        <input type="hidden" v-model="nombrepersonneimplique">

			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion  des appels</h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<!-- <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a> -->
					</div>
					<h4 class="panel-title">Formulaire d'enregistrement des appels  </h4>
				</div>
				<div class="panel-body">
                     <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                	<button type="button"  @click="gotoModale1()" class="btn btn-inverse">RETOUR</button>
              </div>
          </div>

                    <div>
                        
                                <!-- <ul class="nav nav-pills">
						<li class="nav-items">
							<a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active show">
								<span class="d-sm-none">Informations sur la requête </span>
								<span class="d-sm-block d-none">Informations sur la requête </span>
							</a>
						</li>
						<li class="nav-items">
							<a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
								<span class="d-sm-none">Analyse de recevabilité </span>
								<span class="d-sm-block d-none">Analyse de recevabilité </span>
							</a>
						</li>
						
					</ul> -->
                        
                    
        <div class="tab-content">
      

           <ThemifyIcon  />
      <form-wizard 
      @on-complete="enregistrement"
        :nextButtonText="nextButtonText"
        :backButtonText="backButtonText"
        color="#28a745"
        :finishButtonText="finishButtonText"
        :shape="shape"
        :subtitle="subtitle"
        :startIndex="startIndex"
        title=""
        
      >
        <tab-content title="Information(s) sur la requête" :before-change="Testvalidate" >

          <div class="row">

           
                              

             <div class="col-md-3">
                 <div class="form-group">
                         <label class="form-label semibold">Numéro de la requête<b style="color:red">*</b></label>
                            <model-list-select aria-required="required" class="form-control"  option-value="id"
                                    option-text="numrequete" v-model="numrequete" :list="listeAnalyse1"
                                                placeholder="Sélectionnez une requête">
                        </model-list-select>
							         
                  </div>
             </div>
             

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="dateconstitution">Numéro de la requête de l'appel </label>
                    <input type="text"  class="form-control" disabled v-model.trim="formData.numrequete_appel">
                      
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.formData.dateconstitution.$error }">
                  <label class="form-label semibold" for="dateconstitution">Date de constitution du dossier </label>
                    <input type="date"  disabled class="form-control" v-model.trim="formData.dateconstitution" @input="$v.formData.dateconstitution.$touch()">
                   <div style="color: red;" v-if="$v.formData.dateconstitution.$error && !$v.formData.dateconstitution.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.formData.strucuturecharger.$error }">
                  <label class="form-label semibold" for="strucuturecharger">Structure chargée du dossier  </label>
                    <input disabled  type="text" class="form-control" v-model.trim="formData.strucuturecharger" @input="$v.formData.strucuturecharger.$touch()" placeholder="Structure chargé du dossier">
                   <div style="color: red;" v-if="$v.formData.strucuturecharger.$error && !$v.formData.strucuturecharger.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

            <div class="col-md-3">
           <div class="form-group" >
                         <label class="form-label semibold" for="personneCharger">Avocat chargé du dossier  </label>
							<input disabled type="text"  v-model="persCharger" name="personneCharger" class="form-control" id="personneCharger" placeholder="Personne chargé du dossier">
							
                     </div>
                     </div>

            <div class="col-md-3">
           <div class="form-group" >
                         <label class="form-label semibold" for="personneCharger">Juriste chargé du dossier  </label>
							<input disabled type="text"  v-model="data_clinique.nomprenomassocier" name="personneCharger" class="form-control" id="personneCharger" placeholder="Personne chargé du dossier">
							
                     </div>
                     </div>
          
        

            

        </div>

        </tab-content>

    <tab-content title="Partie(s) civile(s)" icon='ti-user'>
           <div class="row">

              <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.PartieCivileformData.nom.$error }">
                  <label class="form-label semibold" for="Juridiction">Nom <b style="color:red">*</b></label>
                    <input type="text" class="form-control" v-model.trim="PartieCivileformData.nom" @input="$v.PartieCivileformData.nom.$touch()" placeholder="Entrer le nom">
                        <div style="color: red;" v-if="$v.PartieCivileformData.nom.$error && !$v.PartieCivileformData.nom.required">
                     Ce champs est requis!
                  </div> 
               </div>
           </div>

              <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.PartieCivileformData.prenom.$error }">
                  <label class="form-label semibold" for="prenom">Prénom(s) <b style="color:red">*</b></label>
                    <input type="text" class="form-control" v-model.trim="PartieCivileformData.prenom" @input="$v.PartieCivileformData.prenom.$touch()" placeholder="Entrer le(s) prénom(s)">
                      <div style="color: red;" v-if="$v.PartieCivileformData.prenom.$error && !$v.PartieCivileformData.prenom.required">
                     Ce champs est requis!
                  </div> 
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.PartieCivileformData.telephone.$error }">
                  <label class="form-label semibold" for="telephone">Téléphone <b style="color:red">*</b></label>
                    <input type="number" class="form-control" v-model.trim="PartieCivileformData.telephone" @input="$v.PartieCivileformData.telephone.$touch()"  placeholder="Entrer le téléphone">
                      <div style="color: red;" v-if="$v.PartieCivileformData.telephone.$error && !$v.PartieCivileformData.telephone.required">
                     Ce champs est requis!
                  </div> 
               </div>
           </div>

           <div class="col-md-2">
               <div class="form-group">
                  <br>
               <a href="#" @click.prevent="ajouterpartiecivile()" class="btn btn-primary"> ajouter
                  <i class="fas fa-plus"></i>
               </a>
                </div>
             </div>

           </div>

             <table class="table table-striped m-b-0" v-if="datapartiecivile.length">
									<thead>
										<tr>
											<th>#</th>
											<th>
                        Nom</th>
											<th>Prénom(s)</th>
                                            <th>Téléphone</th>
                                            <th style="width:16%">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item,index) in datapartiecivile" :key="'='+index">
											<td >{{index + 1}}</td>
											<td>{{item.nom}}</td>
											<td>{{item.prenom}}</td>
                      <td>{{item.telephone}}</td>
                      <td>
                        <a href="#" @click.prevent="deletePartieRequerante(index)" class="btn btn-danger">
                  <i class="fas fa-trash"></i>
                  </a>
                      </td>
                      
										</tr>
										
									</tbody>
</table>
        </tab-content>


        <tab-content title="Audience" icon="ti-check" :before-change="TestvalidateTba3">
         

            <div>

          <div class="row">

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="juridictioncompetente">Niveau </label>
                    <input disabled type="text" v-model.trim="data_clinique.niveau_audience"  class="form-control"   placeholder="">
                      
               </div>
           </div>

             <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.juridictioncompetente.$error }">
                  <label class="form-label semibold" for="juridictioncompetente">Juridiction compétente </label>
                    <input disabled type="text" class="form-control" v-model.trim="data_clinique.juridictioncompetente" @input="$v.data_clinique.juridictioncompetente.$touch()" placeholder="juridiction competente">
                   <div style="color: red;" v-if="$v.data_clinique.juridictioncompetente.$error && !$v.data_clinique.juridictioncompetente.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

           <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.typedossier.$error }">
                  <label class="form-label semibold" for="typedossier">Type de dossier </label>
                    <input disabled type="text" class="form-control" v-model.trim="data_clinique.typedossier" @input="$v.data_clinique.typedossier.$touch()" placeholder="type de dossier">
                   <div style="color: red;" v-if="$v.data_clinique.typedossier.$error && !$v.data_clinique.typedossier.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>
              

            

              <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.nbre_personne_implique.$error }">
                  <label class="form-label semibold" for="nbre_personne_implique">Nombre de personnes impliquées </label>
                    <input disabled type="text" class="form-control" v-model.trim="data_clinique.nbre_personne_implique" @input="$v.data_clinique.nbre_personne_implique.$touch()" placeholder="Nombre de personnes impliquées">
                   <div style="color: red;" v-if="$v.data_clinique.nbre_personne_implique.$error && !$v.data_clinique.nbre_personne_implique.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

           <!-- <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.date_mandatdepot.$error }">
                  <label class="form-label semibold" for="date_mandatdepot">Date de mandat du dépôt <b style="color:red">*</b></label>
                    <input type="date" class="form-control" v-model.trim="data_clinique.date_mandatdepot" @input="$v.data_clinique.date_mandatdepot.$touch()" placeholder="Saisir l'âge">
                   <div style="color: red;" v-if="$v.data_clinique.date_mandatdepot.$error && !$v.data_clinique.date_mandatdepot.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div> -->

            

             <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.numeroParquet.$error }">
                  <label class="form-label semibold" for="numeroParquet">N° du registre du parquet </label>
                    <input disabled type="text" class="form-control" v-model.trim="data_clinique.numeroParquet" @input="$v.data_clinique.numeroParquet.$touch()" placeholder="N° du registre du parquet">
                   <div style="color: red;" v-if="$v.data_clinique.numeroParquet.$error && !$v.data_clinique.numeroParquet.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

           
             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="president">Nom et prénom(s) du Président <b style="color:red"></b></label>
                    <input type="text" class="form-control" v-model.trim="data_clinique.president"  placeholder="Entrer le nom et prénom(s) du Président">
                       
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="ministere_public">Entrer le nom et prénom(s) du Ministère public <b style="color:red"></b></label>
                    <input type="text" class="form-control" v-model="data_clinique.ministere_public" placeholder="Entrer le nom et prénom(s) du Ministère public">
                        
               </div>
           </div>
            <div class="col-md-3">
                            <div class="form-group">
                              <label
                                class="form-label semibold"
                                for="ministere_public"
                                >Date de l'audience 
                                <b style="color: red">*</b></label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model.trim="data_clinique.date_audience"
                                placeholder=""
                              />

                              <div
                                style="color: red"
                                v-if="
                                  $v.data_clinique.date_audience.$error &&
                                  !$v.data_clinique.date_audience.required
                                "
                              >
                                Ce champs est requis!
                              </div>
                            </div>
                          </div>

           </div>



           <div class="row">

                 <div class="col-md-6">
                     <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.rappel_fait.$error }">
                         <label class="form-label semibold" for="rappel_fait">Rappel des faits : <b style="color:red">*</b></label>
                         <textarea  class="form-control" v-model.trim="data_clinique.rappel_fait" name="" id="" cols="60" rows="4"></textarea>
                          <div style="color: red;" v-if="$v.data_clinique.rappel_fait.$error && !$v.data_clinique.rappel_fait.required">
                                  Ce champs est requis!
                            </div>
                    </div>
                </div>
                <div class="col-md-6">
                     <div class="form-group" v-bind:class="{ 'has-error': $v.data_clinique.deroulement_audience.$error }">
                           <label class="form-label semibold" for="deroulement_audience">Déroulé de l’audience :<b style="color:red">*</b> </label>
                             <textarea  class="form-control" v-model.trim="data_clinique.deroulement_audience" name="" id="" cols="120" rows="4"></textarea>
                                 <div style="color: red;" v-if="$v.data_clinique.deroulement_audience.$error && !$v.data_clinique.deroulement_audience.required">
                                      Ce champs est requis!
                                  </div>
                      </div>
                 </div>
            </div>
        </div>


        </tab-content>

      
          <tab-content title="Renvoi(s)">
                      <div class="col-md-12" style="text-align: right">
                        <button
                          type="button"
                          @click="clotueraudience()"
                          class="btn btn-inverse"
                        >
                          Enregistrer et quitter
                        </button>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label
                              class="form-label semibold"
                              for="attestationconcentement"
                              >Date du renvoi
                            </label>
                            <input
                              type="date"
                              v-model="renvoiformData.date"
                              name="attestationconcentement"
                              class="form-control"
                              id="attestationconcentement"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label
                              class="form-label semibold"
                              for="attestationconcentement"
                              >Motif du renvoi
                            </label>
                            <input
                              type="text"
                              v-model="renvoiformData.motif"
                              name="attestationconcentement"
                              class="form-control"
                              id="attestationconcentement"
                              placeholder="Entrer le motif du renvoi "
                            />
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group">
                            <br />
                            <a
                              href="#"
                              @click.prevent="ajouterdaterenvoi()"
                              class="btn btn-primary"
                            >
                              ajouter
                              <i class="fas fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table
                          class="table-bordered table-hover table m-b-0"
                          v-if="datarenvoi.length"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>date</th>
                              <th>Motif</th>
                              <th style="width: 16%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in datarenvoi"
                              :key="'=' + index"
                            >
                              <td>{{ index + 1 }}</td>
                              <!-- <td v-if="index+1>1">{{index + 1+'ème renvoi'}}</td>
                             <td v-if="index+1>1">{{index + 1+'ème renvoi'}}</td> -->
                              <td>{{ formatagedate(item.date) }}</td>
                              <td>{{ item.motif }}</td>

                              <td>
                                <a
                                  href="#"
                                  @click.prevent="deletedatarenvoi(index)"
                                  class="btn btn-danger"
                                >
                                  <i class="fas fa-trash"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </tab-content>

        
        <tab-content
                      title="Décision"
                      :before-change="TestvalidateTba4"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div
                            class="form-group"
                            v-bind:class="{
                              'has-error':
                                $v.lastformData.requisition_procurreur.$error,
                            }"
                          >
                            <label
                              class="form-label semibold"
                              for="requisition_procurreur"
                              >Réquisitions du procureur
                              <b style="color: red">*</b>
                            </label>
                            <textarea
                              class="form-control"
                              v-model.trim="lastformData.requisition_procurreur"
                              name=""
                              id=""
                              cols="120"
                              rows="4"
                            ></textarea>
                            <div
                              style="color: red"
                              v-if="
                                $v.lastformData.requisition_procurreur.$error &&
                                !$v.lastformData.requisition_procurreur.required
                              "
                            >
                              Ce champs est requis!
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div
                            class="form-group"
                            v-bind:class="{
                              'has-error':
                                $v.lastformData.ligne_defense_avocat.$error,
                            }"
                          >
                            <label
                              class="form-label semibold"
                              for="ligne_defense_avocat"
                              >Ligne de défense de l’avocat
                              <b style="color: red">*</b>
                            </label>
                            <textarea
                              class="form-control"
                              v-model.trim="lastformData.ligne_defense_avocat"
                              name=""
                              id=""
                              cols="120"
                              rows="4"
                            ></textarea>
                            <div
                              style="color: red"
                              v-if="
                                $v.lastformData.ligne_defense_avocat.$error &&
                                !$v.lastformData.ligne_defense_avocat.required
                              "
                            >
                              Ce champs est requis!
                            </div>
                          </div>
                        </div>
                      </div>

                    

                      <div class="row">
                        <div class="col-md-6">
                          <div
                            class="form-group"
                            v-bind:class="{
                              'has-error': $v.lastformData.decision.$error,
                            }"
                          >
                            <label class="form-label semibold" for="decision"
                              >Décision <b style="color: red">*</b>
                            </label>
                            <textarea
                              class="form-control"
                              v-model.trim="lastformData.decision"
                              name=""
                              id=""
                              cols="120"
                              rows="4"
                            ></textarea>
                            <div
                              style="color: red"
                              v-if="
                                $v.lastformData.decision.$error &&
                                !$v.lastformData.decision.required
                              "
                            >
                              Ce champs est requis!
                            </div>
                          </div>
                        </div>
                        <br /><br />

                        <div class="col-md-3">
                          <div
                            class="form-group"
                            v-bind:class="{
                              'has-error': $v.lastformData.date_decision.$error,
                            }"
                          >
                            <label
                              class="form-label semibold"
                              for="date_decision"
                              >Date de la décision
                              <b style="color: red">*</b></label
                            >
                            <input
                              type="date"
                              class="form-control"
                              v-model.trim="lastformData.date_decision"
                              @input="$v.lastformData.date_decision.$touch()"
                              placeholder=" "
                            />
                            <div
                              style="color: red"
                              v-if="
                                $v.lastformData.date_decision.$error &&
                                !$v.lastformData.date_decision.required
                              "
                            >
                              Ce champs est requis!
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                       
                      </div>
                    </tab-content>

                    <tab-content title="Peine">
                      <div class="table-responsive">
                        <table
                          class="table m-b-0"
                          v-if="partie_requerante.length"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nom</th>
                              <th>Prénom(s)</th>
                              <th>Contact</th>

                              <th style="width: 200px">Décision / peine</th>
                              <th>Personne chargée du suivi de la décision / peine</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in partie_requerante"
                              :key="'=' + index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  disabled
                                  type="text"
                                  v-model="item.nom"
                                  name="nom"
                                  class="form-control"
                                  id="nom"
                                  placeholder="Nom "
                                />
                              </td>
                              <td>
                                <input
                                  disabled
                                  type="text"
                                  v-model="item.prenom"
                                  name="nom"
                                  class="form-control"
                                  id="nom"
                                  placeholder="Nom "
                                />
                              </td>
                              <td>
                                <input
                                  disabled
                                  type="text"
                                  v-model="item.contact"
                                  name="nom"
                                  class="form-control"
                                  id="nom"
                                  placeholder="Nom "
                                />
                              </td>

                              <td>
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    v-model.trim="item.decision_peine"
                                  >
                                    <option></option>
                                    <option value="1">Condamné</option>
                                    <option value="2">
                                      Condamné avec sursis
                                    </option>
                                    <option value="3">Acquitté relaxé</option>
                                  </select>
                                </div>
                              </td>

                              <td>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model.trim="item.pers_charger_peine"
                                    placeholder=" "
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </tab-content>


        

        <template slot="footer" scope="props">
          <div class="wizard-footer-left">
            <wizard-button
              v-if="props.activeTabIndex > 0 && !props.isLastStep"
              :style="props.fillButtonStyle"
              @click.native="props.prevTab()"
              >Précédent</wizard-button
            >
          </div>
          <div class="wizard-footer-right">
            <wizard-button
              v-if="!props.isLastStep"
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              >Suivant</wizard-button
            >

            <wizard-button
              v-else
              @click.native="enregistrement()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              >{{ props.isLastStep ? "Enregistrer" : "Suivant" }}</wizard-button
            >
          </div>
        </template>
      </form-wizard>

            
        
    

        </div>

        </div>
          </div>
		</div>
		




    
		


<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->


    <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5"> Continuer </v-card-title>
              <v-card-text>Voulez-vous enregistrer la peine ?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="nePasContinueAnalyse()"
                >
                  Non
                </v-btn>
                <v-btn color="green darken-1" text @click="continueAnalyse()">
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

		</div>
    </template>

    <!-- //****** fin gestion des dossiers maison de justice */ -->
{{nouveaunumero}}
    </v-main>
    </v-app>
</template>



<script>
import ThemifyIcon from "vue-themify-icons";
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect,
     Multiselect,
     FormWizard,
    TabContent,
    ThemifyIcon,
    },
    props: {
    source: String,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nextButtonText: {
      type: String,
      default: "Suivant",
    },
    backButtonText: {
      type: String,
      default: "Précedent",
    },
    finishButtonText: {
      type: String,
      default: "Enregistrer",
    },

    validateOnBack: Boolean,

    color: {
      type: String,
      default: "black",
    },

    errorColor: {
      type: String,
      default: "#8b0000",
    },

    shape: {
      type: String,
      default: "circle",
    },

    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },

    startIndex: {
      type: Number,
      default: 0,
    },

    title1: {
    type: String,
    default: ''
  },
  /***
   * Icon name for the upper circle corresponding to the tab
   * Supports themify icons only for now.
   */
  icon: {
    type: String,
    default: ''
  },
  /***
   * Only render the content when the tab is active
   */
  lazy: {
    type: Boolean,
    default: false
  },
  /***
   * Function to execute before tab switch. Return value must be boolean
   * If the return result is false, tab switch is restricted
   */
  beforeChange: {
    type: Function
  }
  },
        data() {
            return {
               dialog:false,
               test:true,
               datatrue:false,
               numero_requette:'',
   //       lastformData:"",
               datashow_renvoi:0,
               infractionvalue:[],
               article_code_penalvalue:[],

                 lastformData: {
        decision: "",
        ligne_defense_avocat: "",
        requisition_procurreur: "",
        nbre_audience: "",
        date_decision: "",
        //  decision_peine:"",
        //  pers_charger_peine:"",
      },
               numrequete:"",
               renvoiformData:{
                 date:"",
                 motif:"",
                // dossier_clinique_id:""
               },
               formData:{
                   numrequete_appel:'',
                   dateconstitution:"",
                   dossier_clinique_id:"",
                   acteur_juridique_id:"",
                   fiche_entretien_id:"",
                   strucuturecharger:"",
                   personneCharger:"",
                   user_id:"",
                   structure_id:''
                 
               },

              data_clinique:{
                ministere_public:"",
                president:"",
                numeroParquet:"",
                date_mandatdepot:"",
                nbre_personne_implique:"",
                typedossier:"",
                juridictioncompetente:"",
                niveau_audience:"",
                date_audience:''
              },

              PartieCivileformData:{
                nom:"",
                prenom:"",
                telephone:"",
               // dossier_clinique_id:""
              },
               partie_requerante:[],
               datapartiecivile:[],
               datarenvoi:[],
               partie:{
                   naturecompromis:"",
                   datesignature:"",
                   suivicompromis:"",
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,
      decisions_peine:[
          {id:'1',libelle:"Condamné"},
          {id:'2',libelle:"Condamné avec sursis"},
          {id:'3',libelle:"Acquitté relaxé"},
          {id:'4',libelle:"Pas de décision"}
          ],

          listtypedossier:[
          {libelle:"Criminel"},
          {libelle:"Correctionnel"}
          ],
         
          listeniveau_audience:[
            {id:1,libelle:"Première instance"},
            {id:1,libelle:"Appel"}
          ],


          situationfamiliale:[
          {id:1,libelle:"Marié(e) civilement"},
          {id:2,libelle:"Marié(e) traditionnellement"},
          {id:3,libelle:"Veuf/ veuve"},
          {id:4,libelle:"Divorcé(e)"},
          {id:5,libelle:"Père/mère de "},
          {id:6,libelle:"Nombre d'épouses : "}
          ],

      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },

            }
        },
        created(){
          
        },

      
        computed:{
            ...mapGetters('Application', ["getterErrorDossiercliniquejuridique",
            "getterLoadingDossiercliniquejuridique",'getterPartieCivil','getterRenvoi',
            "getterDossiercliniquejuridique",'getterAudience',
            "getterInfraction","getterAppel","getterLoadingAppel",'getterErrorAppel',
            "getterCodePenal",
            "getterPartieRequerant",
            "getterActeurJuridique",
            "getterMotifPartie",
            "getterVictimeprevenu",
            'getterModeSaisine',
            "getterFicheEntretien",
            'getterLocaliteSaisine',]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },

            return_id_motif(){
      return (id)=>{
        if(id!=null && id!=""){
          let Objet = this.getterFicheEntretien.find(item=>item.id==id);
          if(Objet){
            return Objet.motif_partie_id
          }return ""
        }
      }
    },

// this.return_libelle_motif(this.return_id_motif())=="Victime diverse"
    return_libelle_motif(){
      return (id)=>{
        if(id!=null && id!=""){
          let Objet = this.getterMotifPartie.find(item=>item.id==id);
          if(Objet){
            return Objet.libelle
          }return ""
        }
      }
    },

    decision_libelle_motif(){

          let Objet = this.partie_requerante.find(item=>item.decision_peine!='');
          if(Objet){
            return Objet.decision_peine
          }return ""

    },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },

            idStructure(){
             if(this.getterUserStore){
               return this.getterUserStore.structure_id
             }
             return ""
           },
           listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

           nouveaunumero(){
             let Objet = this.getterFicheEntretien.find(item=>item.id==this.numrequete)
             let taille =this.getterAppel.length+1
             if(Objet){
                 return this.formData.numrequete_appel =Objet.numrequete+'/A'+taille
             }
           },

            listeAnalyse1(){
             let collect=[];

             this.getterFicheEntretien.filter((item)=>{
              this.getterAudience.filter((item1)=>{
                   if(item1.fiche_entretien_id==item.id && item1.decision_peine!='' && item1.status==2){
                        collect.push(item);
                   }
               })
             });
            return collect;

           },

           listeAnalyse2(){
             let collect=[];

             this.getterDossiercliniquejuridique.filter((item)=>{
              this.getterAudience.filter((item1)=>{
                   if(item1.dossier_clinique_id==item.id ){
                        collect.push(item);
                   }
               })
              //  if(val!=undefined){
              //    collect.push(item);
              //    return item
              //  }
             });
            return collect;

           },
           
           listegetterInfraction(){
              return this.getterInfraction.filter((item)=>item.etat==0)
           },

           listegetterCodePenal(){
              return this.getterCodePenal.filter((item)=>item.etat==0)
           },

           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },

    StrucureCharger(){
      if(this.numrequete=="" || this.numrequete==undefined){
            return this.formData.strucuturecharger=""
        }else{
            let Objet= this.getterActeurJuridique.find(item=>item.id==this.edite.acteur_juridique_id)
           if(Objet){
             return this.formData.strucuturecharger = Objet.libelle;
             }return ""
        }
        
      },

      persCharger(){
        if(this.numrequete=="" || this.numrequete==undefined){
            this.formData.personneCharger=""
        }else{
          let Objet= this.getterUser.find(item=>item.id==this.edite.user_id)
           if(Objet){
             return this.formData.personneCharger = Objet.name;
             }return ""
        }
        
      },

        nombrepersonneimplique(){
          let Objet=this.getterVictimeprevenu.filter(item=>item.dossier_clinique_juridique_id==this.data_clinique.id)
         
         if(Objet){
         
           return this.data_clinique.nbre_personne_implique =this.partie_requerante.length + Objet.length;
         }
         
      },

      motifparti(){
        let Objet= this.getterMotifPartie.find(item=>item.id==this.edite.motif_partie_id)
           if(Objet){
             return  Objet.libelle;
             }return ""
      },
           
        },
        validations: {
         // infractionvalue:{required},
        //  article_code_penalvalue:{required},

      data_clinique:{
                ministere_public:'',
                president:'',
                numeroParquet:{ required },
             //   date_mandatdepot:{ required },
                nbre_personne_implique:{ required },
                typedossier:{ required },
                juridictioncompetente:{ required },
                niveau_audience:{ required },
                deroulement_audience:{ required },
                rappel_fait:{ required },
                date_audience:{ required },
              },

        
            formData: {
                   dateconstitution:{ required },
                   strucuturecharger:{ required },
            },
             renvoiformData:{
                 date:{ required },
                 motif:{ required },
                // dossier_clinique_id:""
               },

           lastformData: {
      decision: { required },
      ligne_defense_avocat: { required },
      requisition_procurreur: { required },
      //  deroulement_audience:{ required },
      //  rappel_fait:{ required },
      nbre_audience: "",
      date_decision: { required },
      // decision_peine:{ required },
      /// pers_charger_peine:{ required },
    },

                PartieCivileformData:{
                nom:{ required },
                prenom:{ required },
                telephone:{ required },
              },
            // partie:{
            //        naturecompromis:{ required },
            //        datesignature:{ required },
            //        suivicompromis:{ required },
            //    }
        },
        methods:{
             ...mapActions('Application', ['modificationPartieRequerant',
             'getTypeActeurJuridique','supprimerAppel','ajouterAppel',
             "getAudience",'modificationAppel',
             "ajouterAudience",
             "ajouterFicheEntretien",
             "getDossierCliniqueJur",
             ]),
            nouveau_rrenvoi(){
              if(this.datashow_renvoi==1){
                 return this.datashow_renvoi=0;
              }
              return this.datashow_renvoi=1;
            },

              gotoModale1(){
              
                this.$router.go(-1)
            },

            addInfraction(newTag){
            this.infractionvalue.push(newTag)
          },

          addArticle_Code_penal(newTag){
            this.article_code_penalvalue.push(newTag)
          },
  //            handleValidation: function(isValid, tabIndex){
  //     console.log('Tab: '+tabIndex+ ' valid: '+isValid)
  //  },

   Testvalidate() {
    // this.numrequete.$touch()
      this.$v.formData.$touch();
     var isValid = !this.$v.formData.$error
      //var isValid1 = !this.$v.numrequete.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba3(){
      this.$v.data_clinique.$touch();
      var isValid = !this.$v.data_clinique.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba4(){
      this.$v.lastformData.$touch();
     // console.log(this.$v.lastformData.$error)
      var isValid = !this.$v.lastformData.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba2() {
      if(this.motifparti=='Détenu Prévenu'){
          this.$v.detenuprevenuformData.$touch();
          var isValid = !this.$v.detenuprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else if(this.motifparti=='Prévenu' || this.motifparti=='Victime'){
        this.$v.victimeprevenuformData.$touch();
          var isValid = !this.$v.victimeprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else{
        return true;
      }
      
    },

    // onComplete: function () {
    //    alert("Submitting Form ! Rock On");
    //   },


    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
          
            ajouterpartiecivile(){
            this.$v.PartieCivileformData.$touch();
                 if(this.$v.PartieCivileformData.$error) {
                     return
                 }
                this.datapartiecivile.push(this.PartieCivileformData)
   this.$v.PartieCivileformData.$reset();
                this.PartieCivileformData={
                nom:"",
                prenom:"",
                telephone:"",
              }

            },

             ajouterdaterenvoi(){
            this.$v.renvoiformData.$touch();
                 if(this.$v.renvoiformData.$error) {
                     return
                 }
                this.datarenvoi.push(this.renvoiformData)
   this.$v.renvoiformData.$reset();
                this.renvoiformData={
                date:"",
                motif:"",
              }

            },

            deletePartieRequerante(item){
            if (item > -1) {
              this.datapartiecivile.splice(item, 1);
            }
            },

            deleteDatarenvoi(item){
            if (item > -1) {
              this.datarenvoi.splice(item, 1);
            }
            },

    clotueraudience() {
       this.formData.fiche_entretien_id=this.numrequete
        this.formData.dossier_clinique_id=this.data_clinique.id  
        this.formData.acteur_juridique_id=this.idActeurJuridique
        this.formData.structure_id=this.idStructure
        this.formData.user_id=this.getterUserStore.id

        let objet={
             ...this.formData,
             // ...this.lastformData,
              ...this.data_clinique,
              renvoiformData:this.datarenvoi,
              PartieCivileformData:this.datapartiecivile,
            //  Partiereq: this.partie_requerante,
                   }
        console.log("lega est dans la place");
        console.log(this.formData.acteur_juridique_id);
         this.ajouterAppel(objet)
         this.$router.push({name:"ListedesAppels"})
    },
        enregistrement(){
          
                  this.formData.fiche_entretien_id=this.numrequete
                  this.formData.dossier_clinique_id=this.data_clinique.id  
                  this.formData.acteur_juridique_id=this.idActeurJuridique
                  this.formData.structure_id=this.idStructure
                  this.formData.user_id=this.getterUserStore.id

                   // console.log(this.idActeurJuridique);
                  //this.renvoiformData.dossier_clinique_id=this.data_clinique.id
                //  this.PartieCivileformData.dossier_clinique_id=this.data_clinique.id
                console.log(this.formData)

                      let objet={
                   ...this.formData,
                    ...this.lastformData,
                   ...this.data_clinique,
                   renvoiformData:this.datarenvoi,
                    PartieCivileformData:this.datapartiecivile,
                     Partiereq: this.partie_requerante,
                     date_decision: this.lastformData.date_decision,
                    decision: this.lastformData.decision,
                //   ...this.instructionformData,
                  // tableau_infraction:this.infractionvalue,
                  // tableau_article_code:this.article_code_penalvalue
                   }
                    console.log("lega est dans la place");
        console.log(this.formData.acteur_juridique_id);
                 // console.log(objet)
                  this.ajouterAppel(objet).then((res) => {
                     let ob = res.data;
      //  console.log(ob)
        console.log(this.decision_libelle_motif)
        // if (ob.decision_peine == 1 || ob.decision_peine == 2) {
        //   this.dialog = true;
        //   this.numero_requette = ob.dossier_clinique_id; Victime diverse
        // }

       // console.log(this.return_libelle_motif(this.return_id_motif(ob.fiche_entretien_id)))
       
      if(this.return_libelle_motif(this.return_id_motif(ob.fiche_entretien_id))=="Victime diverse"){
         this.dialog = false;
         this.$router.push({name:"ListedesAppels"})
      }else if(this.return_libelle_motif(this.return_id_motif(ob.fiche_entretien_id))!="Victime diverse"){
        // if (ob.decision != "" || ob.decision_peine == 1) {
         if (this.decision_libelle_motif == 1 || this.decision_libelle_motif == 2) {
          this.dialog = true;
          this.numero_requette = ob.dossier_clinique_id;
        }else{
          this.dialog = false;
          this.$router.push({name:"ListedesAppels"})
        }
      }

                  })
              // this.$router.go(-1)
              
            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},


continueAnalyse() {
      this.$router.push({
        name: "PeinePEIN",
        params: { id: this.numero_requette,id1:1 },
      });
    },
    nePasContinueAnalyse() {
      this.dialog = false;
      this.$router.go(-1);
    },
      // editItem (item) {
      //  window.$("#myModal").modal({
      //   backdrop: "static",
      //   keyboard: false,
      // });
      // //this.datatrue=true

      //  this.PatiRequerantformData=this.getterPartieRequerant.find(element=>element.id==item)
      // },

      detailItem (item) {
       this.$router.push({ name: 'DetailDossierCliniqueJuridique', params: { id: item } })
      },

      formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

      

    

      
        },
           watch: {
        
         
             numrequete: function (value) {
      console.log(value);
      let vm=this
      vm.partie_requerante=[]
        if(value!=""){
    this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.fiche_analyse_cj_id==value)
    this.formData.dateconstitution=this.data_clinique.dateconstitution
    this.data_clinique.niveau_audience="Appel"
    this.edite=this.getterFicheEntretien.find(item=>item.id==value && item.status!=0)
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        }
          
   
    },
       
      },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        color: black;
    }
    .finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
