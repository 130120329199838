<template>
    <nav class="side-menu-addl" >
        <header class="side-menu-addl-title">
            <div class="caption">Accueil</div>
            <i class="font-icon font-icon-burger"></i>
        </header>
        <ul class="side-menu-addl-list">
            <li class="header">
                <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Tableau bord 1</span>
	                </span>
                </a>
            </li>
            <li>
                <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Tableau bord 2</span>
	                </span>
                </a>
            </li>
            <li>
                <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Tableau bord 3</span>
	                </span>
                </a>
            </li>
            <li>
                <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Tableau bord 4</span>
	                </span>
                </a>
            </li>
            <li>
                <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Tableau bord 5</span>
	                </span>
                </a>
            </li>

        </ul>
    </nav>
</template>

<script>
    export default {
        name: "NavAccueil"
    }
</script>

<style scoped>

</style>
