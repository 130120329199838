<template>
  <div id="content" class="content">
    <Loader :isVisible="loading" :isText="'Chargement en cours...'" />

    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
      <li class="breadcrumb-item active">Tableau de bord</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">
     Tableau de bord du traitement des requêtes par les Maisons de justice
    </h1>
    <!-- end page-header -->

    <!-- end row -->
    <!-- begin row -->


     <div class="row">

            

              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de début
                  </label>
                  <input  type="date" v-model="date_debut"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>

               <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de fin
                  </label>
                  <input  type="date" v-model="date_fin"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>


              

              <div class="col-md-3">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
                  <button @click.prevent="resetdata" class="btn btn-primary">Tous  </button>
                </div>
              </div>

               <div class="col-md-1">
                <div class="form-group" style="margin-top:-7px">
                 
                </div>
              </div>


              <div class="col-md-2">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
              <button type="button"  @click="print()" class="btn btn-primary">Imprimer</button>
              </div>
              </div>

           
          </div>
    <div class="row" id="printMe">
      <!-- begin col-8 -->

      <!-- end col-8 -->
      <!-- begin col-4 -->
      <div class="col-lg-12">
        <!-- begin panel -->
        <div class="panel panel-inverse" data-sortable-id="index-6">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class=""
                data-click="panel-expand"
                ><i class=""></i
              ></a>
              <a
                href="javascript:;"
                class=""
                data-click="panel-reload"
                ><i class=""></i
              ></a>
              <a
                href="javascript:;"
                class=""
                data-click="panel-collapse"
                ><i class=""></i
              ></a>
            </div>

             <h4 v-if="date_debut!='' && date_fin!=''" style="font-size: 20px" class="panel-title">
              Phase de traitement des requêtes par les Maisons de justice du {{formatagedate(date_debut)}} au {{formatagedate(date_fin)}}</h4>

                <h4 v-else style="font-size: 20px" class="panel-title">
              Phase de traitement des requêtes par les Maisons de justice</h4>
          </div>
          <div class="panel-body p-t-0">
            <div class="table-responsive">
              <!-- <table class="table table-valign-middle table-bordered"> -->
              <table class="table table-bordered" id="customers">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>

					 <tr style="font-size: 14px">
                    <th>Total des requêtes traitées </th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ nombreDossierMaisonjustice }}</td>
                    <td style="text-align:center;font-weight:bold">{{ pourcentagetotal }}%</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th rowspan="4">Requêtes traitées par  </th>
                    <th >Accueil-écoute</th>
                    <td style="text-align:center;font-weight:bold">{{NombreDossierTraiterParEcouteAcueil}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageDossierTraiterParEcouteAcueil}} %</td>
                  </tr>
                  <tr style="font-size: 14px">
                    <th >Information-sensibilisation</th>
                    <td  style="text-align:center;font-weight:bold">{{NombreDossierTraiterParInformationSensibilisation}}</td>
                    <td  style="text-align:center;font-weight:bold">{{PourcentageDossierTraiterParInformationSensibilisation}} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th >Orientation</th>
                    <td  style="text-align:center;font-weight:bold">{{NombreDossierTraiterParorientation}}</td>
                    <td  style="text-align:center;font-weight:bold">{{PourcentageDossierTraiterParorientation}} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th >Médiation-conciliation</th> 
                    <td  style="text-align:center;font-weight:bold">{{NombreDossierTraiterParMdiationconciliation}}</td>
                    <td  style="text-align:center;font-weight:bold"> {{PourcentageDossierTraiterParMdiationconciliation}} %</td>
                  </tr>


				  <tr style="font-size: 14px">
                    <th>Délai moyen de traitement par la médiation-conciliation</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossierAvecAppel }}</td>
                    <td  style="text-align:center;font-weight:bold">{{ PourcentageDossierAvecAppel }} %</td>
                  </tr>

				  <tr style="font-size: 14px">
                    <th>Requêtes traitées par la médiation-conciliation  </th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossierTraiterParMdiationconciliation }}</td>
                    <td  style="text-align:center;font-weight:bold">{{ PourcentageDossierTraiterParMdiationconciliation }} %</td>
                  </tr>

				         <tr style="font-size: 14px">
                    <th>Requêtes ayant fait objet d’un compromis </th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossieravecCompromis }}</td>
                    <td  style="text-align:center;font-weight:bold">{{ PourcentageDossieravecCompromis }} %</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end panel -->
      </div>
      <!-- end col-4 -->
    </div>
    <!-- end row -->
  </div>
  <!-- end #content -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import moment from 'moment'
export default {
  name: "App",

  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
        },
        {
          color: "indigo",
          icon: "mdi-buffer",
        },
      ],
      date_debut: "",
      date_fin: "",
      partie_masculin: [],
      partie_feminin: [],

      series: [800, 50, 150],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          "Requêtes en attente d'analyse",
          "Requêtes en cours d'analyse",
          "Requêtes analysées",
        ],
        colors: ["#f59c1a", "#00acac", "#348fe2"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  created() {
    //console.log(this.getterFicheEntretien)
  },
  computed: {
    ...mapGetters("Application", [
      "getterTypeActeurJuridique",'getterDecisionPeine','getterDossierMaisonJustice',
      "getterErrorTypeActeurJuridiqu","getterVictimeprevenue","getterFicheEntretien",
      "getterLoadingFicheEntretien",'getterAudience','getterAppel','getterAnalyseMJ',
      "getterActeurJuridique","getterPartieCivil", "getterRenvoi","getterMotifPartie",
      "getterModeSaisine",'getterDossiercliniquejuridique','gettersCompromisaccord',
      "getterPartieRequerant",'getterFichepartieRequerant'
    ]),
    ...mapGetters("Utilisateurs", [
      "getterErrorUser",
      "getterLoadingUser",
      "getterUser",
      "getterUserStore",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingFicheEntretien;
    },

    test(){
      return this.getterDossierMaisonJustice.filter(item=>item.status==0)
    },
	// dossiertraiterParanalyseRecevabilite(){
	// 	if (this.date_debut != "" && this.date_fin != "") {
	// 		 taille= this.gettleterFicheEntretien.filter(item=>item.status==3 && item.created_at>=this.date_debut &&
    //       item.created_at <=this.date_fin)
	// 	  return taille.length
	// 	}else{
	// 		let taille= this.getterFicheEntretien.filter(item=>item.status==3)
	// 		let taille2= this.getterFicheEntretien.filter(item=>item.status==3)
	// 		let taille3= this.getterFicheEntretien.filter(item=>item.status==3)
	// 		return taille.length
	// 	}
		
	// },


	NombreDossieravecCompromis(){
		let collect=[]
			if (this.date_debut != "" && this.date_fin != "") {
			let taille= this.getterDossierMaisonJustice.filter(item=>item.status==0 && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		  taille.filter(item=>{
			  this.gettersCompromisaccord.filter(item1=>{
				  if(item.id==item1.dossier_maisons_justice_id)
				  collect.push(item)
			  })
		  })
		  if(collect.length >0){
			  return collect.length
		  }return 0
		}else{
			let taille= this.getterDossierMaisonJustice.filter(item=>item.status==0)

			 taille.filter(item=>{
			  this.gettersCompromisaccord.filter(item1=>{
				  if(item.id==item1.dossier_maisons_justice_id)
				  collect.push(item)
			  })
		  })

		  if(collect.length >0){
			  return collect.length
		  }return 0
		  
		
		}
	},


	PourcentageDossieravecCompromis() {
     
          let Objet= this.NombreDossieravecCompromis
          let somme =this.getterFicheEntretien.length
          if(Objet){
           return ((Objet /somme)*100).toFixed(2)
          }return 0
       
    },


	dossiertraiterParanalyseRecevabilite(){
		
			let taille= this.NombreDossierTraiterParEcouteAcueil
			let taille2= this.NombreDossierTraiterParInformationSensibilisation
			let taille3= this.NombreDossierTraiterParorientation
     let taille4 =this.NombreDossierTraiterParMdiationconciliation
			return (taille+taille2+taille3+taille4)
	},

	dossiertraiter(){
		if (this.date_debut != "" && this.date_fin != "") {
			// let taille= this.getterDossierMaisonJustice.filter(item=>item.status==0 && item.created_at>=this.date_debut &&
      //     item.created_at <=this.date_fin)
          let taille1= this.getterFicheEntretien.filter(item=>item.status==3 && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		  return (taille1.length)
		}else{
		//	let taille= this.getterDossierMaisonJustice.filter(item=>item.status==0)
        let taille1= this.getterFicheEntretien.filter(item=>item.status==3)
			return (taille1.length)
		}
		
	},

    nombreDossierMaisonjustice() {
      
      return  this.dossiertraiterParanalyseRecevabilite
    },

	 pourcentagetotal() {
      if(this.nombreDossierMaisonjustice==0){
        return 0.00
      }else{
       // let taille= this.getterDossierMaisonJustice
		//let taille1= this.getterFicheEntretien.filter(item=>item.status==3 && item.recevabilite==null)
		let somme =this.getterFicheEntretien.length
        return ((this.nombreDossierMaisonjustice / somme) * 100).toFixed(2)
		//return somme
      } 
    },

	NombreDossierTraiterParEcouteAcueil(){
		let collect=[]
		if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterAnalyseMJ.filter(item=>item.methode_analyse=='Accueil/Ecoute' &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		let taille= this.getterFicheEntretien.filter(item=>item.status==3 &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}else{
		let data1 = this.getterAnalyseMJ.filter(item=>item.methode_analyse=='Accueil/Ecoute')
		let taille= this.getterFicheEntretien.filter(item=>item.status==3)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}
	},

	PourcentageDossierTraiterParEcouteAcueil() {
     
          let Objet= this.NombreDossierTraiterParEcouteAcueil
          	let somme =this.getterFicheEntretien.length
          if(Objet){
           return ((Objet /somme)*100).toFixed(2)
          }return 0
       
    },


	NombreDossierTraiterParInformationSensibilisation(){
		let collect=[]
		if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterAnalyseMJ.filter(item=>item.methode_analyse=='Information/Sensibilisation' &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		let taille= this.getterFicheEntretien.filter(item=>item.status==3 &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}else{
		let data1 = this.getterAnalyseMJ.filter(item=>item.methode_analyse=='Information/Sensibilisation')
		let taille= this.getterFicheEntretien.filter(item=>item.status==3)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}
	},

	 PourcentageDossierTraiterParInformationSensibilisation() {
     
          let Objet= this.NombreDossierTraiterParInformationSensibilisation
          let somme =this.getterFicheEntretien.length
          if(Objet){
           return ((Objet /somme)*100).toFixed(2)
          }return 0
       
    },


		NombreDossierTraiterParorientation(){
		let collect=[]
		if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterAnalyseMJ.filter(item=>item.methode_analyse=='Orientation' &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		let taille= this.getterFicheEntretien.filter(item=>item.status==3 &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}else{
		let data1 = this.getterAnalyseMJ.filter(item=>item.methode_analyse=='Orientation')
		let taille= this.getterFicheEntretien.filter(item=>item.status==3)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}
	},

	 PourcentageDossierTraiterParorientation() {
     
          let Objet= this.NombreDossierTraiterParorientation
          let somme =this.getterFicheEntretien.length
          if(Objet){
           return ((Objet /somme)*100).toFixed(2)
          }return 0
       
    },


	NombreDossierTraiterParMdiationconciliation(){
		let collect=[]
		if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterAnalyseMJ.filter((item)=>item.methode_analyse=='mediation/conciliation' &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		let taille= this.getterFicheEntretien.filter(item=>item.status==3 &&  item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}else{
			let data1 = this.getterAnalyseMJ.filter((item)=>item.methode_analyse=='mediation/conciliation')
		let taille= this.getterFicheEntretien.filter(item=>item.status==3)
		taille.filter(item=>{
			data1.filter(item1=>{
				if(item.id==item1.fiche_entretien_id)
				collect.push(item)
			})
		})
		return collect.length
		}
	},

	 PourcentageDossierTraiterParMdiationconciliation() {
          let Objet= this.NombreDossierTraiterParMdiationconciliation
           let somme =this.getterFicheEntretien.length
          if(Objet){
           return ((Objet /somme)*100).toFixed(2)
          }return 0
       
    },

	 PourcentageDossieravecTraiter() {
     
          let Objet= this.NombreDossieravecTraiter
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },


    NombreModeSaisine() {
      if (this.date_debut != "" && this.date_fin != "") {
         return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.mode_saisine_id==id && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }else{
        return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.mode_saisine_id==id)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }
      
    },

    PourcentageModeSaisine() {
      return id=>{
        if(id!="" && id!=null){
          let Objet= this.NombreModeSaisine(id)
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
        }return "0"

      }
    },

   

   


    codeUser() {
      if (!this.getterUserStore) return 0;
      return this.getterUserStore.status_acteur_juridique;
    },
    idActeurJuridique() {
      if (this.getterUserStore) {
        return this.getterUserStore.acteur_juridique_id;
      }
      return "";
    },

    idStructure() {
      if (this.getterUserStore) {
        return this.getterUserStore.structure_id;
      }
      return "";
    },

    listeActeurJuriduqe() {
      if (this.idStructure) {
        return this.getterActeurJuridique.filter(
          (item) => item.structure_id == this.getterUserStore.structure_id
        );
      }
      return [];
    },
    nombreTotalRequete() {
      return this.getterFicheEntretien.length;
    },
    nombrePasStatus() {
      return (status) => {
        /* let prixMaison=1000
				 prixMaison.toLocaleString()*/

        return this.getterFicheEntretien.filter((item) => item.status == status)
          .length;
      };
    },
    nombrePasStatusActeur() {
      return (status, acteur) => {
        return this.getterFicheEntretien.filter((item) => {
          if (item.status == status && item.acteur_juridique_id == acteur) {
            return item;
          }
        }).length;
      };
    },
    nombreTraite() {
      return (
        parseFloat(this.nombrePasStatus(2)) +
        parseFloat(this.nombrePasStatus(3))
      );
    },
    pourcentageTraite() {
      let taux = (this.nombreTraite * 100) / this.nombreTotalRequete;
      return taux.toFixed(2);
    },
    pourcentageAUtre() {
      return (status) => {
        let taux =
          (this.nombrePasStatus(status) * 100) / this.nombreTotalRequete;
        return taux.toFixed(2);
      };
    },
    getStatus() {
      return (status) => {
        if (status == 0) return "EN ATTENTES";
        if (status == 1) return "EN-COURS";
        if (status == 2) return "ACCEPTER";
        return "REJETTER";
      };
    },
  },

  methods: {
    resetdata(){
      this.date_debut=""
      this.date_fin=""
      return 1
    },

    formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },
    async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
  },
};
</script>


<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
 
  
}

#customers td,
#customers th {
  border: 1px solid #ddd;
 
}



</style>
