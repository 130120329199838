<template>
  <div>
    <div id="header" class="header navbar-default">
      <!-- begin navbar-header -->
      <div class="navbar-header">
        <a href="" class="navbar-brand"
          ><span class="navbar-logo"></span> <b>GESTICE {{ information }}</b></a
        >
        <button
          type="button"
          class="navbar-toggle"
          data-click="sidebar-toggled"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- end navbar-header -->

      <!-- begin header-nav -->
      <ul class="navbar-nav navbar-right">
        <li class="dropdown navbar-user">
          <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown">
            <img :src="imageUsers" alt="" />
            <span class="d-none d-md-inline"> {{ nameUser }}</span>
            <b class="caret"></b>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
           
            <div class="dropdown-divider"></div>
            <a href="#"  @click.prevent="CallProfile('ProfileUser')" class="dropdown-item"
              >Mon profil</a
            >

             <a href="#" @click="logoutUser()" class="dropdown-item"
              >Déconnexion</a
            >
          </div>
        </li>
      </ul>
      <!-- end header navigation right -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "TheHeader",
  data() {
    return {
      menu_active: 1,
      urlBase: process.env.VUE_APP_BASE,
    };
  },
  created() {
    this.getProfileUsers();
    this.getUsers();
    this.getStructureGeoGraphique();
    this.getTypeAffaire();
    this.getLocaliteSaisine();
    this.getPartieRequerant();
    this.getElementDossier();
    this.getTypePersonneTribunal();
    this.getTypeActeurJuridique();
    this.getNatureDossier();
    this.getMotifPartie();
    this.getModeSaisine();
    this.getCriteIndigence();
    this.getCriteVictimologie();
    this.getLocalisationGeographique();
    this.getActeurJuridique();
    this.getFicheEntretien();
    this.getAnalyseCF();
    this.getAnalyseMJ();
    this.getCodePenal();
    this.getInfraction();
    this.getDossierCliniqueJur();
    this.getVictimePrevenu();
    this.getDossierMaisonJustice();
    this.getCompromisaccord();
    this.getAudience();
    this.getStructure();
    this.getListeDateEntretien();
    this.getListeRenvoi();
    this.getListPartieCivil();
    this.getPeine();
    this.getRenvoi();
    this.getAppel();
    this.getListDecisionPeine();
    this.getSituationFamille();
    this.getNationalitePays();
    this.getFichePartieRequerant();
  },
  computed: {
    ...mapGetters("Utilisateurs", ["getterUserStore"]),
    information() {
      if (this.getterUserStore) {
        if (this.getterUserStore.structure) {
          return "- " + this.getterUserStore.structure.libelle;
        }
        return "- Console administration";
      }
      return "";
    },

    nameUser() {
      if (!this.getterUserStore) return "JUSTICE";

      let objet = this.getterUserStore;
      return objet.name;
    },

    imageUsers() {
      if (this.getterUserStore.profile_photo_path) {
        let id_user = this.getterUserStore.id;
        return (
          this.urlBase +
          "/users/" +
          id_user +
          "/" +
          this.getterUserStore.profile_photo_path
        );
      }
      return this.urlBase + "/users/default/1.jpg";
    },
  },
  methods: {
    ...mapActions("Application", [
      "getRenvoi",'getAppel',
      "getStructureGeoGraphique",
      "getElementDossier",
      "getTypePersonneTribunal",
      "getTypeActeurJuridique",
      "getNatureDossier",
      "getMotifPartie",
      "getModeSaisine",
      "getCriteIndigence",
      "getLocalisationGeographique",
      "getCriteVictimologie",
      "getActeurJuridique",
      "getFicheEntretien",
      "getLocaliteSaisine",
      "getAnalyseCF",'getNationalitePays',
      "getTypeAffaire",'getListDecisionPeine',
      "getAnalyseMJ",'getSituationFamille',
      "getPartieRequerant",
      "getCodePenal",
      "getInfraction",
      "getDossierCliniqueJur",
      "getVictimePrevenu",
      "getDossierMaisonJustice",
      "getCompromisaccord",'getFichePartieRequerant',
      "getAudience",'getListeRenvoi',"getListPartieCivil",
      "getStructure",'getListeDateEntretien',"getPeine"
    ]),
    ...mapActions("Utilisateurs", [
      "logoutUser",
      "getProfileUsers",
      "getUsers",
    ]),
    activedOptionMenu(value, route_name) {
      this.menu_active = value;
      this.$router.push({
        name: route_name,
      });
    },

    CallProfile(route_nam1){
      this.$router.push({
        name: route_nam1,
      });
      
    },


  },
};
</script>

<style scoped>
</style>
