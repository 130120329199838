<template>
  <div id="content" class="content">
    <Loader :isVisible="loading" :isText="'Chargement en cours...'" />

    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
      <li class="breadcrumb-item active">Tableau de bord</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">
      <input  type="hidden" :value="getterAudience"
                  />
      Tableau de bord du traitement des requêtes par les Cliniques juridiques
    </h1>
    <!-- end page-header -->

    <!-- end row -->
    <!-- begin row -->


     <div class="row">

            

              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de début
                  </label>
                  <input  type="date" v-model="date_debut"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>

               <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de fin
                  </label>
                  <input  type="date" v-model="date_fin"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>


              

              <div class="col-md-3">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
                  <button @click.prevent="resetdata" class="btn btn-primary">Tous  </button>
                </div>
              </div>

               <div class="col-md-1">
                <div class="form-group" style="margin-top:-7px">
                 
                </div>
              </div>


              <div class="col-md-2">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
              <button type="button"  @click="print()" class="btn btn-primary">Imprimer</button>
              </div>
              </div>

           
          </div>
    <div class="row" id="printMe">
      <!-- begin col-8 -->

      <!-- end col-8 -->
      <!-- begin col-4 -->
      <div class="col-lg-12">
        <!-- begin panel -->
        <div class="panel panel-inverse" data-sortable-id="index-6">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
            
                data-click="panel-expand"
                ><i class=""></i
              ></a>
              <a
                href="javascript:;"
                data-click="panel-reload"
                ><i class=""></i
              ></a>
              <a
                href="javascript:;"
                data-click="panel-collapse"
                ><i class=""></i
              ></a>
            </div>
            <h4 v-if="date_debut!='' && date_fin!=''" style="font-size: 20px" class="panel-title">
              Phase de traitement des requêtes par les Cliniques juridiques du {{formatagedate(date_debut)}} au {{formatagedate(date_fin)}}</h4>

                <h4 v-else style="font-size: 20px" class="panel-title">
              Phase de traitement des requêtes par les Cliniques juridiques</h4>
          </div>
          <div class="panel-body p-t-0">
            <div class="table-responsive">
              <table class="table table-valign-middle table-bordered" id="customers">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>

					 <tr style="font-size: 14px">
                    <th>Total</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ nombreDossierclinique }}</td>
                    <td style="text-align:center;font-weight:bold">{{ pourcentagetotal }} %</td>
                  </tr>


                  <tr style="font-size: 14px">
                    <th>Requêtes avec RP connu</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossieravecRP }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageDossieravecRP }} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th>Requêtes avec présence de la partie civile</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ recuperationPartieCivil }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageDossierPartieCivil }} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th>Nombre moyen de renvois par requête</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ (NombreMoyenRenvoi/2).toFixed(0) }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageDossierRenvois }} %</td>
                  </tr>

				    <tr style="font-size: 14px">
                    <th>Total des requêtes traitées</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossieravecTraiter }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageDossieravecTraiter }} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th>Délai moyen de traitement de la requête par la justice </th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ dealiMoyenTraitement }} (jours)</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentagedealiMoyenTraitement }} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th>Requête ayant fait l’objet d’une décision de justice </th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossieravecDeciosnJustice }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageDossieravecDeciosnJustice }} %</td>
                  </tr>

				    <tr style="font-size: 14px">
                    <th>Requête ayant fait l’objet d’un appel </th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ NombreDossierAvecAppel }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageDossierAvecAppel }} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th rowspan="3" style="font-weight:bold">Type de décision de justice</th>
                    <th >Acquitté/ Relaxé</th>
                    <td style="text-align:center;font-weight:bold">{{NombreDossieracquitterRelaxer}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageDossieracquitterRelaxer}} %</td>
                  </tr>
                  <tr style="font-size: 14px">
                    <th >Condamné</th>
                    <td style="text-align:center;font-weight:bold">{{NombreDossierDecisioncondamner}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageDossierDecisioncondamner}} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th >Condamné avec sursis</th> 
                    <td style="text-align:center;font-weight:bold">{{NombreDossierConmdaneravecSurcis}}</td>
                    <td style="text-align:center;font-weight:bold"> {{PourcentageConmdaneravecSurcis}} %</td>
                  </tr>



                   <tr style="font-size: 14px">
                    <th rowspan="4" style="font-weight:bold">Types de requêtes traitées </th>
                    <th >Criminelles </th>
                    <td style="text-align:center;font-weight:bold">{{ NombreRequeteCriminel }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageRequeteCriminel }} %</td>
                  </tr>
                  <tr style="font-size: 14px">
                    <th >Correctionnelles </th>
                    <td style="text-align:center;font-weight:bold">{{NombreRequeteCorrectionel}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageRequeteCorrectionel}} %</td>
                  </tr>

                   <tr style="font-size: 14px">
                    <th >Civiles  </th>
                    <td style="text-align:center;font-weight:bold">{{NombreRequeteCivil }}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageRequeteCivil}} %</td>
                  </tr>

				   <tr style="font-size: 14px">
                    <th >Autres </th> 
                    <td style="text-align:center;font-weight:bold">{{NombreRequeteAutre}}</td>
                    <td style="text-align:center;font-weight:bold"> {{PourcentageRequeteAutre}} %</td>
                  </tr>


                 

                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end panel -->
      </div>
      <!-- end col-4 -->
    </div>
    <!-- end row -->
  </div>
  <!-- end #content -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import moment from 'moment'
export default {
  name: "App",

  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
        },
        {
          color: "indigo",
          icon: "mdi-buffer",
        },
      ],
      date_debut: "",
      date_fin: "",
      partie_masculin: [],
      partie_feminin: [],

      series: [800, 50, 150],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          "Requêtes en attente d'analyse",
          "Requêtes en cours d'analyse",
          "Requêtes analysées",
        ],
        colors: ["#f59c1a", "#00acac", "#348fe2"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  created() {
    //console.log(this.getterFicheEntretien)
  },
  computed: {
    ...mapGetters("Application", [
      "getterTypeActeurJuridique",'getterDecisionPeine','getterAnalyseCJ',
      "getterErrorTypeActeurJuridiqu","getterVictimeprevenue","getterFicheEntretien",
      "getterLoadingFicheEntretien",'getterAudience','getterAppel',
      "getterActeurJuridique","getterPartieCivil", "getterRenvoi","getterMotifPartie",
      "getterModeSaisine",'getterDossiercliniquejuridique',
      "getterPartieRequerant",
    ]),
    ...mapGetters("Utilisateurs", [
      "getterErrorUser",
      "getterLoadingUser",
      "getterUser",
      "getterUserStore",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingFicheEntretien;
    },
    //  PourcentageDossieravecRP() {
     
    //       let Objet= this.NombreDossieravecRP
    //       if(Objet){
    //        return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
    //       }return 0
       
    // },
    nombreDossierclinique() {
       if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }
      return this.getterDossiercliniquejuridique.length;
    },

	 pourcentagetotal() {
      if(this.nombreDossierclinique==0){
        return 0.00
      }else{
        //let taille =this.getterDossiercliniquejuridique.length
        return ((this.nombreDossierclinique / this.nombreDossierclinique) * 100).toFixed(2)
      } 
    },

    //delai moyen de traitement
NombredealiMoyenTraitement(){
		let collect=[]
    //let NombreJour=0
		 if (this.date_debut != "" && this.date_fin != "") {
       let data2 = this.getterAudience.filter((item)=> item.date_decision !=null && item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			let data1 = this.getterDossiercliniquejuridique.filter((item)=> item.dateparution !=null && item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 data2.filter(item1=>{ 
					 if(item.id==item1.dossier_clinique_id){
             collect.push(item)
           }
				 })
			 })
			 return collect.length
		
		 }else {
        let data2 = this.getterAudience.filter((item)=> item.date_decision !=null)
			let data1 = this.getterDossiercliniquejuridique.filter((item)=> item.dateparution !=null)
			 data1.filter(item=>{
				 data2.filter(item1=>{ 
					 if(item.id==item1.dossier_clinique_id){
             collect.push(item)
           }
				 })
			 })

		  return collect.length
		 }
		
	},

   PourcentagedealiMoyenTraitement() {
     
          let Objet= this.NombredealiMoyenTraitement
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },


  dealiMoyenTraitement(){
		//let collect=[]
    let NombreJour=0
		 if (this.date_debut != "" && this.date_fin != "") {
       let data2 = this.getterAudience.filter((item)=> item.date_decision !=null && item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			let data1 = this.getterDossiercliniquejuridique.filter((item)=> item.dateparution !=null && item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 data2.filter(item1=>{ 
					 if(item.id==item1.dossier_clinique_id){
              NombreJour = this.calcalEntre2Date(item.dateparution,item1.date_decision) +NombreJour
           }
				 })
			 })
			 return NombreJour
		
		 }else {
        let data2 = this.getterAudience.filter((item)=> item.date_decision !=null)
			let data1 = this.getterDossiercliniquejuridique.filter((item)=> item.dateparution !=null)
			 data1.filter(item=>{
				 data2.filter(item1=>{ 
					 if(item.id==item1.dossier_clinique_id){
              NombreJour = this.calcalEntre2Date(item.dateparution,item1.date_decision) +NombreJour
           }
				 })
			 })

		  return NombreJour
		 }
		
	},

  testdata(){
     
      let date1=new Date(this.date_debut)
     let date2= new Date(this.date_fin)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
     
  },

  calcalEntre2Date(){
     return (d1,d2)=>{
      let date1=new Date(d1)
     let date2= new Date(d2)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
     }
  },
    //fin delai moyen

	NombreDossieravecRP(){
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.numeroParquet!="" && item.numeroParquet!=null
		  && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.numeroParquet!="" && item.numeroParquet!=null)
		   return Ob.length
	   }
       
	},

	 PourcentageDossieravecRP() {
     
          let Objet= this.NombreDossieravecRP
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },

	recuperationPartieCivil(){
		let collect=[]
		 if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterDossiercliniquejuridique.filter(item=>  item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 this.getterPartieCivil.filter(item1=>{
					 if(item.id==item1.dossier_clinique_id)
			  			collect.push(item)
				 })
			 })
			 return collect.length
		
		 }else {
			 this.getterDossiercliniquejuridique.filter(item=>{
			this.getterPartieCivil.filter(item1=>{
				if(item.id==item1.dossier_clinique_id)
				collect.push(item)
			})
		})

		return collect.length
		 }
		
	},

	PourcentageDossierPartieCivil(){
		 let Objet= this.recuperationPartieCivil
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
	},


  	NombreMoyenRenvoi(){
		let collect=[]
		 if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterDossiercliniquejuridique.filter(item=>  item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 this.getterRenvoi.filter(item1=>{
					 if(item.id==item1.dossier_clinique_id)
			  			collect.push(item)
				 })
			 })
			 return collect.length
		
		 }else {
			 this.getterDossiercliniquejuridique.filter(item=>{
			this.getterRenvoi.filter(item1=>{
				if(item.id==item1.dossier_clinique_id)
				collect.push(item)
			})
		})

		return collect.length
		 }
		
	},

  
	PourcentageDossierRenvois(){
		 let Objet= (this.NombreMoyenRenvoi/2).toFixed(0)
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
	},
//gestion des requetes corrctionnel
  NombreRequeteCriminel(){
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1
		 && item.typedossier=='Criminel' && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.status==1 && item.typedossier=='Criminel')
		   return Ob.length
	   }
       
	},
PourcentageRequeteCriminel() {
     
          let Objet= this.NombreRequeteCriminel
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },

//gestion des requetes corrctionnel
     NombreRequeteCorrectionel(){
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1
		 && item.typedossier=='Correctionnel' && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.status==1 && item.typedossier=='Correctionnel')
		   return Ob.length
	   }
       
	},
PourcentageRequeteCorrectionel() {
     
          let Objet= this.NombreRequeteCorrectionel
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },


    //gestion des requetes civile
     NombreRequeteCivil(){
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1
		 && item.typedossier=='Civil' && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.status==1 && item.typedossier=='Civil')
		   return Ob.length
	   }
       
	},
PourcentageRequeteCivil() {
     
          let Objet= this.NombreRequeteCivil
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },


    //gestion des requetes civile
     NombreRequeteAutre(){
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1
		 && item.typedossier=='Autre' && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.status==1 && item.typedossier=='Autre')
		   return Ob.length
	   }
       
	},
PourcentageRequeteAutre() {
     
          let Objet= this.NombreRequeteAutre
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },

//gestion des requetes traitées

	NombreDossieravecTraiter(){
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1
		  && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.status==1)
		   return Ob.length
	   }
       
	},

	 PourcentageDossieravecTraiter() {
     
          let Objet= this.NombreDossieravecTraiter
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },


		NombreDossieravecDeciosnJustice(){
			let collect=[]
		 if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1
		  && item.created_at>=this.date_debut && item.created_at <=this.date_fin);
		  let Objet1 = this.getterAudience.filter((item)=>item.status==2 && item.decision_peine==1 
		  && item.decision!=null && item.decision!="")
         if(Objet){
           Objet.filter(item=>{
			   Objet1.filter(item1=>{
				   if(item.id==item1.dossier_clinique_id)
				   collect.push(item)
			   })
		   })
		   return collect.length
         }
       }else{
		   let Ob =this.getterDossiercliniquejuridique.filter(item=> item.status==1)
		    let Objet1 = this.getterAudience.filter(item=>item.status==2 && item.decision_peine==1 
		  && item.decision!=null && item.decision!="")

		   if(Ob){
           Ob.filter(item=>{
			   Objet1.filter(item1=>{
				   if(item.id==item1.dossier_clinique_id)
				   collect.push(item)
			   })
		   })
		   return collect.length
         }
		  
	   }
       
	},

	 PourcentageDossieravecDeciosnJustice() {
     
          let Objet= this.NombreDossieravecDeciosnJustice
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
       
    },


	NombreDossierAvecAppel(){
		let collect=[]
		 if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterDossiercliniquejuridique.filter(item=>  item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 this.getterAppel.filter(item1=>{
					 if(item.id==item1.dossier_clinique_id)
			  			collect.push(item)
				 })
			 })
			 return collect.length
		
		 }else {
			 this.getterDossiercliniquejuridique.filter(item=>{
			this.getterAppel.filter(item1=>{
				if(item.id==item1.dossier_clinique_id)
				collect.push(item)
			})
		})

		return collect.length
		 }
	},

	PourcentageDossierAvecAppel(){
		 let Objet= this.NombreDossierAvecAppel
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
	},
//item.status==2 && item.decision_peine==1 f8ae2777-210a-4ec2-98c8-83b1e94a7549
tst12(){
  return this.getterDecisionPeine.filter(item=>item.audience_id=='f8ae2777-210a-4ec2-98c8-83b1e94a7549')
},
	recuperationDecisioncondamner(){
	//	let collect=[]
		return this.getterAudience.filter(item=>item.decision!="" && item.decision_peine!="")
		// let Ob1 =this.getterAudience.filter(item=>item.status==2 && item.decision_peine==1 && item.decision!=null && item.decision!="")
		// this.getterAudience.filter(item=>{
		// 	Ob.filter(item1=>{
		// 		if(item.id==item1.audience_id){
    //       collect.push(item)
    //     }
				
		// 	})
		// })

		// return collect
	},
data(){
		let collect=[]
		let Ob =this.getterDecisionPeine.filter(item=>item.decision_peine==3)
		this.getterAudience.filter(item=>{
			Ob.filter(item1=>{
				if(item.id==item1.audience_id)
				collect.push(item)
			})
		})
		return collect
	},


	recuperationConmdaneravecSurcis(){
		let collect=[]
		let Ob =this.getterDecisionPeine.filter(item=>item.decision_peine==2)
		this.getterAudience.filter(item=>{
			Ob.filter(item1=>{
				if(item.id==item1.audience_id)
				collect.push(item)
			})
		})
		return collect
	},


 NombreDossierDecisioncondamner(){
		let collect=[]
		//let vm =this
		 if (this.date_debut != "" && this.date_fin != "") {
			let data1 = this.getterDossiercliniquejuridique.filter((item)=>item.status==1 &&  item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 let data = this.getterAudience.filter(item=>item.decision_peine==1)
       data1.filter(item=>{
				 data.filter(item1=>{
					 if(item.id==item1.dossier_clinique_id)
			  			collect.push(item)
				 })
			 })
			 return collect.length
		
		 }else {
       let data = this.getterAudience.filter(item=>item.decision_peine==1)
			let data1 = this.getterDossiercliniquejuridique.filter((item)=>item.status==1)

			 data1.filter(item=>{
			data.filter(item1=>{
				if(item.id==item1.dossier_clinique_id)
				collect.push(item)
			})
		})

		return collect.length
		 }
		
  },


	PourcentageDossierDecisioncondamner(){
		 let Objet= this.NombreDossierDecisioncondamner
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
	    },


	NombreDossieracquitterRelaxer(){ 
		let collect=[]
	//	let vm =this
		 if (this.date_debut != "" && this.date_fin != "") {
       let data = this.getterAudience.filter(item=>item.decision_peine==3)
			let data1 = this.getterDossiercliniquejuridique.filter(item=>  item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 data.filter(item1=>{
					 if(item.id==item1.dossier_clinique_id)
			  			collect.push(item)
				 })
			 })
			 return collect.length
		 }else {
        let data = this.getterAudience.filter(item=>item.decision_peine==3)
			let data1 = this.getterDossiercliniquejuridique.filter((item)=>item.status==1)
			 data1.filter(item=>{
			data.filter(item1=>{
				if(item.id==item1.dossier_clinique_id)
				collect.push(item)
			})
		})

		return collect.length
		 }
	},


	PourcentageDossieracquitterRelaxer(){
		 let Objet= this.NombreDossieracquitterRelaxer
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
	},


	NombreDossierConmdaneravecSurcis(){ 
		let collect=[]
		let vm =this
		 if (this.date_debut != "" && this.date_fin != "") {
       let data = this.getterAudience.filter(item=>item.decision_peine==2)
			let data1 = this.getterDossiercliniquejuridique.filter(item=> item.created_at>=this.date_debut && item.created_at <=this.date_fin)
			 data1.filter(item=>{
				 data.filter(item1=>{
					 if(item.id==item1.dossier_clinique_id)
			  			collect.push(item)
				 })
			 })
			 return collect.length
		
		 }else {
       let data = this.getterAudience.filter(item=>item.decision_peine==2)
       	let data1 = this.getterDossiercliniquejuridique.filter(item=> item.status==1)
			 data1.filter(item=>{
			data.filter(item1=>{
				if(item.id==item1.dossier_clinique_id)
				collect.push(item)
			})
		})

		return collect.length
		 }
		
	},


	PourcentageConmdaneravecSurcis(){
		 let Objet= this.NombreDossierConmdaneravecSurcis
          if(Objet){
           return ((Objet /this.nombreDossierclinique)*100).toFixed(2)
          }return 0
	}, 


    NombreModeSaisine() {
      if (this.date_debut != "" && this.date_fin != "") {
         return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.mode_saisine_id==id && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }else{
        return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.mode_saisine_id==id)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }
      
    },


    PourcentageModeSaisine() {
      return id=>{
        if(id!="" && id!=null){
          let Objet= this.NombreModeSaisine(id)
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
        }return "0"

      }
    },

   

   


    codeUser() {
      if (!this.getterUserStore) return 0;
      return this.getterUserStore.status_acteur_juridique;
    },
    idActeurJuridique() {
      if (this.getterUserStore) {
        return this.getterUserStore.acteur_juridique_id;
      }
      return "";
    },

    idStructure() {
      if (this.getterUserStore) {
        return this.getterUserStore.structure_id;
      }
      return "";
    },

    listeActeurJuriduqe() {
      if (this.idStructure) {
        return this.getterActeurJuridique.filter(
          (item) => item.structure_id == this.getterUserStore.structure_id
        );
      }
      return [];
    },
    nombreTotalRequete() {
      return this.getterFicheEntretien.length;
    },
    nombrePasStatus() {
      return (status) => {
        /* let prixMaison=1000
				 prixMaison.toLocaleString()*/

        return this.getterFicheEntretien.filter((item) => item.status == status)
          .length;
      };
    },
    nombrePasStatusActeur() {
      return (status, acteur) => {
        return this.getterFicheEntretien.filter((item) => {
          if (item.status == status && item.acteur_juridique_id == acteur) {
            return item;
          }
        }).length;
      };
    },
    nombreTraite() {
      return (
        parseFloat(this.nombrePasStatus(2)) +
        parseFloat(this.nombrePasStatus(3))
      );
    },
    pourcentageTraite() {
      let taux = (this.nombreTraite * 100) / this.nombreTotalRequete;
      return taux.toFixed(2);
    },
    pourcentageAUtre() {
      return (status) => {
        let taux =
          (this.nombrePasStatus(status) * 100) / this.nombreTotalRequete;
        return taux.toFixed(2);
      };
    },
    getStatus() {
      return (status) => {
        if (status == 0) return "EN ATTENTES";
        if (status == 1) return "EN-COURS";
        if (status == 2) return "ACCEPTER";
        return "REJETTER";
      };
    },
  },

  methods: {
    resetdata(){
      this.date_debut=""
      this.date_fin=""
      return 1
    },
    formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },
    async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
  },
};
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
 
  
}

#customers td,
#customers th {
  border: 1px solid #ddd;
 
}

#customers tr:nth-child(even) {

}



#customers th {
 

 
}
</style>
