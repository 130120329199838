
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
          <li class="breadcrumb-item active">Gestion des peines</li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h5 class="page-header">
          Gestion  des peines 
        </h5>
        <!-- end page-header -->
        <!-- begin panel -->
        <div class="panel panel-inverse">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-default"
                data-click="panel-expand"
                ><i class="fa fa-expand"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-success"
                data-click="panel-reload"
                ><i class="fa fa-redo"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-warning"
                data-click="panel-collapse"
                ><i class="fa fa-minus"></i
              ></a>
            </div>
            <h4 class="panel-title">Liste des peines</h4>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                <button
                  type="button"
                  @click="gotoModale()"
                  class="btn btn-inverse"
                >
                  NOUVEAU
                </button>
              </div>
            </div>

            <div>
              <div class="col-lg-12 ui-sortable">
                <!-- begin panel -->
                <div
                  class="panel panel-inverse panel-with-tabs"
                  data-sortable-id="ui-unlimited-tabs-1"
                >
                  <!-- begin panel-heading -->
                  <div class="panel-heading p-0 ui-sortable-handle">
                    <div class="panel-heading-btn m-r-10 m-t-10">
                      <a
                        href="javascript:;"
                        class="btn btn-xs btn-icon btn-circle btn-success"
                        data-click="panel-expand"
                        ><i class="fa fa-expand"></i
                      ></a>
                    </div>
                    <!-- begin nav-tabs -->
                    <div class="tab-overflow overflow-right">
                      <ul class="nav nav-tabs nav-tabs-inverse">
                        <li class="nav-item prev-button" style="">
                          <a
                            href="javascript:;"
                            data-click="prev-tab"
                            class="nav-link text-success"
                            ><i class="fa fa-arrow-left"></i
                          ></a>
                        </li>
                        <li class="nav-item">
                          <a
                            href="#nav-tab-1"
                            data-toggle="tab"
                            class="nav-link active show"
                            >Toutes les peines  ({{getterPeine.length}})</a>
                        </li>
                        <li class="nav-item">
                          <a
                            href="#nav-tab-P01"
                            data-toggle="tab"
                            class="nav-link"
                            >Peine(s) en cours ({{peineEncour.length}})</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            href="#nav-tab-P02"
                            data-toggle="tab"
                            class="nav-link"
                            >Peine(s) achevée(s) ({{peineAcheve.length}})</a
                          >
                        </li>
                       

                        <li class="nav-item next-button" style="">
                          <a
                            href="javascript:;"
                            data-click="next-tab"
                            class="nav-link text-success"
                            ><i class="fa fa-arrow-right"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <!-- end nav-tabs -->
                  </div>
                  <!-- end panel-heading -->
                  <!-- begin tab-content typeDuree -->
                  <div class="tab-content">
                    <!-- begin tab-pane -->
                    <div class="tab-pane fade active show" id="nav-tab-1">
                      <h3 class="m-t-10">Liste des peines</h3>
                      <v-data-table
                        :headers="headers"
                        :items="getterPeine"
                        sort-by="code"
                        class="elevation-1"
                      >
                       <template
                          v-slot:item.peine="{ item }"
                        >
                          {{item.peine}} {{typeDuree(item.type_peine,item.peine)}}
                        </template>
                      
                       <template
                          v-slot:item.partie_requerente_id="{ item }"
                        >
                          {{
                            partieRequerente(
                              item.partie_requerente_id
                            )
                          }}
                        </template>
                        <template
                          v-slot:item.date_debut="{ item }"
                        >
                          {{
                            formatDateFr(
                              item.date_debut
                            )
                          }}
                        </template>
                        
                         <template
                          v-slot:item.date_fin="{ item }"
                        >
                          {{
                            formatDateFr(
                              item.date_fin
                            )
                          }}
                        </template>
                         <!-- <template
                          v-slot:item.subire="{ item }"
                        >
                          {{
                            peineResteAsubir(
                              item.date_fin
                            )
                          }}
                        </template> -->
                             <template
                          v-slot:item.etat="{ item }"
                        >
                        <button type="button" class="btn btn-danger btn-lg" v-if="nombreDejourCalcule1(item.date_fin)==0">En cours</button>
                          <button type="button" class="btn btn-success btn-lg" v-if="nombreDejourCalcule1(item.date_fin)==1"> achevé</button>
                        </template>
                        
                        <template v-slot:item.actions="{ item }">
                          <div class="btn-group btn-group-justified">
                            <!--<a class="btn btn-default" @click="editItem(item)"
                              ><i class="fas fa-edit"></i
                            ></a>-->
                            <a style="color:red" class="btn btn-default" @click="deleteItem(item)"
                              ><i class="fas fa-trash-alt"></i
                            ></a>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                   



                   <div class="tab-pane fade" id="nav-tab-P01">
                      <h3 class="m-t-10">Liste des peines en cours</h3>
                      <v-data-table
                        :headers="headers"
                        :items="peineEncour"
                        sort-by="code"
                        class="elevation-1"
                      >
                       <template
                          v-slot:item.peine="{ item }"
                        >
                          {{item.peine}} {{typeDuree(item.type_peine,item.peine)}}
                        </template>
                      
                       <template
                          v-slot:item.partie_requerente_id="{ item }"
                        >
                          {{
                            partieRequerente(
                              item.partie_requerente_id
                            )
                          }}
                        </template>
                        <template
                          v-slot:item.date_debut="{ item }"
                        >
                          {{
                            formatDateFr(
                              item.date_debut
                            )
                          }}
                        </template>
                        
                         <template
                          v-slot:item.date_fin="{ item }"
                        >
                          {{
                            formatDateFr(
                              item.date_fin
                            )
                          }}
                        </template>
                         <template
                          v-slot:item.subire="{ item }"
                        >
                          {{
                            peineResteAsubir(
                              item.date_fin
                            )
                          }}
                        </template>
                          <template
                          v-slot:item.etat="{ item }"
                        >
                                            <button type="button" class="btn btn-danger btn-lg" v-if="nombreDejourCalcule1(item.date_fin)==0">En cours</button>
                          <button type="button" class="btn btn-success btn-lg" v-if="nombreDejourCalcule1(item.date_fin)==1"> achevé</button>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div class="btn-group btn-group-justified">
                            <!-- <a class="btn btn-default" @click="editItem(item)"
                              ><i class="fas fa-edit"></i
                            ></a> -->
                            <a  style="color:red" class="btn btn-default" @click="deleteItem(item)"
                              ><i class="fas fa-trash-alt"></i
                            ></a>
                          </div>
                        </template>
                      </v-data-table>
                    </div>

                      <div class="tab-pane fade" id="nav-tab-P02">
                      <h3 class="m-t-10">Liste des peines achévées</h3>
                   <v-data-table
                        :headers="headers"
                        :items="peineAcheve"
                        sort-by="code"
                        class="elevation-1"
                      >
                       <template
                          v-slot:item.peine="{ item }"
                        >
                          {{item.peine}} {{typeDuree(item.type_peine,item.peine)}}
                        </template>
                      
                       <template
                          v-slot:item.partie_requerente_id="{ item }"
                        >
                          {{
                            partieRequerente(
                              item.partie_requerente_id
                            )
                          }}
                        </template>
                        <template
                          v-slot:item.date_debut="{ item }"
                        >
                          {{
                            formatDateFr(
                              item.date_debut
                            )
                          }}
                        </template>
                        
                         <template
                          v-slot:item.date_fin="{ item }"
                        >
                          {{
                            formatDateFr(
                              item.date_fin
                            )
                          }}
                        </template>
                         <template
                          v-slot:item.subire="{ item }"
                        >
                          {{
                            peineResteAsubir(
                              item.date_fin
                            )
                          }}
                        </template>
                          <template
                          v-slot:item.etat="{ item }"
                        >
                       <button type="button" class="btn btn-danger btn-lg" v-if="nombreDejourCalcule1(item.date_fin)==0">En cours</button>
                          <button type="button" class="btn btn-success btn-lg" v-if="nombreDejourCalcule1(item.date_fin)==1"> achevé</button>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div class="btn-group btn-group-justified">
                            <!-- <a class="btn btn-default" @click="editItem(item)"
                              ><i class="fas fa-edit"></i
                            ></a> -->
                            <a class="btn btn-default" @click="deleteItem(item)"
                              ><i class="fas fa-trash-alt"></i
                            ></a>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                  
                  </div>
                  <!-- end tab-content -->
                </div>
                <!-- end panel -->
              </div>
            </div>
          </div>
        </div>

        <v-dialog max-width="800" v-model="dialog" persistent>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar dark>Formulaire d'enregistrement</v-toolbar>
              <v-card-text>
                <br />
                <div class="form-group">
                  <label class="form-label semibold">Dossier </label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.dossier_clinique_id.$error }"
                    v-model="dossier_clinique_id" placeholder="veuillez sélectionner le dossier"
                  >
                    <option></option>
                    <option
                      v-for="item in listeDesDossierAvecCondation"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                     {{recup_numero_requete(item.fiche_analyse_cj_id)}} - {{ item.numero_dossier_sys }}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.dossier_clinique_id.$error"
                  >
                    veuillez entrer le dossier
                  </div>
                </div>
    
    <div class="row">
      <div class="col-md-12" >
          <div class="form-group">
                  <label class="form-label semibold">Partie requérante </label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.partie_requerente_id.$error }"
                    v-model="formData.partie_requerente_id" placeholder="veuillez selectionner le dossier"
                  >
                    <option></option>
                    <option
                      v-for="item in listePartieRequerant"
                      :key="item.id"
                      :value="item.id"
                    >
                     {{item.nom}}   {{item.prenom}}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.partie_requerente_id.$error"
                  >
                    veuillez entrer la partie
                  </div>
                </div>
      </div>


<div class="col-md-6" v-if="decision_peine">



                <div class="form-group">
                  <label class="form-label semibold">Type de Durée  succis </label>
                  <select
                    class="form-control"
                    
                    v-model="type_peine" placeholder="veuillez selectionner le type de dure"
                  >
                    <option></option>
                    <option value="1">
                      Jour(s)
                    </option>
                    <option value="2">
                      Moi(s)
                    </option>
                    <option value="3">
                      Annee(s)
                    </option>
                  </select>

                </div>

</div>
       <div class="col-md-6" v-if="decision_peine">
         
<div class="form-group">
                  <label class="form-label semibold" for="peine">Durée du sursis</label>
                  <input
                    type="number"
                    v-model="sursis"
                    name="code"
                    class="form-control"
                    id=" peine"
                    placeholder="Durrée de sursis"
                  />
                  
                </div>

       </div>
    </div>
              

               <div class="row">
                   <div class="col-md-4">
                     


                <div class="form-group">
                  <label class="form-label semibold">Type de Durée   </label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.type_peine.$error }"
                    v-model="type_peine" placeholder="veuillez selectionner le type de dure"
                  >
                    <option></option>
                    <option value="1">
                      Jour(s)
                    </option>
                    <option value="2">
                      Moi(s)
                    </option>
                    <option value="3">
                      Annee(s)
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.type_peine.$error"
                  >
                    veuillez entrer la partie
                  </div>
                </div>

                   </div>
                   <div class="col-md-4">
                  <div class="form-group">
                  <label class="form-label semibold" for="peine">Peine</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.peine.$error }"
                    v-model="peine"
                    name="code"
                    class="form-control"
                    id=" peine"
                    placeholder="Peine"

                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.peine.$error"
                  >
                    veuillez entrer la peine
                  </div>
                </div>
                   </div>

                   <div class="col-md-4">
                  <div class="form-group">
                  <label class="form-label semibold" for="deja_puge">Déja purgée</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.deja_puge.$error }"
                    v-model="formData.deja_puge"
                    name="code"
                    class="form-control"
                    id=" deja_puge"
                    placeholder="Peine déja puge"
                    disabled
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.deja_puge.$error"
                  >
                    veuillez renseigner ce champs
                  </div>
                </div>

                   </div>
                   <div class="col-md-6">

                 <div class="form-group">
                  <label class="form-label semibold" for="date_debut">Date de debut</label>
                  <input
                    type="date"
                    :class="{ hasErroInpu: $v.formData.date_debut.$error }"
                    v-model="date_debut"
                    name="code"
                    class="form-control"
                    id="date_debut"
                    placeholder="Date"
                    disabled
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.date_debut.$error"
                  >
                    veuillez entrer la date de début
                  </div>
                </div>
                   </div>
                   <div class="col-md-6">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="date_fin">Date de fin</label>
                  <input
                    type="date"
                    :class="{ hasErroInpu: $v.formData.date_fin.$error }"
                    v-model="formData.date_fin"
                    name="code"
                    class="form-control"
                    id="date_fin"
                    placeholder="Date"
                    disabled
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.date_fin.$error"
                  >
                    veuillez entrer le date de fin
                  </div>
                </div>

                   </div>


                   <div class="col-md-4">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="personne_suivie">Nom de la personne chargée du suivi</label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.personne_suivie.$error }"
                    v-model="formData.personne_suivie"
                    name="code"
                    class="form-control"
                    id="personne_suivie"
                    placeholder="Nom de la Personne chargée du suivi"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.personne_suivie.$error"
                  >
                    veuillez renseigner ce champ
                  </div>
                </div>

                   </div>

<div class="col-md-4">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="contact">Contact</label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.contact.$error }"
                    v-model="formData.contact"
                    name="code"
                    class="form-control"
                    id="contact"
                    placeholder="Contact"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.contact.$error"
                  >
                    veuillez entrer le contact
                  </div>
                </div>

                   </div>
                   <div class="col-md-4">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="email">Email </label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.email.$error }"
                    v-model="formData.email"
                    name="code"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.email.$error"
                  >
                    veuillez entrer le Mail
                  </div>
                </div>

                   </div>




               </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Fermer</v-btn>
                <v-btn class="btn btn-primary"
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement()"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!---DIALOGUE DE SUPPRESION-->

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="headline"> Suppression !! </v-card-title>

            <v-card-text>
               Voulez-vous réellement effectuer cette action ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green" text @click="dialogDelete = false">
                Annuler
              </v-btn>

              <v-btn color="red" text @click="deleteItemConfirm(edite)">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogEdite">
          <template v-slot:default="dialogEdite">
            <v-card>
              <v-toolbar dark>Modification</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="code">Code</label>
                  <input
                    type="text"
                    v-model="edite.code"
                    name="code"
                    class="form-control"
                    id="code"
                    placeholder="Code"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Structure</label>
                  <select class="form-control" v-model="edite.structure_id">
                    <option></option>
                    <option
                      v-for="item in liste_structure"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libelle</label
                  >
                  <input
                    type="text"
                    v-model="edite.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Libelle"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Type Acteur</label>
                  <select class="form-control" v-model="edite.type_acteur_id">
                    <option></option>
                    <option
                      v-for="item in typeActeurJu"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Régions</label>
                  <select
                    class="form-control"
                    v-model="edite.localisation_geo_graphique_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdite.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="modification(edite)"
                >
                  Modification
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "ActeurJuridique",
  components: {},
  data() {
    return {
      dialog: false,
      test: true,
      peine:"",
      sursis:0,
      type_peine:"",
      formData: {
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      },
      date_debut:"",
      edite: "",
    partie_requerante:[],
      dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: "Personne condamnée", value: "partie_requerente_id" },
        { text: "Durée de la peine", value: "peine" },
        { text: "Date de début", value: "date_debut" },
        { text: "Date de Fin", value: "date_fin" },
        { text: "Peine restante à purger (Jour(s))", value: "sursis" },
        { text: "Nom de la personne chargée du suivi", value: "personne_suivie" },
        { text: "Contact ", value: "contact" },
        { text: "Email ", value: "email" },
        { text: "Etat", value: "etat" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
      dossier_clinique_id:"",
      decision_peine:false,
      type_durre_succis:"",
    };
  },
  created() {
    if(this.$route.params.id){
     // console.log()
     this.dossier_clinique_id=this.$route.params.id
     this.dialog=true
    }
  },
  computed: {
     ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),
    ...mapGetters("Application", [
      "getterLocalisationGeographique",
      "getterStructure",
      "getterActeurJuridique",
      "getterLoadingActeurJurique",
      "getterErrorActeurJuridique",
      "getterTypeActeurJuridique",
       "getterDossiercliniquejuridique",
       "getterFicheEntretien",
       "getterAnalyseMJ",
       "getterLoadingPeine",
       "getterPeine",
       "getterPartieRequerant"
    ]),
    listeDesDossierAvecCondation(){
     
      let objet=this.getterDossiercliniquejuridique.filter(item=>{
        if((item.decision==1 || item.decision==2 ) && item.etat!=2){
          return item
        }
      })
      
      return objet
    },


     calcalEntre2Date(){
     return (d1)=>{
      let date1=new Date(d1)
     let date2= new Date()
     let  diffTime = Math.abs(date1- date2);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
console.log(diffDays) 
return diffDays
     }
  },

  nombreDejourCalcule1() {
       return id => {
        if (id != null && id != "") {
          // let vM = this;
           var dateF = new Date();
           var dateO = new Date(id);
           if(dateO>=dateF){
            return 0
           }else{
            return 1
           }
          //  var oneDay = 24*60*60*1000;
          //  var diffJour = Math.abs((dateO.getTime() -dateF.getTime())/(oneDay));
          //  return diffJour.toFixed(0);
        }
      };
     
    },
   etatPeine(){
    return date_fin=>{
        let date=this.peineResteAsubir(date_fin)
        if(date<1) return true
        return false
    }
   },
   listePartieRequerant(){
      let arr=[]
       let vm=this 
      this.partie_requerante.forEach(value=>{
           let objet=this.getterPeine.find(item=>item.partie_requerente_id ==value.id)
           if(objet){
            if(objet.status!=0){
            arr.push(value)
           }
         }else{
 arr.push(value)
         }


           
      })
      return arr
   },
peineAcheveEncour(){
  return typ=>{
    if(!typ) return ""
       let encours=[]
      let acheve=[]
      let vm=this 
      this.getterPeine.forEach(value=>{
         let day=vm.peineResteAsubir(value.date_fin)
         if (day<1 && value.status==0) {
          acheve.push(value)
         }
         if (day>0 && value.status==0) {
          encours.push(value)
         }
      })

      if(typ==1) return encours
        return acheve
  }
 

},


peineAcheve(){
    //   let encours=[]
      let acheve=[]
      let vm=this 
      this.getterPeine.filter(value=>{
        //nombreDejourCalcule1(item.date_fin)==1
         //let day=vm.peineResteAsubir(value.date_fin)
         if (vm.nombreDejourCalcule1(value.date_fin)==1) {
          acheve.push(value)
         }
      })

     // if(typ==1) return encours
        return acheve
  },

  peineEncour(){
       let encours=[]

      let vm=this 
      this.getterPeine.filter(value=>{
        //nombreDejourCalcule1(item.date_fin)==1
         //let day=vm.peineResteAsubir(value.date_fin)
         if (vm.nombreDejourCalcule1(value.date_fin)==0) {
          encours.push(value)
         }
      })

     // if(typ==1) return encours
        return encours
  },





  peineResteAsubir(){
   return date_fin=>{
    if(!date_fin) return null
       let date1=new Date()
     let date2= new Date(date_fin)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
   }
  },
  calcalEntre2Date1(){
     return (d1,d2)=>{
      let date1=new Date(d1)
     let date2= new Date(d2)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
     }
  },
typeDuree(){
  return (type,peine)=>{
    if(!type) return null
      if (type==1) {
        if(peine<2) return "Jour"
           if(peine>1) return "Jours"
      }
    if (type==3) {
        if(peine<2) return "An"
           if(peine>1) return "Ans"
      }
    if (type==2) {
        if(peine<2) return "Moi"
           if(peine>1) return "Mois"
      }
           return null 
  }
},
     dateDayCalcule() {
        return (date, info)=>{

            var result = new Date(date);

            result.setDate(result.getDate() + parseInt(info));
            //console.log(result)
            return this.formatDate(result);
        }
     },
     dateMoiCalcule() {
        return (date, info)=>{

            var result = new Date(date);
            result.setMonth(result.getMonth() + parseInt(info));
            //console.log(result)
            return this.formatDate(result);
        }
     },
     dateYearCalcule() {
        return (date, info)=>{

            var result = new Date(date);
            result.setFullYear(result.getFullYear() + parseInt(info));
            //console.log(result)
            return this.formatDate(result);
        }
     },
      formatDate() {
        return date=>{
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
      },
      formatDateFr(){
          return date=>{
              var d = new Date(date),
                  month = '' + (d.getMonth() + 1),
                  day = '' + d.getDate(),
                  year = d.getFullYear();

              if (month.length < 2)
                  month = '0' + month;
              if (day.length < 2)
                  day = '0' + day;

              return [day,month,year].join('/');
          }
      },
    partieRequerente(){
    return id=>{
      if(!id) return ""
        let obj=this.getterPartieRequerant.find(item=>item.id==id)
      if(obj==undefined) return null
        return obj.nom+" "+obj.prenom
    }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingPeine;
    },
    typeActeurJu() {
      return this.getterTypeActeurJuridique.reverse();
    },

    liste_structure() {
      return this.getterStructure.reverse();
    },
    acteurJuridiquePasTypeActeur() {
      return (type_acteur_id) => {
        if (!type_acteur_id) return [];
        return this.getterActeurJuridique.filter(
          (item) => item.type_acteur_id == type_acteur_id
        );
      };
    },
    regionActeurJuridique() {
      return (localisation_geo_graphique_id) => {
        if (!localisation_geo_graphique_id) return "";

        let objet = this.getterLocalisationGeographique.find(
          (item) => item.id == localisation_geo_graphique_id
        );
        if (objet == undefined) return "";
        return objet.libelle;
      };
    },
     recup_numero_requete(){
        return(id)=>{
          if (id != null && id != "" && id != undefined) {
              const Objet=this.getterFicheEntretien.find((item)=>item.id==id)
              if(Objet){
             return Objet.numrequete;
           }
           return ''
          }
          return ""
        }
       
      },
  },
  validations: {
    formData: {
       type_peine: { required },
        peine: { required },
        partie_requerente_id: { required },
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: { required },
        contact: { required },
        email: { required },
        status:"",
        date_fin:"",
        date_debut: "",
        deja_puge: { required },
    },
  },
  methods: {
    ...mapActions("Application", [
      "getActeurJuridique",
      "ajouterActeurJuridique",
      "supprimerActeurJuridique",
      "modificationActeurJuridique",
      "ajouterPeine",
      "supprimerPeine",
      "modificationPeine"
    ]),
    gotoModale() {
      this.$router.push({name:"AjouterPeine"})
    //  this.dialog = true;
    },
    enregistrement() {
      this.formData.peine=this.peine
      this.formData.type_peine=this.type_peine
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }
    let newObjet={
      ...this.formData,
      date_debut:this.date_debut,
      dossier_clinique_id:this.dossier_clinique_id,
      structure_id:this.getterUserStore.structure_id,
      peine:this.peine,
      type_peine:this.type_peine,
      sursis:this.sursis
    }


       console.log(newObjet)
      this.ajouterPeine(newObjet).then(res=>{
        console.log(res)
        this.$v.formData.$reset();
        this.date_debut=""
        this.dossier_maisons_id=""
this.formData={
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      }
      });
     
    },

    modification(item) {
      this.modificationActeurJuridique(item);
    },
    editItem(item) {
      this.edite = item;
      this.dialogEdite = true;
    },

    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerPeine(item.id);
    },
  },
  watch: {
    sursis:function (value) {
  
      let peine=parseInt(this.peine) - (parseInt(this.formData.deja_puge) + parseInt(value))
    if (this.type_peine==1) {
   
      this.formData.date_fin=this.dateDayCalcule(this.date_debut,this.peine)
    }
    if (this.type_peine==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(this.date_debut,this.peine)
    }

     if (this.type_peine==3) {
    
      this.formData.date_fin=this.dateYearCalcule(this.date_debut,this.peine)
     
    }
     
    },
    type_peine:function (value) {
  
      let peine=parseInt(this.peine) - (parseInt(this.formData.deja_puge) + parseInt(this.sursis))
    if (value==1) {
   
      this.formData.date_fin=this.dateDayCalcule(this.date_debut,this.peine)
    }
    if (value==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(this.date_debut,this.peine)
    }

     if (value==3) {
    
      this.formData.date_fin=this.dateYearCalcule(this.date_debut,this.peine)
     
    }
     
    },
    peine:function (value) {
  
      let peine=parseInt(this.peine) - (parseInt(this.formData.deja_puge) + parseInt(this.sursis))
    if (this.type_peine==1) {
   
      this.formData.date_fin=this.dateDayCalcule(this.date_debut,value)
    }
    if (this.type_peine==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(this.date_debut,value)
    }

     if (this.type_peine==3) {
    
      this.formData.date_fin=this.dateYearCalcule(this.date_debut,value)
     
    }
     
    },
    date_debut: function (value) {
      console.log(value)
      let peine=parseInt(this.formData.peine) - parseInt(this.formData.deja_puge)
    if (this.formData.type_peine==1) {
   
      this.formData.date_fin=this.dateDayCalcule(value,peine)
    }
    if (this.formData.type_peine==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(value,peine)
    }

     if (this.formData.type_peine==3) {
    
      this.formData.date_fin=this.dateYearCalcule(value,peine)
     
    }
     
    },
     dossier_clinique_id: function (value) {
     
  let objet_dossier=this.listeDesDossierAvecCondation.find(item=>item.id==value)
     this.formData.deja_puge=this.calcalEntre2Date(objet_dossier.date_mandatdepot,objet_dossier.date_decision)
     this.date_debut=objet_dossier.date_mandatdepot
     //this.decision_peine=objet_dossier.decision
     if(objet_dossier.decision==2){
      this.decision_peine=true
     }else{
      this.decision_peine=false
     }
      let vm = this;
      vm.partie_requerante = [];
      if (value != "") {
        let objt = this.getterFicheEntretien.find((item) => item.id == objet_dossier.fiche_analyse_cj_id);
        objt.fiche_partie_requerant.forEach((element) => {
          vm.partie_requerante.push(element.partie_requerant);
        });
      }
    },
  },
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
.icon:hover {
  cursor: pointer;
}
.muted {
  color: gray;
  font-size: 80%;
}
</style>



<style>
.vtl .vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}
.vtl .vtl-disabled {
  background-color: #d0cfcf;
}
</style> 
 
