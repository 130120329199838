
<template>
  <v-app>
    <v-main>
      <TableauBordDesDossiersCJ v-if="codeUser == 1"></TableauBordDesDossiersCJ>
      <TableauBordDesDossiersMJ v-if="codeUser == 2"></TableauBordDesDossiersMJ>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters } from "vuex";
import TableauBordDesDossiersCJ from "@/views/home/TableauBordDesDossiersCJ";
import TableauBordDesDossiersMJ from "@/views/home/TableauBordDesDossiersMJ";
export default {
  name: "IndexDetailAnalyse",
  components: {
    TableauBordDesDossiersCJ,
    TableauBordDesDossiersMJ,
  },
  data() {
    return {
      array1: [1, 3],
      array2: [2, 3],
    };
  },
  created() {},
  computed: {
    ...mapGetters("Utilisateurs", ["getterUserStore"]),
    inArray() {
      return (valeur, tableau) => {
        let length = tableau.length;
        for (let i = 0; i < length; i++) {
          if (tableau[i] == valeur) return true;
        }
        return false;
      };
    },

    codeUser() {
      if (!this.getterUserStore) return 0;
      return this.getterUserStore.status_acteur_juridique;
    },
  },
  validations: {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
</style>
