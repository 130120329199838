<template>
  <div id="content" class="content">
    <Loader :isVisible="loading" :isText="'Chargement en cours...'" />

    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
      <li class="breadcrumb-item active">Tableau de bord</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">
      Tableau de bord de la recevabilité des requêtes par les Cliniques juridiques
    </h1>
    <!-- end page-header -->

    <!-- end row -->
    <!-- begin row -->


     <div class="row">

            

              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de début
                  </label>
                  <input  type="date" v-model="date_debut"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>

               <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de fin
                  </label>
                  <input  type="date" v-model="date_fin"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>


              

              <div class="col-md-3">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
                  <button @click.prevent="resetdata" class="btn btn-primary">Tous  </button>
                </div>
              </div>

               <div class="col-md-1">
                <div class="form-group" style="margin-top:-7px">
                 
                </div>
              </div>


              <div class="col-md-2">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
              <button type="button"  @click="print()" class="btn btn-primary">Imprimer</button>
              </div>
              </div>

           
          </div>
    <div class="row" id="printMe">
      <!-- begin col-8 -->

      <!-- end col-8 -->
      <!-- begin col-4 -->
      <div class="col-lg-12">
        <!-- begin panel -->
        <div class="panel panel-inverse" data-sortable-id="index-6">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                data-click="panel-expand"
                ><i class=""></i
              ></a>
              <a
                href="javascript:;"
                data-click="panel-reload"
                ><i class=""></i
              ></a>
              <a
                href="javascript:;"
                class=""
                data-click="panel-collapse"
                ><i class=""></i
              ></a>
            </div>

             <h4 v-if="date_debut!='' && date_fin!=''" style="font-size: 20px" class="panel-title">
              Phases d’enregistrement et d’analyse de recevabilité des requêtes par les Cliniques juridiques du {{formatagedate(date_debut)}} au {{formatagedate(date_fin)}}</h4>

                <h4 v-else style="font-size: 20px" class="panel-title">
              Phases d’enregistrement et d’analyse de recevabilité des requêtes par les Cliniques juridiques</h4>

            <!-- <h4 style="font-size: 20px" class="panel-title">
              Phases d’enregistrement et d’analyse de recevabilité des requêtes par les Cliniques juridiques </h4> -->
          </div>
          <div class="panel-body p-t-0">
            <div class="table-responsive">
              <table class="table table-valign-middle table-bordered" id="customers">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="font-size: 14px">
                    <th>Requêtes enregistrées</th>
                    <td colspan="2" style="text-align:center;font-weight:bold">{{ nombrederequetes }}</td>
                    <td style="text-align:center;font-weight:bold">{{ pourcentagetotal }}%</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th rowspan="2">Genre du requérant</th>
                    <th colspan="">Masculin</th>
                    <td style="text-align:center;font-weight:bold">{{ nombreMasculin12 }} </td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageNombreMascumlin }} %</td>
                  </tr>
                  <tr style="font-size: 14px">
                    <th colspan="">Féminin</th>
                    <td style="text-align:center;font-weight:bold">{{nombreFeminin}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageNombreFeminin}} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th rowspan="4">Statut du requérant</th>
                    <th colspan="2"></th>
                    <td></td>
                  </tr>
                  <tr
                    style="font-size: 14px"
                    v-for="item in getterMotifPartie"
                    :key="item.id"
                  >
                    <th colspan="">{{ item.libelle }}</th>
                    <td style="text-align:center;font-weight:bold">{{NombreMotifpartie(item.id)}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageMotifpartie(item.id)}} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th :rowspan="getterModeSaisine.length+1">Mode de saisine </th>
                    <th colspan="2"></th>
                    <td></td>
                  </tr>
                  <tr
                    style="font-size: 14px"
                    v-for="item in getterModeSaisine"
                    :key="item.id"
                  >
                    <th>{{ item.libelle }}</th>
                    <td style="text-align: center;font-weight:bold">{{NombreModeSaisine(item.id)}}</td>
                    <td style="text-align:center;font-weight:bold">{{PourcentageModeSaisine(item.id)}} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th>Requêtes en cours d’analyse</th>
                    <td style="text-align: center;font-weight:bold" colspan="2">{{ nombreRequeteEncoursAnalyse }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageRequeteEncoursAnalyse }} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th>Requêtes analysées</th>
                    <td style="text-align: center;font-weight:bold" colspan="2">{{ nombreRequeteTraiteAnalyse }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageRequeteTraiteAnalyse }} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th>Requêtes acceptées</th>
                    <td style="text-align: center;font-weight:bold" colspan="2">{{ nombreRequeteAccepteAnalyse }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageRequeteAccepteAnalyse }} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th>Requêtes rejetées</th>
                    <td style="text-align: center;font-weight:bold" colspan="2">{{ nombreRequeteRejeteAnalyse }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageRequeteRejeteAnalyse }} %</td>
                  </tr>

                  <tr style="font-size: 14px">
                    <th>Requêtes réorientées</th>
                    <td style="text-align: center;font-weight:bold" colspan="2">{{ nombreRequeteOrienteAnalyse }}</td>
                    <td style="text-align:center;font-weight:bold">{{ PourcentageRequeteOrienteAnalyse }} %</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end panel -->
      </div>
      <!-- end col-4 -->
    </div>
    <!-- end row -->
  </div>
  <!-- end #content -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import moment from 'moment'
export default {
  name: "App",

  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
        },
        {
          color: "indigo",
          icon: "mdi-buffer",
        },
      ],
      date_debut: "",
      date_fin: "",
      partie_masculin: [],
      partie_feminin: [],

      series: [800, 50, 150],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          "Requêtes en attente d'analyse",
          "Requêtes en cours d'analyse",
          "Requêtes analysées",
        ],
        colors: ["#f59c1a", "#00acac", "#348fe2"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  created() {
    //console.log(this.getterFicheEntretien)
  },
  computed: {
    ...mapGetters("Application", [
      "getterTypeActeurJuridique",
      "getterLoadingTypeActeurJuridique",
      "getterErrorTypeActeurJuridique",
      "getterFicheEntretien",'getterFichepartieRequerant',
      "getterLoadingFicheEntretien",
      "getterErrorFicheEntretien",
      "getterActeurJuridique",
      "getterMotifPartie",
      "getterModeSaisine",
      "getterPartieRequerant",
    ]),
    ...mapGetters("Utilisateurs", [
      "getterErrorUser",
      "getterLoadingUser",
      "getterUser",
      "getterUserStore",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingFicheEntretien;
    },
    nombrederequetes() {
       if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterFicheEntretien.filter((item) => item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin);
         if(Objet){
           return Objet.length
         }
       }else{
         return this.getterFicheEntretien.length;
       }
      
    },

    pourcentagetotal() {
      if(this.nombrederequetes==0){
        return 0.00
      }else{
        let taille =this.getterFicheEntretien.length
        return ((this.nombrederequetes / taille) * 100).toFixed(2)
      } 
    },


      NombreMotifpartie() {
      if (this.date_debut != "" && this.date_fin != "") {
         return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.motif_partie_id==id && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }else{
        return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.motif_partie_id==id)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }
      
    },

    PourcentageMotifpartie() {
      return id=>{
        if(id!="" && id!=null){
          let Objet= this.NombreMotifpartie(id)
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
        }return "0"

      }
    },

    NombreModeSaisine() {
      if (this.date_debut != "" && this.date_fin != "") {
         return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.mode_saisine_id==id && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }else{
        return id=>{
        if(id!="" && id!=null){
          let Objet= this.getterFicheEntretien.filter(item=>item.mode_saisine_id==id)
          if(Objet){
           return Objet.length
          }return 0
        }return "0"

      }
      }
      
    },

    PourcentageModeSaisine() {
      return id=>{
        if(id!="" && id!=null){
          let Objet= this.NombreModeSaisine(id)
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
        }return "0"

      }
    },

    nombreRequeteEncoursAnalyse(){
      if (this.date_debut != "" && this.date_fin != "") {
          let Objet= this.getterFicheEntretien.filter(item=>item.status==0 && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
         if(Objet){
           return Objet.length
         }
      }else{
        let Objet= this.getterFicheEntretien.filter(item=>item.status==0)
         if(Objet){
           return Objet.length
         }
      }
         
    },

     PourcentageRequeteEncoursAnalyse() {
     
          let Objet= this.nombreRequeteEncoursAnalyse
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },

    nombreRequeteTraiteAnalyse(){
       if (this.date_debut != "" && this.date_fin != "") {
         let Objet= this.getterFicheEntretien.filter(item=>item.status==3 && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
         if(Objet){
           return Objet.length
         }
       }else{
         let Objet= this.getterFicheEntretien.filter(item=>item.status==3)
         if(Objet){
           return Objet.length
         }
       }
         
    },

    PourcentageRequeteTraiteAnalyse() {
     
          let Objet= this.nombreRequeteTraiteAnalyse
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },

     nombreRequeteAccepteAnalyse(){
        if (this.date_debut != "" && this.date_fin != "") {
             let Objet= this.getterFicheEntretien.filter(item=>item.status==2 && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
         if(Objet){
           return Objet.length
         }
        }else{
             let Objet= this.getterFicheEntretien.filter(item=>item.status==2)
         if(Objet){
           return Objet.length
         }
        }
        
    },

     PourcentageRequeteAccepteAnalyse() {
     
          let Objet= this.nombreRequeteAccepteAnalyse
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },

    nombreRequeteOrienteAnalyse(){
       if (this.date_debut != "" && this.date_fin != "") {
           let Objet= this.getterFicheEntretien.filter(item=>item.status==4 && item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin)
         if(Objet){
           return Objet.length
         }
       }else{
           let Objet= this.getterFicheEntretien.filter(item=>item.status==4)
         if(Objet){
           return Objet.length
         }
       }
        
    },

     PourcentageRequeteOrienteAnalyse() {
     
          let Objet= this.nombreRequeteOrienteAnalyse
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },

    nombreRequeteRejeteAnalyse(){
      if (this.date_debut != "" && this.date_fin != "") {
           let Objet= this.getterFicheEntretien.filter(item=>item.status!=2 && item.status!=3 && item.status!=4
         && item.status!=0 && item.created_at>=this.date_debut && item.created_at <=this.date_fin)
         if(Objet){
           return Objet.length
         }
      }else{
           let Objet= this.getterFicheEntretien.filter(item=>item.status!=2 && item.status!=3 && item.status!=4
         && item.status!=0)
         if(Objet){
           return Objet.length
         }
      }
        
    },

    PourcentageRequeteRejeteAnalyse() {
     
          let Objet= this.nombreRequeteRejeteAnalyse
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },


    recuperation_masculine(){
      let collect=[]
       if (this.date_debut != "" && this.date_fin != "") {
           let Objet= this.getterFicheEntretien.filter((item) => item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin);

          let Objet1 = this.getterFichepartieRequerant.filter((item) => item.created_at>=this.date_debut &&
          item.created_at <=this.date_fin);
          Objet.filter(item=>{
            Objet1.filter(item1=>{
              if(item.id==item1.fiche_entretien_id)
              collect.push(item1)
            })
          })

          return collect

       }else{
          
          this.getterFicheEntretien.filter(item=>{
            this.getterFichepartieRequerant.filter(item1=>{
              if(item.id==item1.fiche_entretien_id)
              collect.push(item1)
            })
          })

          return collect
       }

    },

    nombreMasculin12() {
      let collect=[]
      let Objet = this.getterPartieRequerant.filter(item=>item.genre=='M')
    Objet.filter(item=>{
      this.recuperation_masculine.filter(item1=>{
        if(item.id==item1.partie_requerant_id)
        collect.push(item)
      })
    })

    return collect.length
    },

     PourcentageNombreMascumlin() {
     
          let Objet= this.nombreMasculin12
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },



     nombreFeminin() {
      let collect=[]
      let Objet = this.getterPartieRequerant.filter(item=>item.genre=='F')
    Objet.filter(item=>{
      this.recuperation_masculine.filter(item1=>{
        if(item.id==item1.partie_requerant_id)
        collect.push(item)
      })
    })

    return collect.length
    },


     PourcentageNombreFeminin() {
     
          let Objet= this.nombreFeminin
          if(Objet){
           return ((Objet /this.nombrederequetes)*100).toFixed(2)
          }return 0
       
    },

  

    nombreMasculin1220() {
    let Objet = this.getterPartieRequerant.filter(item=>item.genre=='M')
    if(Objet){
      return Objet
    }return ''
    },

    nombreMasculin() {
      let vm = this;
      if (this.date_debut != "" && this.date_fin != "") {
        this.edite = this.getterFicheEntretien.find((item) => item.id == value);
        this.edite.fiche_partie_requerant.forEach((element) => {
          vm.partie_masculin.push(element.partie_requerant);
        });
      } else {
        let edite = this.getterFicheEntretien;
        edite.fiche_partie_requerant.forEach((element) => {
          vm.partie_masculin.push(element.partie_requerant);
        });
        let taille = vm.partie_masculin.forEach((element) => {
          element.genre == "M";
        });
        return (vm.nombrederequetes / taille) * 100;
      }
    },

    codeUser() {
      if (!this.getterUserStore) return 0;
      return this.getterUserStore.status_acteur_juridique;
    },
    idActeurJuridique() {
      if (this.getterUserStore) {
        return this.getterUserStore.acteur_juridique_id;
      }
      return "";
    },

    idStructure() {
      if (this.getterUserStore) {
        return this.getterUserStore.structure_id;
      }
      return "";
    },

    listeActeurJuriduqe() {
      if (this.idStructure) {
        return this.getterActeurJuridique.filter(
          (item) => item.structure_id == this.getterUserStore.structure_id
        );
      }
      return [];
    },
    nombreTotalRequete() {
      return this.getterFicheEntretien.length;
    },
    nombrePasStatus() {
      return (status) => {
        /* let prixMaison=1000
				 prixMaison.toLocaleString()*/

        return this.getterFicheEntretien.filter((item) => item.status == status)
          .length;
      };
    },
    nombrePasStatusActeur() {
      return (status, acteur) => {
        return this.getterFicheEntretien.filter((item) => {
          if (item.status == status && item.acteur_juridique_id == acteur) {
            return item;
          }
        }).length;
      };
    },
    nombreTraite() {
      return (
        parseFloat(this.nombrePasStatus(2)) +
        parseFloat(this.nombrePasStatus(3))
      );
    },
    pourcentageTraite() {
      let taux = (this.nombreTraite * 100) / this.nombreTotalRequete;
      return taux.toFixed(2);
    },
    pourcentageAUtre() {
      return (status) => {
        let taux =
          (this.nombrePasStatus(status) * 100) / this.nombreTotalRequete;
        return taux.toFixed(2);
      };
    },
    getStatus() {
      return (status) => {
        if (status == 0) return "EN ATTENTES";
        if (status == 1) return "EN-COURS";
        if (status == 2) return "ACCEPTER";
        return "REJETTER";
      };
    },
  },

  methods: {
    resetdata(){
      this.date_debut=""
      this.date_fin=""
      return 1
    },
     formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },
    async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
  },
};
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
 
  
}

#customers td,
#customers th {
  border: 1px solid #ddd;
 
}

#customers tr:nth-child(even) {

}



</style>

