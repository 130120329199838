import { render, staticRenderFns } from "./TableauBordDesDossiersCJ.vue?vue&type=template&id=df2013e2&scoped=true&"
import script from "./TableauBordDesDossiersCJ.vue?vue&type=script&lang=js&"
export * from "./TableauBordDesDossiersCJ.vue?vue&type=script&lang=js&"
import style0 from "./TableauBordDesDossiersCJ.vue?vue&type=style&index=0&id=df2013e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2013e2",
  null
  
)

export default component.exports