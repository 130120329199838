
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:;">Configuration</a>
          </li>
          <li class="breadcrumb-item active">Acteur juridique</li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h5 class="page-header">
          Gestion  des acteurs juridiques
        </h5>
        <!-- end page-header -->
        <!-- begin panel -->
        <div class="panel panel-inverse">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-default"
                data-click="panel-expand"
                ><i class="fa fa-expand"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-success"
                data-click="panel-reload"
                ><i class="fa fa-redo"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-warning"
                data-click="panel-collapse"
                ><i class="fa fa-minus"></i
              ></a>
            </div>
            <h4 class="panel-title">Liste des acteurs juridiques</h4>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                <button
                  type="button"
                  @click="gotoModale()"
                  class="btn btn-inverse"
                >
                  NOUVEAU
                </button>
              </div>
            </div>

            <div>
              <div class="col-lg-12 ui-sortable">
                <!-- begin panel -->
                <div
                  class="panel panel-inverse panel-with-tabs"
                  data-sortable-id="ui-unlimited-tabs-1"
                >
                  <!-- begin panel-heading -->
                  <div class="panel-heading p-0 ui-sortable-handle">
                    <div class="panel-heading-btn m-r-10 m-t-10">
                      <a
                        href="javascript:;"
                        class="btn btn-xs btn-icon btn-circle btn-success"
                        data-click="panel-expand"
                        ><i class="fa fa-expand"></i
                      ></a>
                    </div>
                    <!-- begin nav-tabs -->
                    <div class="tab-overflow overflow-right">
                      <ul class="nav nav-tabs nav-tabs-inverse">
                        <li class="nav-item prev-button" style="">
                          <a
                            href="javascript:;"
                            data-click="prev-tab"
                            class="nav-link text-success"
                            ><i class="fa fa-arrow-left"></i
                          ></a>
                        </li>
                        <li class="nav-item">
                          <a
                            href="#nav-tab-1"
                            data-toggle="tab"
                            class="nav-link active show"
                            >Tous les acteurs juridiques</a
                          >
                        </li>
                        <li
                          class="nav-item"
                          v-for="item in typeActeurJu"
                          :key="item.id"
                        >
                          <a
                            :href="'#nav-tab-2' + item.id"
                            data-toggle="tab"
                            class="nav-link"
                            >{{ item.libelle }}</a
                          >
                        </li>

                        <li class="nav-item next-button" style="">
                          <a
                            href="javascript:;"
                            data-click="next-tab"
                            class="nav-link text-success"
                            ><i class="fa fa-arrow-right"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <!-- end nav-tabs -->
                  </div>
                  <!-- end panel-heading -->
                  <!-- begin tab-content -->
                  <div class="tab-content">
                    <!-- begin tab-pane -->
                    <div class="tab-pane fade active show" id="nav-tab-1">
                      <h3 class="m-t-10">Tous les acteurs juridiques</h3>
                      <v-data-table
                        :headers="headers"
                        :items="getterActeurJuridique"
                        sort-by="code"
                        class="elevation-1"
                      >
                        <template
                          v-slot:item.localisation_geo_graphique_id="{ item }"
                        >
                          {{
                            regionActeurJuridique(
                              item.localisation_geo_graphique_id
                            )
                          }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div class="btn-group btn-group-justified">
                            <a class="btn btn-default" @click="editItem(item)"
                              ><i class="fas fa-edit"></i
                            ></a>
                            <a style="color:red" class="btn btn-default" @click="deleteItem(item)"
                              ><i class="fas fa-trash-alt"></i
                            ></a>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                    <!-- end tab-pane -->
                    <!-- begin tab-pane -->
                    <div
                      v-for="item in typeActeurJu"
                      :key="'-' + item.id"
                      class="tab-pane fade"
                      :id="'nav-tab-2' + item.id"
                    >
                      <h3 class="m-t-10">{{ item.libelle }}</h3>
                      <v-data-table
                        :headers="headers"
                        :items="acteurJuridiquePasTypeActeur(item.id)"
                        sort-by="code"
                        class="elevation-1"
                      >
                        <template
                          v-slot:item.localisation_geo_graphique_id="{ item }"
                        >
                          {{
                            regionActeurJuridique(
                              item.localisation_geo_graphique_id
                            )
                          }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div class="btn-group btn-group-justified">
                            <a class="btn btn-default" @click="editItem(item)"
                              ><i class="fas fa-edit"></i
                            ></a>
                            <a style="color:red" class="btn btn-default" @click="deleteItem(item)"
                              ><i class="fas fa-trash-alt"></i
                            ></a>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                  <!-- end tab-content -->
                </div>
                <!-- end panel -->
              </div>
            </div>
          </div>
        </div>

        <v-dialog max-width="600" v-model="dialog" persistent>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar dark>Enregistrement des acteurs juridiques</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="code">Code</label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.code.$error }"
                    v-model="formData.code"
                    name="code"
                    class="form-control"
                    id="code"
                    placeholder="Entrer le code"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.code.$error"
                  >
                    veuillez entrer le code
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Structure</label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.structure_id.$error }"
                    v-model="formData.structure_id"
                  >
                    <option></option>
                    <option
                      v-for="item in liste_structure"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.structure_id.$error"
                  >
                    veuillez entrer la structure
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libellé</label
                  >
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.libelle.$error }"
                    v-model="formData.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Entrer le libellé"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.libelle.$error"
                  >
                    veuillez entrer le libellé
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Type d'acteur</label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.type_acteur_id.$error }"
                    v-model="formData.type_acteur_id"
                  >
                    <option></option>
                    <option
                      v-for="item in typeActeurJu"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.type_acteur_id.$error"
                  >
                    veuillez entrer le type d'acteur
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Région</label>
                  <select
                    class="form-control"
                    :class="{
                      hasErroInpu:
                        $v.formData.localisation_geo_graphique_id.$error,
                    }"
                    v-model="formData.localisation_geo_graphique_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.localisation_geo_graphique_id.$error"
                  >
                    veuillez entrer la régions
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement()"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!---DIALOGUE DE SUPPRESION-->

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="headline"> Suppression !! </v-card-title>

            <v-card-text>
              Voulez-vous réellement effectuer cette action ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green" text @click="dialogDelete = false">
                Annuler
              </v-btn>

              <v-btn color="red" text @click="deleteItemConfirm(edite)">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogEdite">
          <template v-slot:default="dialogEdite">
            <v-card>
              <v-toolbar dark>Modification des acteurs juridiques</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="code">Code</label>
                  <input
                    type="text"
                    v-model="edite.code"
                    name="code"
                    class="form-control"
                    id="code"
                    placeholder="Code"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Structure</label>
                  <select class="form-control" v-model="edite.structure_id">
                    <option></option>
                    <option
                      v-for="item in liste_structure"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libelle</label
                  >
                  <input
                    type="text"
                    v-model="edite.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Libelle"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Type Acteur</label>
                  <select class="form-control" v-model="edite.type_acteur_id">
                    <option></option>
                    <option
                      v-for="item in typeActeurJu"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Région</label>
                  <select
                    class="form-control"
                    v-model="edite.localisation_geo_graphique_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdite.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="modification(edite)"
                >
                  Modifier
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "ActeurJuridique",
  components: {},
  data() {
    return {
      dialog: false,
      test: true,
      formData: {
        code: "",
        libelle: "",
        localisation_geo_graphique_id: "",
        type_acteur_id: "",
        structure_id: "",
      },
      edite: "",

      dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: "Code", value: "code" },
        { text: "Structure", value: "structure.libelle" },
        { text: "Libellé", value: "libelle" },
        { text: "Région", value: "localisation_geo_graphique_id" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters("Application", [
      "getterLocalisationGeographique",
      "getterStructure",
      "getterActeurJuridique",
      "getterLoadingActeurJurique",
      "getterErrorActeurJuridique",
      "getterTypeActeurJuridique",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingActeurJurique;
    },
    typeActeurJu() {
      return this.getterTypeActeurJuridique.reverse();
    },

    liste_structure() {
      return this.getterStructure.reverse();
    },
    acteurJuridiquePasTypeActeur() {
      return (type_acteur_id) => {
        if (!type_acteur_id) return [];
        return this.getterActeurJuridique.filter(
          (item) => item.type_acteur_id == type_acteur_id
        );
      };
    },
    regionActeurJuridique() {
      return (localisation_geo_graphique_id) => {
        if (!localisation_geo_graphique_id) return "";

        let objet = this.getterLocalisationGeographique.find(
          (item) => item.id == localisation_geo_graphique_id
        );
        if (objet == undefined) return "";
        return objet.libelle;
      };
    },
  },
  validations: {
    formData: {
      code: { required },
      libelle: { required },
      localisation_geo_graphique_id: { required },
      type_acteur_id: { required },
      structure_id: { required },
    },
  },
  methods: {
    ...mapActions("Application", [
      "getActeurJuridique",
      "ajouterActeurJuridique",
      "supprimerActeurJuridique",
      "modificationActeurJuridique",
    ]),
    gotoModale() {
      this.dialog = true;
    },
    enregistrement() {
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }

      console.log(this.formData);
      this.ajouterActeurJuridique(this.formData);
      if (this.getterErrorActeurJuridique == false) {
        this.$v.formData.$reset();
        this.formData = {
          code: "",
          libelle: "",
          localisation_geo_graphique_id: "",
          type_acteur_id: "",
          structure_id: "",
        };
      }
    },

    modification(item) {
      this.modificationActeurJuridique(item);
    },
    editItem(item) {
      this.edite = item;
      this.dialogEdite = true;
    },

    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerActeurJuridique(item.id);
    },
  },
  watch: {},
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
.icon:hover {
  cursor: pointer;
}
.muted {
  color: gray;
  font-size: 80%;
}
</style>



<style>
.vtl .vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}
.vtl .vtl-disabled {
  background-color: #d0cfcf;
}
</style> 
 
