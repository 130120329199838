const defaultstate = {



     structureGeographique: [],
     loadingStructureGeographique: false,
     errorStructureGeographique: false,


     localisationGeoGraphique: [],
     loadingLocalisationGeoGraphique: false,
     errorLocalisationGeoGraphique: false,

     tribunal: [],
     loadingTribunal: false,
     errorTribunal: false,
    
    peine:[],
    loadingPeine:false,

  
    renvoi:[],
    loadingRenvoi:false,



     typeElementDossier: [],
     loadingTypeElementDossier: false,
     errorTypeElementDossier: false,


     typePersonneTribunal: [],
     loadingtypeTypePersonneTribunal: false,
     errortypeTypePersonneTribunal: false,

     typeActeurJuridique: [],
     loadingtypeTypeActeurJuridique: false,
     errortypeTypeActeurJuridique: false,

     natureDossier: [],
     loadingtypeNatureDossier: false,
     errortypeNatureDossier: false,


     motifPartie: [],
     loadingMotifPartie: false,
     errorMotifPartie: false,


     modeSaisine: [],
     loadingModeSaisine: false,
     errorModeSaisine: false,

     criterIndigence: [],
     loadingCriterIndigence: false,
     errorCriterIndigence: false,


     critereVictimologie: [],
     loadingCritereVictimologie: false,
     errorCritereVictimologie: false,

     codePenal: [],
     loadingCodePenal: false,
     errorCodePenal: false,


     acteurJuridique: [],
     loadingActeurJuridique: false,
     errorActeurJuridique: false,

     localisationGeographique: [],
     loadingLocalisationGeographisue: false,
     errorLocalisationGeographisue: false,

     LocaliteSaisine: [],
     loadingLocaliteSaisine: false,
     errorLocaliteSaisine: false,

     CodePenal: [],
     loadingCodePenal: false,
     errorCodePenal: false,


     ficheEntretien: [],
     loadingFicheEntretien: false,
     errorFicheEntretien: false,


     DossierMaisonJustice: [],
     loadingDossierMaisonJustice: false,
     errorDossierMaisonJustice: false,


     Dossiercliniquejuridique: [],
     loadingDossiercliniquejuridique: false,
     errorDossiercliniquejuridique: false,



     analyseCJ: [],
     loadingAnalyseCJ: false,
     errorAnalyseCJ: false,


     analyseMJ: [],
     loadingAnalyseMJ: false,
     errorAnalyseMJ: false,

     typeAffaire: [],
     loadingTypeAffaire: false,
     errorTypeAffaire: false,


     partierequerant: [],
     loadingpartierequerant: false,
     errorpartierequerant: false,

     infraction: [],
     loadinginfraction: false,
     errorinfraction: false,

     victimeprevenu: [],
     loadingvictimeprevenu: false,
     errorvictimeprevenu:false,

     compromisaccord: [],
     loadingcompromisaccord: false,

     audience: [],
     loadingaudiance: false,
     erroraudience: false,

     Structure: [],
     loadingStructure: false,
     errorStructure: false,
     dataparam:[],

     DateEntretien:[],
     listPartieCivil:[],
     liistRenvoi:[],

     listDecisionPeine:[],

     appel: [],
     loadingappel: false,
     errorappel: false,

     Nationalite: [],
     loadingNationalite: false,
     errorNationalite: false,


     SituationFamille: [],
     loadingSituationFamille: false,
     errorSituationFamille: false,

     FichepartieRequerant: [],
     loadingFichepartieRequerant: false,

};

export default defaultstate