
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale()">Modification des requêtes</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Modifier</a></li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion des analyses de recevabilité </h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Modification </h4>
				</div>
				<div class="panel-body">
           <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                	<button type="button"  @click="gotoModale()" class="btn btn-inverse">RETOUR</button>
              </div>
          </div>
          
          <div class="row">
             <div class="col-md-2">
                  <div class="form-group">
                         <label class="form-label semibold" for="num">Numéro de la requête </label>
							<input disabled type="text"  v-model="edite.numrequete" name="num" class="form-control" id="num" placeholder="Numéro de la requête dans la base ">
							 
                  </div>
             </div>
             <div class="col-md-2">
                  <div class="form-group">
                         <label class="form-label semibold" for="datareception">Date de réception  </label>
							<input type="date"  v-model="edite.datareception" name="datareception" class="form-control" id="datareception" placeholder="Date de réception de la requête">
							 
                     </div>
             </div>
             <div class="col-md-5">
                 
                     <div class="form-group">
                         <label class="form-label semibold">Mode saisine</label>
                            <model-list-select class="form-control"  option-value="id"
                                    option-text="libelle" v-model="edite.mode_saisine_id" :list="getterModeSaisine"
                                                placeholder="Selectionner la saisine">
                        </model-list-select>
							    
                    </div>
             </div>
             <div class="col-md-3">
                 <div class="form-group">
                         <label class="form-label semibold">Localite de saisine</label>
                            <model-list-select class="form-control"  option-value="id"
                                    option-text="libelle" v-model="edite.localite_saisine_id" :list="getterLocaliteSaisine"
                                                placeholder="Sélectionner la localite de saisine">
                        </model-list-select>
							      
                  </div>
             </div>
             <div class="col-md-2" v-if="codeUser==1">
                 <div class="form-group">
                         <label class="form-label semibold">Status Partie requerant</label>
                            <model-list-select class="form-control"  option-value="id"
                                    option-text="libelle" v-model="edite.motif_partie_id" :list="getterMotifPartie"
                                                placeholder="Status">
                        </model-list-select>
							    
                  </div>
             </div>
            
    
          </div>
		

				</div>

			</div>
<table class="table table-striped m-b-0" v-if="partie_requerante.length">
									<thead>
										<tr>
											<th>#</th>
											<th>Nom</th>
											<th>Prénom(s)</th>
                      <th>Contact</th>
											<th>Age</th>
                      <th>Genre</th>
                      
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item,index) in partie_requerante" :key="'='+index">
											<td >{{index + 1}}</td>
											<td><input type="text" v-model="item.nom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
											<td><input type="text" v-model="item.prenom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                      	<td><input type="text" v-model="item.contact" name="nom" class="form-control" id="nom" placeholder="Nom ">
                          </td>
											<td>
                                                <input type="text" v-model="item.age" name="nom" class="form-control" id="nom" placeholder="Nom ">
                                               </td>
                      <td>
                          <select  class="form-control"  v-model="item.genre">
                           <option></option>
                           <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                         </select>
                      </td>
                     
                      
										</tr>
										
									</tbody>
								</table>
                <br>

<v-btn class="btn btn-primary"
            :disabled="loading"
           :loading="loading"
            text
      @click="enregistrement()"
          >
            Modifier
          </v-btn>
{{numeroRequete}}
    



<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect
    },
        data() {
            return {
               dialog:false,
               test:true,
               formData:{
                 
                   numrequete:"",
                   datareception:"",
                   mode_saisine_id:"",
                    localite_saisine_id:"",
                    motif_partie_id:"",
                    user_id:"",
                    acteur_juridique_id:""
                 
               },

               partie_requerante:[],
               partie:{
                   nom:"",
                   prenom:"",
                   contact:"",
                   age:"",
                    genre:"",
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,
      headers: [
      
        { text: 'Libelle', value: 'libelle' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 this.edite=this.getterFicheEntretien.find(item=>item.id==this.$route.params.id)
 console.log(this.getterFicheEntretien)
 let vm=this
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        },
        computed:{
            ...mapGetters('Application', ["getterMotifPartie",'getterModeSaisine', 
            'getterLocaliteSaisine',"getterErrorTypeActeurJuridique","getterLoadingFicheEntretien","getterErrorFicheEntretien","getterFicheEntretien"]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },

               codeUser(){
            if(!this.getterUserStore) return 0
            return this.getterUserStore.status_acteur_juridique
        },
           numeroRequete(){
            if(this.getterUserStore){
              let vm=this
              const date = new Date();
              let moi=date.getMonth()
              
              let annee=date.getFullYear()
              let jour=date.getDay()
              let heure=date.getHours()
              let mili=date.getMilliseconds()
              moi=moi+1
              let code=+mili+""+heure+""+jour+""+moi+""+annee
              console.log(code)
                if(this.getterUserStore.acteur_juridique){
                  vm.formData.numrequete=this.getterUserStore.acteur_juridique.code+"/"+code
                   return ""
                }
                return ""
            }
            return ""
           }
        },
        validations: {
            formData: {
                numrequete:{ required },
                   datareception:{ required },
                   mode_saisine_id:{ required },
                    localite_saisine_id:{ required },
                  // motif_partie_id:{ required },
                   user_id:"",
                    acteur_juridique_id:""
            },
            partie:{
                   nom:{ required },
                   prenom:{ required },
                   contact:{ required },
                   age:{ required },
                    genre:{ required },
               }
        },
        methods:{
             ...mapActions('Application', ['getTypeActeurJuridique',"ajouterTypeActeurJurique",
             "supprimerTypeActeurJuridique","modificationTypeActeurJuridique","ajouterFicheEntretien","modificationFicheEntretien"]),
            gotoModale(){
              
                this.$router.go(-1)
            },
           
            ajouterPartieRequerante(){
             this.$v.partie.$touch();
                if(this.$v.partie.$error) {
                    return
                }
                this.partie_requerante.push(this.partie)
   this.$v.partie.$reset();
                this.partie={
                   nom:"",
                   prenom:"",
                   contact:"",
                   age:"",
                    genre:"",
               }

            },
            deletePartieRequerante(item){
            if (item > -1) {
              this.partie_requerante.splice(item, 1);
            }
            },
            enregistrement () {

                
                 let objet={
                   ...this.edite,
                   partie:this.partie_requerante
                 }
                 console.log(objet)
                this.modificationFicheEntretien(objet)
                
               

            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
