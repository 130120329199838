import Home from '../../views/home/Home'
import Tableaubord_des_dossiers from '../../views/home/Tableaubord_des_dossiers'
import Tableaubord_des_requetes from '../../views/home/Tableaubord_des_requetes'
import StructureGeographique from "../../views/parametre/StructureGeographique";

import TypeElementDossier from "../../views/parametre/TypeElementDossier";
import TypePersonneTribunal from "../../views/parametre/TypePersonneTribunal";
import TypeActeur from "../../views/parametre/TypeActeur";
import NatureDossier from "../../views/parametre/NatureDossier";
import Situationfamille from "../../views/parametre/Situationfamille";
import PaysNationalite from "../../views/parametre/PaysNationalite";
import MotifPartie from "../../views/parametre/MotifPartie";
import ModeSaisine from "../../views/parametre/ModeSaisine";
import CriteIngigence from "../../views/parametre/CriteIngigence";
import Structure from "../../views/parametre/Structure";
import CritereVictimologie from '@/views/parametre/CritereVictimologie'
import LocalisationGeographique from '@/views/parametre/LocalisationGeographique'
import ActeurJuridique from '@/views/parametre/ActeurJuridique'

import TypeAffaire from '@/views/parametre/TypeAffaire'
import Infraction from '@/views/parametre/Infraction'


import Utilisateur from '@/views/gestionAccess/Utilisateur'
import ProfileUser from '@/views/gestionAccess/ProfileUser'

import LocaliteSaisine from '@/views/parametre/LocaliteSaisine'
import CodePenal from '@/views/parametre/CodePenal'
import FicheRecevabilite from '@/views/recevabiliteRequete/FicheRecevabilite'
import AjouterRecevabiliteRequete from '@/views/recevabiliteRequete/AjouterRecevabiliteRequete'
import ModificationRecevabilite from '@/views/recevabiliteRequete/ModificationRecevabilite'
import InfoFicheRecevabilite from '@/views/recevabiliteRequete/InfoFicheRecevabilite'

import Dossier from '@/views/gestionDesDossiers/DossierMaisonJustice/Dossier'
import AjoutDossier from '@/views/gestionDesDossiers/DossierMaisonJustice/AjoutDossier'
import ModifDossier from '@/views/gestionDesDossiers/DossierMaisonJustice/ModifDossier'
import GestionDateEntretien from '@/views/gestionDesDossiers/DossierMaisonJustice/GestionDateEntretien'
import ConstitutionDossier from '@/views/gestionDesDossiers/DossierMaisonJustice/ConstitutionDossier'
import Traitementdossier from '@/views/gestionDesDossiers/DossierMaisonJustice/Traitementdossier'
import ModifierdossierTraitement from '@/views/gestionDesDossiers/DossierMaisonJustice/ModifierdossierTraitement'
import DetaildossierTraitement from '@/views/gestionDesDossiers/DossierMaisonJustice/DetaildossierTraitement'
import AjoutdossierTraitement from '@/views/gestionDesDossiers/DossierMaisonJustice/AjoutdossierTraitement'
import IndexGestionDossier from '@/views/gestionDesDossiers/IndexGestionDossier'

import DossierCliniqueJuridique from '@/views/gestionDesDossiers/dossierCliniqueJuridique/DossierCliniqueJuridique'
import AjoutDossierCliniqueJuridique from '@/views/gestionDesDossiers/dossierCliniqueJuridique/AjoutDossierCliniqueJuridique'
import DetailDossierCliniqueJuridique from '@/views/gestionDesDossiers/dossierCliniqueJuridique/DetailDossierCliniqueJuridique'
import ModifDossierCliniqueJuridique from '@/views/gestionDesDossiers/dossierCliniqueJuridique/ModifDossierCliniqueJuridique'
import DetailDossierCliniqueJuridique1 from '@/views/gestionDesDossiers/dossierCliniqueJuridique/DetailDossierCliniqueJuridique1'

import NouvelAnalyse from '@/views/analyseRecevabilite/AjouterAnalyse'
import NouvelAnalyse1 from '@/views/analyseRecevabilite/AjouterAnalyse'
//import ListeRequeteAnalyse from '@/views/analyseRecevabilite/cj/ListeRequeteAnalyse'
import AnalyseRecevabilite from '@/views/analyseRecevabilite/AnalyseRecevabilite'
import DetailAnalyse from '@/views/analyseRecevabilite/IndexDetailAnalyse'

import ListeAudiance from '@/views/gesttionAudiance/ListeAudiance'
import AudianceNonCloturer from '@/views/gesttionAudiance/AudianceNonCloturer'
import AjoutAudiance from '@/views/gesttionAudiance/AjoutAudiance'
import DetailAudiance from '@/views/gesttionAudiance/DetailAudiance'
import ModificationAudiance from '@/views/gesttionAudiance/ModificationAudiance'
import NouvelAnalyseMJ from '@/views/analyseRecevabilite/mj/NouvelAnalyseMJ'
import DetailAnalyseMJ from '@/views/analyseRecevabilite/mj/DetailAnalyseMJ'
import NouvelAnalyseCJ from '@/views/analyseRecevabilite/cj/NouvelAnalyse'
//import AjoutdossierTraitement from "@/views/gestionDesDossiers/DossierMaisonJustice/AjoutdossierTraitement.vue"

import Peine from "@/views/peine/Peine"
import Renvoi from "@/views/renvoi/Renvoi"
import Appel from "@/views/Appel/Appel"
import AjouterPeine from "@/views/peine/AjouterPeine"

import ListedesAppels from '@/views/GestionAppels/ListedesAppels'
import AjoutAppel from '@/views/GestionAppels/AjoutAppel'
import ModifierAppel from '@/views/GestionAppels/ModifierAppel'
import DetailAppel from '@/views/GestionAppels/DetailAppel'
const AuthRoutes = [
   // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: {layout: "no-sidebar"} },

   {
      path: '/ajouter-peine',
      name: "AjouterPeine",
      meta: { requiresAuth: true },
      component: AjouterPeine
   },
   {
      path: '/peine/:id',
      name: "PeinePEIN",
      meta: { requiresAuth: true },
      component: AjouterPeine
   },
   {
      path: '/gestion-appel',
      name: "Appel",
      meta: { requiresAuth: true },
      component: Appel
   },
   {
      path: '/gestion-renvoi',
      name: "Renvoi",
      meta: { requiresAuth: true },
      component: Renvoi
   },
   {
      path: '/gestion-peine',
      name: "Peine",
      meta: { requiresAuth: true },
      component: Peine
   },

   {
      path: '/NouvelAnalyse',
      name: "NouvelAnalyse",
      meta: { requiresAuth: true },
      component: NouvelAnalyse
   },
   {
      path: '/constitution-dossier-cj/:id',
      name: "AjoutDossierCliniqueJuridique2",
      meta: { requiresAuth: true },
      component: AjoutDossierCliniqueJuridique
   },
   {
      path: '/dossier-traitement-mj/:id',
      name: "AjoutdossierTraitement2",
      meta: { requiresAuth: true },
      component: AjoutdossierTraitement
   },
   {
      path: '/analyse-maison-justice/:id',
      name: "NouvelAnalyseMJ",
      meta: { requiresAuth: true },
      component: NouvelAnalyseMJ
   },

   {
      path: '/analyse-clinique-juridique/:id',
      name: "NouvelAnalyseCJ",
      meta: { requiresAuth: true },
      component: NouvelAnalyseCJ
   },
   {
      path: '/AudianceNonCloturer',
      name: "AudianceNonCloturer",
      meta: { requiresAuth: true },
      component: AudianceNonCloturer
   },

   {
      path: '/ListeAudiance',
      name: "ListeAudiance",
      meta: { requiresAuth: true },
      component: ListeAudiance
   },

   {
      path: '/ListedesAppels',
      name: "ListedesAppels",
      meta: { requiresAuth: true },
      component: ListedesAppels
   },

   {
      path: '/AjoutAppel',
      name: "AjoutAppel",
      meta: { requiresAuth: true },
      component: AjoutAppel
   },

   {
      path: '/ModifierAppel/:id',
      name: "ModifierAppel",
      meta: { requiresAuth: true },
      component: ModifierAppel
   },

   {
      path: '/DetailAppel/:id',
      name: "DetailAppel",
      meta: { requiresAuth: true },
      component: DetailAppel
   },


   {
      path: '/ConstitutionDossier',
      name: "ConstitutionDossier",
      meta: { requiresAuth: true },
      component: ConstitutionDossier
   },

   {
      path: '/AjoutdossierTraitement',
      name: "AjoutdossierTraitement",
      meta: { requiresAuth: true },
      component: AjoutdossierTraitement
   },

   {
      path: '/ModifierdossierTraitement/:id',
      name: "ModifierdossierTraitement",
      meta: { requiresAuth: true },
      component: ModifierdossierTraitement
   },

   {
      path: '/DetaildossierTraitement/:id',
      name: "DetaildossierTraitement",
      meta: { requiresAuth: true },
      component: DetaildossierTraitement
   },

   {
      path: '/Traitementdossier',
      name: "Traitementdossier",
      meta: { requiresAuth: true },
      component: Traitementdossier
   },

   {
      path: '/AjoutAudiance',
      name: "AjoutAudiance",
      meta: { requiresAuth: true },
      component: AjoutAudiance
   },

   {
      path: '/ajout-audiance/:id',
      name: "AjoutAudiance2",
      meta: { requiresAuth: true },
      component: AjoutAudiance
   },

   {
      path: '/DetailAudiance/:id',
      name: "DetailAudiance",
      meta: { requiresAuth: true },
      component: DetailAudiance
   },

   {
      path: '/ModificationAudiance/:id',
      name: "ModificationAudiance",
      meta: { requiresAuth: true },
      component: ModificationAudiance
   },

   {
      path: '/type-affaire',
      name: "TypeAffaire",
      meta: { requiresAuth: true },
      component: TypeAffaire
   },
   {
      path: '/detail-analyse/:id',
      name: "DetailAnalyse",
      meta: { requiresAuth: true },
      component: DetailAnalyse
   },

   {
      path: '/detail1-analyse/:id',
      name: "DetailAnalyseMJ",
      meta: { requiresAuth: true },
      component: DetailAnalyseMJ
   },
   {
      path: '/liste-analyse-recevabilite',
      name: "AnalyseRecevabilite",
      meta: { requiresAuth: true },
      component: AnalyseRecevabilite
   },
   {
      path: '/nouvelle-analyse-recevabilte',
      name: "NouvelAnalyse",
      meta: { requiresAuth: true },
      component: NouvelAnalyse
   },

   {
      path: '/NouvelAnalyse1/:id',
      name: "NouvelAnalyse1",
      meta: { requiresAuth: true },
      component: NouvelAnalyse1
   },

   {
      path: '/IndexGestionDossier',
      name: "IndexGestionDossier",
      meta: { requiresAuth: true },
      component: IndexGestionDossier
   },
   {
      path: '/DetailDossierCliniqueJuridique/:id',
      name: "DetailDossierCliniqueJuridique",
      meta: { requiresAuth: true },
      component: DetailDossierCliniqueJuridique
   },

   {
      path: '/DetailDossierCliniqueJuridique1/:id',
      name: "DetailDossierCliniqueJuridique1",
      meta: { requiresAuth: true },
      component: DetailDossierCliniqueJuridique1
   },

   {
      path: '/ModifDossierCliniqueJuridique/:id',
      name: "ModifDossierCliniqueJuridique",
      meta: { requiresAuth: true },
      component: ModifDossierCliniqueJuridique
   },
   {
      path: '/AjoutDossierCliniqueJuridique',
      name: "AjoutDossierCliniqueJuridique",
      meta: { requiresAuth: true },
      component: AjoutDossierCliniqueJuridique
   },

   {
      path: '/DossierCliniqueJuridique',
      name: "DossierCliniqueJuridique",
      meta: { requiresAuth: true },
      component: DossierCliniqueJuridique
   },


   {
      path: '/detail-fiche-recevabilite/:id',
      name: "InfoFicheRecevabilite",
      meta: { requiresAuth: true },
      component: InfoFicheRecevabilite
   },

   {
      path: '/GestionDateEntretien/:id',
      name: "GestionDateEntretien",
      meta: { requiresAuth: true },
      component: GestionDateEntretien
   },

   {
      path: '/Infraction',
      name: "Infraction",
      meta: { requiresAuth: true },
      component: Infraction
   },

   {
      path: '/ModifDossier/:id',
      name: "ModifDossier",
      meta: { requiresAuth: true },
      component: ModifDossier
   },
   {
      path: '/Dossier',
      name: "Dossier",
      meta: { requiresAuth: true },
      component: Dossier
   },

   {
      path: '/ajout_Dossier',
      name: "AjoutDossier",
      meta: { requiresAuth: true },
      component: AjoutDossier
   },
   {
      path: '/modification-recevabilite/:id',
      name: "ModificationRecevabilite",
      meta: { requiresAuth: true },
      component: ModificationRecevabilite
   },
   {
      path: '/fiche-recevabilite',
      name: "FicheRecevabilite",
      meta: { requiresAuth: true },
      component: FicheRecevabilite
   },
   {
      path: '/ajouter-recevabiliteRequete',
      name: "AjouterRecevabiliteRequete",
      meta: { requiresAuth: true },
      component: AjouterRecevabiliteRequete
   },
   {
      path: '/acteur-juridique',
      name: "ActeurJuridique",
      meta: { requiresAuth: true },
      component: ActeurJuridique
   },

   {
      path: '/utilisateur',
      name: "Utilisateur",
      meta: { requiresAuth: true },
      component: Utilisateur
   },

   {
      path: '/ProfileUser',
      name: "ProfileUser",
      meta: { requiresAuth: true },
      component: ProfileUser
   },
   {
      path: '/accueil',
      name: "Home",
      meta: { requiresAuth: true },
      component: Home
   },

   {
      path: '/Tableaubord_des_dossiers',
      name: "Tableaubord_des_dossiers",
      meta: { requiresAuth: true },
      component: Tableaubord_des_dossiers
   },

   {
      path: '/Tableaubord_des_requetes',
      name: "Tableaubord_des_requetes",
      meta: { requiresAuth: true },
      component: Tableaubord_des_requetes
   },
   {
      path: '/structure-geographique',
      name: "StructureGeographique",
      meta: { requiresAuth: true },
      component: StructureGeographique
   }
   ,

   {
      path: '/Code-Penal',
      name: "CodePenal",
      meta: { requiresAuth: true },
      component: CodePenal
   }
   ,
   {
      path: '/type-element-dossier',
      name: "TypeElementDossier",
      meta: { requiresAuth: true },
      component: TypeElementDossier
   }
   ,

   {
      path: '/Localite-Saisine',
      name: "LocaliteSaisine",
      meta: { requiresAuth: true },
      component: LocaliteSaisine
   }
   ,
   {
      path: '/type-personne-tribunal',
      name: "TypePersonneTribunal",
      meta: { requiresAuth: true },
      component: TypePersonneTribunal
   }
   ,
   {
      path: '/type-acteur-juridique',
      name: "TypeActeur",
      meta: { requiresAuth: true },
      component: TypeActeur
   },

   {
      path: '/PaysNationalite',
      name: "PaysNationalite",
      meta: { requiresAuth: true },
      component: PaysNationalite
   },

   {
      path: '/Situationfamille',
      name: "Situationfamille",
      meta: { requiresAuth: true },
      component: Situationfamille
   },
   {
      path: '/nature-dossier',
      name: "NatureDossier",
      meta: { requiresAuth: true },
      component: NatureDossier
   }
   ,
   {
      path: '/motif-partie',
      name: "MotifPartie",
      meta: { requiresAuth: true },
      component: MotifPartie
   }
   ,
   {
      path: '/mode-saisine',
      name: "ModeSaisine",
      meta: { requiresAuth: true },
      component: ModeSaisine
   }
   ,
   {
      path: '/crite-indigence',
      name: "CriteIngigence",
      meta: { requiresAuth: true },
      component: CriteIngigence
   },

   {
      path: '/Structure',
      name: "Structure",
      meta: { requiresAuth: true },
      component: Structure
   }
   ,
   {
      path: '/critere-victimologie',
      name: "CritereVictimologie",
      meta: { requiresAuth: true },
      component: CritereVictimologie
   }
   ,
   {
      path: '/localisation-geographique',
      name: "LocalisationGeographique",
      meta: { requiresAuth: true },
      component: LocalisationGeographique
   }



]

export default AuthRoutes;
