
<template>
<v-app>
    <notifications/>
    <v-main>

    <template v-if="codeUser==1 || 3">
                <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale()">Dossiers des Cliniques juridiques</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Détails</a></li>
        <input type="hidden" v-model="StrucureCharger">
        <input type="hidden" v-model="recup_numero_requete">
        <input type="hidden" v-model="persCharger">
        <input type="hidden" v-model="persCharger">

			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion  des dossiers Cliniques juridiques  </h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<button type="button"  @click="print()" class="btn btn-primary">Imprimer</button>
                <button type="button"  @click="gotoModale()" class="btn btn-dark">RETOUR</button>
                <input type="hidden" :value="getterRenvoi">
					</div>
					<h4 class="panel-title">Détails </h4>
				</div>
				<div class="panel-body" id="printMe" >



<div class="row">
    <div class="col-md-2">
         <!-- <qrcode-vue :value="qrvalue" :size="size" level="H">11</qrcode-vue> -->
         <qr-code :text="edite.numrequete"></qr-code> <br>
    </div>
</div>



            <div class="row">
         <div class="col-md-2">
<address>
  <strong>Numéro de la requête </strong><br />
 {{edite.numrequete}}<br />

</address>
         </div>

         <div class="col-md-2" v-if="editeAppel12">
<address>
  <strong>Numéro de l'appel</strong><br />
 {{editeAppel12.numrequete_appel}}<br />

</address>
         </div>
          <div class="col-md-2">
<address>
  <strong>Date de réception </strong><br />
  {{edite.datareception}}<br />
 
  
</address>
         </div>

          <div class="col-md-3">
<address>
  <strong>Mode de saisine </strong><br />
  <p v-if="edite.mode_saisine">{{edite.mode_saisine.libelle}}</p><br />

</address>
         </div>
          <div class="col-md-3">
<address>
  <strong>Localité de la saisine initiale </strong><br />
   <p v-if="edite.localite_saisine">{{edite.localite_saisine.libelle}}</p><br />
  
</address>
         </div>
       
    
            </div>






            		
<div class="table-responsive">
								
                                  <h5>Partie requérante</h5>
      <table class="table  m-b-0" v-if="partie_requerante.length">
									<thead>
										<tr>
											
											<th>Statut de la partie requérante</th>
											<th>Nom</th>
											<th>Prénom(s)</th>
                                          <th>Contact</th>
                                             <th>Age</th>
                                            <th>Genre</th>
                      
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item,index) in partie_requerante" :key="'='+index">
										<td>{{libellemotif(item.motif_partie_id)}}</td>
											<td>{{item.nom}}</td>
											<td>{{item.prenom}}</td>
                      	<td>{{item.contact}}</td>
											<td>{{item.age}}</td>
                      <td>
                          <select disabled  v-model="item.genre">
                           <option></option>
                           <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                         </select>
                      </td>
                     
                      
										</tr>
										
									</tbody>
			</table>
	</div>


<hr>


<table class="table table-bordered m-b-0">
  <tr>
    <td style="background:#d5dce4;text-align:center !important;font-weight: bold;font-size:50;text-decoration: underline;"  colspan="3">I.Analyse de recevabilité pour l’assistance juridique et judiciaire des Cliniques juridiques</td>
  </tr>
  <tr v-if="detail_analyse">
    <td style="background:#fbe4d5">Critères de vulnérabilité</td>
    <td style="background:#fbe4d5">Conclusion</td>
    <td style="background:#fbe4d5">Justifications</td>
  </tr>
  <tr v-if="detail_analyse">
    <td>1. Critère d’indigence(cf justificatifs)</td>
    <td>{{conclusion(detail_analyse.critere_indigence)}}</td>
    <td>
     
        
    <div class="box" v-if="detail_analyse">
    <ul class="df">
    <li v-for="item in detail_analyse.indigence_analyse" :key="item.id">
      {{critereIndigence(item.criter_indigence_id)}}</li>
    
    </ul> 
  </div>


    </td>
  </tr>
  <tr v-if="detail_analyse">
    <td>2. Critère de victimologie (cf certificats médicaux)</td>
    <td>{{conclusion(detail_analyse.critere_victimologie)}}</td>
    <td><li v-for="item in detail_analyse.victimologie_analyse" :key="item.id">
      {{critereVictimologie(item.critere_victimologie_id)}}</li></td>
  </tr>
  <tr v-if="detail_analyse">
    <td>3. Critère de non prise en charge par une autre structure d’accompagnement juridique/judiciaire</td>
    <td>{{conclusion(detail_analyse.criterede_non_prise_encharge_par_sturcture)}}</td>
    <td>{{detail_analyse.justification_autre_structure}}</td>
  </tr>
  <tr v-if="detail_analyse">
    <td>Conclusion  pour l’AJJ des CJ</td>
    <td>{{conclusionAnalyse(detail_analyse.conclusion_pourAJJ_desCJ)}}</td>
    <td>{{detail_analyse.justification_conclusion}}</td>
  </tr>
</table> <br>
         

         <table class="table table-bordered m-b-0">
  <tr>
    <td style="background:#d5dce4;text-align:center !important;font-weight: bold;font-size:50;text-decoration: underline;"  colspan="3">
      Informations sur le dossier</td>
  </tr>
  <tr>
    <td style="background:#fbe4d5">Date de constitution du dossier</td>
    <td style="background:#fbe4d5">Structure chargée du dossier</td>
    <td style="background:#fbe4d5">Personne chargée du dossier</td>
  </tr>
  <tr>
    
    <td> {{editeclinique.dateconstitution}} </td>
     <td> {{editeclinique.strucuturecharger}} </td>
     <td> {{editeclinique.personneCharger}} </td>
  </tr> <br>
  </table>
 <table class="table table-bordered m-b-0">
    <tr>
      <td style="background:#d5dce4;text-align:center !important;font-weight: bold;font-size:50;text-decoration: underline;"  colspan="4">
     Informations sur  l'instruction</td>
    </tr>

  <tr>
    <td style="background:#fbe4d5">Juridiction compétente</td>
    <td style="background:#fbe4d5">N° du registre du parquet (RP)</td>
    <td style="background:#fbe4d5">Type de dossier</td>
    <td style="background:#fbe4d5">Cabinet d’instruction</td>
  </tr>

  <tr>
    
    <td> {{editeclinique.juridictioncompetente}} </td>
     <td> {{editeclinique.numeroParquet}} </td>
     <td> {{editeclinique.typedossier}} </td>
     <td> {{editeclinique.cabinetinstruction}} </td>
  </tr>

   <tr>
    <td style="background:#fbe4d5">Numéro du registre d’instruction</td>
    <td style="background:#fbe4d5">Magistrat en charge du dossier</td>
    <td style="background:#fbe4d5">Date de parution devant le juge d’instruction</td>
    <td style="background:#fbe4d5">Nom et prénom(s) de l'avocat associé si applicable</td>
  </tr>

  <tr>
    
    <td> {{editeclinique.numeroregistreinstruction}} </td>
     <td> {{editeclinique.magistratdossier}} </td>
     <td> {{formatagedate(editeclinique.dateparution)}} </td>
     <td> {{editeclinique.nomprenomassocier}} </td>
  </tr> <br>
</table>
 <table class="table table-bordered m-b-0">
   <tr>
      <td style="background:#d5dce4;text-align:center !important;font-weight: bold;font-size:50;text-decoration: underline;"  colspan="3">
      Description des faits</td>
    </tr>

   <tr>
    <td style="background:#fbe4d5">Résumé des faits (avec le plus de précision possible)</td>
    <td style="background:#fbe4d5">Qualification des faits</td>
    <td colspan="2" style="background:#fbe4d5">Analyse du dossier par rapport à la version des faits de la victime / prévenu</td>
  </tr>

  <tr>
    
    <td> {{editeclinique.resume_fait}} </td>
     <td> {{editeclinique.qualification_fait}} </td>
     <td colspan="2"> {{editeclinique.analyse_dossier}} </td>
  </tr>

  </table>






<br>

<table class="table table-bordered m-b-0">
   <tr>
      <td style="background:#d5dce4;text-align:center !important;font-weight: bold;font-size:50;text-decoration: underline;"  colspan="3">
      Le(s) renvoi(s)</td>
    </tr>

   <tr >
    <td style="background:#fbe4d5">N°</td>
    <td style="background:#fbe4d5">Date du renvoi</td>
    <td style="background:#fbe4d5">Motif du renvoi</td>
    
  </tr>

   <tr v-for="(item,index) in editeRenvoi" :key="item.id">
    <td >{{(index+1)}}</td>
    <td >{{formatagedate(item.date)}}</td>
    <td >{{item.motif}}</td>
    
  </tr>

  </table>
  <br>







 <table class="table table-bordered m-b-0">
   <tr>
      <td style="background:#d5dce4;text-align:center !important;font-weight: bold;font-size:50;text-decoration: underline;"  colspan="6">
      Information sur les audiences </td>
    </tr>


    <!-- <div v-for="item in editedateaudience" :key="item.id" style="width:1000px"> -->
    <!-- <tr > -->
    <tbody v-for="item in editedateaudience" :key="item.id" style="margin-bottom:10px;">
      <tr>
      <td style="background:#d5dce4 !important;font-weight: bold;font-size:50;"  colspan="6">
      Audience du {{formatagedate(item.date_audience)}} :</td>
    </tr>
  


   <tr>
    <td style="background:#fbe4d5">Niveau d'instruction</td>
    <td colspan="3" style="background:#fbe4d5">Déroulé de l’audience </td>
     <td style="background:#fbe4d5">Président</td>
     <td colspan="3" style="background:#fbe4d5"> Rappel des faits </td>
  </tr>

  <tr>
    
    <td> {{item.niveau_audience}} </td>
     <td colspan="3"> {{item.deroulement_audience}} </td>
     <td> {{item.president}} </td>
     <td colspan="3"> {{item.rappel_fait}} </td>
  </tr>


   <tr>
   
    <td style="background:#fbe4d5">Ministère public</td>
    <td colspan="" style="background:#fbe4d5"> N° Registre du parquet</td>
    <td colspan="2" style="background:#fbe4d5"> Réquisitions du procureur</td>
    <td colspan="2" style="background:#fbe4d5"> Ligne de défense de l’avocat</td>
  </tr>

  <tr>
    
    <td> {{item.ministere_public}} </td>
     <td> {{item.numeroParquet}} </td>
     <td colspan="2"> {{item.requisition_procurreur}} </td>
     <td colspan="2"> {{item.ligne_defense_avocat}} </td>
  </tr>


   <tr>
   
    <td colspan="2" style="background:#fbe4d5">Décision   </td>
    <td colspan="2" style="background:#fbe4d5"> Date de la décision</td>
    <td colspan="" style="background:#fbe4d5"> Décision de la peine</td>
    <td colspan="2" style="background:#fbe4d5"> Peine</td>

  </tr>

  <tr>
    
    <td colspan="2"> {{item.decision}} </td>
     <td colspan="2"> {{item.date_decision}} </td>

     <td colspan="" v-if="item.decision_peine==1" > Condamné </td>
     <td colspan="" v-else-if="item.decision_peine==2" > Condamné avec sursis </td>
     <td colspan=""  v-else-if="item.decision_peine==3" > Acquitté relaxé </td>
     <td colspan="" v-else > Pas de décision</td>

     <td colspan="2" v-if=" mapeine(item.dossier_clinique_id)!=0">{{mapeine(item.dossier_clinique_id)}} JOURS</td>
     <td colspan="2" v-else>NON RENSEIGNE</td>
     <!-- <td colspan="2" v-if="editepeine">{{editepeine.peine}} JOURS</td>
     <td colspan="2" v-else>NON RENSEIGNE</td> -->

  </tr>
  

   </tbody>

   

   

  <!-- </div> -->

  </table>





  



  
            
               

          <!-- <h5 style="text-align:center;text-decoration:underline">Informations sur le requérant</h5> -->
         

          <!-- <div class="row"   v-if="motifparti=='Détenu Prévenu'">
           

            <div class="col-md-2">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_mandatdepot">Date du mandat de dépôt  </label>
                    <input type="date" disabled class="form-control" v-model="victimeprevenuformData.date_mandatdepot" placeholder="Date du mandat de dépôt">
                  
               </div>
           </div>

           <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_renouvellement_mandat">Date de renouvellement de mandat de dépôt  </label>
                    <input type="date" disabled class="form-control" v-model.trim="victimeprevenuformData.date_renouvellement_mandat">
                 
               </div>
           </div>


            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="lieu_detention">Lieu de détention </label>
                    <input type="text" disabled class="form-control" v-model.trim="victimeprevenuformData.lieu_detention"  placeholder="Saisir le Lieu de détention">
                  
               </div>
           </div>

           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="numero_cellule">N° de cellule </label>
                    <input type="text" disabled class="form-control" v-model.trim="victimeprevenuformData.numero_cellule"  placeholder="Saisir le N° de cellule">
       
               </div>
           </div>



          </div> -->

<!-- 
          <div v-if="motifparti=='Victime'">
         
          <div class="row">

             <div class="col-md-2">
               <div class="form-group">
                  <label class="form-label semibold" for="date_fait">Date des faits </label>
                    <input type="date" class="form-control" v-model.trim="victimeprevenuformData.date_fait" placeholder="">
                 
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="lieu_fait">Lieu des faits </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.lieu_fait" placeholder="Saisir le Lieu des faits">
                 
               </div>
           </div>


           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nature_infraction">Nature de l'infraction </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nature_infraction"  placeholder="Nature de l'infraction">
                  
               </div>
           </div>

            <div class="col-md-4">
               <div class="form-group">
                  <label class="form-label semibold" for="info_auteur_presume">Identité et informations sur l’auteur présumé </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.info_auteur_presume"  placeholder="Entrer l'Identité et informations sur l’auteur présumé">
                   
               </div>
           </div>

              
           

          

          </div>
         

          <div class="row">

             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nom_prenom">Nom et prénom(s) </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nom_prenom"  placeholder="Saisir le nom et le prénom(s)">
                  
               </div>
           </div>
              

            

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="profession">Profession </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.profession"  placeholder="Saisir la Profession">
                 
               </div>
           </div>

            

             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="age">Age <b style="color:red">*</b></label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.age"  placeholder="Saisir l'âge">
                  
               </div>
           </div>

            

              <div class="col-md-2">
                 <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.sexe.$error }">
                         <label class="form-label semibold">Sexe</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="victimeprevenuformData.sexe" :list="listSexe"
                                                placeholder="Sélectionner le sexe">
                           </model-list-select>
                         <div style="color: red;" v-if="$v.victimeprevenuformData.sexe.$error && !$v.victimeprevenuformData.sexe.required">
                              Ce champs est requis!
                         </div>  
                  </div>
             </div>

             <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.nationalite.$error }">
                  <label class="form-label semibold" for="nationalite">Nationalité <b style="color:red">*</b></label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nationalite" @input="$v.victimeprevenuformData.nationalite.$touch()" placeholder="Saisir la Nationalité">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.nationalite.$error && !$v.victimeprevenuformData.nationalite.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>



              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="lien_avec_victime">Lien avec la victime</label>
							<input type="text" v-model="victimeprevenuformData.lien_avec_victime" name="lien_avec_victime" class="form-control" id="lien_avec_victime" placeholder="Saisir le Lien avec la victime">
							
                     </div>
             </div>

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_officier">Identité de l'officier en chargé du dossier</label>
							<input type="text" v-model="victimeprevenuformData.identite_officier" name="identite_officier" class="form-control" id="identite_officier" placeholder="Saisir le Nom, prenom et contact de l'officier">
							
                     </div>
             </div>

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_suivi_medical">Identité du chargé du suivi médical</label>
							<input type="text" v-model="victimeprevenuformData.identite_suivi_medical" name="identite_suivi_medical" class="form-control" id="identite_suivi_medical" placeholder="Saisir le Nom, prenom et contact chargé du suivi médical">
							
                     </div>
             </div>

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_suivi_psychologie">Identité du chargé du suivi psychologique</label>
							<input type="text" v-model="victimeprevenuformData.identite_suivi_psychologie" name="identite_suivi_psychologie" class="form-control" id="identite_suivi_psychologie" placeholder="Saisir le Nom, prenom et contact chargé du suivi psychologique">
							
                     </div>
             </div>

          </div>
        </div> -->


        <!-- <div  v-if="motifparti=='Prévenu'">
         

          <div class="row">

             <div class="col-md-2">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.date_fait.$error }">
                  <label class="form-label semibold" for="date_fait">Date des faits </label>
                    <input type="date" class="form-control" v-model.trim="victimeprevenuformData.date_fait" @input="$v.victimeprevenuformData.date_fait.$touch()" placeholder="">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.date_fait.$error && !$v.victimeprevenuformData.date_fait.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.lieu_fait.$error }">
                  <label class="form-label semibold" for="lieu_fait">Lieu des faits </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.lieu_fait" @input="$v.victimeprevenuformData.lieu_fait.$touch()" placeholder="Saisir Lieu des faits">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.lieu_fait.$error && !$v.victimeprevenuformData.lieu_fait.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.nature_infraction.$error }">
                  <label class="form-label semibold" for="nature_infraction">Nature de l'infraction </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nature_infraction" @input="$v.victimeprevenuformData.nature_infraction.$touch()" placeholder="Nature de l'infraction">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.nature_infraction.$error && !$v.victimeprevenuformData.nature_infraction.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

              
           <div class="col-md-4">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.info_auteur_presume.$error }">
                  <label class="form-label semibold" for="info_auteur_presume">Identité et informations sur l’auteur présumé </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.info_auteur_presume" @input="$v.victimeprevenuformData.info_auteur_presume.$touch()" placeholder="Entrer l'Identité et informations sur l’auteur présumé">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.info_auteur_presume.$error && !$v.victimeprevenuformData.info_auteur_presume.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

          

          </div>

          <div class="row">
              

               <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.nom_prenom.$error }">
                  <label class="form-label semibold" for="nom_prenom">Nom et prenom(s) </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nom_prenom" @input="$v.victimeprevenuformData.nom_prenom.$touch()" placeholder="Saisir le nom et le prénom(s)">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.nom_prenom.$error && !$v.victimeprevenuformData.nom_prenom.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>
              

            

              <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.profession.$error }">
                  <label class="form-label semibold" for="profession">Profession </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.profession" @input="$v.victimeprevenuformData.profession.$touch()" placeholder="Saisir la Profession">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.profession.$error && !$v.victimeprevenuformData.profession.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

            

             <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.age.$error }">
                  <label class="form-label semibold" for="age">Age <b style="color:red">*</b></label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.age" @input="$v.victimeprevenuformData.age.$touch()" placeholder="Saisir l'âge">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.age.$error && !$v.victimeprevenuformData.age.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

            

              <div class="col-md-2">
                 <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.sexe.$error }">
                         <label class="form-label semibold">Sexe</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="victimeprevenuformData.sexe" :list="listSexe"
                                                placeholder="Selectionner">
                           </model-list-select>
                         <div style="color: red;" v-if="$v.victimeprevenuformData.sexe.$error && !$v.victimeprevenuformData.sexe.required">
                              Ce champs est requis!
                         </div>  
                  </div>
             </div>

             <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.nationalite.$error }">
                  <label class="form-label semibold" for="nationalite">Nationalité <b style="color:red">*</b></label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nationalite" @input="$v.victimeprevenuformData.nationalite.$touch()" placeholder="Saisir la Nationalité">
                   <div style="color: red;" v-if="$v.victimeprevenuformData.nationalite.$error && !$v.victimeprevenuformData.nationalite.required">
                     Ce champs est requis!
                  </div>     
               </div>
           </div>

              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="lien_avec_victime">Lien avec la victime</label>
							<input type="text" v-model="victimeprevenuformData.lien_avec_victime" name="lien_avec_victime" class="form-control" id="lien_avec_victime" placeholder="Saisir le Lien avec la victime">
							
                     </div>
             </div>

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_officier">Identité de l'officier en chargé du dossier</label>
							<input type="text" v-model="victimeprevenuformData.identite_officier" name="identite_officier" class="form-control" id="identite_officier" placeholder="Saisir le Nom, prenom et contact de l'officier">
							
                     </div>
             </div>

          </div>
        </div> -->


        <br> <br>

   
     

    

  <div>
                        
                             
                        
                    
        

            
        
    

      

        </div>
          </div>
		</div>
		




    
		


<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </template>

    <!-- //****** fin gestion des dossiers maison de justice */ -->

    </v-main>
    </v-app>
</template>



<script>
import ThemifyIcon from "vue-themify-icons";
import Multiselect from 'vue-multiselect'
import { FormWizard, TabContent } from "vue-form-wizard";
import moment from 'moment'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    import QrcodeVue from 'qrcode.vue'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect,
     Multiselect,
     FormWizard,
    TabContent,
    ThemifyIcon,
    QrcodeVue
    },
    props: {
    source: String,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nextButtonText: {
      type: String,
      default: "Suivant",
    },
    backButtonText: {
      type: String,
      default: "Précedent",
    },
    finishButtonText: {
      type: String,
      default: "Enregistrer",
    },

    validateOnBack: Boolean,

    color: {
      type: String,
      default: "black",
    },

    errorColor: {
      type: String,
      default: "#8b0000",
    },

    shape: {
      type: String,
      default: "circle",
    },

    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },

    startIndex: {
      type: Number,
      default: 0,
    },

    title1: {
    type: String,
    default: ''
  },
  /***
   * Icon name for the upper circle corresponding to the tab
   * Supports themify icons only for now.
   */
  icon: {
    type: String,
    default: ''
  },
  /***
   * Only render the content when the tab is active
   */
  lazy: {
    type: Boolean,
    default: false
  },
  /***
   * Function to execute before tab switch. Return value must be boolean
   * If the return result is false, tab switch is restricted
   */
  beforeChange: {
    type: Function
  }
  },
        data() {
            return {
               dialog:false,
               test:true,
               datatrue:false,
               infractionvalue:[],
               article_code_penalvalue:[],
              // numrequete:"",
              qrvalue: 'test-124',
               size: 100,
               PatiRequerantformData:{
                 nom:"",
                 prenom:"",
                 nationalite:"",
                 genre:"",
                 age:"",
                 datenaissance:"",
                 lieunaissance:"",
                 adresse:"",
                 profession:"",
                 situationfamiliale:"",
                 contact:"",
                 motif:"",
                 representantlegale:"",
                 contact_representantlegale:"",
                 consentement_ajj:"",
                 attestationconcentement:"",
               },

               victimeprevenuformData:{
                   id_victime:"",
                   date_fait:"",
                   lieu_fait:"",
                   nature_infraction:"",
                   info_auteur_presume:"",
                   nom_prenom:"",
                   profession:"",
                   age:"",
                   sexe:"",
                   nationalite:"",
                   lien_avec_victime:"",
                   identite_officier:"",
                   identite_suivi_medical:"",
                   identite_suivi_psychologie:"",
                   date_mandatdepot:"",
                   date_renouvellement_mandat:"",
                   lieu_detention:"",
                   numero_cellule:"",

               },
               editeclinique:{
                   juridictioncompetente:"",
                   id_dossier:"",
                   numeroParquet:"",
                   typedossier:"",
                   natureinfraction:"",
                   articlereference:"",
                   cabinetinstruction:"",
                   numeroregistreinstruction:"",
                   magistratdossier:"",
                   dateparution:"",
                   nomprenomassocier:"",
                   elementdossier:"",
                   resume_fait:"",
                 qualification_fait:"",
                 analyse_dossier:"",
                 dateconstitution:"",
                   fiche_analyse_cj_id:"",
                   acteur_juridique_id:"",
                   strucuturecharger:"",
                   personneCharger:"",
                   user_id:""
               },
               partie_requerante:[],
               partie:{
                   naturecompromis:"",
                   datesignature:"",
                   suivicompromis:"",
               },
               edite:"",
               



     dialogEdite: false,
      dialogDelete: false,
      listecompromis:[
          {libelle:"F"},
          {libelle:"M"}
          ],

         listtypedossier:[
          {libelle:"Criminel"},
          {libelle:"Correctionnel"},
          {libelle:"Civil"},
          {libelle:"Autre"}
          ],
          listconsentementajj:[
          {id:1,libelle:"oui"},
          {id:2,libelle:"non"}
          ],

          listSexe:[
              {id:1,libelle:"Femme"},
              {id:2,libelle:"Homme"},
              {id:3,libelle:"Autre"}
          ],


          situationfamiliale:[
          {id:1,libelle:"Marié(e) civilement"},
          {id:2,libelle:"Marié(e) traditionnellement"},
          {id:3,libelle:"Veuf/ veuve"},
          {id:4,libelle:"Divorcé(e)"},
          {id:5,libelle:"Père/mère de "},
          {id:6,libelle:"Nombre d'épouses : "}
          ],

      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },

      editedateaudience:"",
      editepeine:"",
      detail_analyse:'',
      editeRenvoi:'',

            }
        },
        created(){
            this.value=this.$route.params.id
             this.editedateaudience=this.getterAudience.filter(item=>item.dossier_clinique_id==this.$route.params.id).sort((a, b) => (a.created_at > b.created_at) ? 1 : -1)
             this.editepeine=this.getterPeine.find(item=>item.dossier_clinique_id==this.$route.params.id)

            this.victimeprevenuformData =this.getterVictimeprevenu.find((item)=>item.dossier_clinique_juridique_id==this.$route.params.id)
            console.log(this.getterVictimeprevenu)
            this.editeclinique = this.getterDossiercliniquejuridique.find((item)=>item.id==this.$route.params.id)
            this.edite=this.getterFicheEntretien.find(item=>item.id==this.editeclinique.fiche_analyse_cj_id)
            console.log(this.edite)
            let vm =this
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });

    this.editeAppel12 =this.getterAppel.find(item=>item.dossier_clinique_id==this.$route.params.id)

    this.editeRenvoi =this.getterRenvoi.filter(item=>item.dossier_clinique_id==this.$route.params.id && item.audience_id!=null)

//    this.detail_analyse=this.getterAnalyseCJ.find(item=>{
//     if(item.fiche_entretien_id==this.edite.id && item.acteur_juridique_id==this.idActeurJuridique){
//         return item
//     }
// })

this.detail_analyse=this.getterAnalyseCJ.find(item=>item.fiche_entretien_id==this.edite.id && item.acteur_juridique_id==this.idActeurJuridique)
        },

      
        computed:{
            ...mapGetters('Application', ["getterErrorDossiercliniquejuridique",
            "getterLoadingDossiercliniquejuridique",'getterCriteIndigence',
            "getterDossiercliniquejuridique",'getterCritereVictimologie',
            "getterInfraction",'getterAnalyseCJ',
            "getterCodePenal",'getterPeine',
            "getterPartieRequerant",
            "getterActeurJuridique",'getterAppel',
            "getterMotifPartie",
            'getterModeSaisine','getterRenvoi',
            "getterFicheEntretien",'getterAudience',
            'getterLocaliteSaisine',
            "getterLoadingFicheEntretien",
            "getterErrorFicheEntretien",
            "getterDossierMaisonJustice",'getterRenvoi2',
            "getterLoadingDossierMaisonJustice",
            "getterErrorDossierMaisonJustice",
            "getterVictimeprevenu"]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),

             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },

            critereIndigence(){
             return id=>{
               if(!id) return ""
               let objet=this.getterCriteIndigence.find(item=>item.id==id)
               return objet.libelle
             }
           },
           
            critereVictimologie(){
             return id=>{
               if(!id) return ""
               let objet=this.getterCritereVictimologie.find(item=>item.id==id)
               return objet.libelle
             }
           },

           mapeine(){
             return id=>{
               if(!id) return ""
               let objet=this.getterPeine.find(item=>item.dossier_clinique_id==id && item.peine!=null)
               if(objet){
                return objet.peine
               } return 0
             }
           },

                conclusion(){
               return critere=>{
                   if(!critere) return ""
                   if(critere==0) return "Non rempli"
                   if(critere==1) return "rempli"
               }
           },
            conclusionAnalyse(){
               return critere=>{
                   if(!critere) return ""
                   if(critere==2) return "Recevable"
                   if(critere==3) return "Irrecevable"
               }
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },
           listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },


    StrucureCharger(){
  
            let Objet= this.getterActeurJuridique.find(item=>item.id==this.editeclinique.acteur_juridique_id)
           if(Objet){
             return this.editeclinique.strucuturecharger = Objet.libelle;
             }return ""
        
        
      },

      persCharger(){

          let Objet= this.getterUser.find(item=>item.id==this.editeclinique.user_id)
           if(Objet){
             return this.editeclinique.personneCharger = Objet.name;
             }return ""
        
        
      },

      recup_numero_requete(){
          let Objet= this.getterFicheEntretien.find(item=>item.id==this.editeclinique.fiche_analyse_cj_id)
           if(Objet){
             return this.editeclinique.acteur_juridique_id = Objet.numrequete;
             }return ""
      },

      libellemotif(){
        return(id)=>{
          if (id != null && id != "" && id != undefined) {
              const Objet=this.getterMotifPartie.find((item)=>item.id==id)
              if(Objet){
             return Objet.libelle;
           }return ''
          }

        }
       
      },

      motifparti(){
        let Objet= this.getterMotifPartie.find(item=>item.id==this.edite.motif_partie_id)
           if(Objet){
             return  Objet.libelle;
             }return ""
      },
           
        },
        validations: {
        //  infractionvalue:{required},
        //  article_code_penalvalue:{required},
            PatiRequerantformData:{
                 nomprenom:{ required },
                 nationalite:{ required },
                 genre:{ required },
                 date_naissance:{ required },
                 lieu_naissance:{ required },
                 lieu_residence:{ required },
                 profession:{ required },
                 situation_famille:{ required },
                 contact:{ required },
                 motif:{ required },
                 representant_legal:{ required },
                 contact_representant_legal:{ required },
                 consentement_ajj:{ required },
               },
        
            partie:{
                   naturecompromis:{ required },
                   datesignature:{ required },
                   suivicompromis:{ required },
               }
        },
        methods:{
             ...mapActions('Application', ['modificationPartieRequerant',
             'getTypeActeurJuridique',
             "ajouterTypeActeurJurique",'getAudience',
             "supprimerTypeActeurJuridique",
             "modificationTypeActeurJuridique",
             "ajouterFicheEntretien",
             "ajouterDossierMaisonJustice",
             "ajouterDossierCliniqueJur",
             "getDossierCliniqueJur",
             "supprimerDossierCliniqueJur",
             "modificationDossierCliniqueJur"]),
async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
            gotoModale(){
              
                      this.$router.push({
        name: "DossierCliniqueJuridique"
      })
            },

          //   addInfraction(newTag){
          //   this.infractionvalue.push(newTag)
          // },

          // addArticle_Code_penal(newTag){
          //   this.article_code_penalvalue.push(newTag)
          // },
  //            handleValidation: function(isValid, tabIndex){
  //     console.log('Tab: '+tabIndex+ ' valid: '+isValid)
  //  },

//    Testvalidate() {
//       this.$v.formData.$touch();
//       var isValid = !this.$v.formData.$error
//       this.$emit('on-validate', this.$data, isValid)
//       return isValid
//     },

    // TestvalidateTba3(){
    //   this.$v.instructionformData.$touch();
    //   var isValid = !this.$v.instructionformData.$error
    //   this.$emit('on-validate', this.$data, isValid)
    //   return isValid
    // },


    // TestvalidateTba2() {
    //   if(this.motifparti=='Détenu Prévenu'){
    //       this.$v.detenuprevenuformData.$touch();
    //       var isValid = !this.$v.detenuprevenuformData.$error
    //        this.$emit('on-validate', this.$data, isValid)
    //          return isValid
    //   }else if(this.motifparti=='Prévenu' || this.motifparti=='Victime'){
    //     this.$v.victimeprevenuformData.$touch();
    //       var isValid = !this.$v.victimeprevenuformData.$error
    //        this.$emit('on-validate', this.$data, isValid)
    //          return isValid
    //   }else{
    //     return true;
    //   }
      
    // },

    // onComplete: function () {
    //    alert("Submitting Form ! Rock On");
    //   },


    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
          
            ajoutercompromis(){
             this.$v.partie.$touch();
                if(this.$v.partie.$error) {
                    return
                }
                this.partie_requerante.push(this.partie)
   this.$v.partie.$reset();
                this.partie={
                   suivicompromis:"",
                   datesignature:"",
                   naturecompromis:"",
               }

            },
            deletePartieRequerante(item){
            if (item > -1) {
              this.partie_requerante.splice(item, 1);
            }
            },
            enregistrement(){
              this.victimeprevenuformData.id_victime =this.victimeprevenuformData.id
              this.editeclinique.id_dossier =this.editeclinique.id
             //console.log(this.editeclinique.id_dossier)
                      let objet={
                   ...this.victimeprevenuformData,
                   ...this.editeclinique
                   }
                   console.log(objet)
                  this.modificationDossierCliniqueJur(objet)
                   this.$router.push({name: "DossierCliniqueJuridique"})

              
            },
            enregistrement1 () {
                  this.formData.fiche_analyse_cj_id=this.critere_indigence
                  this.formData.acteur_juridique_id=this.idActeurJuridique

                  if(this.motifparti=='Victime' || this.motifparti=='Victime'){
                      let objet={
                   ...this.formData,
                   ...this.victimeprevenuformData,
                   ...this.lastformData,
                   ...this.instructionformData
                   }
                   console.log(objet)
                //  this.ajouterDossierMaisonJustice(objet)
                  }else{
                       let objet={
                   ...this.formData,
                   ...this.detenuprevenuformData,
                   ...this.lastformData,
                   ...this.instructionformData
                    }
                    console.log(objet)
                  // this.ajouterDossierMaisonJustice(objet)
                  }
                    
                

                 
              //   if(this.getterErrorFicheEntretien==false){
              //       this.$v.formData.$reset();
              //     this.formData={
              //      dateconstitution:"",
              //      dateentretien:"",
              //       compromis:"",
              //     //motif_partie_id:"",
              //       user_id:"",
              //      ficche_recevabilite_mj_id:""
                 
              //  }
              //  this.numrequete=""
                this.partie_requerante=[]
              //   }
               

            },

             modificationpartie () { 
                 let objet={
                   ...this.PatiRequerantformData,
                 }
                 //console.log(objet)
               this.modificationPartieRequerant(objet)
            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},

 formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },
      editItem (item) {
       window.$("#myModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      //this.datatrue=true

       this.PatiRequerantformData=this.getterPartieRequerant.find(element=>element.id==item)
      },

      detailItem (item) {
       this.$router.push({ name: 'DetailDossierCliniqueJuridique', params: { id: item } })
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

      

    

      
        },
           watch: {
        
         
    //          numrequete: function (value) {
    //   console.log(value);
    //   let vm=this
    //   vm.partie_requerante=[]
    //     if(value!=""){
    // this.edite=this.getterFicheEntretien.find(item=>item.id==value && item.status!=0)
    // this.edite.fiche_partie_requerant.forEach(element => {
    //     vm.partie_requerante.push(element.partie_requerant)
    // });
    //     }
          
   
    // },
       
      },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        color: black;
    }
    .finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
