
<template>
  <v-app>
    <notifications />
    <v-main>

        <Traitementdossier></Traitementdossier>
       <!-- // <ConstitutionDossier></ConstitutionDossier> -->


     
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import Traitementdossier from "./Traitementdossier";
//import ConstitutionDossier from "./ConstitutionDossier";
export default {
  name: "TypeActeur",
  components: {
    Traitementdossier,
   // ConstitutionDossier
  },
  data() {
    return {
      dialog: false,
      test: true,
      legaData: "",
     
      edite: "",

      singleExpand: false,
      expanded: [],
      dialogEdite: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters("Application", [
      "getterDossierMaisonJustice",
      "getterLoadingDossierMaisonJustice",
      "getterErrorTypeActeurJuridique",
      "getterFicheEntretien",
      "getterLoadingFicheEntretien",
      "getterErrorFicheEntretien",
      "getterActeurJuridique",
    ]),
    ...mapGetters("Utilisateurs", [
      "getterErrorUser",
      "getterLoadingUser",
      "getterUser",
      "getterUserStore",
    ]),


    gestionD(){
             if(this.getterUserStore){
               return this.getterUserStore.role_id
             }
             return ""
           },

    Liste_dossier() {
      return this.getterDossierMaisonJustice.filter((item) => item.status == 0);
    },


  },
  methods: {
    ...mapActions("Application", [
    ]), 
    
  },
  watch: {},
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
</style>
