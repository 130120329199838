const defaultstate = {
    
    tableaubord: 15,
    paramGe: 17,
    UnitAdminist: 1,
    Acteurdepense: 2,
    Panification: 3,
    SuivieControBudget: 4,
    BudgetControl:13,
    ComptaMatieres: 5,
    ControlPerfor: 6,
    OpRaportAct: 7,
    Catographie: 8,
    rapport:20,
    ActeurdepenseHS:31,
    ParametreGestionVehicule:42,
    GestionParc:43,
    Reparation:49,
    RapportMarche:89,
    RapportVehicule:90,
    menuExecutionBudgetaire:100,
    MenuTableauBordGestionMarche:45,
    MenuTableauBordGeneral:123,
    MenuBudgetEclater:780,
    MenuOrdrePaiement:1200,
    MenuRapportBudgetExecuteHorsSib:1033,
    active_el: 0
};


 export default defaultstate