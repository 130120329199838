
<template>
<v-app>
    <notifications/>
    <v-main>

    <template v-if="codeUser==1 || 3">
                <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale12()">Gestion des audiences</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Modifier</a></li>
        <input type="hidden" v-model="StrucureCharger">
        <input type="hidden" v-model="nombrepersonneimplique">

			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion des audiences</h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<!-- <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a> -->
					</div>
					<h4 class="panel-title">Formulaire de modification des audiences </h4>
				</div>
				<div class="panel-body">
          <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-7" style="text-align: right">
                  <button
                    type="button"
                    @click="gotoModale12()"
                    class="btn btn-inverse"
                  >
                    RETOUR
                  </button>
                </div>
              </div>

                    <div>
                        
                                <!-- <ul class="nav nav-pills">
						<li class="nav-items">
							<a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active show">
								<span class="d-sm-none">Informations sur la requête </span>
								<span class="d-sm-block d-none">Informations sur la requête </span>
							</a>
						</li>
						<li class="nav-items">
							<a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
								<span class="d-sm-none">Analyse de recevabilité </span>
								<span class="d-sm-block d-none">Analyse de recevabilité </span>
							</a>
						</li>
						
					</ul> -->
                        
                    
        <div class="tab-content">
      

           <ThemifyIcon  />
      <form-wizard 
      @on-complete="enregistrement"
        :nextButtonText="nextButtonText"
        :backButtonText="backButtonText"
        color="#28a745"
        :finishButtonText="finishButtonText"
        :shape="shape"
        :subtitle="subtitle"
        :startIndex="startIndex"
        title=""
        
      >
        <tab-content title="Information(s) sur la requête" >

          <div class="row">

           
                              

             <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="dateconstitution">Numéro de la requête </label>
                    <input type="text" disabled class="form-control" v-model="edite.numrequete">
                   <!-- <div style="color: red;" v-if="$v.editeclinique.dateconstitution.$error && !$v.editeclinique.dateconstitution.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="dateconstitution">Date de constitution  </label>
                    <input type="date" disabled class="form-control" v-model="data_clinique.dateconstitution" >
                        
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="strucuturecharger">Structure chargée du dossier  </label>
                    <input disabled  type="text" class="form-control" v-model="StrucureCharger" placeholder="Structure chargé du dossier">
                        
               </div>
           </div>

            <div class="col-md-3">
           <div class="form-group" >
                         <label class="form-label semibold" for="personneCharger">Avocat Chargé du dossier  </label>
							<input disabled type="text"  v-model="persCharger" name="personneCharger" class="form-control" id="personneCharger" placeholder="Personne Chargée du dossier">
							
                     </div>
                     </div>

            <div class="col-md-3">
           <div class="form-group" >
                         <label class="form-label semibold" for="personneCharger">Juriste Chargé du dossier  </label>
							<input disabled type="text"  v-model="data_clinique.nomprenomassocier" name="personneCharger" class="form-control" id="personneCharger" placeholder="Personne Chargée du dossier">
							
                     </div>
                     </div>
          
        

            

        </div>

        </tab-content>


       <!--  <tab-content title="Partie(s) Requérante(s)" icon="ti-user">
          
            <div class="table-responsive">
               <table class="table  m-b-0" v-if="partie_requerante.length">
                      <thead>
                         <tr>
                            <th>#</th>
                            <th>Nom</th>
                            <th>Prenom</th>
                            <th>Contact</th>
                            <th>Age</th>
                            <th>Genre</th>
                            <th>Action</th>
                          </tr>
                       </thead>
                      <tbody>
                         <tr v-for="(item,index) in partie_requerante" :key="'='+index">
                             <td >{{index + 1}}</td>
                             <td><input disabled type="text" v-model="item.nom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                             <td><input disabled type="text" v-model="item.prenom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                             <td><input disabled type="text" v-model="item.contact" name="nom" class="form-control" id="nom" placeholder="Nom ">
                              </td>
                             
                             <td>
                                <select disabled class="form-control"  v-model="item.genre">
                                    <option></option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                               </select>
                          </td>
                          <td><input disabled type="text" v-model="item.age" name="nom" class="form-control" id="nom" placeholder="Nom ">
                                                  </td>
                          <td class="btn-group btn-group-justified">
                            <a class="btn btn-default" @click="detailItem(item.id)"><i class="fas fa-list-alt"></i></a>
                             <a class="btn btn-default" @click="editItem(item.id)"><i class="fas fa-edit"></i></a>
                          </td>
                        </tr>
                        
                      </tbody>
          </table>
      </div> 



  <div class="modal fade" id="myModal" >
      <div class="modal-dialog modal-lg" style="width:200%">
        <div class="modal-content">
        
         
          <div class="modal-header">
            <h4 class="modal-title">Modification de la partie requérante</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          
         
          <div class="modal-body">
              <div class="row">
                        <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="nomprenom">Nom</label>
                <input type="text" v-model="PatiRequerantformData.nom" name="nomprenom" class="form-control" id="nom" placeholder="Nom et prenom(s)">
                
                      </div>
              </div>

                <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="nomprenom">Prenom(s) </label>
                <input type="text" v-model="PatiRequerantformData.prenom" name="nomprenom" class="form-control" id="prenom" placeholder="Nom et prenom(s)">
                
                      </div>
              </div>


              <div class="col-md-2">
                  <div class="form-group">
                          <label class="form-label semibold">Genre</label>
                              <model-list-select class="form-control"  option-value="libelle"
                                      option-text="libelle" v-model="PatiRequerantformData.genre" :list="listecompromis"
                                                  placeholder="Selectionner">
                          </model-list-select>
                        
                    </div>
              </div>

                <div class="col-md-4">
                    <div class="form-group">
                          <label class="form-label semibold" for="nationalite">Nationalité </label>
                <input type="text" v-model="PatiRequerantformData.nationalite" name="nationalite" class="form-control" id="nationalite" placeholder="Saisir la nationalité">
                
                      </div>
              </div>
              </div>
              <div class="row">


              <div class="col-md-2">
                    <div class="form-group">
                          <label class="form-label semibold" for="datenaissance">Age </label>
                <input type="numnber" v-model="PatiRequerantformData.age" name="datenaissance" class="form-control" id="datenaissance" placeholder="Entrer la date de naissance">
                
                      </div>
              </div>

                <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="contact">Contact </label>
                <input type="number" v-model="PatiRequerantformData.contact" name="contact" class="form-control" id="contact" placeholder="Saisir le contact">
                
                      </div>
              </div>

                <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="datenaissance">Date de naissance </label>
                <input type="date" v-model="PatiRequerantformData.datenaissance" name="datenaissance" class="form-control" id="datenaissance" placeholder="Entrer la date de naissance">
                
                      </div>
              </div>

              <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="lieu_naissance">Lieu de naissance </label>
                <input type="text" v-model="PatiRequerantformData.lieunaissance" name="lieu_naissance" class="form-control" id="lieu_naissance" placeholder="Entrer le lieu de naissance">
                
                      </div>
              </div>

                    

              </div>

              <div class="row">

                  <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="profession">Profession </label>
                <input type="text" v-model="PatiRequerantformData.profession" name="profession" class="form-control" id="profession" placeholder="Saisir la profession">
                
                      </div>
              </div>

              <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="lieu_residence">Lieu de résidence </label>
                <input type="text" v-model="PatiRequerantformData.adresse" name="lieu_residence" class="form-control" id="lieu_residence" placeholder="Saisir le lieu de résidence">
                
                      </div>
              </div>

                <div class="col-md-3">
                  <div class="form-group">
                          <label class="form-label semibold">Situation familiale</label>
                              <model-list-select class="form-control" option-value="libelle"
                                      option-text="libelle" v-model="PatiRequerantformData.situationfamiliale" :list="situationfamiliale"
                                                  placeholder="Choisir la situation familiale">
                          </model-list-select>
                        
                    </div>
              </div>

              <div class="col-md-3">
                      <label class="form-label semibold" for="representant_legal">Représentant légal </label>
                <input  type="text" v-model="PatiRequerantformData.representantlegale" name="representant_legal" class="form-control" id="representant_legal" placeholder="Nom et prenom(s)">
                  </div>

              </div>

              <div class="row">
                <div class="col-md-3">
                      <label class="form-label semibold" for="contact_representant_legal">Contact Représentant légal </label>
                <input  type="text" v-model="PatiRequerantformData.contact_representantlegale" name="contact_representant_legal" class="form-control" id="contact_representant_legal" placeholder="Contact du représentant légal">
                  

              </div>
            
              <div class="col-md-3">
                  <div class="form-group">
                          <label class="form-label semibold">Consentement pour l’AJJ</label>
                              <model-list-select class="form-control"  option-value="libelle"
                                      option-text="libelle" v-model="PatiRequerantformData.consentement_ajj" :list="listconsentementajj"
                                                  placeholder="Selectionner">
                          </model-list-select>
                        
                    </div>
              </div>

                <div class="col-md-3">
                    <div class="form-group">
                          <label class="form-label semibold" for="attestationconcentement">Attestation de concentement </label>
                <input  type="text" v-model="PatiRequerantformData.attestationconcentement" name="attestationconcentement" class="form-control" id="attestationconcentement" placeholder="attestation de concentement">
                
                      </div>
              </div> 

              </div>
          </div>
    
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
            <button @click="modificationpartie()" class="btn btn-success" data-dismiss="modal">Enregistrer</button>
          </div>
          
        </div>
      </div>
    </div>  
              
  </tab-content>-->


  <tab-content title="Partie(s) Civile(s)" icon='ti-user'>
           <!-- <div class="row">

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="Juridiction">Nom <b style="color:red">(*)</b></label>
                    <input type="text" class="form-control" v-model.trim="PartieCivileformData.nom"  placeholder="nom">
                        
               </div>
           </div>

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="prenom">Prénom(s) <b style="color:red">(*)</b></label>
                    <input type="text" class="form-control" v-model.trim="PartieCivileformData.prenom" @input="$v.PartieCivileformData.prenom.$touch()" placeholder="prenom">
                      <div style="color: red;" v-if="$v.PartieCivileformData.prenom.$error && !$v.PartieCivileformData.prenom.required">
                     Ce champs est requis!
                  </div> 
               </div>
           </div> -->

            <!-- <div class="col-md-3">
               <div class="form-group" v-bind:class="{ 'has-error': $v.PartieCivileformData.telephone.$error }">
                  <label class="form-label semibold" for="telephone">Télephone <b style="color:red">(*)</b></label>
                    <input type="number" class="form-control" v-model.trim="PartieCivileformData.telephone" @input="$v.PartieCivileformData.telephone.$touch()"  placeholder="telephone">
                      <div style="color: red;" v-if="$v.PartieCivileformData.telephone.$error && !$v.PartieCivileformData.telephone.required">
                     Ce champs est requis!
                  </div> 
               </div>
           </div> -->

           <!-- <div class="col-md-2">
               <div class="form-group">
                  <br>
               <a href="#" @click.prevent="ajouterpartiecivile()" class="btn btn-primary"> ajouter
                  <i class="fas fa-plus"></i>
               </a>
                </div>
             </div> -->

           <!-- </div> -->

             <table class="table table-striped m-b-0" >
									<thead>
										<tr>
											<th>#</th>
											<th>
                        Nom</th>
											<th>Prénom(s)</th>
                                            <th>Téléphone</th>
                                            <th style="width:16%">Action</th>
										</tr>
									</thead>
									<tbody v-if="datapartiecivile.length">
										<tr v-for="(item,index) in datapartiecivile" :key="'='+index">
											<td >{{index + 1}}</td>
                                            <td><input  type="text" v-model="item.nom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                                            <td><input  type="text" v-model="item.prenom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                                            <td><input  type="text" v-model="item.telephone" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
											
                      <td>
                        <a href="#" @click.prevent="deletePartieRequerante(index)" class="btn btn-danger">
                  <i class="fas fa-trash"></i>
                  </a>
                      </td>
                      
										</tr>
										
									</tbody>
</table>
        </tab-content>

        <tab-content title="Audience" icon="ti-check" >
         

            <div>

          <div class="row">

             <div class="col-md-3">
                 <div class="form-group" >
                         <label class="form-label semibold">Niveau <b style="color:red">(*)</b></label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model="editedateaudience.niveau_audience" :list="listeniveau_audience"
                                                placeholder="Selectionner">
                           </model-list-select>
                         
                  </div>
             </div>

             <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="juridictioncompetente">juridiction compétente </label>
                    <input disabled type="text" class="form-control" v-model="data_clinique.juridictioncompetente"  placeholder="juridiction competente">
                       
               </div>
           </div>

           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="typedossier">Type de dossier </label>
                    <input disabled type="text" class="form-control" v-model="editedateaudience.typedossier"  placeholder="type de dossier">
                      
               </div>
           </div>
              

            

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nbre_personne_implique">Nombre de personnes impliquées </label>
                    <input disabled type="text" class="form-control" v-model.trim="editedateaudience.nbre_personne_implique"  placeholder="Nombre de personnes impliquées">
                        
               </div>
           </div>

           <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_mandatdepot">Date de mandat du dépôt <b style="color:red">(*)</b></label>
                    <input type="date" class="form-control" v-model="editedateaudience.date_mandatdepot"  placeholder="Saisir l'âge">
                       
               </div>
           </div> -->

            

             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="numeroParquet">N° Registre du parquet </label>
                    <input disabled type="text" class="form-control" v-model="editedateaudience.numeroParquet"  placeholder="N° Registre parquet">
                      
               </div>
           </div>

           
             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="president">Nom et prénom(s) du Président </label>
                    <input type="text" class="form-control" v-model="editedateaudience.president"  placeholder="Nom & prénom(s) du Président">
                        
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="ministere_public">Nom et prénom(s) du ministère Public </label>
                    <input type="text" class="form-control" v-model="editedateaudience.ministere_public"  placeholder="Nom & prénom(s) du Ministère Public">
                       
               </div>
           </div>

            <div class="col-md-3">
                            <div class="form-group">
                              <label
                                class="form-label semibold"
                                for="ministere_public"
                                >Date de l'audience
                                <b style="color: red">*</b></label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model.trim="editedateaudience.date_audience"
                                placeholder=""
                              />
                            </div>
                          </div>

           </div>



           <div class="row">

                 <div class="col-md-6">
                     <div class="form-group" >
                         <label class="form-label semibold" for="rappel_fait">Rappel des faits : <b style="color:red">(*)</b></label>
                         <textarea  class="form-control" v-model="editedateaudience.rappel_fait" name="" id="" cols="60" rows="4"></textarea>
                        
                    </div>
                </div>
                <div class="col-md-6">
                     <div class="form-group" >
                           <label class="form-label semibold" for="deroulement_audience">Déroulé de l’audience :<b style="color:red">(*)</b> </label>
                             <textarea  class="form-control" v-model="editedateaudience.deroulement_audience" name="" id="" cols="120" rows="4"></textarea>
                                 
                      </div>
                 </div>
            </div>
        </div>


        </tab-content>

         <tab-content title="Renvoi(s)" >
          <!-- <div class="col-md-12" style="text-align: right">
                      	<button type="button"  @click="gotoModale()" class="btn btn-inverse">Nouveau renvoi
                        </button>
                    </div> -->
                    <!-- <div class="row" >
                        <div class="col-md-4">
                           <div class="form-group">
                               <label class="form-label semibold" for="attestationconcentement">Date renvoi </label>
                               <input  type="date" v-model="renvoiformData.date" name="attestationconcentement" class="form-control" id="attestationconcentement" placeholder="">
                
                            </div>
                        </div> 

                        <div class="col-md-4">
                           <div class="form-group">
                               <label class="form-label semibold" for="attestationconcentement">Motif renvoi </label>
                               <input  type="text" v-model="renvoiformData.motif" name="attestationconcentement" class="form-control" id="attestationconcentement" placeholder="Entrer le motif du renvoi ">
                
                            </div>
                        </div> 
                    </div> -->



                     <table class="table table-striped m-b-0" >
									<thead>
										<tr>
											<th>#</th>
											
											<th>Date renvoi</th>
                                            <th>Motif renvoi</th>
                                            <th style="width:16%">Action</th>
										</tr>
									</thead>
									<tbody v-if="datapartiecivile1.length">
										<tr v-for="(item,index) in datapartiecivile1" :key="'='+index">
											<td >{{index + 1}}</td>
                                            <td><input  type="date" v-model="item.date" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                                            <td><input  type="text" v-model="item.motif" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                                           
											
                      <td>
                        <a href="#" @click.prevent="deletePartieRequerante1(index)" class="btn btn-danger">
                  <i class="fas fa-trash"></i>
                  </a>
                      </td>
                      
										</tr>
										
									</tbody>
</table>
             <!-- <div class="table-responsive">
                     
               <table class="table-bordered table-hover table  m-b-0" v-if="partie_requerante.length">
                      <thead>
                         <tr>
                            <th>Renvoi</th>
                            <th>date</th>
                            <th>Motif</th>
                          </tr>
                       </thead>
                      <tbody>
                         <tr v-for="(item,index) in partie_requerante" :key="'='+index">
                             <td v-if="index+1==1">{{index + 1+'ère renvoi'}}</td>
                             <td v-if="index+1>1">{{index + 1+'ème renvoi'}}</td>
                             <td>{{item.nom}}</td>
                             <td>{{item.prenom}}</td>
                            
                        </tr>
                        
                      </tbody>
          </table>
      </div>  -->
           
        </tab-content>

        
        <tab-content title="Décision">
             
            <div class="row">
                 <div class="col-md-6">
                       <div class="form-group" >
                             <label class="form-label semibold" for="requisition_procurreur">Réquisitions du procureur <b style="color:red">*</b>  </label>
                             <textarea  class="form-control" v-model.trim="editedateaudience.requisition_procurreur" name="" id="" cols="120" rows="4"></textarea>

                       </div>
                  </div>
                    <div class="col-md-6">
                         <div class="form-group" >
                             <label class="form-label semibold" for="ligne_defense_avocat">Ligne de défense de l’avocat <b style="color:red">*</b> </label>
                             <textarea  class="form-control" v-model.trim="editedateaudience.ligne_defense_avocat" name="" id="" cols="120" rows="4"></textarea>
                              
                          </div>
                     </div>
            </div>
           <div class="row">
              <div class="col-md-6">
                     <div class="form-group" >
                           <label class="form-label semibold" for="decision">Décision <b style="color:red">*</b> </label>
                           <textarea  class="form-control" v-model.trim="editedateaudience.decision" name="" id="" cols="120" rows="4"></textarea>
                           
                     </div>
                  </div>

            

              <!-- <div class="col-md-2">
                 <div class="form-group" v-bind:class="{ 'has-error': $v.victimeprevenuformData.sexe.$error }">
                         <label class="form-label semibold">Sexe</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="victimeprevenuformData.sexe" :list="listSexe"
                                                placeholder="Selectionner">
                           </model-list-select>
                         <div style="color: red;" v-if="$v.victimeprevenuformData.sexe.$error && !$v.victimeprevenuformData.sexe.required">
                              Ce champs est requis!
                         </div>  
                  </div>
             </div> -->

             <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nbre_audience">Nombre d’audience <b style="color:red">(*)</b></label>
                    <input type="number" class="form-control" v-model="editedateaudience.nbre_audience"  placeholder="Nombre d'audience ">
                     
               </div>
           </div> -->

           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_decision">Date de la décision <b style="color:red">*</b></label>
                    <input type="date" class="form-control" v-model="editedateaudience.date_decision"  placeholder=" ">
                       
               </div>
           </div>

            </div>

            <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="decision_peine">Décision(peine) <b style="color:red">(*)</b></label>
                    <input type="text" class="form-control" v-model.trim="editedateaudience.decision_peine"  placeholder=" ">
                      
               </div>
           </div> -->

           <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="pers_charger_peine">Personne chargée du suivi de la peine<b style="color:red">(*)</b></label>
                    <input type="text" class="form-control" v-model.trim="editedateaudience.pers_charger_peine"  placeholder=" ">
                      
               </div>
           </div> -->
            

         
            

           

           

        
        </tab-content>


          <tab-content title="Peine">
                      <div class="table-responsive">
                        <table class="table m-b-0"  v-if="partie_requerante.length"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nom</th>
                              <th>Prénom(s)</th>
                              <th>Contact</th>

                              <th style="width: 200px">Décision / peine</th>
                              <th>Personne chargée du suivi de la décision / peine</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in partie_requerante"
                              :key="'=' + index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td> <input disabled  type="text"  v-model="item.nom"   name="nom"
                                  class="form-control"    id="nom"  placeholder="Nom " />
                              </td>
                              <td><input  disabled  type="text" v-model="item.prenom"
                                  name="nom"  class="form-control" id="nom" placeholder="Nom "/>
                              </td>
                              <td>
                                <input  disabled type="text"  v-model="item.contact"
                                  name="nom"  class="form-control" id="nom" placeholder="Nom " />
                              </td>

                              <td>
                                <div class="form-group">
                                  <select class="form-control" v-model.trim="item.decision_peine"
                                  >
                                    <option></option>
                                    <option value="1">Condamné</option>
                                    <option value="2">
                                      Condamné avec sursis
                                    </option>
                                    <option value="3">Acquitté relaxé</option>
                                  </select>
                                </div>
                              </td>

                              <td>
                                <div class="form-group">
                                  <input  type="text"  class="form-control"
                                    v-model.trim="item.pers_charger_peine" placeholder=" " />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </tab-content>


        

        <template slot="footer" scope="props">
          <div class="wizard-footer-left">
            <wizard-button
              v-if="props.activeTabIndex > 0 && !props.isLastStep"
              :style="props.fillButtonStyle"
              @click.native="props.prevTab()"
              >Précédent</wizard-button
            >
          </div>
          <div class="wizard-footer-right">
            <wizard-button
              v-if="!props.isLastStep"
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              >Suivant</wizard-button
            >

            <wizard-button
              v-else
              @click.native="enregistrement()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              >{{ props.isLastStep ? "Enregistrer" : "Suivant" }}</wizard-button
            >
          </div>
        </template>
      </form-wizard>

            
        
    

        </div>

        </div>
          </div>
		</div>
		




    
		


<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </template>

    <!-- //****** fin gestion des dossiers maison de justice */ -->

    </v-main>
    </v-app>
</template>



<script>
import ThemifyIcon from "vue-themify-icons";
import Multiselect from 'vue-multiselect'
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect,
     Multiselect,
     FormWizard,
    TabContent,
    ThemifyIcon,
    },
    props: {
    source: String,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nextButtonText: {
      type: String,
      default: "Suivant",
    },
    backButtonText: {
      type: String,
      default: "Précedent",
    },
    finishButtonText: {
      type: String,
      default: "Enregistrer",
    },

    validateOnBack: Boolean,

    color: {
      type: String,
      default: "black",
    },

    errorColor: {
      type: String,
      default: "#8b0000",
    },

    shape: {
      type: String,
      default: "circle",
    },

    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },

    startIndex: {
      type: Number,
      default: 0,
    },

    title1: {
    type: String,
    default: ''
  },
  /***
   * Icon name for the upper circle corresponding to the tab
   * Supports themify icons only for now.
   */
  icon: {
    type: String,
    default: ''
  },
  /***
   * Only render the content when the tab is active
   */
  lazy: {
    type: Boolean,
    default: false
  },
  /***
   * Function to execute before tab switch. Return value must be boolean
   * If the return result is false, tab switch is restricted
   */
  beforeChange: {
    type: Function
  }
  },
        data() {
            return {
               dialog:false,
               test:true,
               datatrue:false,
   //       lastformData:"",
               datashow_renvoi:0,
               infractionvalue:[],
               article_code_penalvalue:[],

               lastformData:{
                 decision:"",
                 ligne_defense_avocat:"",
                 requisition_procurreur:"",
                 nbre_audience:"",
                 date_decision:"",
                 decision_peine:"",
                 pers_charger_peine:"",

               },
               numrequete:"",
               renvoiformData:{
                 date:"",
                 motif:"",
                // dossier_clinique_id:""
               },
               formData:{
                   dateconstitution:"",
                   dossier_clinique_id:"",
                   acteur_juridique_id:"",
                   fiche_entretien_id:"",
                   strucuturecharger:"",
                   personneCharger:"",
                   user_id:"",
                   structure_id:''
                 
               },
              //  PatiRequerantformData:{
              //    nom:"",
              //    prenom:"",
              //    nationalite:"",
              //    genre:"",
              //    age:"",
              //    datenaissance:"",
              //    lieunaissance:"",
              //    adresse:"",
              //    profession:"",
              //    situationfamiliale:"",
              //    contact:"",
              //    motif:"",
              //    representantlegale:"",
              //    contact_representantlegale:"",
              //    consentement_ajj:"",
              //    attestationconcentement:"",
              //  },

              data_clinique:{
                ministere_public:"",
                president:"",
                numeroParquet:"",
                date_mandatdepot:"",
                nbre_personne_implique:"",
                typedossier:"",
                juridictioncompetente:"",
                niveau_audience:"",
               // nbre_audience:"",
               // date_decision:"",
              //  pers_charger_peine:"",
                //decision_peine:""
              },

              PartieCivileformData:{
                nom:"",
                prenom:"",
                telephone:"",
               // dossier_clinique_id:""
              },
               partie_requerante:[],
               datapartiecivile:[],
               datapartiecivile1:[],
               
               partie:{
                   naturecompromis:"",
                   datesignature:"",
                   suivicompromis:"",
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,
      listecompromis:[
          {libelle:"oui"},
          {libelle:"non"}
          ],

          listtypedossier:[
          {libelle:"Criminel"},
          {libelle:"Correctionnel"}
          ],
         
          listeniveau_audience:[
            {id:1,libelle:"Première instance"},
            {id:1,libelle:"Appel"}
          ],


          situationfamiliale:[
          {id:1,libelle:"Marié(e) civilement"},
          {id:2,libelle:"Marié(e) traditionnellement"},
          {id:3,libelle:"Veuf/ veuve"},
          {id:4,libelle:"Divorcé(e)"},
          {id:5,libelle:"Père/mère de "},
          {id:6,libelle:"Nombre d'épouses : "}
          ],

      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },
      editedateaudience:{
niveau_audience:'',
juridictioncompetente:'',
typedossier:"",
nbre_personne_implique:'',
date_mandatdepot:'',
numeroParquet:"",
president:'',
ministere_public:'',
rappel_fait:'',
deroulement_audience:'',
nbre_audience:'',
date_decision:'',
decision_peine:'',
pers_charger_peine:'',
requisition_procurreur:'',
ligne_defense_avocat:'',
decision:'',

},
        editepartiecivile:"",
        editeRenvoi:"",
            }
        },
        created(){
            //this.$route.params.id  datapartiecivile  datapartiecivile1
            let vm =this
            this.editedateaudience=this.getterAudience.find(item=>item.id==this.$route.params.id)
            this.editepartiecivile=this.getterPartieCivil.filter(item=>item.audience_id==this.editedateaudience.id)
            this.editepartiecivile.forEach(item2=>{
                vm.datapartiecivile.push(item2)
            });

            this.editeRenvoi=this.getterRenvoi.filter(item=>item.audience_id==this.editedateaudience.id)
            this.editeRenvoi.forEach(item2=>{
                vm.datapartiecivile1.push(item2)
            });

          this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.id==this.editedateaudience.dossier_clinique_id)
    this.formData.dateconstitution=this.data_clinique.dateconstitution
    this.edite=this.getterFicheEntretien.find(item=>item.id==this.data_clinique.fiche_analyse_cj_id && item.status!=0)
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        },

      
        computed:{
            ...mapGetters('Application', ["getterErrorDossiercliniquejuridique",
            "getterAudience",
            "getterDossiercliniquejuridique","getterRenvoi",'getterPartieCivil',
            "getterInfraction",
            "getterCodePenal",
            "getterPartieRequerant",
            "getterActeurJuridique",
            "getterMotifPartie",
            "getterVictimeprevenu",
            'getterModeSaisine',
            "getterFicheEntretien",
            'getterLocaliteSaisine',]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },

            idStructure(){
             if(this.getterUserStore){
               return this.getterUserStore.structure_id
             }
             return ""
           },
           listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

            listeAnalyse1(){
             let collect=[];

             this.getterFicheEntretien.filter((item)=>{
              this.getterDossiercliniquejuridique.filter((item1)=>{
                   if(item1.fiche_analyse_cj_id==item.id && item1.etat==0){
                        collect.push(item);
                   }
               })
              //  if(val!=undefined){
              //    collect.push(item);
              //    return item
              //  }
             });
            return collect;

           },
           
           listegetterInfraction(){
              return this.getterInfraction.filter((item)=>item.etat==0)
           },

           listegetterCodePenal(){
              return this.getterCodePenal.filter((item)=>item.etat==0)
           },

           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },

    StrucureCharger(){
            let Objet= this.getterActeurJuridique.find(item=>item.id==this.editedateaudience.acteur_juridique_id)
           if(Objet){
             return Objet.libelle;
             }return ""
        
        
      },

      persCharger(){
        
          let Objet= this.getterUser.find(item=>item.id==this.editedateaudience.user_id)
           if(Objet){
             return  Objet.name;
             }return ""
        
        
      },

        nombrepersonneimplique(){
          let Objet=this.getterVictimeprevenu.filter(item=>item.dossier_clinique_juridique_id==this.data_clinique.id)
         
         if(Objet){
         
           return this.data_clinique.nbre_personne_implique =this.partie_requerante.length + Objet.length;
         }
         
      },

      motifparti(){
        let Objet= this.getterMotifPartie.find(item=>item.id==this.edite.motif_partie_id)
           if(Objet){
             return  Objet.libelle;
             }return ""
      },
           
        },
        validations: {
          infractionvalue:{required},
          article_code_penalvalue:{required},

            // PatiRequerantformData:{
            //      nomprenom:{ required },
            //      nationalite:{ required },
            //      genre:{ required },
            //      date_naissance:{ required },
            //      lieu_naissance:{ required },
            //      lieu_residence:{ required },
            //      profession:{ required },
            //      situation_famille:{ required },
            //      contact:{ required },
            //      motif:{ required },
            //      representant_legal:{ required },
            //      contact_representant_legal:{ required },
            //      consentement_ajj:{ required },
            //    },

      data_clinique:{
                ministere_public:{ required },
                president:{ required },
                numeroParquet:{ required },
                date_mandatdepot:{ required },
                nbre_personne_implique:{ required },
                typedossier:{ required },
                juridictioncompetente:{ required },
                niveau_audience:{ required },
              //  nbre_audience:{ required },
              //  date_decision:{ required },
              //  pers_charger_peine:{ required },
             //   decision_peine:{ required },
                deroulement_audience:{ required },
                rappel_fait:{ required },
              },
            //  numrequete:{ required },

        
            formData: {
                  // dateconstitution:{ required },
                  // strucuturecharger:{ required },
            },

           lastformData:{
                 decision:{ required },
                 ligne_defense_avocat:{ required },
                 requisition_procurreur:{ required },
                //  deroulement_audience:{ required },
                //  rappel_fait:{ required },
                  nbre_audience:{ required },
                 date_decision:{ required },
                 decision_peine:{ required },
                 pers_charger_peine:{ required },
               },

                PartieCivileformData:{
                nom:{ required },
                prenom:{ required },
                telephone:{ required },
              },
            // partie:{
            //        naturecompromis:{ required },
            //        datesignature:{ required },
            //        suivicompromis:{ required },
            //    }
        },
        methods:{
             ...mapActions('Application', ['modificationPartieRequerant',
             'getTypeActeurJuridique',
             "getAudience",
             "ajouterAudience","modificationAudience",
             "ajouterFicheEntretien",
             "getDossierCliniqueJur",
             ]),
            gotoModale(){
              if(this.datashow_renvoi==1){
                 return this.datashow_renvoi=0;
              }
              return this.datashow_renvoi=1;
            },

            gotoModale12() {
      this.$router.push({
        name: "ListeAudiance",
      });
    },

            addInfraction(newTag){
            this.infractionvalue.push(newTag)
          },

          addArticle_Code_penal(newTag){
            this.article_code_penalvalue.push(newTag)
          },
  //            handleValidation: function(isValid, tabIndex){
  //     console.log('Tab: '+tabIndex+ ' valid: '+isValid)
  //  },

   Testvalidate() {
    // this.numrequete.$touch()
      this.$v.formData.$touch();
     var isValid = !this.$v.formData.$error
      //var isValid1 = !this.$v.numrequete.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba3(){
      this.$v.data_clinique.$touch();
      var isValid = !this.$v.data_clinique.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    // TestvalidateTba4(){
    //   this.$v.ajouterAudience.$touch();
    //   console.log(this.$v.ajouterAudience.$error)
    //   var isValid = !this.$v.ajouterAudience.$error
    //   this.$emit('on-complete', this.$data, isValid)
    //   return isValid
    // },

    TestvalidateTba2() {
      if(this.motifparti=='Détenu Prévenu'){
          this.$v.detenuprevenuformData.$touch();
          var isValid = !this.$v.detenuprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else if(this.motifparti=='Prévenu' || this.motifparti=='Victime'){
        this.$v.victimeprevenuformData.$touch();
          var isValid = !this.$v.victimeprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else{
        return true;
      }
      
    },

    // onComplete: function () {
    //    alert("Submitting Form ! Rock On");
    //   },


    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
          
            ajouterpartiecivile(){
            this.$v.PartieCivileformData.$touch();
                 if(this.$v.PartieCivileformData.$error) {
                     return
                 }
                this.datapartiecivile.push(this.PartieCivileformData)
   this.$v.PartieCivileformData.$reset();
                this.PartieCivileformData={
                nom:"",
                prenom:"",
                telephone:"",
              }

            },
            //datapartiecivile1

            deletePartieRequerante1(item){
            if (item > -1) {
              this.datapartiecivile1.splice(item, 1);
            }
            },
            deletePartieRequerante(item){
            if (item > -1) {
              this.datapartiecivile.splice(item, 1);
            }
            },
            enregistrement(){
             
                //   this.formData.fiche_entretien_id=this.numrequete
                //   this.formData.dossier_clinique_id=this.data_clinique.id  
                //   this.formData.acteur_juridique_id=this.idActeurJuridique
                //   this.formData.structure_id=this.idStructure
                //   this.formData.user_id=this.getterUserStore.id
                  //this.renvoiformData.dossier_clinique_id=this.data_clinique.id
                //  this.PartieCivileformData.dossier_clinique_id=this.data_clinique.id
               // console.log(this.formData.acteur_juridique_id)

                      let objet={
                   ...this.editedateaudience,
                    PartieCivileformData:this.datapartiecivile,
                    datarenvoi:this.datapartiecivile1,
                     Partiereq: this.partie_requerante,
                //   ...this.instructionformData,
                  // tableau_infraction:this.infractionvalue,
                  // tableau_article_code:this.article_code_penalvalue
                   }
                   console.log(this.datapartiecivile1)
                  this.modificationAudience(objet)
                  this.$router.push({name:"ListeAudiance"})
  
              
            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},
      // editItem (item) {
      //  window.$("#myModal").modal({
      //   backdrop: "static",
      //   keyboard: false,
      // });
      // //this.datatrue=true

      //  this.PatiRequerantformData=this.getterPartieRequerant.find(element=>element.id==item)
      // },

      detailItem (item) {
       this.$router.push({ name: 'DetailDossierCliniqueJuridique', params: { id: item } })
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

      

    

      
        },
           watch: {
        
         
             numrequete: function (value) {
      console.log(value);
      let vm=this
      vm.partie_requerante=[]
        if(value!=""){
    this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.fiche_analyse_cj_id==value)
    this.formData.dateconstitution=this.data_clinique.dateconstitution
    this.edite=this.getterFicheEntretien.find(item=>item.id==value && item.status!=0)
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        }
          
   
    },
       
      },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        color: black;
    }
    .finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
