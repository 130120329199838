
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
          <li class="breadcrumb-item">
            <a href="#" @click.prevent="gotoModale()"
              >Enregistrement de la requête</a
            >
          </li>
          <li class="breadcrumb-item"><a href="javascript:;">Ajouter</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h5 class="page-header" style="color:#fff">
          Gestion 
        </h5>
        <!--  end page-header -->
        <!-- begin panel -->
        <div class="panel panel-inverse">
          <div class="panel-heading">
           	<div class="panel-heading-btn xl">
					
						<a @click="gotoModale()" class="btn btn-dark" ><i class=""></i>Retour</a>
						<a href="javascript:;"  ><i class=""></i></a>
						<a href="javascript:;" class="" ><i class=""></i></a>

					</div>
            <h4 class="panel-title">Formulaire d'enregistrement de la requête </h4>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-5">
                <div class="form-group">
                  <label class="form-label semibold">Acteur juridique</label>
                  <model-list-select
                    class="form-control"
                    :class="{
                      hasErroInpu: $v.formData.acteur_juridique_id.$error,
                    }"
                    option-value="id"
                    option-text="libelle"
                    v-model="formData.acteur_juridique_id"
                    :list="listeActeurJuriduqe"
                    placeholder="Sélectionner l'acteur juridique"
                  >
                  </model-list-select>
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.acteur_juridique_id.$error"
                  >
                    veuillez sélectionner l'acteur juridique
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="num"
                    >Numéro de la requête
                  </label>
                  <input
                    disabled
                    type="text"
                    :class="{ hasErroInpu: $v.formData.numrequete.$error }"
                    v-model="formData.numrequete"
                    name="num"
                    class="form-control"
                    id="num"
                    placeholder="Numéro de la requête dans la base "
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.numrequete.$error"
                  >
                    veuillez entrer le numero de la requête
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de réception
                  </label>
                  <input
                    type="date"
                    :class="{ hasErroInpu: $v.formData.datareception.$error }"
                    v-model="formData.datareception"
                    name="datareception"
                    class="form-control"
                    id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.datareception.$error"
                  >
                    veuillez entrer le date
                  </div>
                </div>
              </div>

              <div class="col-md-7">
                <div class="form-group">
                  <label class="form-label semibold">Mode de saisine</label>
                  <model-list-select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.mode_saisine_id.$error }"
                    option-value="id"
                    option-text="libelle"
                    v-model="formData.mode_saisine_id"
                    :list="listemodesaisine"
                    placeholder="Sélectionner le mode de saisine"
                  >
                  </model-list-select>
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.mode_saisine_id.$error"
                  >
                    veuillez entrer le mode de saisine
                  </div>
                </div>
              </div>


               <div class="col-md-5">
                <div class="form-group">
                  <label class="form-label semibold">Localité de saisine</label>
                  <model-list-select
                    class="form-control"
                    :class="{
                      hasErroInpu: $v.formData.localite_saisine_id.$error,
                    }"
                    option-value="id"
                    option-text="libelle"
                    v-model="formData.localite_saisine_id"
                    :list="getterLocaliteSaisine"
                    placeholder="Sélectionner localité de saisine"
                  >
                  </model-list-select>
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.localite_saisine_id.$error"
                  >
                    veuillez entrer localite de saisine
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
             
            </div>
            <div class="row">
              <div class="col-md-3" v-if="codeUser == 1">
                <div class="form-group">
                  <label class="form-label semibold"
                    >Statut de la partie requérante </label
                  >
                  <model-list-select
                    class="form-control"
                    option-value="id"
                    option-text="libelle"
                    v-model="formData.motif_partie_id"
                    :list="getterMotifPartie"
                    placeholder="Statut de la partie requérante"
                  >
                  </model-list-select>
                  <!-- <div style="color: red;" class="" v-if="$v.formData.motif_partie_id.$error">
                                Veuillez entrer motif  partie requerant
                       </div> -->
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label semibold" for="nom">Nom </label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.partie.nom.$error }"
                    v-model="partie.nom"
                    name="nom"
                    class="form-control"
                    id="nom"
                    placeholder="Nom "
                  />
                  <div style="color: red" class="" v-if="$v.partie.nom.$error">
                    Veuillez entrer le nom
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label semibold" for="nom">Prénom(s)</label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.partie.prenom.$error }"
                    v-model="partie.prenom"
                    name="nom"
                    class="form-control"
                    id="prenom"
                    placeholder="Prénom(s)"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.partie.prenom.$error"
                  >
                    Veuillez entrer le(s) prénom(s)
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label semibold" for="contact"
                    >Contact
                  </label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.partie.contact.$error }"
                    v-model="partie.contact"
                    name="contact"
                    class="form-control"
                    id="contact"
                    placeholder="Contact "
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.partie.contact.$error"
                  >
                    Veuillez entrer le contact
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label class="form-label semibold" for="age">Age </label>
                  <input
                    type="text"
                   
                    v-model="partie.age"
                    name="age"
                    class="form-control"
                    id="age"
                    placeholder="Age "
                  />
                  
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label class="form-label semibold">Genre</label>
                  <select
                    :class="{ hasErroInpu: $v.partie.genre.$error }"
                    class="form-control"
                    v-model="partie.genre"
                  >
                    <option></option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                  <div style="color: red" class="" v-if="$v.partie.age.$error">
                    Veuillez entrer le genre
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label class="form-label semibold" style="color: #fff"
                    >Genre</label
                  >
                  <br />
                  <a
                    href="#"
                    @click.prevent="ajouterPartieRequerante()"
                    class="btn btn-primary "
                  >
                    <i class="fas fa-plus"></i> Ajouter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table
          class="table table-striped m-b-0"
          v-if="partie_requerante.length"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Prénom(s)</th>
              <th>Contact</th>
              <th>Age</th>
              <th>Genre</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in partie_requerante" :key="'=' + index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.nom }}</td>
              <td>{{ item.prenom }}</td>
              <td>{{ item.contact }}</td>
              <td>{{ item.age }}</td>
              <td>{{ item.genre }}</td>
              <td>
                <a
                  href="#"
                  @click.prevent="deletePartieRequerante(index)"
                  class="btn btn-danger btn-icon btn-circle"
                >
                  <i class="fas fa-trash"></i> 
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <br /> <br>

        <v-btn class="btn btn-primary"
          :disabled="loading"
          :loading="loading"
          text
          v-if="partie_requerante.length>0"
          @click="enregistrement()"
        >
          Enregistrer
        </v-btn>

        {{ numeroRequete }}

        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!---DIALOGUE DE SUPPRESION-->
<v-dialog
      v-model="dialog1"
      persistent
      max-width="290"
    >
      
      <v-card>
        <v-card-title class="text-h5">
         Continuer
        </v-card-title>
        <v-card-text>Voulez-vous continuer le traitement ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="nePasContinueAnalyse()"
          >
            Non
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="continueAnalyse()"
          >
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

 <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      
      <v-card>
        <v-card-title class="text-h5">
         Continuer
        </v-card-title>
        <v-card-text>Voulez-vous continuer l'analyse ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="nePasContinueAnalyse()"
          >
            Non
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="continueAnalyse()"
          >
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { ModelListSelect } from "vue-search-select";
export default {
  name: "TypeActeur",
  components: {
    ModelListSelect,
  },
  data() {
    return {
      dialog: false,
      dialog1:false,
      test: true,
      formData: {
        numrequete: "",
        datareception: "",
        mode_saisine_id: "",
        localite_saisine_id: "",
        motif_partie_id: "",
        user_id: "",
        acteur_juridique_id: "",
        structure_id: "",
      },
      partie_requerante: [],
      partie: {
        nom: "",
        prenom: "",
        contact: "",
        age: "",
        genre: "",
        motif_partie_id:''
      },
      //formData.motif_partie_id
      edite: "",
      numero_requette:"",
      dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: "Libelle", value: "libelle" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
    };
  },
  created() {
    
  },
  computed: {
    ...mapGetters("Application", [
      "getterMotifPartie",
      "getterModeSaisine",
      "getterLocaliteSaisine",
      "getterErrorTypeActeurJuridique",
      "getterLoadingFicheEntretien",
      "getterErrorFicheEntretien",
      "getterActeurJuridique",
      "getterelementParam",
    ]),
    ...mapGetters("Utilisateurs", [
      "getterErrorUser",
      "getterLoadingUser",
      "getterUser",
      "getterUserStore",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingFicheEntretien;
    },
    codeUser() {
      if (!this.getterUserStore) return 0;
      return this.getterUserStore.status_acteur_juridique;
    },
    idActeurJuridique() {
      if (this.getterUserStore) {
        return this.getterUserStore.acteur_juridique_id;
      }
      return "";
    },

    codeUser12(){
            if(!this.getterUserStore) return 0
            return this.getterUserStore.status_acteur_juridique
        },
    idStructure() {
      if (this.getterUserStore) {
        return this.getterUserStore.structure_id;
      }
      return "";
    },
    listemodesaisine(){
      return this.getterModeSaisine.sort((a, b) => a.created_at - b.created_at)
    },
    listeActeurJuriduqe() {
      if (this.idStructure) {
        return this.getterActeurJuridique.filter(
          (item) => item.structure_id == this.getterUserStore.structure_id
        );
      }
      return [];
    },
    numeroRequete() {
      if (this.getterUserStore) {
        let vm = this;
        const date = new Date();
        let moi = date.getMonth();
        let annee = date.getFullYear();
        let jour = date.getDay();
        let heure = date.getHours();
        let mili = date.getMilliseconds();
        moi = moi + 1;
        let code = +mili + "" + heure + "" + jour + "" + moi + "" + annee;
        console.log(code);
        if (this.getterUserStore.acteur_juridique) {
          vm.formData.numrequete =
            this.getterUserStore.acteur_juridique.code + "/" + code;
          return "";
        }
        return "";
      }
      return "";
    },
  },
  validations: {
    formData: {
      numrequete: { required },
      datareception: { required },
      mode_saisine_id: { required },
      localite_saisine_id: { required },
      //  motif_partie_id:{ required },
      user_id: "",
      acteur_juridique_id: { required },
    },
    partie: {
      nom: { required },
      prenom: { required },
      contact: { required },
      age: "",
      genre: { required },
    },
  },
  methods: {
    ...mapActions("Application", [
      "getTypeActeurJuridique",
      "ajouterTypeActeurJurique",
      "supprimerTypeActeurJuridique",
      "modificationTypeActeurJuridique",
      "ajouterFicheEntretien",
      "ajouterTypeAc1teurJurique",
    ]),
    gotoModale() {
      this.$router.go(-1);
    },
  continueAnalyse(){
if(this.codeUser12==2){
 this.$router.push({name: "NouvelAnalyseMJ", params:{id:this.numero_requette}})
}else{
  this.$router.push({name: "NouvelAnalyseCJ", params:{id:this.numero_requette}})
}
  },
  nePasContinueAnalyse(){
   this.dialog=false
    this.$router.go(-1);
  },


   continueAnalyse1(){
      this.$router.push({name: "AjoutdossierTraitement2", params:{id:this.numero_requette}})
       },
  nePasContinueAnalyse1(){
   this.dialog1=false
    //this.$router.go(-1);
  },
    ajouterPartieRequerante() {
   //   this.dialog= true;
      this.$v.partie.$touch();
      if (this.$v.partie.$error) {
        return;
      }
      this.partie.motif_partie_id=this.formData.motif_partie_id
      console.log(this.partie.motif_partie_id)
      this.partie_requerante.push(this.partie);
      this.$v.partie.$reset();
      this.partie = {
        nom: "",
        prenom: "",
        contact: "",
        age: "",
        genre: "",
      };
    },

    
    deletePartieRequerante(item) {
      if (item > -1) {
        this.partie_requerante.splice(item, 1);
      }
    },
    enregistrement() {
      
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }
      // this.formData.acteur_juridique_id=this.idActeurJuridique
      this.formData.user_id = this.getterUserStore.id;
      this.formData.structure_id = this.idStructure;
      let objet = {
        ...this.formData,
        partie: this.partie_requerante,
      };
      this.ajouterFicheEntretien(objet).then(res=>{
           this.dialog=true
           let data=res.data
           this.numero_requette=data.id
           console.log(res)
      });
      if (this.getterErrorFicheEntretien == false) {
        // if (confirm("vouliez-vous effectué l'analyse de cette requête ?")) {
        //   console.log(this.formData.structure_id);
        //   this.ajouterTypeAc1teurJurique(this.formData);
        //   this.$router.push({ name: "NouvelAnalyse" });
        // } else {
          this.$v.formData.$reset();
          this.formData = {
            numrequete: "",
            datareception: "",
            mode_saisine_id: "",
            localite_saisine_id: "",
            motif_partie_id: "",
            user_id: "",
            acteur_juridique_id: "",
            structure_id: "",
          };
          this.partie_requerante = [];
       // }
      }
    },
    modification(item) {
      this.modificationTypeActeurJuridique(item);
    },
    editItem(item) {
      this.edite = item;
      this.dialogEdite = true;
    },
    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },
    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerTypeActeurJuridique(item.id);
    },
  },
  watch: {},
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
</style>