
<template>
<v-app>
    <notifications/>
    <v-main>

    <template v-if="codeUser==1 || 3">
                <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale()">Dossiers des Cliniques juridiques</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Modification</a></li>
        <input type="hidden" v-model="StrucureCharger">
        <input type="hidden" v-model="recup_numero_requete">
        <input type="hidden" v-model="persCharger">

			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion des dossiers des Cliniques juridiques </h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Formulaire de Modification  </h4>
				</div>
				<div class="panel-body">
          <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                	<button type="button"  @click="gotoModale()" class="btn btn-inverse">RETOUR</button>
              </div>
          </div>

                    <div>
                        
                                <!-- <ul class="nav nav-pills">
						<li class="nav-items">
							<a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active show">
								<span class="d-sm-none">Informations sur la requête </span>
								<span class="d-sm-block d-none">Informations sur la requête </span>
							</a>
						</li>
						<li class="nav-items">
							<a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
								<span class="d-sm-none">Analyse de recevabilité </span>
								<span class="d-sm-block d-none">Analyse de recevabilité </span>
							</a>
						</li>
						
					</ul> -->
                        
                    
        <div class="tab-content">
      

           <ThemifyIcon  />
      <form-wizard 
      @on-complete="enregistrement"
        :nextButtonText="nextButtonText"
        :backButtonText="backButtonText"
        color="#28a745"
        :finishButtonText="finishButtonText"
        :shape="shape"
        :subtitle="subtitle"
        :startIndex="startIndex"
        title=""
        
      >
        <tab-content title="Information sur la requête">
          <div class="row">
             <!-- <div class="col-md-3">
                 <div class="form-group">
                         <label class="form-label semibold">Numéro de la requête</label>
                            <model-list-select aria-required="required" class="form-control"  option-value="id"
                                    option-text="numrequete" v-model="editeclinique.acteur_juridique_id" :list="listeAnalyse"
                                                placeholder="Selectionner">
                        </model-list-select>
							         
                  </div>
             </div> -->

             <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="dateconstitution">Numéro de la requête </label>
                    <input type="text" disabled class="form-control" v-model="editeclinique.acteur_juridique_id">
                   <!-- <div style="color: red;" v-if="$v.editeclinique.dateconstitution.$error && !$v.editeclinique.dateconstitution.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>
             

            <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="dateconstitution">Date de constitution  </label>
                    <input type="date" class="form-control" v-model="editeclinique.dateconstitution">
                   <!-- <div style="color: red;" v-if="$v.editeclinique.dateconstitution.$error && !$v.editeclinique.dateconstitution.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="strucuturecharger">Structure chargé du dossier  </label>
                    <input disabled  type="text" class="form-control" v-model.trim="editeclinique.strucuturecharger"  placeholder="Structure chargé du dossier">
                   <!-- <div style="color: red;" v-if="$v.formData.strucuturecharger.$error && !$v.formData.strucuturecharger.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-3">
           <div class="form-group" >
                         <label class="form-label semibold" for="personneCharger">Personne Chargée du dossier  </label>
							<input disabled type="text"  v-model.trim="editeclinique.personneCharger" name="personneCharger" class="form-control" id="personneCharger" placeholder="Personne Chargée du dossier">
							
                     </div>
                     </div>
          
        

            

        </div>

        </tab-content>


        <tab-content title="Partie(s) Requérante(s)" icon="ti-user">
          
            <div class="table-responsive">
               <table class="table  m-b-0" v-if="partie_requerante.length">
                      <thead>
                         <tr>
                            <th>#</th>
                            <th>Nom</th>
                            <th>Prénom(s)</th>
                            <th>Contact</th>
                            <th>Age</th>
                            <th style="width:16%">Genre</th>
                            <th style="">Action</th>
                          </tr>
                       </thead>
                      <tbody>
                         <tr v-for="(item,index) in partie_requerante" :key="'='+index">
                             <td >{{index + 1}}</td>
                             <td><input disabled type="text" v-model="item.nom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                             <td><input disabled type="text" v-model="item.prenom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                             <td><input disabled type="text" v-model="item.contact" name="nom" class="form-control" id="nom" placeholder="Nom ">
                              </td>
                             <td><input disabled type="text" v-model="item.age" name="nom" class="form-control" id="nom" placeholder="Nom ">
                                                  </td>
                             <td>
                                <select disabled class="form-control"  v-model="item.genre">
                                    <option></option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                               </select>
                          </td>
                          
                          <td class="btn-group btn-group-justified">
                            <!-- <a class="btn btn-default" @click="detailItem(item.id)"><i class="fas fa-list-alt"></i></a> -->
                             <a title="Mettre a jour les infos du requérant" class="btn btn-default" @click="editItem(item.id)"><i class="fas fa-edit"></i></a>
                             <!-- <a class="btn btn-default" @click="deleteItem(item.id)"><i class="fas fa-trash-alt"></i></a> -->
                          </td>
                        
                          
                        </tr>
                        
                      </tbody>
          </table>
            <hr>
          <p style="text-align:center"><b style="color:red">*</b> vous devez cliquer sur l'icone au niveau de l'action pour mettre les infos du requérant a jour merci !</p>
      </div>



       <!-- The Modal -->
  <div class="modal fade" id="myModal" >
    <div class="modal-dialog modal-lg" style="width:200%">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Informations sur la partie requérante</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
             <div class="row">
                       <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="nomprenom">Nom</label>
							<input type="text" v-model="PatiRequerantformData.nom" name="nomprenom" class="form-control" id="nom" placeholder="Nom et prenom(s)">
							
                     </div>
             </div>

              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="nomprenom">Prenom(s) </label>
							<input type="text" v-model="PatiRequerantformData.prenom" name="nomprenom" class="form-control" id="prenom" placeholder="prénom(s)">
							
                     </div>
             </div>


             <div class="col-md-2">
                 <div class="form-group">
                         <label class="form-label semibold">Genre</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model="PatiRequerantformData.genre" :list="listecompromis"
                                                placeholder="Sélectionner le genre">
                        </model-list-select>
							         
                  </div>
             </div>

             <!-- <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="nationalite">Nationalité </label>
							<input type="text" v-model="PatiRequerantformData.nationalite" name="nationalite" class="form-control" id="nationalite" placeholder="Saisir la nationalité">
							
                     </div>
             </div>-->

              <div class="col-md-4">
                 <div class="form-group">
                         <label class="form-label semibold">Nationalité</label>
                            <model-list-select class="form-control" option-value="id"
                                    option-text="libelle" v-model="PatiRequerantformData.nationalite" :list="getterPaysNationalite"
                                                placeholder="Choisir la nationalité">
                        </model-list-select>
							        
                  </div>
             </div> 

             <div class="col-md-2">
                  <div class="form-group">
                         <label class="form-label semibold" for="datenaissance">Age </label>
							<input type="numnber" v-model="PatiRequerantformData.age" name="datenaissance" class="form-control" id="datenaissance" placeholder="Entrer l'âge">
							
                     </div>
             </div>

              <div class="col-md-2">
                  <div class="form-group">
                         <label class="form-label semibold" for="contact">Contact </label>
							<input type="number" v-model="PatiRequerantformData.contact" name="contact" class="form-control" id="contact" placeholder="Entrer le contact">
							
                    </div>
             </div>

              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="datenaissance">Date de naissance </label>
							<input type="date" v-model="PatiRequerantformData.datenaissance" name="datenaissance" class="form-control" id="datenaissance" placeholder="Entrer la date de naissance">
							
                     </div>
             </div>

             <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="lieu_naissance">Lieu de naissance </label>
							<input type="text" v-model="PatiRequerantformData.lieunaissance" name="lieu_naissance" class="form-control" id="lieu_naissance" placeholder="Entrer le lieu de naissance">
							
                     </div>
             </div>

                    <div class="col-md-2">
                  <div class="form-group">
                         <label class="form-label semibold" for="profession">Profession </label>
							<input type="text" v-model="PatiRequerantformData.profession" name="profession" class="form-control" id="profession" placeholder="Entrer la profession">
							
                    </div>
             </div>

            </div>

            <div class="row">

             <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="lieu_residence">Lieu de résidence </label>
							<input type="text" v-model="PatiRequerantformData.adresse" name="lieu_residence" class="form-control" id="lieu_residence" placeholder="Saisir le lieu de résidence">
							
                    </div>
             </div>

              <div class="col-md-3">
                 <div class="form-group">
                         <label class="form-label semibold">Situation familiale</label>
                            <model-list-select class="form-control" option-value="id"
                                    option-text="libelle" v-model="PatiRequerantformData.situationfamiliale" :list="getterSituationFamille"
                                                placeholder="Sélectionner la situation familiale">
                        </model-list-select>
							        
                  </div>
             </div>

             <div class="col-md-3">
                     <label class="form-label semibold" for="representant_legal">Représentant légal </label>
							<input  type="text" v-model="PatiRequerantformData.representantlegale" name="representant_legal" class="form-control" id="representant_legal" placeholder="nom et prénom(s)">
                </div>

                 <div class="col-md-3">
                     <label class="form-label semibold" for="contact_representant_legal">Contact Représentant légal </label>
							<input  type="text" v-model="PatiRequerantformData.contact_representantlegale" name="contact_representant_legal" class="form-control" id="contact_representant_legal" placeholder="Contact du représentant légal">
                

            </div>

             

              <!-- <div class="col-md-2">
                  <div class="form-group">
                         <label class="form-label semibold" for="motif">Motif </label>
							<input disabled type="text" v-model="PatiRequerantformData.motif" name="motif" class="form-control" id="motif" placeholder="Saisir le motif">
							
                    </div>
             </div> -->


            </div>

            <div class="row">
                
               

             <div class="col-md-3">
                 <div class="form-group">
                         <label class="form-label semibold">Consentement pour l’AJJ</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model="PatiRequerantformData.consentement_ajj" :list="listconsentementajj"
                                                placeholder="Sélectionner">
                        </model-list-select>
							         
                  </div>
             </div>

              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="attestationconcentement">Attestation de concentement </label>
							<input  type="text" v-model="PatiRequerantformData.attestationconcentement" name="attestationconcentement" class="form-control" id="attestationconcentement" placeholder="attestation de concentement">
							
                    </div>
             </div> 

             </div>
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
          <button @click="modificationpartie()" class="btn btn-success" data-dismiss="modal">Enregistrer</button>
        </div>
        
      </div>
    </div>
  </div>

       
              
        </tab-content>


        <tab-content title="informations sur le requérant" icon="ti-check" :before-change="TestvalidateTba4">
          <div class="table-responsive">
               <table class="table  m-b-0" v-if="partie_requerante.length">
                      <thead>
                         <tr>
                            <th>#</th>
                            <th>Nom</th>
                            <th>Prénom(s)</th>
                            <th>Contact</th>
                            <th>Age</th>
                            <th style="width:200px">Genre</th>
                            <th>Action</th>
                          </tr>
                       </thead>
                      <tbody>
                         <tr v-for="(item,index) in partie_requerante" :key="'='+index">
                             <td >{{index + 1}}</td>
                             <td><input disabled type="text" v-model="item.nom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                             <td><input disabled type="text" v-model="item.prenom" name="nom" class="form-control" id="nom" placeholder="Nom "></td>
                             <td><input disabled type="text" v-model="item.contact" name="nom" class="form-control" id="nom" placeholder="Nom ">
                              </td>
                                <td><input disabled type="text" v-model="item.age" name="nom" class="form-control" id="nom" placeholder="Nom ">
                                                  </td>
                             
                             <td>
                                <select disabled class="form-control"  v-model="item.genre">
                                    <option></option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                               </select>
                          </td>
                        
                          <td class="btn-group btn-group-justified">
                             <a  title="Mise à jour déjà effectué" class="btn btn-default" @click="editItAdd(item.id)"><i class="fas fa-check-circle" style="color:green;font-size:25px"></i></a>
                             <!-- <a  title="Mettre a jour les infos sur le requérant" class="btn btn-default" @click="editItAdd(item.id)"><i class="fas fa-edit"  style="color:red;font-size:25px"></i></a> -->
                             <!-- <a class="btn btn-default" @click="deleteItem(item.id)"><i class="fas fa-trash-alt"></i></a> -->
                          </td>
                        
                         
                        </tr>
                        
                      </tbody>
          </table>
          <hr>
          <p style="text-align:center"><b style="color:red">*</b> Vous devez renseigner les informations du requérant avant de continuer merci !</p>
      </div>
         

           <!-- The Modal -->
  <div class="modal fade" id="myModal12" >
    <div class="modal-dialog modal-lg" style="width:200%">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Renseigner les informations sur le réquerant:/ {{libellemotif}}  </h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          <input type="hidden" v-model="id_partie_requerant">
          <!-- si c'est un detenu -->

          <div  v-if="libellemotif=='Prévenu / accusé détenu'">

          
          <div class="row">

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_mandatdepot">Date du mandat de dépôt <b style="color:red"></b>  </label>
                    <input type="date" class="form-control" v-model.trim="victimeprevenuformData.date_mandatdepot"  placeholder="Date du mandat de dépôt">
                   <!-- <div style="color: red;" v-if="$v.detenuprevenuformData.date_mandatdepot.$error && !$v.detenuprevenuformData.date_mandatdepot.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

           <div class="col-md-6">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_renouvellement_mandat">Date de renouvellement du mandat de dépôt le cas échéant  </label>
                    <input type="date" class="form-control" v-model.trim="victimeprevenuformData.date_renouvellement_mandat">
                   <!-- <div style="color: red;" v-if="$v.detenuprevenuformData.date_renouvellement_mandat.$error && !$v.detenuprevenuformData.date_renouvellement_mandat.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="numero_cellule">Numéro de la cellule  </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.numero_cellule"  placeholder="Numéro de cellule">
                   
               </div>
           </div>


            <div class="col-md-5">
                 <div class="form-group" >
                         <label class="form-label semibold">Lieu de détention</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="victimeprevenuformData.lieu_detention" :list="getterLocalisationGeographique"
                                                placeholder="Sélectionner le lieu de détention">
                           </model-list-select>
                          
                  </div>
             </div>


             <div class="col-md-7">
                 <div class="form-group" >
                         <label class="form-label semibold">Nature Infraction <b style="color:red">*</b></label>
                              <multiselect v-model="infractionvalue" tag-placeholder="Ajouter" placeholder="Sélectionner les infractions" label="libelle" track-by="id" :options="listegetterInfraction" :multiple="true" :taggable="true" @tag="addInfraction"></multiselect>
                          
							        
                  </div>
             </div>

          



          </div>


            <div class="row">

             <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_fait">Date des faits </label>
                    <input type="date" class="form-control" v-model="victimeprevenuformData.date_fait"  placeholder="Saisir le N° de cellule">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.date_fait.$error && !$v.victimeprevenuformData.date_fait.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="lieu_fait">Lieu des faits </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.lieu_fait"  placeholder="Saisir le lieu des faits">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.lieu_fait.$error && !$v.victimeprevenuformData.lieu_fait.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>
        
           
            <div class="col-md-5">
               <div class="form-group" >
                  <label class="form-label semibold" for="info_auteur_presume">Identité et informations sur l’auteur présumé </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.info_auteur_presume"  placeholder="Entrer l'identité et informations sur l’auteur présumé">
                  
               </div>
           </div> 

          </div>


            <div class="row">   

             

              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="lien_avec_victime">Lien avec la victime</label>
							<input type="text" v-model="victimeprevenuformData.lien_avec_victime" name="lien_avec_victime" class="form-control" id="lien_avec_victime" placeholder="Saisir le lien avec la victime">
							
                     </div>
             </div>

              <div class="col-md-5">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_officier">Identité de l'officier en charge du dossier</label>
							<input type="text" v-model="victimeprevenuformData.identite_officier" name="identite_officier" class="form-control" id="identite_officier" placeholder="Identité de l'officier en charge du dossier">
							
                     </div>
             </div>

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_suivi_medical">Identité du chargé du suivi médical</label>
							<input type="text" v-model="victimeprevenuformData.identite_suivi_medical" name="identite_suivi_medical" class="form-control" id="identite_suivi_medical" placeholder="Identité du chargé du suivi médical">
							
                     </div>
             </div>

              <div class="col-md-7">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_suivi_psychologie">Identité du chargé du suivi psychologique</label>
							<input type="text" v-model="victimeprevenuformData.identite_suivi_psychologie" name="identite_suivi_psychologie" class="form-control" id="identite_suivi_psychologie" placeholder="Saisir le nom, prénom(s) et contact du chargé du suivi psychologique">
							
                     </div>
             </div>

          </div>

        </div>


           

            <div v-if="libellemotif=='Victime diverse'">
            <!-- <h1>victime</h1> -->
          <div class="row">

             <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_fait">Date des faits </label>
                    <input type="date" class="form-control" v-model="victimeprevenuformData.date_fait"  placeholder="Saisir le N° de cellule">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.date_fait.$error && !$v.victimeprevenuformData.date_fait.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="lieu_fait">Lieu des faits </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.lieu_fait"  placeholder="Saisir le lieu des faits">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.lieu_fait.$error && !$v.victimeprevenuformData.lieu_fait.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>
           <!-- <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="nom_prenom">Nom et prénom(s) </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nom_prenom"  placeholder="Entrer le nom et prénom(s)">
                 
               </div>
           </div> -->




         
            <div class="col-md-7">
                 <div class="form-group" >
                         <label class="form-label semibold">Nature Infraction <b style="color:red">*</b></label>
                              <multiselect v-model="infractionvalue" tag-placeholder="Ajouter" placeholder="Sélectionner les infractions" label="libelle" track-by="id" :options="listegetterInfraction" :multiple="true" :taggable="true" @tag="addInfraction"></multiselect>
                            
							        
                  </div>
             </div>
           
            <div class="col-md-5">
               <div class="form-group" >
                  <label class="form-label semibold" for="info_auteur_presume">Identité et informations sur l’auteur présumé </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.info_auteur_presume"  placeholder="Entrer l'identité et informations sur l’auteur présumé">
                  
               </div>
           </div> 

          </div>
         

          <div class="row">   

             

              <div class="col-md-3">
                  <div class="form-group">
                         <label class="form-label semibold" for="lien_avec_victime">Lien avec la victime</label>
							<input type="text" v-model="victimeprevenuformData.lien_avec_victime" name="lien_avec_victime" class="form-control" id="lien_avec_victime" placeholder="Saisir le lien avec la victime">
							
                     </div>
             </div>

              <div class="col-md-5">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_officier">Identité de l'officier en charge du dossier</label>
							<input type="text" v-model="victimeprevenuformData.identite_officier" name="identite_officier" class="form-control" id="identite_officier" placeholder="Identité de l'officier en charge du dossier">
							
                     </div>
             </div>

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_suivi_medical">Identité du chargé du suivi médical</label>
							<input type="text" v-model="victimeprevenuformData.identite_suivi_medical" name="identite_suivi_medical" class="form-control" id="identite_suivi_medical" placeholder="Identité du chargé du suivi médical">
							
                     </div>
             </div>

              <div class="col-md-7">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_suivi_psychologie">Identité du chargé du suivi psychologique</label>
							<input type="text" v-model="victimeprevenuformData.identite_suivi_psychologie" name="identite_suivi_psychologie" class="form-control" id="identite_suivi_psychologie" placeholder="Saisir le nom, prénom(s) et contact du chargé du suivi psychologique">
							
                     </div>
             </div>

          </div>
        </div>




          <div  v-if="libellemotif=='Prévenu / accusé non détenu'">
            <!-- <h1>prevenue</h1> -->

          <div class="row">

             <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="date_fait">Date des faits </label>
                    <input type="date" class="form-control" v-model.trim="victimeprevenuformData.date_fait" placeholder="Saisir le N° de cellule">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.date_fait.$error && !$v.victimeprevenuformData.date_fait.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="lieu_fait">Lieu des faits </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.lieu_fait"  placeholder="Saisir le lieu des faits">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.lieu_fait.$error && !$v.victimeprevenuformData.lieu_fait.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

            <!-- <div class="col-md-5">
               <div class="form-group">
                  <label class="form-label semibold" for="nom_prenom">Nom et prénom(s) </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nom_prenom" placeholder="Saisir le nom et prénom(s)">
                       
               </div>
           </div> -->

            <!-- <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="nature_infraction">Nature de l'infraction </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.nature_infraction" placeholder="Nature de l'infraction">
                   
               </div>
           </div> -->

            <!-- gestion des nature d'infraction -->
            <div class="col-md-6">
                 <div class="form-group">
                         <label class="form-label semibold">Nature de l'infraction <b style="color:red">*</b></label>
                              <multiselect v-model="infractionvalue" tag-placeholder="Ajouter" placeholder="Sélectionner les infractions" label="libelle" track-by="id" :options="listegetterInfraction" :multiple="true" :taggable="true" @tag="addInfraction"></multiselect>
                           <!-- <div style="color: red;" v-if="$v.infractionvalue.$error && !$v.infractionvalue.required">
                             Ce champs est requis!
                          </div>  -->
							        
                  </div>
             </div>
             <!-- fin des nature d'infraction -->

              
           <div class="col-md-6">
               <div class="form-group" >
                  <label class="form-label semibold" for="info_auteur_presume">Identité et informations sur l’auteur présumé </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.info_auteur_presume"  placeholder="Entrer l'identité et informations sur l’auteur présumé">
                   <!-- <div style="color: red;" v-if="$v.victimeprevenuformData.info_auteur_presume.$error && !$v.victimeprevenuformData.info_auteur_presume.required">
                     Ce champs est requis!
                  </div>  -->
               </div>
           </div>

           </div>
              

            <div class="row">
              <!-- <div class="col-md-5">
               <div class="form-group" >
                  <label class="form-label semibold" for="profession">Profession </label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.profession"  placeholder="Saisir la profession">
                        
               </div>
           </div> -->

           <!-- <div class="col-md-4">
               <div class="form-group" >
                  <label class="form-label semibold" for="age">Age <b style="color:red"></b></label>
                    <input type="text" class="form-control" v-model.trim="victimeprevenuformData.age" placeholder="Saisir l'âge">
                       
               </div>
           </div> -->


            <!-- <div class="col-md-3">
                 <div class="form-group">
                         <label class="form-label semibold">Sexe</label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model="victimeprevenuformData.sexe" :list="listSexe"
                                                placeholder="Sélectionner le sexe">
                           </model-list-select>
                         
                  </div>
             </div> -->

             </div>

          

         

          <div class="row">

             <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nationalite">Nationalité <b style="color:red"></b></label>
                    <input type="text" class="form-control" v-model="victimeprevenuformData.nationalite"  placeholder="Saisir la nationalité">
                  
               </div>
           </div> -->

              <div class="col-md-4">
                  <div class="form-group">
                         <label class="form-label semibold" for="lien_avec_victime">Lien avec la victime</label>
							<input type="text" v-model="victimeprevenuformData.lien_avec_victime" name="lien_avec_victime" class="form-control" id="lien_avec_victime" placeholder="Saisir le lien avec la victime">
							
                     </div>
             </div>

              <div class="col-md-5">
                  <div class="form-group">
                         <label class="form-label semibold" for="identite_officier">Identité de l'officier en charge du dossier</label>
							<input type="text" v-model="victimeprevenuformData.identite_officier" name="identite_officier" class="form-control" id="identite_officier" placeholder="Saisir le nom, prénom(s) et contact de l'officier">
							
                     </div>
             </div>

          </div>
           </div>
      
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
          <button @click="AddinformationRequerant()" class="btn btn-success" data-dismiss="modal">Modifier</button>
        </div>
        
      </div>
    </div>
  </div>
          
        </tab-content>

        <tab-content title="instruction" icon="ti-check" >
         <div class="card bg-light border">
              <div style="text-decoration:underline" class="card-header text-center">Informations sur l’instruction:</div>
            <div class="card-body">
                <div class="row">

                   <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="juridictioncompetente">Juridiction compétente<b style="color:red">(*)</b></label>
                    <input type="text" class="form-control" v-model.trim="editeclinique.juridictioncompetente"  placeholder="Saisir la Juridiction compétente">
                   <!-- <div style="color: red;" v-if="$v.editeclinique.juridictioncompetente.$error && !$v.instructionformData.juridictioncompetente.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

              <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="numeroParquet">N° Registre parquet (RP)<b style="color:red">(*)</b></label>
                    <input type="text" class="form-control" v-model.trim="editeclinique.numeroParquet"  placeholder="Saisir le N° du parquet">
                   <!-- <div style="color: red;" v-if="$v.instructionformData.numeroParquet.$error && !$v.instructionformData.numeroParquet.required">
                     Ce champs est requis!
                  </div>      -->
               </div>
           </div>

             <div class="col-md-3">
                 <div class="form-group" >
                         <label class="form-label semibold">Type de dossier <b style="color:red">(*)</b></label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="editeclinique.typedossier" :list="listtypedossier"
                                                placeholder="Choisir le type de dossier">
                        </model-list-select>
                        <!-- <div style="color: red;" v-if="$v.instructionformData.typedossier.$error && !$v.instructionformData.typedossier.required">
                     Ce champs est requis!
                  </div>  -->
							        
                  </div>
             </div>

             <div class="col-md-3">
                    <div class="form-group" >
                         <label class="form-label semibold" for="cabinetinstruction">Cabinet d’instruction</label>
                         <input type="text" class="form-control" v-model.trim="editeclinique.cabinetinstruction"  placeholder="Entrer le Cabinet d’instruction">
                            <!-- <div style="color: red;" v-if="$v.instructionformData.cabinetinstruction.$error && !$v.instructionformData.cabinetinstruction.required">
                                  Ce champs est requis!
                            </div>      -->
                       </div>
                  </div>


              <!-- <div class="col-md-6">
                 <div class="form-group" >
                         <label class="form-label semibold">Nature Infraction <b style="color:red">(*)</b></label> -->
                           <!-- <label class="typo__label">Sélectionner les critères</label> -->
                              <!-- <multiselect v-model="infractionvalue" tag-placeholder="Ajouter" placeholder="Sélectionner les infractions" label="libelle" track-by="id" :options="getterInfraction" :multiple="true" :taggable="true" @tag="addInfraction"></multiselect> -->
                            <!-- <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="instructionformData.natureinfraction" :list="listtypedossier"
                                                placeholder="Choisir le type de dossier">
                        </model-list-select> -->
                           <!-- <div style="color: red;" v-if="$v.infractionvalue.$error && !$v.infractionvalue.required">
                             Ce champs est requis!
                          </div>  -->
							        
                  <!-- </div>
             </div> -->

              <!-- <div class="col-md-6">
                 <div class="form-group">
                         <label class="form-label semibold">Article(s) de référence <b style="color:red">(*)</b></label>
                           <label class="typo__label">Sélectionner les critères</label>
                              <multiselect v-model="article_code_penalvalue" tag-placeholder="Ajouter" placeholder="Sélectionner les articles du code penal" label="libelle" track-by="id" :options="getterCodePenal" :multiple="true" :taggable="true" @tag="addArticle_Code_penal"></multiselect> -->
                            <!-- <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="instructionformData.natureinfraction" :list="listtypedossier"
                                                placeholder="Choisir le type de dossier">
                        </model-list-select> -->
                           <!-- <div style="color: red;" v-if="$v.article_code_penalvalue.$error && !$v.article_code_penalvalue.required">
                             Ce champs est requis!
                          </div>  
							        
                  </div>
             </div>-->

              <!-- <div class="col-md-4">
                  <div class="form-group" v-bind:class="{ 'has-error': $v.instructionformData.articlereference.$error }">
                         <label class="form-label semibold" for="articlereference">Article(s) de référence<b style="color:red">(*)</b></label>
							              <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="instructionformData.articlereference" :list="listtypedossier"
                                                placeholder="Choisir l'Article(s) de référence">
                           </model-list-select>
                            <div style="color: red;" v-if="$v.instructionformData.articlereference.$error && !$v.instructionformData.articlereference.required">
                                 Ce champs est requis!
                             </div>
                  </div>
               </div> -->

               

              <div class="col-md-4">
                    <div class="form-group" >
                         <label class="form-label semibold" for="numeroregistreinstruction">Numéro du registre d’instruction<b style="color:red">(*)</b></label>
                         <input type="text" class="form-control" v-model.trim="editeclinique.numeroregistreinstruction"  placeholder="Saisir le Numéro du registre d’instruction">
                            <!-- <div style="color: red;" v-if="$v.instructionformData.numeroregistreinstruction.$error && !$v.instructionformData.numeroregistreinstruction.required">
                                  Ce champs est requis!
                            </div>      -->
                     </div>
                </div>
            
            <div class="col-md-4">
                    <div class="form-group" >
                         <label class="form-label semibold" for="magistratdossier">Magistrat en charge du dossier</label>
                         <input type="text" class="form-control" v-model.trim="editeclinique.magistratdossier"  placeholder="Entrer le Magistrat en charge du dossier">
                            <!-- <div style="color: red;" v-if="$v.instructionformData.magistratdossier.$error && !$v.instructionformData.magistratdossier.required">
                                  Ce champs est requis!
                            </div>      -->
                     </div>
                </div>


                <div class="col-md-4">
                    <div class="form-group" >
                         <label class="form-label semibold" for="dateparution">Date de parution devant le juge d’instruction</label>
                         <input type="date" class="form-control" v-model.trim="editeclinique.dateparution"  placeholder="Saisir Date de parution devant le juge d’instruction">
                            <!-- <div style="color: red;" v-if="$v.instructionformData.dateparution.$error && !$v.instructionformData.dateparution.required">
                                  Ce champs est requis!
                            </div>      -->
                     </div>
                </div>

              <div class="col-md-4">
                    <div class="form-group" >
                         <label class="form-label semibold" for="nomprenomassocier">Prénom(s) NOM avocat associé si applicable<b style="color:red">(*)</b></label>
                         <input type="text" class="form-control" v-model.trim="editeclinique.nomprenomassocier"  placeholder="Saisir le Prénom(s) NOM le avocat">
                            <!-- <div style="color: red;" v-if="$v.instructionformData.nomprenomassocier.$error && !$v.instructionformData.nomprenomassocier.required">
                                  Ce champs est requis!
                            </div>      -->
                     </div>
                </div>

              <!-- <div class="col-md-3">
                 <div class="form-group" >
                         <label class="form-label semibold">Element du dossier<b style="color:red">(*)</b></label>
                            <model-list-select class="form-control"  option-value="libelle"
                                    option-text="libelle" v-model.trim="editeclinique.elementdossier" :list="situationfamiliale"
                                                placeholder="Choisir l'element du dossier">
                        </model-list-select>
                       
							         
                  </div>
             </div> -->




            </div>



            </div>

          </div>
        </tab-content >
        
        <tab-content title="Description des Faits">
             <div>
            <div class="row">

                <!-- <div class="col-md-6">
                     <div class="form-group" v-bind:class="{ 'has-error': $v.lastformData.resume_fait.$error }">
                         <label class="form-label semibold" for="resume_fait">Résumé des faits (avec le plus de précision possible)<b style="color:red">(*)</b></label>
                         <textarea  class="form-control" v-model.trim="lastformData.resume_fait" name="" id="" cols="60" rows="4"></textarea>
                          <div style="color: red;" v-if="$v.lastformData.resume_fait.$error && !$v.lastformData.resume_fait.required">
                                  Ce champs est requis!
                            </div>
                    </div>
                </div> -->

                 <div class="col-md-6">
                     <div class="form-group" >
                         <label class="form-label semibold" for="resume_fait">Résumé des faits (avec le plus de précision possible)<b style="color:red">(*)</b></label>
                         <textarea  class="form-control" v-model.trim="editeclinique.resume_fait" name="" id="" cols="60" rows="4"></textarea>
                          <!-- <div style="color: red;" v-if="$v.lastformData.resume_fait.$error && !$v.lastformData.resume_fait.required">
                                  Ce champs est requis!
                            </div> -->
                    </div>
                </div>


                 <div class="col-md-6">
                     <div class="form-group" >
                         <label class="form-label semibold" for="qualification_fait">Qualification des faits<b style="color:red">(*)</b></label>
                         <textarea  class="form-control" v-model.trim="editeclinique.qualification_fait" name="" id="" cols="60" rows="4"></textarea>
                          <!-- <div style="color: red;" v-if="$v.lastformData.qualification_fait.$error && !$v.lastformData.qualification_fait.required">
                                  Ce champs est requis!
                            </div> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group" >
                        <label class="form-label semibold" for="analyse_dossier">Analyse du dossier par rapport à la version des faits de la victime/prévenu  </label>
                        <textarea  class="form-control" v-model.trim="editeclinique.analyse_dossier" name="" id="" cols="120" rows="4"></textarea>
                        <!-- <div style="color: red;" v-if="$v.lastformData.analyse_dossier.$error && !$v.lastformData.analyse_dossier.required">
                                  Ce champs est requis!
                            </div> -->
                    </div>
            </div>
        </div>
        </tab-content>

        <template slot="footer" scope="props">
          <div class="wizard-footer-left">
            <wizard-button
              v-if="props.activeTabIndex > 0 && !props.isLastStep"
              :style="props.fillButtonStyle"
              @click.native="props.prevTab()"
              >Précédent</wizard-button
            >
          </div>
          <div class="wizard-footer-right">
            <wizard-button
              v-if="!props.isLastStep"
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              >Suivant</wizard-button
            >

            <wizard-button
              v-else
              @click.native="enregistrement()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              >{{ props.isLastStep ? "Modifier" : "Suivant" }}</wizard-button
            >
          </div>
        </template>
      </form-wizard>

            
        
    

        </div>

        </div>
          </div>
		</div>
		




    
		


<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </template>

    <!-- //****** fin gestion des dossiers maison de justice */ -->

    </v-main>
    </v-app>
</template>



<script>
import ThemifyIcon from "vue-themify-icons";
import Multiselect from 'vue-multiselect'
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect,
     Multiselect,
     FormWizard,
    TabContent,
    ThemifyIcon,
    },
    props: {
    source: String,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nextButtonText: {
      type: String,
      default: "Suivant",
    },
    backButtonText: {
      type: String,
      default: "Précedent",
    },
    finishButtonText: {
      type: String,
      default: "Enregistrer",
    },

    validateOnBack: Boolean,

    color: {
      type: String,
      default: "black",
    },

    errorColor: {
      type: String,
      default: "#8b0000",
    },

    shape: {
      type: String,
      default: "circle",
    },

    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },

    startIndex: {
      type: Number,
      default: 0,
    },

    title1: {
    type: String,
    default: ''
  },
  /***
   * Icon name for the upper circle corresponding to the tab
   * Supports themify icons only for now.
   */
  icon: {
    type: String,
    default: ''
  },
  /***
   * Only render the content when the tab is active
   */
  lazy: {
    type: Boolean,
    default: false
  },
  /***
   * Function to execute before tab switch. Return value must be boolean
   * If the return result is false, tab switch is restricted
   */
  beforeChange: {
    type: Function
  }
  },
        data() {
            return {
               dialog:false,
                motif_Parti_Req:"",
               libellemotif:'',
               id_partie_requerant:'',
               test:true,
               datatrue:false,
               infractionvalue:[],
               article_code_penalvalue:[],
              // numrequete:"",
               PatiRequerantformData:{
                 nom:"",
                 prenom:"",
                 nationalite:"",
                 genre:"",
                 age:"",
                 datenaissance:"",
                 lieunaissance:"",
                 adresse:"",
                 profession:"",
                 situationfamiliale:"",
                 contact:"",
                 motif:"",
                 representantlegale:"",
                 contact_representantlegale:"",
                 consentement_ajj:"",
                 attestationconcentement:"",
               },

               victimeprevenuformData:{
                   id_victime:"",
                   date_fait:"",
                   lieu_fait:"",
                   nature_infraction:"",
                   info_auteur_presume:"",
                   nom_prenom:"",
                   profession:"",
                   age:"",
                   sexe:"",
                   nationalite:"",
                   lien_avec_victime:"",
                   identite_officier:"",
                   identite_suivi_medical:"",
                   identite_suivi_psychologie:"",
                   date_mandatdepot:"",
                   date_renouvellement_mandat:"",
                   lieu_detention:"",
                   numero_cellule:"",

               },
               editeclinique:{
                   juridictioncompetente:"",
                   id_dossier:"",
                   numeroParquet:"",
                   typedossier:"",
                   natureinfraction:"",
                   articlereference:"",
                   cabinetinstruction:"",
                   numeroregistreinstruction:"",
                   magistratdossier:"",
                   dateparution:"",
                   nomprenomassocier:"",
                   elementdossier:"",
                   resume_fait:"",
                 qualification_fait:"",
                 analyse_dossier:"",
                 dateconstitution:"",
                   fiche_analyse_cj_id:"",
                   acteur_juridique_id:"",
                   strucuturecharger:"",
                   personneCharger:"",
                   user_id:""
               },
               partie_requerante:[],
               partie:{
                   naturecompromis:"",
                   datesignature:"",
                   suivicompromis:"",
               },
               edite:"",
               



     dialogEdite: false,
      dialogDelete: false,
      listecompromis:[
          {libelle:"F"},
          {libelle:"M"}
          ],

         listtypedossier:[
          {libelle:"Criminel"},
          {libelle:"Correctionnel"},
          {libelle:"Civil"},
          {libelle:"Autre"}
          ],
          listconsentementajj:[
          {id:1,libelle:"oui"},
          {id:2,libelle:"non"}
          ],

          listSexe:[
              {id:1,libelle:"Femme"},
              {id:2,libelle:"Homme"},
              {id:3,libelle:"Autre"}
          ],


          situationfamiliale:[
          {id:1,libelle:"Marié(e) civilement"},
          {id:2,libelle:"Marié(e) traditionnellement"},
          {id:3,libelle:"Veuf/ veuve"},
          {id:4,libelle:"Divorcé(e)"},
          {id:5,libelle:"Père/mère de "},
          {id:6,libelle:"Nombre d'épouses : "}
          ],

      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },

            }
        },
        created(){
            this.victimeprevenuformData =this.getterVictimeprevenu.find((item)=>item.dossier_clinique_juridique_id==this.$route.params.id)
            console.log(this.getterVictimeprevenu)
            this.editeclinique = this.getterDossiercliniquejuridique.find((item)=>item.id==this.$route.params.id)
            this.edite=this.getterFicheEntretien.find(item=>item.id==this.editeclinique.fiche_analyse_cj_id)
            console.log(this.edite)
            let vm =this
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        },

      
        computed:{
            ...mapGetters('Application', ["getterErrorDossiercliniquejuridique",
            "getterLoadingDossiercliniquejuridique",'getterLocalisationGeographique',
            "getterDossiercliniquejuridique",
            "getterInfraction",'getterSituationFamille',
            "getterCodePenal",
            "getterPartieRequerant",
            "getterActeurJuridique",
            "getterMotifPartie",
            'getterModeSaisine',
            "getterFicheEntretien",
            'getterLocaliteSaisine',
            "getterLoadingFicheEntretien",
            "getterErrorFicheEntretien",
            "getterDossierMaisonJustice",
            "getterLoadingDossierMaisonJustice",
            "getterErrorDossierMaisonJustice",
            "getterVictimeprevenu","getterPaysNationalite"]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),

             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },
                   listegetterInfraction(){
              return this.getterInfraction.filter((item)=>item.etat==0)
           },
           listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },


    StrucureCharger(){
  
            let Objet= this.getterActeurJuridique.find(item=>item.id==this.editeclinique.acteur_juridique_id)
           if(Objet){
             return this.editeclinique.strucuturecharger = Objet.libelle;
             }return ""
        
        
      },

      persCharger(){

          let Objet= this.getterUser.find(item=>item.id==this.editeclinique.user_id)
           if(Objet){
             return this.editeclinique.personneCharger = Objet.name;
             }return ""
        
        
      },

      recup_numero_requete(){
          let Objet= this.getterFicheEntretien.find(item=>item.id==this.editeclinique.fiche_analyse_cj_id)
           if(Objet){
             return this.editeclinique.acteur_juridique_id = Objet.numrequete;
             }return ""
      },

    //   recup_numero_requete(){
    //     return(id)=>{
    //       if (id != null && id != "" && id != undefined) {
    //           const Objet=this.getterFicheEntretien.find((item)=>item.id==id)
    //           if(Objet){
    //          return Objet.numrequete;
    //        }return ''
    //       }

    //     }
       
    //   },

       motifparti(){
        return id=>{
          if(id!="" && id!=null){
             let Objet= this.getterMotifPartie.find(item=>item.id==id)
             if(Objet){
               return  Objet.libelle;
             }return ""
          }
        }  
      },
           
        },
        validations: {
        //  infractionvalue:{required},
        //  article_code_penalvalue:{required},
            PatiRequerantformData:{
                 nomprenom:{ required },
                 nationalite:{ required },
                 genre:{ required },
                 date_naissance:{ required },
                 lieu_naissance:{ required },
                 lieu_residence:{ required },
                 profession:{ required },
                 situation_famille:{ required },
                 contact:{ required },
                 motif:{ required },
                 representant_legal:{ required },
                 contact_representant_legal:{ required },
                 consentement_ajj:{ required },
               },
        
            partie:{
                   naturecompromis:{ required },
                   datesignature:{ required },
                   suivicompromis:{ required },
               }
        },
        methods:{
             ...mapActions('Application', ['modificationPartieRequerant',
             'getTypeActeurJuridique',
             "ajouterTypeActeurJurique",'ModifierVictimeprevenu',
             "supprimerTypeActeurJuridique",
             "modificationTypeActeurJuridique",
             "ajouterFicheEntretien",
             "ajouterDossierMaisonJustice",
             "ajouterDossierCliniqueJur",
             "getDossierCliniqueJur",
             "supprimerDossierCliniqueJur",
             "modificationDossierCliniqueJur"]),

            gotoModale(){
              
                     // this.$router.push({name: "DossierCliniqueJuridique"})
                      this.$router.go(-1)
            },

          //   addInfraction(newTag){
          //   this.infractionvalue.push(newTag)
          // },

          // addArticle_Code_penal(newTag){
          //   this.article_code_penalvalue.push(newTag)
          // },
  //            handleValidation: function(isValid, tabIndex){
  //     console.log('Tab: '+tabIndex+ ' valid: '+isValid)
  //  },

//    Testvalidate() {
//       this.$v.formData.$touch();
//       var isValid = !this.$v.formData.$error
//       this.$emit('on-validate', this.$data, isValid)
//       return isValid
//     },

    // TestvalidateTba3(){
    //   this.$v.instructionformData.$touch();
    //   var isValid = !this.$v.instructionformData.$error
    //   this.$emit('on-validate', this.$data, isValid)
    //   return isValid
    // },


    // TestvalidateTba2() {
    //   if(this.motifparti=='Détenu Prévenu'){
    //       this.$v.detenuprevenuformData.$touch();
    //       var isValid = !this.$v.detenuprevenuformData.$error
    //        this.$emit('on-validate', this.$data, isValid)
    //          return isValid
    //   }else if(this.motifparti=='Prévenu' || this.motifparti=='Victime'){
    //     this.$v.victimeprevenuformData.$touch();
    //       var isValid = !this.$v.victimeprevenuformData.$error
    //        this.$emit('on-validate', this.$data, isValid)
    //          return isValid
    //   }else{
    //     return true;
    //   }
      
    // },

    // onComplete: function () {
    //    alert("Submitting Form ! Rock On");
    //   },


    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
          
            ajoutercompromis(){
             this.$v.partie.$touch();
                if(this.$v.partie.$error) {
                    return
                }
                this.partie_requerante.push(this.partie)
   this.$v.partie.$reset();
                this.partie={
                   suivicompromis:"",
                   datesignature:"",
                   naturecompromis:"",
               }

            },
            deletePartieRequerante(item){
            if (item > -1) {
              this.partie_requerante.splice(item, 1);
            }
            },
            enregistrement(){
             // this.victimeprevenuformData.id_victime =this.victimeprevenuformData.id
              this.editeclinique.id_dossier =this.editeclinique.id
                      let objet={
                   ...this.editeclinique
                   }
                   console.log(objet)
                  this.modificationDossierCliniqueJur(objet)
                   this.$router.push({name: "DossierCliniqueJuridique"})

              
            },
            enregistrement1 () {
                  this.formData.fiche_analyse_cj_id=this.critere_indigence
                  this.formData.acteur_juridique_id=this.idActeurJuridique

                  if(this.motifparti=='Victime' || this.motifparti=='Victime'){
                      let objet={
                   ...this.formData,
                   ...this.victimeprevenuformData,
                   ...this.lastformData,
                   ...this.instructionformData
                   }
                   console.log(objet)
                //  this.ajouterDossierMaisonJustice(objet)
                  }else{
                       let objet={
                   ...this.formData,
                   ...this.detenuprevenuformData,
                   ...this.lastformData,
                   ...this.instructionformData
                    }
                    console.log(objet)
                  // this.ajouterDossierMaisonJustice(objet)
                  }
                    
                

                 
              //   if(this.getterErrorFicheEntretien==false){
              //       this.$v.formData.$reset();
              //     this.formData={
              //      dateconstitution:"",
              //      dateentretien:"",
              //       compromis:"",
              //     //motif_partie_id:"",
              //       user_id:"",
              //      ficche_recevabilite_mj_id:""
                 
              //  }
              //  this.numrequete=""
                this.partie_requerante=[]
              //   }
               

            },

             modificationpartie () { 
                 let objet={
                   ...this.PatiRequerantformData,
                 }
                 //console.log(objet)
               this.modificationPartieRequerant(objet)
            },

 editItAdd(data) {
       window.$("#myModal12").modal({
        backdrop: "static",
        keyboard: false,
      });
      this.infractionvalue=[]
      
       this.motif_Parti_Req=this.getterPartieRequerant.find(element=>element.id==data)
      
      
       this.id_partie_requerant=this.motif_Parti_Req.id
     
      this.libellemotif=this.motifparti(this.motif_Parti_Req.motif_partie_id)
      // let Objet=this.victimeprevenu(this.motif_Parti_Req.id)  victime_prevenues_id  infractionvalue
       let Objet=this.getterVictimeprevenu.find(item=>item.partie_requerant_id==data)
        this.getterInfraction.filter(item=>{
         if(item.victime_prevenues_id==Objet.id){
           this.infractionvalue.push(item)
         }
       })
      console.log(Objet)
      if(Objet!=undefined){
        this.victimeprevenuformData=Objet
      }else{
         this.victimeprevenuformData={
            date_fait:"",
           lieu_fait:"",
           nature_infraction:"",
           info_auteur_presume:"",
           nom_prenom:"",
            // profession:"",
            // age:"",
           // sexe:"",
           // nationalite:"",
           lien_avec_victime:"",
           identite_officier:"",
           identite_suivi_medical:"",
           identite_suivi_psychologie:"",
             date_mandatdepot:"",
            date_renouvellement_mandat:"",
           lieu_detention:"",
           numero_cellule:"",
           partie_requerant_id:'',
          };
      }
        
    
       //this.getterPartieRequerant.find(item=>item.id==item)
       

      },

   AddinformationRequerant(){
       // this.victimeprevenuformData.partie_requerant_id=this.id_partie_requerant
        
         let objet={
                   ...this.victimeprevenuformData,
                   tableau_infraction:this.infractionvalue,
                 }
                 console.log(objet);
               this.ModifierVictimeprevenu(objet)

        //this.DatavictimeprevenuformData.push(this.victimeprevenuformData);
 
      
      },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},
      editItem (item) {
       window.$("#myModal").modal({
        backdrop: "static",
        keyboard: false,
      });
      //this.datatrue=true

       this.PatiRequerantformData=this.getterPartieRequerant.find(element=>element.id==item)
      },

      detailItem (item) {
       this.$router.push({ name: 'DetailDossierCliniqueJuridique', params: { id: item } })
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

      

    

      
        },
           watch: {
        
         
    //          numrequete: function (value) {
    //   console.log(value);
    //   let vm=this
    //   vm.partie_requerante=[]
    //     if(value!=""){
    // this.edite=this.getterFicheEntretien.find(item=>item.id==value && item.status!=0)
    // this.edite.fiche_partie_requerant.forEach(element => {
    //     vm.partie_requerante.push(element.partie_requerant)
    // });
    //     }
          
   
    // },
       
      },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        color: black;
    }
    .finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
