<template>
   <div>
	<nav class="side-menu-addl">
	    <header class="side-menu-addl-title">
	        <div class="caption">Planification du projet</div>
	        <i class="font-icon font-icon-burger"></i>
	    </header>
	    <ul class="side-menu-addl-list">
	        <li class="header" style="background: orange;">
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie et consolidation des exigences des parties prenantes</span>
	                </span>
	            </a>
	        </li>
	        <li class="divider"></li>
	        <li class="header" style="background: orange;">
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Gestion du perimetre du projet</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des composantes du projet avec poids</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des sous composantes avec poids</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des activites</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des sous activites</span>
	                </span>
	            </a>
	        </li>
			<li >
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Affectation de poids aux sous activites</span>
	                </span>
				</a>
			</li>

	        <li class="divider"></li>
	        <li class="header" style="background: orange;">
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des indicateurs de suivi sur les sous activites</span>
	                </span>
	            </a>
	        </li>
			<li class="divider"></li>
			<li class="header" style="background: orange;">
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des indicateurs strategiques du projet</span>
	                </span>
				</a>
			</li>
			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie indicateur stratégique sur composante</span>
	                </span>
				</a>
			</li>

			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie indicateur stratégique sur sous composante</span>
	                </span>
				</a>
			</li>

			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie indicateur stratégique sur activités</span>
	                </span>
				</a>
			</li>

			<li class="divider"></li>
			<li class="header purple" style="background: orange;">
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des indicateurs strategiques du projet</span>
	                </span>
				</a>
			</li>

			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des ressources du projet</span>
	                </span>
				</a>
			</li>

			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des risques lies aux ressources</span>
	                </span>
				</a>
			</li>

			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie indicateur stratégique sur activités</span>
	                </span>
				</a>
			</li>

			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Saisie des reponses aux risques</span>
	                </span>
				</a>
			</li>
			<li>
				<a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Cartographie pat gravite</span>
	                </span>
				</a>
			</li>
	    </ul>
	</nav><!--.side-menu-addl-->
   </div>



</template>
<script>


    export default {
  props: {
  },
        name: "TheNavBar",
        data() {
            return {

            }
        },
        components: {

        },
computed: {

},
        methods: {


        }
    }
</script>

<style scoped>

</style>
