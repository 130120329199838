<template>
   <div>
   <div id="sidebar" class="sidebar">
			<!-- begin sidebar scrollbar -->
			<div data-scrollbar="true" data-height="100%">
				<!-- begin sidebar user -->
				<ul class="nav">
					<li class="nav-profile">
						<a href="javascript:;">
							<div class="cover with-shadow"></div>
							<div class="image">
								<img :src="imageUsers" alt="" />
							</div>
							<div class="info">
								<b class="caret pull-right"></b>
								{{nameUser}}
								<small></small>
							</div>
						</a>
					</li>
					
				</ul>
				<!-- end sidebar user -->
				<!-- begin sidebar nav -->
				<ul class="nav">
					<li class="nav-header">Navigation</li>
					<li class="has-sub">
						<a href="javascript:;">
							<b class="caret"></b>
							<i class="fa fa-th-large"></i>
							<span>Tableau de bord </span>
						</a>
						<ul class="sub-menu">
							<li :class="{active: menu_active == 1 }" @click.prevent="activedOptionMenu(1,'Home')" ><a href="index.html">Tableau de bord général des requêtes </a></li>
							<!-- <li :class="{active: menu_active == 2 }" @click.prevent="activedOptionMenu(2,'Home2')" ><a href="index_v2.html">Tableau de bord 2</a></li> -->
							<li :class="{active: menu_active == 2 }" @click.prevent="activedOptionMenu(2,'Tableaubord_des_requetes')" ><a href="index_v2.html">Tableau de bord de la recevabilité des requêtes </a></li>
							<li :class="{active: menu_active == 332 }" @click.prevent="activedOptionMenu(332,'Tableaubord_des_dossiers')" ><a href="index_v2.html">Tableau de bord du traitement des </a></li>

							
						</ul>
					</li>
					<!--<li class="has-sub">
						<a href="javascript:;">
							<span class="badge pull-right">10</span>
							<i class="fa fa-hdd"></i> 
							<span>Requête de recevabilite</span>
						</a>
						<ul class="sub-menu">
							<li><a href="email_inbox.html">Inbox</a></li>
							<li><a href="email_compose.html">Compose</a></li>
							<li><a href="email_detail.html">Detail</a></li>
						</ul>
					</li>-->
					<li :class="{active: menu_active == 3 }" @click.prevent="activedOptionMenu(3,'FicheRecevabilite')">
						<a href="widget.html">
						<i class="fas fa-book fa-fw"></i>
							<span>Enregistrement de la requête</span> 
						</a>
					</li>
					<!--<li :class="{active: menu_active == 4 }" @click.prevent="activedOptionMenu(4,'Home')">
						<a href="widget.html">
							<i class="fas fa-spinner fa-pulse"></i>
							<span>Analyse de recevabilité  </span> 
						</a>
					</li>-->
					<li class="has-sub">
						<a href="javascript:;">
							<i class="fas fa-spinner fa-pulse"></i>
							<span>Analyse de la recevabilité  </span> 
						</a>
						<ul class="sub-menu">
							<li><a href="#" :class="{active: menu_active == 4 }" @click.prevent="activedOptionMenu(4,'NouvelAnalyse')">Nouvelle analyse</a></li>
							<li><a href="#" :class="{active: menu_active == 26 }" @click.prevent="activedOptionMenu(26,'AnalyseRecevabilite')">Requêtes analysées</a></li>
					
						</ul>
					</li>
					<li :class="{active: menu_active == 5 }" @click.prevent="activedOptionMenu(5,'IndexGestionDossier')">
						<a href="widget.html">
							<i class="fas fa-folder-open"></i> 
							<span>Gestion des dossiers</span> 
						</a>
					</li>
                   <li v-if="inArray(codeUser,array1)" :class="{active: menu_active == 6 }" @click.prevent="activedOptionMenu(6,'ListeAudiance')">
						<a href="widget.html">
							<i class="fas fa-quote-left fa-2x fa-pull-left "></i> 
							<span>Gestion des audiences </span> 
						</a>
					</li>

					<li v-if="inArray(codeUser,array1)" :class="{active: menu_active == 8 }" @click.prevent="activedOptionMenu(8,'Renvoi')">
						<a href="widget.html">
							<i class="fas fa-pencil-alt fa-fw"></i> 
							<span>Gestion des renvois </span> 
						</a>
					</li>
					
					<li v-if="inArray(codeUser,array1)" :class="{active: menu_active == 7 }" @click.prevent="activedOptionMenu(7,'ListedesAppels')">
						<a href="widget.html">
							<i class="fas fa-clock"></i> 
							<span>Gestion des appels </span> 
						</a>
					</li>
					
					
					<li v-if="inArray(codeUser,array1)" :class="{active: menu_active == 9 }" @click.prevent="activedOptionMenu(9,'Peine')">
						<a href="widget.html">
							<i class="fas fa-calendar-check"></i> 
							<span>Suivi des peines </span> 
						</a>
					</li>
				
					
					<li class="has-sub" v-if='codeUser==3'>
						<a href="javascript:;">
							<b class="caret"></b>
							<i class="fa fa-cogs"></i>
							<span>Configuration</span>
						</a>
						<ul class="sub-menu" >
							<li :class="{active: menu_active == 10 }" @click.prevent="activedOptionMenu(10,'StructureGeographique')"><a href="#">Structure géographique</a></li>
							<li :class="{active: menu_active == 29 }" @click.prevent="activedOptionMenu(29,'Structure')"><a href="#">Liste des structures</a></li>
							<li :class="{active: menu_active == 11 }" @click.prevent="activedOptionMenu(11,'LocalisationGeographique')"><a href="#">Localisation Géographique</a></li>
							<li :class="{active: menu_active == 12 }" @click.prevent="activedOptionMenu(12,'CodePenal')"><a href="#">Article du code pénal </a></li>
							<li :class="{active: menu_active == 13 }" @click.prevent="activedOptionMenu(13,'CritereVictimologie')"><a href="#">Critères de victimologie </a></li>
							<li :class="{active: menu_active == 14 }" @click.prevent="activedOptionMenu(14,'CriteIngigence')"><a href="#">Critères d’indigence </a></li>
							<li :class="{active: menu_active == 15 }" @click.prevent="activedOptionMenu(15,'ModeSaisine')"><a href="#">Mode de saisine </a></li>
							<li :class="{active: menu_active == 24 }" @click.prevent="activedOptionMenu(24,'LocaliteSaisine')"><a href="#">Localité de saisine</a></li>
							<li :class="{active: menu_active == 16 }" @click.prevent="activedOptionMenu(16,'MotifPartie')"><a href="#">Statut de la partie requérante</a></li>
							<li :class="{active: menu_active == 18 }" @click.prevent="activedOptionMenu(18,'NatureDossier')"><a href="#">Nature du dossier </a></li>
							<li :class="{active: menu_active == 19 }" @click.prevent="activedOptionMenu(19,'TypePersonneTribunal')"><a href="#">Type de personnes du tribunal </a></li>
							<li :class="{active: menu_active == 20 }" @click.prevent="activedOptionMenu(20,'TypeElementDossier')"><a href="#">Type d’élément du dossier</a></li>
							<li :class="{active: menu_active == 21 }" @click.prevent="activedOptionMenu(21,'TypeActeur')"><a href="#">Type d’acteurs </a></li>
							<li :class="{active: menu_active == 22 }" @click.prevent="activedOptionMenu(22,'ActeurJuridique')"><a href="#">Acteur juridique</a></li>
							<!-- <li :class="{active: menu_active == 23 }" @click.prevent="activedOptionMenu(23,'StructureGeographique')"><a href="#">Tribunal</a></li> -->
							<li :class="{active: menu_active == 27 }" @click.prevent="activedOptionMenu(27,'TypeAffaire')"><a href="#">Type d’affaire </a></li>
							<li :class="{active: menu_active == 28 }" @click.prevent="activedOptionMenu(28,'Infraction')"><a href="#">Liste des infractions </a></li>
							<li :class="{active: menu_active == 280 }" @click.prevent="activedOptionMenu(280,'Situationfamille')"><a href="#">Situation familiale </a></li>
							<li :class="{active: menu_active == 281 }" @click.prevent="activedOptionMenu(281,'PaysNationalite')"><a href="#">Liste des pays </a></li>
							
						</ul>
					</li>
					
					<li class="has-sub" v-if='codeUser==3'>
						<a href="javascript:;">
							<b class="caret"></b>
							<i class="fa fa-key"></i>
							<span>Gestion des accès</span>
						</a>
						<ul class="sub-menu">
							<li><a href="#" :class="{active: menu_active == 24 }" @click.prevent="activedOptionMenu(24,'Utilisateur')">Gestion des utilisateurs</a></li>
							<!-- <li  v-if='codeUser==3'><a href="login_v2.html">Gestion rôle & permission</a></li> -->
						
						</ul>
					</li>
				
					<li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i class="fa fa-angle-double-left"></i></a></li>
					<!-- end sidebar minify button -->
				</ul>
				<!-- end sidebar nav -->
			</div>
			<!-- end sidebar scrollbar -->
		</div>
		<div class="sidebar-bg"></div>
   </div>



</template>
<script>
 import TheNavClotureProject from "./TheNavClotureProject";
 import TheNavExecutionProject from "./TheNavExecutionProject";
 import TheNavInitialisationProject from "./TheNavInitialisationProject";
 import TheNavPlanAction from "./TheNavPlanAction";
 import TheNavPlanificationProjet from "./TheNavPlanificationProjet";

 import TheNavSuivieMaitrise from "./TheNavSuivieMaitrise";
  import NavAccueil from "./NavAccueil";

import { mapState, mapActions ,mapMutations,mapGetters} from "vuex";
    export default {
		components: {
			TheNavSuivieMaitrise,
			NavAccueil,
			TheNavClotureProject,
			TheNavExecutionProject,
			TheNavInitialisationProject,
			TheNavPlanAction,
			TheNavPlanificationProjet
		},
        name: "TheNavBar",
        data() {
            return {
		menu_active:1,
		urlBase:process.env.VUE_APP_BASE,
		array1:[1,3],
		array2:[2,3],
            }
        },
		 created(){
			 
			 
        },

computed: {
...mapGetters("Utilisateurs",["getterUserStore"]),
       ...mapState('parametrageMenu', {
     active_el: state => state.active_el
  }),
  nameUser(){
	  if(!this.getterUserStore) return "JUSTICE"

	  let objet=this.getterUserStore
	  return objet.name
  },
 
   inArray() {
      return (valeur, tableau)=>{
         let length = tableau.length;
        for(let i = 0; i < length; i++) {
            if(tableau[i] == valeur) return true;
        }
    return false;
      }
   
},
  imageUsers(){
	    if(this.getterUserStore.profile_photo_path){
			  let id_user=this.getterUserStore.id
			  return this.urlBase+'/users/'+id_user+"/"+this.getterUserStore.profile_photo_path
		  }
		    return this.urlBase+"/users/default/1.jpg"
  },
  codeUser(){
	  if(!this.getterUserStore) return 0
	  return this.getterUserStore.status_acteur_juridique
  }
},
        methods: {
        ...mapActions('Utilisateurs', ['logoutUser']),
			afficheMenu(value){
	this.activate(value)
			},
				activedOptionMenu(value,route_name){
		  this.menu_active= value
		  this.$router.push({
        name: route_name
      })
	  
	   }
        }
    }
</script>

<style scoped>

</style>
