<template>
  
		<div id="content" class="content">
			<Loader :isVisible="loading" :isText="'Chargement en cours...'"/>
    
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item active">Tableau de bord général des requêtes  </li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Tableau de bord général des requêtes  </h1>
			<!-- end page-header -->
			
			<!-- begin row -->
			<div class="row">
				<!-- begin col-3 -->
				<div class="col-lg-3 col-md-6">
					<div class="widget widget-stats bg-red">
						<div class="stats-icon"><i class="fa fa-desktop"></i></div>
						<div class="stats-info">
							<h4 style="font-size:15px">Requêtes enregistrées totales  </h4>
							<p>{{nombreTotalRequete}}</p>	
                             <p>100 %</p>
						</div>
						<div class="stats-link">
							<a href="javascript:;"> Détails <i class="fa fa-arrow-alt-circle-right"></i></a>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="widget widget-stats bg-orange">
						<div class="stats-icon"><i class="fa fa-link"></i></div>
						<div class="stats-info">
							<h4 style="font-size:15px">Requêtes analysées rejetées </h4>
							<p>{{RequeteAnalyseRejetes}}</p>	
							<p>{{PourcentageRequeteAnalyseRejetes}} %</p>
						</div>
						<div class="stats-link">
							<a href="javascript:;"> Détails <i class="fa fa-arrow-alt-circle-right"></i></a>
						</div>
					</div>
				</div>
				
	
				<div class="col-lg-3 col-md-6">
					<div class="widget widget-stats bg-success">
						<div class="stats-icon"><i class="fa fa-users"></i></div>
						<div class="stats-info">
							<h4 style="font-size:15px">Requêtes analysées acceptées </h4>
							<p>{{RequeteAnalyseAccepte}}</p>	
							<p>{{PourcentageRequeteAnalyseAccepte}} %</p>
						</div>
						<div class="stats-link">
							<a href="javascript:;">Détails <i class="fa fa-arrow-alt-circle-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-md-6" v-if="codeUser==1">
					<div class="widget widget-stats bg-primary">
						<div class="stats-icon"><i class="fa fa-clock"></i></div>
						<div class="stats-info">
							<h4 style="font-size:15px">Requêtes acceptées traitées </h4>
							<p>{{NombreRequeteAccepterTraiter}}</p>
							<p>{{PourcentageNombreRequeteAccepterTraiter}} %</p>		
						</div>
						<div class="stats-link">
							<a href="javascript:;">Détails <i class="fa fa-arrow-alt-circle-right"></i></a>
						</div>
					</div>
				</div>


				<div class="col-lg-3 col-md-6" v-if="codeUser==2">
					<div class="widget widget-stats bg-primary">
						<div class="stats-icon"><i class="fa fa-clock"></i></div>
						<div class="stats-info">
							<h4 style="font-size:15px">Requêtes acceptées traitées </h4>
							<p>{{NombreRequeteAccepterTraiterMJ}}</p>
							<p>{{PourcentageNombreRequeteAccepterEnCoursTraitementMJ}} %</p>		
						</div>
						<div class="stats-link">
							<a href="javascript:;">Détails <i class="fa fa-arrow-alt-circle-right"></i></a>
						</div>
					</div>
				</div>


				<div class="col-lg-3 col-md-6" v-if="codeUser==3">
					<div class="widget widget-stats bg-primary">
						<div class="stats-icon"><i class="fa fa-clock"></i></div>
						<div class="stats-info">
							<h4 style="font-size:15px">Requêtes acceptées traitées </h4>
							<p>{{NombreRequeteAccepterTraiterMJ + NombreRequeteAccepterTraiter}}</p>
							<p>{{PourcentageTraiterTotalAdministrateur }} %</p>		
						</div>
						<div class="stats-link">
							<a href="javascript:;">Détails <i class="fa fa-arrow-alt-circle-right"></i></a>
						</div>
					</div>
				</div>
				<!-- end col-3 -->
				<!-- begin col-3 -->
				
				<!-- end col-3 -->
			</div>
			<!-- end row -->
			
			<div class="row">
				<div class="col-lg-6" v-if="codeUser==1">
					<!-- begin panel -->
					<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Enregistrement et analyse de recevabilité des requêtes par les Cliniques juridiques </h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
								
										
								</table>

			<table class="table table-valign-middle">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>
					<tr>
                    <th>Requêtes enregistrées totales </th>
                    <td colspan="2" >{{ nombreTotalRequete }}</td>
                    <td >{{ 100 }} %</td>
                  </tr>

                  <tr>
                    <th>Requêtes enregistrées en cours d’analyse </th>
                    <td colspan="2">{{ RequeteEnAttenteAnalyse }}</td>
                    <td>{{ PourcentageRequeteEnAttenteAnalyse }} %</td>
                  </tr>

				   

				   <tr >
                    <th>Requêtes analysées rejetées</th>
                    <td colspan="2" >{{ RequeteAnalyseRejetes}}</td>
                    <td >{{ PourcentageRequeteAnalyseRejetes }} %</td>
                  </tr>

				    <tr >
                    <th>Requêtes analysées acceptées</th>
                    <td colspan="2">{{ RequeteAnalyseAccepte }}</td>
                    <td>{{ PourcentageRequeteAnalyseAccepte }} %</td>
                  </tr>

                  
                </tbody>
              </table>
							</div>
						</div>
					</div>
					<!-- end panel -->
					
					
				</div>

					<div class="col-lg-6" v-if="codeUser==2">
					<!-- begin panel -->
					<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Enregistrement et analyse de recevabilité des requêtes par les Maisons de justice </h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
								
										
								</table>

			<table class="table table-valign-middle">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>
					<tr>
                    <th>Requêtes enregistrées totales </th>
                    <td colspan="2" >{{ nombreTotalRequete }}</td>
                    <td >{{ 100 }} %</td>
                  </tr>

                  <tr>
                    <th>Requêtes enregistrées en cours d’analyse </th>
                    <td colspan="2">{{ RequeteEnAttenteAnalyse }}</td>
                    <td>{{ PourcentageRequeteEnAttenteAnalyse }} %</td>
                  </tr>

				   

				   <tr >
                    <th>Requêtes analysées rejetées</th>
                    <td colspan="2" >{{ RequeteAnalyseRejetes}}</td>
                    <td >{{ PourcentageRequeteAnalyseRejetes }} %</td>
                  </tr>

				    <tr >
                    <th>Requêtes analysées acceptées</th>
                    <td colspan="2">{{ RequeteAnalyseAccepte }}</td>
                    <td>{{ PourcentageRequeteAnalyseAccepte }} %</td>
                  </tr>

                  
                </tbody>
              </table>
							</div>
						</div>
					</div>
					<!-- end panel -->
					
					
				</div>

				<div class="col-lg-6" v-if="codeUser==3">
					<!-- begin panel -->
					<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Enregistrement et analyse de recevabilité des requêtes </h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
								
										
								</table>

			<table class="table table-valign-middle">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>
					<tr>
                    <th>Requêtes enregistrées totales</th>
                    <td colspan="2" >{{ nombreTotalRequete }}</td>
                    <td >{{ 100 }} %</td>
                  </tr>

                  <tr>
                    <th>Requêtes enregistrées en cours d’analyse </th>
                    <td colspan="2">{{ RequeteEnAttenteAnalyse }}</td>
                    <td>{{ PourcentageRequeteEnAttenteAnalyse }} %</td>
                  </tr>

				   

				   <tr >
                    <th>Requêtes analysées rejetées</th>
                    <td colspan="2" >{{ RequeteAnalyseRejetes}}</td>
                    <td >{{ PourcentageRequeteAnalyseRejetes }} %</td>
                  </tr>

				    <tr >
                    <th>Requêtes analysées acceptées</th>
                    <td colspan="2">{{ RequeteAnalyseAccepte }}</td>
                    <td>{{ PourcentageRequeteAnalyseAccepte }} %</td>
                  </tr>

                  
                </tbody>
              </table>
							</div>
						</div>
					</div>
					<!-- end panel -->
					
					
				</div>




					<div class="col-lg-6" v-if="codeUser==1">

						<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Analyse de la recevabilité des requêtes détaillées par les Cliniques juridiques</h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
									<thead>
										<tr>	
											<th>Acteurs juridiques</th>
											<th>Requêtes acceptées</th>
											<th>Requêtes rejetées</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in listeActeurJuriduqe" :key="item.id">
											<td>{{item.libelle}}</td>
											<td>{{nombrePasStatusActeur(2,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id) + nombrePasStatusActeur(2,item.id)}} </td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<!-- begin panel -->
					<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Traitement des requêtes par les Cliniques juridiques </h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								

			<table class="table table-valign-middle">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>
					<tr>
                    <th>Total requêtes acceptées</th>
                    <td colspan="2" >{{ TotalRequeteAccepteCJ }}</td>
                    <td >{{ PourcentageTotalRequeteAccepteCJ }} %</td>
                  </tr>
					<tr>
                    <th>requêtes acceptées en cours de traitement</th>
                    <td colspan="2" >{{ NombreRequeteAccepterEnCoursTraitement }}</td>
                    <td >{{ PourcentageNombreRequeteAccepterEnCoursTraitement }} %</td>
                  </tr>

                  <tr>
                    <th>Requêtes acceptées traitées </th>
                    <td colspan="2">{{ NombreRequeteAccepterTraiter }}</td>
                    <td>{{ PourcentageNombreRequeteAccepterTraiter }} %</td>
                  </tr>
                  
                </tbody>
              </table> 
			  
							</div>

							
						</div>
					</div>


					
					<!-- end panel -->
					
					
				</div>

		

				<div class="col-lg-6" v-if="codeUser==2">

						<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Analyse de la recevabilité des requêtes détaillée par Maison de justice</h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
									<thead>
										<tr>	
											<th>Acteurs juridiques</th>
											<th>Requêtes acceptées</th>
											<th>Requêtes rejetées</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in listeActeurJuriduqe" :key="item.id">
											<td>{{item.libelle}}</td>
											<td>{{nombrePasStatusActeur(2,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id) + nombrePasStatusActeur(2,item.id)}} </td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<!-- begin panel -->
					<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Traitement des requêtes détaillé par Maison de justice  </h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								

			<table class="table table-valign-middle">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>

					<tr>
                    <th>Total requêtées acceptées</th>
                    <td colspan="2" >{{ TotalRequeteAccepteMj }}</td>
                    <td >{{ PourcentageTotalRequeteAccepteMj }} %</td>
                  </tr>
					<tr>
                    <th>Requêtées acceptées en cours de traitement</th>
                    <td colspan="2" >{{ NombreRequeteAccepterEnCoursTraitementMJ }}</td>
                    <td >{{ PourcentageNombreRequeteAccepterEnCoursTraitementMJ }} %</td>
                  </tr>

                  <tr>
                    <th>Requêtes acceptées traitées </th>
                    <td colspan="2">{{ NombreRequeteAccepterTraiterMJ }}</td>
                    <td>{{  PourcentageNombreRequeteAccepterTraiterMJ}} %</td>
                  </tr>
                  
                </tbody>
              </table> 
			  
							</div>

							
						</div>
					</div>


				
					<!-- end panel -->
					
					
				</div>

					<div class="col-lg-6" v-if="codeUser==3">


						<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Analyse de la recevabilité des requêtes détaillée</h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
									<thead>
										<tr>	
											<th>Acteurs juridiques</th>
											<th>Requêtes acceptées</th>
											<th>Requêtes rejetées</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in listeActeurJuriduqe" :key="item.id">
											<td>{{item.libelle}}</td>
											<td>{{nombrePasStatusActeur(2,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id) + nombrePasStatusActeur(2,item.id)}} </td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
					</div>

						<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Traitement des requêtes </h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								

			<table class="table table-valign-middle">
                <thead>
                  <tr style="font-size: 16px">
                    <th>Libellé</th>
                    <th colspan="2">Nombre</th>
                    <th>Taux (%)</th>
                  </tr>
                </thead>
                <tbody>

					<tr>
                    <th>Total requêtées acceptées</th>
                    <td colspan="2" >{{ TotalRequeteAccepteAdmin }}</td>
                    <td >{{ PourcentageTotalRequeteAccepteAdmin }} %</td>
                  </tr>
					<tr>
                    <th>Requêtées acceptées en cours de traitement </th>
                    <td colspan="2" >{{ NombreRequeteAccepterEnCoursTraitementMJ + NombreRequeteAccepterEnCoursTraitement }}</td>
                    <td >{{ PourcentageEncoursTotalAdministrateur }} %</td>
                  </tr>

                  <tr>
                    <th>Requêtes acceptées traitées </th>
                    <td colspan="2">{{ NombreRequeteAccepterTraiterMJ + NombreRequeteAccepterTraiter }}</td>
                    <td>{{ PourcentageTraiterTotalAdministrateur }} %</td>
                  </tr>
                  
                </tbody>
              </table> 
			  
							</div>

							
						</div>
					</div>
				
			</div>
			</div>
			<!-- begin row -->
			<div class="row">
				<div class="col-lg-6">
				</div>
				<!-- begin col-8 -->
				<!-- <div class="col-lg-6">
					<div class="panel panel-inverse" data-sortable-id="index-1">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Statistiques des requêtes </h4>
						</div>
						<div class="panel-body">
						<div id="chart">
        <apexchart type="pie" width="500" :options="chartOptions" :series="[nombrePasStatus(0),nombrePasStatus(1),nombreTraite]"></apexchart>
      </div>
						</div>
					</div>
					
				</div> -->
				<!-- end col-8 -->
				<!-- begin col-4 -->
				<!-- <div class="col-lg-6">
					
					<div class="panel panel-inverse" data-sortable-id="index-6">
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Analyses détaillées</h4>
						</div>
						<div class="panel-body p-t-0">
							<div class="table-responsive">
								<table class="table table-valign-middle">
									<thead>
										<tr>	
											<th>Acteurs Juridiques</th>
											<th>Requêtes Acceptées</th>
											<th>Requêtes Réjetées</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in listeActeurJuriduqe" :key="item.id">
											<td>{{item.libelle}}</td>
											<td>{{nombrePasStatusActeur(2,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id)}} </td>
											<td>{{nombrePasStatusActeur(3,item.id) + nombrePasStatusActeur(2,item.id)}} </td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
				
					
					
				</div> -->
				<!-- end col-4 -->
			</div>
			<!-- end row -->

		</div>
		<!-- end #content -->
 
   
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'App',

  components: {
    apexchart: VueApexCharts,
  },

  data(){
        return {
            
             items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],


	  series: [800, 50, 150],
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ["Requêtes en attente d'analyse", "Requêtes en cours d'analyse",'Requêtes analysées'],
          colors:['#f59c1a', '#00acac', '#348fe2'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            xaxis: {
							tickPlacement: 'on'
            },
          },
          

            }
        },
        created(){
console.log(this.getterFicheEntretien)
        },
    

		computed:{
            ...mapGetters('Application', ['getterTypeActeurJuridique','getterAnalyseCJ',
			'getterLoadingTypeActeurJuridique',"getterErrorTypeActeurJuridique",'getterDecisionPeine',
			"getterFicheEntretien","getterLoadingFicheEntretien","getterErrorFicheEntretien",
			"getterActeurJuridique",'getterVictimeprevenue','getterAudience','getterPartieCivil','getterAppel',
			'getterPartieRequerant','getterModeSaisine','getterMotifPartie','getterRenvoi','getterDossiercliniquejuridique',
			'getterDossierMaisonJustice']),
         
		 ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
			"getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },

             codeUser(){
            if(!this.getterUserStore) return 0
            return this.getterUserStore.status_acteur_juridique
        },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },



		   //gestion if is CJ
			RequeteEnAttenteAnalyse(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status == 0);
				  return Objet.length
    
			},

			PourcentageRequeteEnAttenteAnalyse() {
			let Objet= this.RequeteEnAttenteAnalyse
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },

			RequeteAnalyseTotal(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status != 0);
				  return Objet.length
    
			},

			TotalTousRequetes(){
				return this.getterFicheEntretien.length
			},

			 pourcentageRequeteAnalyseTotal() {
				if(this.RequeteAnalyseTotal==0){
					return 0.00
				}else{
					//let taille =this.getterDossiercliniquejuridique.length
					return ((this.RequeteAnalyseTotal / this.TotalTousRequetes) * 100).toFixed(2)
				} 
   			 },

			RequeteAnalyseRejetes(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status != 0 && item.status != 1 &&
				  item.status != 2 && item.status != 3 && item.status != 4);
				  return Objet.length
    
			},

			PourcentageRequeteAnalyseRejetes() {
			let Objet= this.RequeteAnalyseRejetes
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },

			RequeteAnalyseAccepte(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status == 2);
				  return Objet.length
    
			},

			PourcentageRequeteAnalyseAccepte() {
			let Objet= this.RequeteAnalyseAccepte
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },

		TotalCliniqueJur(){
			return this.getterDossiercliniquejuridique.length
		}
		,

		TotalRequeteAccepteCJ(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status == 2);
				  return Objet.length+this.TotalCliniqueJur
    
			},

			PourcentageTotalRequeteAccepteCJ() {
			let Objet= this.TotalRequeteAccepteCJ
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },
		NombreRequeteAccepterTraiter(){
			//let collect=[]
			 let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status==1)
				// this.getterFicheEntretien.filter(item=>{
				// 	Objet.filter(item1=>{
				// 	if(item.id==item1.fiche_analyse_cj_id)
				// 		collect.push(item)
				// 	})
				// })
			return Objet.length
		},

		PourcentageNombreRequeteAccepterTraiter() {
			let Objet= this.NombreRequeteAccepterTraiter
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },


	NombreRequeteAccepterEnCoursTraitement(){
		let collect=[]
		 let Objet= this.getterDossiercliniquejuridique.filter((item) => item.status!=1)
			this.getterFicheEntretien.filter(item=>{
			Objet.filter(item1=>{
				if(item.id==item1.fiche_analyse_cj_id)
				collect.push(item)
			})
		})
		return collect.length 
	},

		PourcentageNombreRequeteAccepterEnCoursTraitement() {
			let Objet= this.NombreRequeteAccepterEnCoursTraitement
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },
	   //fin
			PourcentageEncoursTotalAdministrateur(){
				let Objet= this.NombreRequeteAccepterEnCoursTraitement + this.NombreRequeteAccepterEnCoursTraitementMJ
				let Objet1= this.getterFicheEntretien.length
				if(Objet){
			return ((Objet /Objet1)*100).toFixed(2)
			}return 0

			},

			PourcentageTraiterTotalAdministrateur(){
				let Objet= this.NombreRequeteAccepterTraiter+this.NombreRequeteAccepterTraiterMJ
				let Objet1= this.getterFicheEntretien.length
				if(Objet){
			return ((Objet /Objet1)*100).toFixed(2)
			}return 0

			},

			PourcentageTotalRequeteAccepteAdmin(){
				let Objet= this.TotalRequeteAccepteAdmin
				let Objet1= this.getterFicheEntretien.length
				if(Objet){
			return ((Objet /Objet1)*100).toFixed(2)
			}return 0

			},

			TotalRequeteAccepteAdmin(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status == 2);
				  return Objet.length+this.TotalCliniqueMJ+this.TotalCliniqueJur
    
			},
	   //les MJ

	   TotalCliniqueMJ(){
			return this.getterDossierMaisonJustice.length
		},
		TotalRequeteAccepteMj(){
     			 let Objet= this.getterFicheEntretien.filter((item) => item.status == 2);
				  return Objet.length+this.TotalCliniqueMJ
    
			},

			PourcentageTotalRequeteAccepteMj() {
			let Objet= this.TotalRequeteAccepteMj
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },

		NombreRequeteAccepterTraiterMJ(){
			//let collect=[]
			 let Objet= this.getterDossierMaisonJustice.filter((item) => item.status==0)
				// this.getterFicheEntretien.filter(item=>{
				// 	Objet.filter(item1=>{
				// 	if(item.id==item1.analyse_recevabilite_maison_justice_id)
				// 		collect.push(item)
				// 	})
				// })
			return Objet.length
		},

		PourcentageNombreRequeteAccepterTraiterMJ() {
			let Objet= this.NombreRequeteAccepterTraiterMJ
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },


	NombreRequeteAccepterEnCoursTraitementMJ(){
		let collect=[]
		 let Objet= this.getterDossierMaisonJustice.filter((item) => item.status==2)
			this.getterFicheEntretien.filter(item=>{
			Objet.filter(item1=>{
				if(item.id==item1.analyse_recevabilite_maison_justice_id)
				collect.push(item)
			})
		})
		return collect.length 
	},

		PourcentageNombreRequeteAccepterEnCoursTraitementMJ() {
			let Objet= this.NombreRequeteAccepterEnCoursTraitementMJ
			if(Objet){
			return ((Objet /this.TotalTousRequetes)*100).toFixed(2)
			}return 0
       
   			 },
	   // fin



           idStructure(){
             if(this.getterUserStore){

               return this.getterUserStore.structure_id
             }
             return ""
           },
         
         listeActeurJuriduqe(){
           if(this.idStructure){
            return this.getterActeurJuridique.filter(item=>item.structure_id==this.getterUserStore.structure_id)
           }
           return []
         },
            nombreTotalRequete(){
              return this.getterFicheEntretien.length
			},

			nombrePasStatusenAnalyse(){
				 return this.getterFicheEntretien.filter(item=>item.status==0).length
			},
			nombrePasStatus(){
             return status=>{
				/* let prixMaison=1000
				 prixMaison.toLocaleString()*/
				 
				 return this.getterFicheEntretien.filter(item=>item.status==status).length
			 }
			},
			
			nombrePasStatusActeur(){
             return (status,acteur)=>{
			
				 return this.getterFicheEntretien.filter(item=>{
				 	if(item.status==status && item.acteur_juridique_id==acteur){
				 		return item
				 	}
				 }).length
			 }
			},
			// nombreTraite(){
            //   return parseFloat(this.nombrePasStatus(2)) + parseFloat(this.nombrePasStatus(3))
			// },

			nombreTraite(){
              let Obet= this.getterFicheEntretien.filter(item=>item.status!=0)
			  return Obet.length
			},
			pourcentageTraite(){
              let taux=(this.nombreTraite * 100)/this.nombreTotalRequete
			  return taux.toFixed(2)
			},
			pourcentageAUtre(){
				return status=>{
  					let taux=(this.nombrePasStatus(status) * 100)/this.nombreTotalRequete
			  		return taux.toFixed(2)
				}
            
			},
           getStatus () {
             return status=>{
        if (status == 0) return 'EN ATTENTES'
         if (status ==1) return 'EN-COURS'
         if (status ==2) return 'ACCEPTER'
         return 'REJETTER'
             }
      
      },
	  },
};
</script>
