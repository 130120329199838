<template>
   <div>
	<nav class="side-menu-addl">
	    <header class="side-menu-addl-title">
	        <div class="caption">Suivie et maitrise</div>
	        <i class="font-icon font-icon-burger"></i>
	    </header>
	    <ul class="side-menu-addl-list">
	        <li class="header">
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Video</span>
	                    <span class="tbl-cell tbl-cell-num">16</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Action Required</span>
	                    <span class="tbl-cell tbl-cell-num">4</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Fund YouTuber</span>
	                    <span class="tbl-cell tbl-cell-num">1</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Hired & Funded</span>
	                    <span class="tbl-cell tbl-cell-num">1</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Edit Requested</span>
	                    <span class="tbl-cell tbl-cell-num">10</span>
	                </span>
	            </a>
	        </li>
	        <li class="divider"></li>
	        <li class="header">
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Proposals</span>
	                    <span class="tbl-cell tbl-cell-num">15</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">New</span>
	                    <span class="tbl-cell tbl-cell-num">4</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Declined</span>
	                    <span class="tbl-cell tbl-cell-num">10</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Changes Requested</span>
	                    <span class="tbl-cell tbl-cell-num">1</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Changes Declined</span>
	                    <span class="tbl-cell tbl-cell-num">2</span>
	                </span>
	            </a>
	        </li>
	        <li class="divider"></li>
	        <li class="header">
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Approvals</span>
	                    <span class="tbl-cell tbl-cell-num">15</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Waiting for Approval Long Example</span>
	                    <span class="tbl-cell tbl-cell-num">4</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Approved / Completed</span>
	                    <span class="tbl-cell tbl-cell-num">10</span>
	                </span>
	            </a>
	        </li>
	        <li>
	            <a href="#">
	                <span class="tbl-row">
	                    <span class="tbl-cell tbl-cell-caption">Video in Dispute</span>
	                    <span class="tbl-cell tbl-cell-num">11</span>
	                </span>
	            </a>
	        </li>
	    </ul>
	</nav><!--.side-menu-addl-->
   </div>



</template>
<script>


    export default {
  props: {
  },
        name: "TheNavBar",
        data() {
            return {

            }
        },
        components: {

        },
computed: {

},
        methods: {


        }
    }
</script>

<style scoped>

</style>
