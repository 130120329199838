
<template>
<v-app>
    <notifications/>
    <v-main>

    <template v-if="codeUser==1 || 3">
                <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="#" @click.prevent="gotoModale12()">Gestion des audiences</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Détails</a></li>
        <input type="hidden" v-model="StrucureCharger">
        <input type="hidden" v-model="nombrepersonneimplique">
        <input type="hidden" :value="getterRenvoi">

       

			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion des audiences </h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<!-- <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a> -->
					</div>
					<h4 class="panel-title">Détails  </h4>
				</div>
				<div class="panel-body">

           <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-7" style="text-align: right">
                  <button
                    type="button"
                    @click="gotoModale12()"
                    class="btn btn-inverse"
                  >
                    RETOUR
                  </button>
                </div>
              </div>





          <h5 style="text-align:center;text-decoration:underline">Informations sur la requête</h5>
           <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Numéro de la requête</th>
                  <th>Date de constitution</th>
                  <th>Structure chargée du dossier</th>
                  <th>Avocat chargé du dossier</th>
                  <th>Juriste chargé du dossier</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {{edite.numrequete}} </td>
                  <td> {{data_clinique.dateconstitution}} </td>
                  <td> {{StrucureCharger}} </td>
                  <td> {{persCharger}} </td>
                  <td> {{data_clinique.nomprenomassocier}} </td>
                </tr>
              </tbody>
           </table> <br>

            

            <div>
              <h5 style="text-align:center;text-decoration:underline">Informations sur l'audience</h5>

          <div class="row">

             <div class="col-md-3">
                 <div class="form-group" >
                         <label class="form-label semibold">Niveau <b style="color:red">(*)</b></label>
                          <input disabled type="text" class="form-control" v-model="editedateaudience.niveau_audience"  placeholder="juridiction competente">
                           
                         
                  </div>
             </div>

             <div class="col-md-3">
               <div class="form-group">
                  <label class="form-label semibold" for="juridictioncompetente">juridiction compétente </label>
                    <input disabled type="text" class="form-control" v-model="data_clinique.juridictioncompetente"  placeholder="juridiction competente">
                       
               </div>
           </div>

           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="typedossier">Type de dossier </label>
                    <input disabled type="text" class="form-control" v-model="editedateaudience.typedossier"  placeholder="type de dossier">
                      
               </div>
           </div>
              

            

              <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nbre_personne_implique">Nombre de personnes impliquées </label>
                    <input disabled type="text" class="form-control" v-model.trim="editedateaudience.nbre_personne_implique"  placeholder="Nombre de personnes impliquées">
                        
               </div>
           </div>

           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_mandatdepot">Date de mandat du dépôt <b style="color:red">(*)</b></label>
                    <input type="date" disabled class="form-control" v-model="editedateaudience.date_mandatdepot"  placeholder="Saisir l'âge">
                       
               </div>
           </div>

            

             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="numeroParquet">N° Registre du parquet </label>
                    <input disabled type="text" class="form-control" v-model="editedateaudience.numeroParquet"  placeholder="N° Registre parquet">
                      
               </div>
           </div>

           
             <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="president">Président </label>
                    <input type="text" disabled class="form-control" v-model="editedateaudience.president"  placeholder="Nom & prénom(s) du Président">
                        
               </div>
           </div>

            <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="ministere_public">Ministère public </label>
                    <input type="text" disabled class="form-control" v-model="editedateaudience.ministere_public"  placeholder="Nom & prénom(s) du Ministère Public">
                       
               </div>
           </div>

           </div>



           <div class="row">

                 <div class="col-md-6">
                     <div class="form-group" >
                         <label class="form-label semibold" for="rappel_fait">Rappel des faits : <b style="color:red">(*)</b></label>
                         <textarea disabled class="form-control" v-model="editedateaudience.rappel_fait" name="" id="" cols="60" rows="4"></textarea>
                        
                    </div>
                </div>
                <div class="col-md-6">
                     <div class="form-group" >
                           <label class="form-label semibold" for="deroulement_audience">Déroulé de l’audience :<b style="color:red">(*)</b> </label>
                             <textarea disabled class="form-control" v-model="editedateaudience.deroulement_audience" name="" id="" cols="120" rows="4"></textarea>
                                 
                      </div>
                 </div>
            </div>
        </div> <br>

        <div>
          <h5 style="text-align:center;text-decoration:underline">Informations sur la partie civile</h5>
          <table class="table table-striped m-b-0" >
									<thead>
										<tr>
											<th>#</th>
											<th>
                        Nom</th>
											<th>Prénom(s)</th>
                                            <th>Téléphone</th>
                                          
										</tr>
									</thead>
									<tbody v-if="datapartiecivile.length">
										<tr v-for="(item,index) in datapartiecivile" :key="'='+index">
											<td >{{index + 1}}</td>
                                            <td>{{item.nom}}</td>
                                            <td>{{item.prenom}}</td>
                                            <td>{{item.telephone}}</td>
											
                     
                      
										</tr>
										
									</tbody>
</table>
        </div> <br> <br>



        <div>
            <h5 style="text-align:center;text-decoration:underline">Décision  </h5>
           <div class="row">

             <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="nbre_audience">Nombre d’audience</label>
                    <input type="number" disabled class="form-control" v-model="editedateaudience.nbre_audience"  placeholder="Nombre d'audience ">
                     
               </div>
           </div> -->

          

            <!-- <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="decision_peine">Décision(peine)</label>
                    <input type="text" disabled class="form-control" v-model.trim="editedateaudience.decision_peine"  placeholder=" ">
                      
               </div>
           </div>

           <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="pers_charger_peine">Personne chargée du suivi de la peine</label>
                    <input type="text" disabled class="form-control" v-model.trim="editedateaudience.pers_charger_peine"  placeholder=" ">
                      
               </div>
           </div> -->
            

          </div>
            

            <div class="row">
                 <div class="col-md-6">
                       <div class="form-group" >
                             <label class="form-label semibold" for="requisition_procurreur">Réquisitions du procureur   </label>
                             <textarea  disabled class="form-control" v-model.trim="editedateaudience.requisition_procurreur" name="" id="" cols="120" rows="4"></textarea>

                       </div>
                  </div>
                    <div class="col-md-6">
                         <div class="form-group" >
                             <label class="form-label semibold" for="ligne_defense_avocat">Ligne de défense de l’avocat  </label>
                             <textarea disabled class="form-control" v-model.trim="editedateaudience.ligne_defense_avocat" name="" id="" cols="120" rows="4"></textarea>
                              
                          </div>
                     </div>
            </div>

            <div class="row">
               <div class="col-md-6">
                     <div class="form-group" >
                           <label class="form-label semibold" for="decision">Décision  </label>
                           <textarea disabled class="form-control" v-model.trim="editedateaudience.decision" name="" id="" cols="120" rows="4"></textarea>
                           
                     </div>
                  </div>

                   <div class="col-md-3">
               <div class="form-group" >
                  <label class="form-label semibold" for="date_decision">Date de la décision</label>
                    <input type="date" disabled class="form-control" v-model="editedateaudience.date_decision"  placeholder=" ">
                       
               </div>
           </div>

            </div>
        </div>


        <div>
               <h5 style="text-align:center;text-decoration:underline">Renvoi(s)  </h5>

                     <table class="table table-striped m-b-0" >
									<thead>
										<tr>
											<th>#</th>
											
											<th>Date renvoi</th>
                                            <th>Motif renvoi</th>
                                           
										</tr>
									</thead>
									<tbody v-if="datapartiecivile1.length">
										<tr v-for="(item,index) in datapartiecivile1" :key="'='+index">
											<td >{{index + 1}}</td>
                       <td>{{item.date}}</td>
                       <td>{{item.motif}}</td>   
										</tr>
										
									</tbody>
            </table>
      </div>


             <div>
                        
                               
                    
        </div>
          </div>
		</div>
		


<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

		</div>
    </template>

    <!-- //****** fin gestion des dossiers maison de justice */ -->

    </v-main>
    </v-app>
</template>



<script>
import ThemifyIcon from "vue-themify-icons";
import Multiselect from 'vue-multiselect'
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
    import { ModelListSelect } from 'vue-search-select'
    export default {
        name: "TypeActeur",
        components: {
     ModelListSelect,
     Multiselect,
     FormWizard,
    TabContent,
    ThemifyIcon,
    },
    props: {
    source: String,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    nextButtonText: {
      type: String,
      default: "Suivant",
    },
    backButtonText: {
      type: String,
      default: "Précedent",
    },
    finishButtonText: {
      type: String,
      default: "Enregistrer",
    },

    validateOnBack: Boolean,

    color: {
      type: String,
      default: "black",
    },

    errorColor: {
      type: String,
      default: "#8b0000",
    },

    shape: {
      type: String,
      default: "circle",
    },

    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },

    startIndex: {
      type: Number,
      default: 0,
    },

    title1: {
    type: String,
    default: ''
  },
  /***
   * Icon name for the upper circle corresponding to the tab
   * Supports themify icons only for now.
   */
  icon: {
    type: String,
    default: ''
  },
  /***
   * Only render the content when the tab is active
   */
  lazy: {
    type: Boolean,
    default: false
  },
  /***
   * Function to execute before tab switch. Return value must be boolean
   * If the return result is false, tab switch is restricted
   */
  beforeChange: {
    type: Function
  }
  },
        data() {
            return {
               dialog:false,
               test:true,
               datatrue:false,
   //       lastformData:"",
               datashow_renvoi:0,
               infractionvalue:[],
               article_code_penalvalue:[],

               lastformData:{
                 decision:"",
                 ligne_defense_avocat:"",
                 requisition_procurreur:"",
                 nbre_audience:"",
                 date_decision:"",
                 decision_peine:"",
                 pers_charger_peine:"",

               },
               numrequete:"",
               renvoiformData:{
                 date:"",
                 motif:"",
                // dossier_clinique_id:""
               },
               formData:{
                   dateconstitution:"",
                   dossier_clinique_id:"",
                   acteur_juridique_id:"",
                   fiche_entretien_id:"",
                   strucuturecharger:"",
                   personneCharger:"",
                   user_id:"",
                   structure_id:''
                 
               },
              //  PatiRequerantformData:{
              //    nom:"",
              //    prenom:"",
              //    nationalite:"",
              //    genre:"",
              //    age:"",
              //    datenaissance:"",
              //    lieunaissance:"",
              //    adresse:"",
              //    profession:"",
              //    situationfamiliale:"",
              //    contact:"",
              //    motif:"",
              //    representantlegale:"",
              //    contact_representantlegale:"",
              //    consentement_ajj:"",
              //    attestationconcentement:"",
              //  },

              data_clinique:{
                ministere_public:"",
                president:"",
                numeroParquet:"",
                date_mandatdepot:"",
                nbre_personne_implique:"",
                typedossier:"",
                juridictioncompetente:"",
                niveau_audience:"",
               // nbre_audience:"",
               // date_decision:"",
              //  pers_charger_peine:"",
                //decision_peine:""
              },

              PartieCivileformData:{
                nom:"",
                prenom:"",
                telephone:"",
               // dossier_clinique_id:""
              },
               partie_requerante:[],
               datapartiecivile:[],
               datapartiecivile1:[],
               
               partie:{
                   naturecompromis:"",
                   datesignature:"",
                   suivicompromis:"",
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,
      listecompromis:[
          {libelle:"oui"},
          {libelle:"non"}
          ],

          listtypedossier:[
          {libelle:"Criminel"},
          {libelle:"Correctionnel"}
          ],
         
          listeniveau_audience:[
            {id:1,libelle:"Première instance"},
            {id:1,libelle:"Appel"}
          ],


          situationfamiliale:[
          {id:1,libelle:"Marié(e) civilement"},
          {id:2,libelle:"Marié(e) traditionnellement"},
          {id:3,libelle:"Veuf/ veuve"},
          {id:4,libelle:"Divorcé(e)"},
          {id:5,libelle:"Père/mère de "},
          {id:6,libelle:"Nombre d'épouses : "}
          ],

      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },
      editedateaudience:{
niveau_audience:'',
juridictioncompetente:'',
typedossier:"",
nbre_personne_implique:'',
date_mandatdepot:'',
numeroParquet:"",
president:'',
ministere_public:'',
rappel_fait:'',
deroulement_audience:'',
nbre_audience:'',
date_decision:'',
decision_peine:'',
pers_charger_peine:'',
requisition_procurreur:'',
ligne_defense_avocat:'',
decision:'',

},
        editepartiecivile:"",
        editeRenvoi:"",
            }
        },
        created(){
            //this.$route.params.id  datapartiecivile  datapartiecivile1
            let vm =this
            this.editedateaudience=this.getterAudience.find(item=>item.id==this.$route.params.id)
            this.editepartiecivile=this.getterPartieCivil.filter(item=>item.audience_id==this.editedateaudience.id)
            this.editepartiecivile.forEach(item2=>{
                vm.datapartiecivile.push(item2)
            });

            this.editeRenvoi=this.getterRenvoi.filter(item=>item.audience_id==this.editedateaudience.id)
            this.editeRenvoi.forEach(item2=>{
                vm.datapartiecivile1.push(item2)
            });

          this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.id==this.editedateaudience.dossier_clinique_id)
    this.formData.dateconstitution=this.data_clinique.dateconstitution
    this.edite=this.getterFicheEntretien.find(item=>item.id==this.data_clinique.fiche_analyse_cj_id && item.status!=0)
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        },

      
        computed:{
            ...mapGetters('Application', ["getterErrorDossiercliniquejuridique",
            "getterAudience",
            "getterDossiercliniquejuridique","getterRenvoi",'getterPartieCivil',
            "getterInfraction",
            "getterCodePenal",
            "getterPartieRequerant",
            "getterActeurJuridique",
            "getterMotifPartie",
            "getterVictimeprevenu",
            'getterModeSaisine',
            "getterFicheEntretien",
            'getterLocaliteSaisine',]),
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingFicheEntretien
           },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },

            idStructure(){
             if(this.getterUserStore){
               return this.getterUserStore.structure_id
             }
             return ""
           },
           listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

            listeAnalyse1(){
             let collect=[];

             this.getterFicheEntretien.filter((item)=>{
              this.getterDossiercliniquejuridique.filter((item1)=>{
                   if(item1.fiche_analyse_cj_id==item.id && item1.etat==0){
                        collect.push(item);
                   }
               })
              //  if(val!=undefined){
              //    collect.push(item);
              //    return item
              //  }
             });
            return collect;

           },
           
           listegetterInfraction(){
              return this.getterInfraction.filter((item)=>item.etat==0)
           },

           listegetterCodePenal(){
              return this.getterCodePenal.filter((item)=>item.etat==0)
           },

           codeUser(){
	           if(!this.getterUserStore) return 0
	               return this.getterUserStore.status_acteur_juridique
                },

    StrucureCharger(){
            let Objet= this.getterActeurJuridique.find(item=>item.id==this.editedateaudience.acteur_juridique_id)
           if(Objet){
             return Objet.libelle;
             }return ""
        
        
      },

      persCharger(){
        
          let Objet= this.getterUser.find(item=>item.id==this.editedateaudience.user_id)
           if(Objet){
             return  Objet.name;
             }return ""
        
        
      },

        nombrepersonneimplique(){
          let Objet=this.getterVictimeprevenu.filter(item=>item.dossier_clinique_juridique_id==this.data_clinique.id)
         
         if(Objet){
         
           return this.data_clinique.nbre_personne_implique =this.partie_requerante.length + Objet.length;
         }
         
      },

      motifparti(){
        let Objet= this.getterMotifPartie.find(item=>item.id==this.edite.motif_partie_id)
           if(Objet){
             return  Objet.libelle;
             }return ""
      },
           
        },
        validations: {
          infractionvalue:{required},
          article_code_penalvalue:{required},

            // PatiRequerantformData:{
            //      nomprenom:{ required },
            //      nationalite:{ required },
            //      genre:{ required },
            //      date_naissance:{ required },
            //      lieu_naissance:{ required },
            //      lieu_residence:{ required },
            //      profession:{ required },
            //      situation_famille:{ required },
            //      contact:{ required },
            //      motif:{ required },
            //      representant_legal:{ required },
            //      contact_representant_legal:{ required },
            //      consentement_ajj:{ required },
            //    },

      data_clinique:{
                ministere_public:{ required },
                president:{ required },
                numeroParquet:{ required },
                date_mandatdepot:{ required },
                nbre_personne_implique:{ required },
                typedossier:{ required },
                juridictioncompetente:{ required },
                niveau_audience:{ required },
              //  nbre_audience:{ required },
              //  date_decision:{ required },
              //  pers_charger_peine:{ required },
             //   decision_peine:{ required },
                deroulement_audience:{ required },
                rappel_fait:{ required },
              },
            //  numrequete:{ required },

        
            formData: {
                  // dateconstitution:{ required },
                  // strucuturecharger:{ required },
            },

           lastformData:{
                 decision:{ required },
                 ligne_defense_avocat:{ required },
                 requisition_procurreur:{ required },
                //  deroulement_audience:{ required },
                //  rappel_fait:{ required },
                  nbre_audience:{ required },
                 date_decision:{ required },
                 decision_peine:{ required },
                 pers_charger_peine:{ required },
               },

                PartieCivileformData:{
                nom:{ required },
                prenom:{ required },
                telephone:{ required },
              },
            // partie:{
            //        naturecompromis:{ required },
            //        datesignature:{ required },
            //        suivicompromis:{ required },
            //    }
        },
        methods:{
             ...mapActions('Application', ['modificationPartieRequerant',
             'getTypeActeurJuridique',
             "getAudience",
             "ajouterAudience","modificationAudience",
             "ajouterFicheEntretien",
             "getDossierCliniqueJur",
             ]),
            gotoModale(){
              if(this.datashow_renvoi==1){
                 return this.datashow_renvoi=0;
              }
              return this.datashow_renvoi=1;
            },

              gotoModale12() {
      this.$router.push({
        name: "ListeAudiance",
      });
    },

            addInfraction(newTag){
            this.infractionvalue.push(newTag)
          },

          addArticle_Code_penal(newTag){
            this.article_code_penalvalue.push(newTag)
          },
  //            handleValidation: function(isValid, tabIndex){
  //     console.log('Tab: '+tabIndex+ ' valid: '+isValid)
  //  },

   Testvalidate() {
    // this.numrequete.$touch()
      this.$v.formData.$touch();
     var isValid = !this.$v.formData.$error
      //var isValid1 = !this.$v.numrequete.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    TestvalidateTba3(){
      this.$v.data_clinique.$touch();
      var isValid = !this.$v.data_clinique.$error
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    // TestvalidateTba4(){
    //   this.$v.ajouterAudience.$touch();
    //   console.log(this.$v.ajouterAudience.$error)
    //   var isValid = !this.$v.ajouterAudience.$error
    //   this.$emit('on-complete', this.$data, isValid)
    //   return isValid
    // },

    TestvalidateTba2() {
      if(this.motifparti=='Détenu Prévenu'){
          this.$v.detenuprevenuformData.$touch();
          var isValid = !this.$v.detenuprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else if(this.motifparti=='Prévenu' || this.motifparti=='Victime'){
        this.$v.victimeprevenuformData.$touch();
          var isValid = !this.$v.victimeprevenuformData.$error
           this.$emit('on-validate', this.$data, isValid)
             return isValid
      }else{
        return true;
      }
      
    },

    // onComplete: function () {
    //    alert("Submitting Form ! Rock On");
    //   },


    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
          
            ajouterpartiecivile(){
            this.$v.PartieCivileformData.$touch();
                 if(this.$v.PartieCivileformData.$error) {
                     return
                 }
                this.datapartiecivile.push(this.PartieCivileformData)
   this.$v.PartieCivileformData.$reset();
                this.PartieCivileformData={
                nom:"",
                prenom:"",
                telephone:"",
              }

            },
            //datapartiecivile1

            deletePartieRequerante1(item){
            if (item > -1) {
              this.datapartiecivile1.splice(item, 1);
            }
            },
            deletePartieRequerante(item){
            if (item > -1) {
              this.datapartiecivile.splice(item, 1);
            }
            },
            enregistrement(){
             
                //   this.formData.fiche_entretien_id=this.numrequete
                //   this.formData.dossier_clinique_id=this.data_clinique.id  
                //   this.formData.acteur_juridique_id=this.idActeurJuridique
                //   this.formData.structure_id=this.idStructure
                //   this.formData.user_id=this.getterUserStore.id
                  //this.renvoiformData.dossier_clinique_id=this.data_clinique.id
                //  this.PartieCivileformData.dossier_clinique_id=this.data_clinique.id
               // console.log(this.formData.acteur_juridique_id)

                      let objet={
                   ...this.editedateaudience,
                    PartieCivileformData:this.datapartiecivile,
                    datarenvoi:this.datapartiecivile1
                //   ...this.instructionformData,
                  // tableau_infraction:this.infractionvalue,
                  // tableau_article_code:this.article_code_penalvalue
                   }
                   console.log(objet)
                  this.modificationAudience(objet)
                  this.$router.push({name:"ListeAudiance"})
  
              
            },
              

modification(item){
this.modificationTypeActeurJuridique(item)
},
      // editItem (item) {
      //  window.$("#myModal").modal({
      //   backdrop: "static",
      //   keyboard: false,
      // });
      // //this.datatrue=true

      //  this.PatiRequerantformData=this.getterPartieRequerant.find(element=>element.id==item)
      // },

      detailItem (item) {
       this.$router.push({ name: 'DetailDossierCliniqueJuridique', params: { id: item } })
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerTypeActeurJuridique(item.id)
      },

      

    

      
        },
           watch: {
        
         
             numrequete: function (value) {
      console.log(value);
      let vm=this
      vm.partie_requerante=[]
        if(value!=""){
    this.data_clinique=this.getterDossiercliniquejuridique.find(item=>item.fiche_analyse_cj_id==value)
    this.formData.dateconstitution=this.data_clinique.dateconstitution
    this.edite=this.getterFicheEntretien.find(item=>item.id==value && item.status!=0)
    this.edite.fiche_partie_requerant.forEach(element => {
        vm.partie_requerante.push(element.partie_requerant)
    });
        }
          
   
    },
       
      },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        color: black;
    }
    .finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
