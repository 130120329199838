
<template>
<v-app>
    <notifications/>

    
    <!-- <v-main v-if="gestionD1==1 || gestionD1==2" > -->
    <v-main >
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Dossiers des Maisons de justice</a></li>
				
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h5 class="page-header">Gestion des dossiers par les Maisons de justice</h5>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Liste des dossiers des Maisons de justice</h4>
				</div>
				<div class="panel-body">
          <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                	<button type="button"  @click="gotoModale()" class="btn btn-inverse">NOUVEAU</button>
              </div>
          </div>

           <div class="row">

             <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold">Numéro de la requête</label>
                  <model-list-select option-value="id"
                    option-text="numrequete"
                    v-model="recherche"
                    :list="listeAnalyse1"
                    placeholder="Entrer le numéro de la requête"
                  >
                  </model-list-select>
                 
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold" for="datareception"
                    >Date de constitution du dossier
                  </label>
                  <input  type="date" v-model="dateconstitution"  name="datareception"
                    class="form-control"  id="datareception"
                    placeholder="Date de réception de la requête"
                  />
                </div>
              </div>

               <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label semibold">Statut </label>
                  <model-list-select option-value="code"
                    option-text="libelle"
                    v-model="statut_etat"
                    :list="ListeStatut"
                    placeholder="Methode d'analyse"
                  >
                  </model-list-select>
                 
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group" style="margin-top:-7px">
                 <br> <br>
                  <button @click.prevent="resetdata" class="btn btn-primary">Tous</button>
                </div>
              </div>

           
          </div>
		
 <v-data-table
    :headers="headers"
    :items="ListeDossierMaison"
    sort-by="id"
    class="elevation-1"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="id"
    show-expand
  >

<template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        {{item.fiche_partie_requerant}}
      </td>
    </template>
  

     <!-- <template v-slot:item.analyse_recevabilite_maison_justice_id="{ item }">
      
        {{ recup_numero_requete(item.analyse_recevabilite_maison_justice_id) }}
      
    </template> -->

     <template v-slot:item.dateconstitution="{ item }">
      
        {{ formatagedate(item.dateconstitution) }}
      
    </template>

    <template v-slot:item.acteur_juridique_id="{ item }">
      
        {{ StrucureCharger(item.acteur_juridique_id) }}
      
    </template>

    <template v-slot:item.user_id="{ item }">
      
        {{ persCharger(item.user_id) }}
      
    </template>

    <!-- <template v-slot:item.dateentretien="{ item }">
      
        {{ formatagedate(item.dateentretien) }}
      
    </template> -->

     <template v-slot:item.status="{ item }">
      <v-chip
        :color="getColor(item.status)"
        dark
      >
        {{ getStatus(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
   <div class="btn-group btn-group-justified">
  <a title="Détail" class="btn btn-default" @click="detailItem(item)"><i class="fas fa-list-alt"></i></a>
  <a title="Modifier" v-if="item.compromis=='non' &&  item.status!=1" class="btn btn-default" @click="editItem(item)"><i class="fas fa-edit"></i></a>
  <a title="Date d'entretien" v-if="item.status!=1"  class="btn btn-default" @click="editdate(item)"><i class="fas fa-share"></i></a>
  <a title="Réorienter" v-if="item.status!=1"  class="btn btn-default" @click="gotoReorienter(item.id)"><i class="fas fa-location-arrow"></i></a> 
  <a style="color:red" title="Supprimer" class="btn btn-default" @click="deleteItem(item)"><i class="fas fa-trash-alt"></i></a>
   </div>
    </template>
    
  </v-data-table>
				</div>
			</div>
		


<v-dialog 
        max-width="600"
        max-heigth="600"
         v-model="dialog"
           persistent
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
             
              dark
            >Réorientation</v-toolbar>
            <v-card-text>
              <div class="form-group">
              <input hidden type="hidden" v-model="edite.id" class="form-control">
               </div>
                <br>
                

                   <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label semibold">Structures</label>
                  <model-list-select
                    class="form-control"
                    option-value="id"
                    option-text="libelle"
                    v-model="edite.structure_id"
                    :list="getterStructure"
                    placeholder="Sélectionner la structure"
                  >
                  </model-list-select>
                </div>
              </div>
            	
							
            </v-card-text>
            <br><br><br><br>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Fermer</v-btn>
              <v-btn
            :disabled="loading"
           :loading="loading"
            text
      @click="enregistrementreorienter()"
          >
            Réorienter
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
     



<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

         <v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Suppression !!
                </v-card-title>

                <v-card-text>
                 Voulez-vous réellement effectuer cette action ?
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green"
                    text
                    @click="dialogDelete = false"
                  >
                    Annuler
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm(edite)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>



         <v-dialog
        max-width="600"
         v-model="dialogEdite"
      >
        <template v-slot:default="dialogEdite">
          <v-card>
            <v-toolbar
              dark
            >Modification</v-toolbar>
            <v-card-text>
                <br>
                

                <div class="form-group">
                  <label class="form-label semibold" for="libelle">Libelle</label>
						     	<input type="text"  v-model="edite.libelle" name="libelle" class="form-control" id="libelle" placeholder="Libelle">
							
                </div>
            	
							
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialogEdite.value = false"
              >Fermer</v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
              text
            @click="modification(edite)"
          >
            Modification
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
		</div>
    </v-main>


    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
import moment from 'moment'
//import Traitementdossier from './Traitementdossier'
  import { ModelListSelect } from "vue-search-select";
    import { required, minLength } from "vuelidate/lib/validators";
   
    export default {
        name: "TypeActeur",
           components: {
    ModelListSelect,
  },

        data() {
            return {
               dialog:false,
               dateconstitution:'',
               statut_etat:'',
               recherche:'',
               edite:{
                 structure_id:'',
                 id:''
               },
               //structure_id:"",
               test:true,
               formData:{
                 
                   libelle:"",
               },
             //  edite:"",
              

            singleExpand: false,
                expanded: [],
     dialogEdite: false,
      dialogDelete: false,
      headers: [
         { text: 'Numéro de la requête ', value: 'numero_requete' },
          { text: 'Date de constitution du dossier ', value: 'dateconstitution' },
           { text: 'Structure chargée du dossier', value: 'acteur_juridique_id' },
           { text: 'Personne chargée du dossier', value: 'user_id' },
          { text: 'Statut', value: 'status' },
          { text: 'Actions', value: 'actions', sortable: false },
      ],
             ListeStatut: [
        { code:0,libelle: "Traitée"},
        { code:1,libelle: "Reorientée"},
        { code:2,libelle: "Rejetée"},
      
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
       
        },
        computed:{
            ...mapGetters('Application', ['getterDossierMaisonJustice',
             'getterLoadingDossierMaisonJustice','getterStructure','getterErrorDossierMaisonJustice',
             "getterErrorTypeActeurJuridique",
             "getterFicheEntretien",
             "getterLoadingFicheEntretien",
             "getterErrorFicheEntretien",
             "getterActeurJuridique"]),
             ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser",
              "getterUser","getterUserStore"]),
              formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingDossierMaisonJustice
           },
           getStatus () {
             return status=>{
        //if (status == 0) return 'EN ATTENTES'
         if (status ==0) return 'Traitée'
         if (status ==2) return 'En cours'
         if (status ==1) return 'Reorientée'
         return 'Rejetée'
             }
      
      },

      datadd(){
          return this.getterDossierMaisonJustice.filter(item=>item.status==this.statut_etat)
      },

      
  
      ListeDossierMaison(){
        if(this.recherche!="" && this.statut_etat=="" && this.dateconstitution==""){
           return this.getterDossierMaisonJustice.filter(item=>item.analyse_recevabilite_maison_justice_id==this.recherche)

        }else if(this.recherche=="" && this.statut_etat!="" && this.dateconstitution==""){
           return this.getterDossierMaisonJustice.filter(item=>item.status==this.statut_etat)

        }else if(this.recherche=="" && this.statut_etat=="" && this.dateconstitution!=""){
          return this.getterDossierMaisonJustice.filter(item=>item.dateconstitution==this.dateconstitution)

        }else if(this.recherche!="" && this.statut_etat!="" && this.dateconstitution!=""){
          return this.getterDossierMaisonJustice.filter(item=>item.dateconstitution==this.dateconstitution &&
          item.status==this.statut_etat && item.analyse_recevabilite_maison_justice_id==this.recherche)

        } else if(this.recherche!="" && this.statut_etat!="" && this.dateconstitution==""){
           return this.getterDossierMaisonJustice.filter(item=>item.status==this.statut_etat 
           && item.analyse_recevabilite_maison_justice_id==this.recherche)

        }else if(this.recherche!="" && this.statut_etat=="" && this.dateconstitution!=""){
          return this.getterDossierMaisonJustice.filter(item=>item.dateconstitution==this.dateconstitution && 
          item.analyse_recevabilite_maison_justice_id==this.recherche)

        }else if(this.recherche=="" && this.statut_etat!="" && this.dateconstitution!=""){
          return this.getterDossierMaisonJustice.filter(item=>item.dateconstitution==this.dateconstitution && 
          item.status==this.statut_etat)

        }else{
          return this.getterDossierMaisonJustice
        }
      },


      recupnumrequete(){
        let Objet= this.getterFicheEntretien.find(item=>item.id==this.recherche)
        if(Objet){
          return Objet.id
        }return ""
      },

      gestionD1(){
             if(this.getterUserStore){
               return this.getterUserStore.role_id
             }
             return ""
           },

      gestionD(){
	   if(!this.getterUser) return ""
	     let objet=this.getterUser.filter(item=>item.role_id!=null)
       if(objet){
          return objet.role_id;
       }
	    
     },

       listeAnalyse1(){
             let collect=[];

             this.getterFicheEntretien.filter(item=>{
                this.getterDossierMaisonJustice.filter(item1=>{
                   if(item1.analyse_recevabilite_maison_justice_id==item.id){
                        collect.push(item)
                   }
               })
           
             });
             return collect;

           },

      listeAnalyse(){
             return this.getterFicheEntretien.filter(item=>item.status==2)
           },

      Liste_dossier(){
          return this.getterDossierMaisonJustice.filter(item=>item.status==0)
      },

      StrucureCharger(){
        return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet= this.getterActeurJuridique.find((item)=>item.id==id)
           if(Objet){
             return Objet.libelle;
             }return ""
        }
        }
          
      },

       persCharger(){
        return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet= this.getterUser.find((item)=>item.id==id)
           if(Objet){
             return Objet.name;
             }return ""
        }
        }
          
      },

       recup_numero_requete(){
        return(id)=>{
          if (id != null && id != "" && id != undefined) {
              const Objet=this.getterFicheEntretien.find((item)=>item.id==id)
              if(Objet){
             return Objet.numrequete;
           }return ''
          }

        }
       
      },
        },
        validations: {
            formData: {
               
                libelle: { required },
            }
        },
        methods:{
             ...mapActions('Application', ['getTypeActeurJuridique',
             "ajouterTypeActeurJurique",'reorientationDossierMaisonJustice',
             "supprimerTypeActeurJuridique",
             "modificationDossierMaisonJustice",
             "supprimerDossierMaisonJustice"]),
              getColor (status) {
        if (status == 0) return '#348fe2'
        else if (status ==1) return '#c47d15'
        else  return 'red'
      },
            gotoModale(){
              
                this.$router.push({
        name: "AjoutdossierTraitement"
      })
            },

             gotoReorienter(id){
              
               this.dialog=true 
              // this.edite=this.getterDossierMaisonJustice.find(item=item.id==id)
               this.edite.id=id
            } ,

            resetdata(){
               this.dateconstitution='',
               this.statut_etat='',
               this.recherche=''
              return 1
             },
            formatagedate(dat){
              return moment(dat).format('DD/MM/YYYY');
            },
            enregistrement () {

                this.$v.formData.$touch();
                if(this.$v.formData.$error) {

                    return
                }
                
                
                this.ajouterTypeActeurJurique(this.formData)
                if(this.getterErrorTypeActeurJuridique==false){
                    this.$v.formData.$reset();
                  this.formData={
                         libelle:""
                     }
                }
               

            },

            enregistrementreorienter () {
              console.log(this.edite)
                
                this.reorientationDossierMaisonJustice(this.edite)
                
                  if(this.getterErrorDossierMaisonJustice==false){
                    this.$v.edite.$reset();
                  this.edite={
                         id:"",
                         structure_id:''
                     }
                }
                
               

            },
              

editdate (item) {
       this.$router.push({ name: 'GestionDateEntretien', params: { id: item.id } })
      },

      editItem (item) {
       this.$router.push({ name: 'ModifierdossierTraitement', params: { id: item.id } })
      },
 detailItem (item) {
       this.$router.push({ name: 'DetaildossierTraitement', params: { id: item.id } })
      },
      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerDossierMaisonJustice(item.id)
      },

    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
</style>
