
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
          <li class="breadcrumb-item active">Gestion des peines</li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h5 class="page-header">
          Gestion  des peines
        </h5>
        <!-- end page-header -->
        <!-- begin panel -->
        <div class="panel panel-inverse">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-7" style="text-align: right">
                  <button
                    type="button"
                    @click="gotoModale()"
                    class="btn btn-inverse"
                  >
                    RETOUR
                  </button>
                </div>
              </div>
            </div>
            <h4 class="panel-title">Ajouter la peine </h4>
          </div>
          <div class="panel-body">
            

            <div>
              <div class="col-lg-12 ui-sortable">
                <br />
   <div class="row">
     <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label semibold"
                              >Dossier {{return_libelle_motif(return_id_motif(id_ficheentretien(dossier_clinique_id)))}} <b style="color: red"
                                >*</b
                              ></label
                            >
                            <model-list-select
                              aria-required="required"
                              class="form-control"
                              option-value="id"
                              option-text="numrequette"
                              v-model="dossier_clinique_id"
                              :list="listeDesDossierAvecCondation"
                              placeholder="Sélectionnez une requête"
                            >
                            </model-list-select>
                          </div>

                          <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.dossier_clinique_id.$error"
                  >
                    veuillez entrer le dossier
                  </div>
                       


       <!-- <div class="form-group">
                  <label class="form-label semibold">Dossier </label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.dossier_clinique_id.$error }"
                    v-model="dossier_clinique_id" placeholder="veuillez sélectionner le dossier"
                  >
                    <option></option>
                    <option
                      v-for="item in listeDesDossierAvecCondation"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                     {{recup_numero_requete(item.fiche_analyse_cj_id)}} - {{ item.numero_dossier_sys }}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.dossier_clinique_id.$error"
                  >
                    veuillez entrer le dossier
                  </div>
                </div> -->
     </div>
     <div  v-bind:class="decision_peine ? 'col-md-4' : 'col-md-6' " >
          <div class="form-group">
                  <label class="form-label semibold">Partie requérante </label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.partie_requerente_id.$error }"
                    v-model="formData.partie_requerente_id" @change="determinesur" placeholder="veuillez selectionner le dossier"
                  >
                    <option></option>
                    <option
                      v-for="item in partie_requerante"
                      :key="item.id"
                      :value="item.id"
                    >
                     {{item.nom}}   {{item.prenom}}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.partie_requerente_id.$error"
                  >
                    veuillez entrer la partie
                  </div>
                </div>
     
     </div>

   

          

   </div>

  

          <div class="row" >
               <div class="col-md-3" v-if="return_libelle_motif(return_id_motif(id_ficheentretien(dossier_clinique_id)))!='Prévenu / accusé non détenu' || dossier_clinique_id==''">
                 <div class="form-group">
                  <label class="form-label semibold" for="date_debut">Date de mandat de dépôt</label>
                  <input
                    type="date"
                    
                    v-model="date_debut"
                    name="code"
                    class="form-control"
                    id="date_debut"
                    placeholder="Date"
                     
                  />
                  <!-- <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.date_debut.$error"
                  >
                    veuillez entrer la date de début
                  </div> -->
                </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                  <label class="form-label semibold" for="date_decision">Date de la décision </label>
                  <input
                    type="date" :class="{ hasErroInpu: $v.formData.annee.$error }"
                    v-model="date_decision"
                    name="code"
                    class="form-control"
                    id="date_decision"
                    placeholder="Date de la décision"
                   readonly
                  />
                 
                </div>
            </div>

           <div class="col-md-2">
                  <div class="form-group">
                  <label class="form-label semibold" for="annee">Années</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.annee.$error }"
                    v-model="annee"
                    name="code"
                    class="form-control"
                    id=" annee"
                    placeholder="Nombre d'annee"

                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.annee.$error"
                  >
                    veuillez entrer la année
                  </div>
                </div>
            </div>


           <div class="col-md-2">
                  <div class="form-group">
                  <label class="form-label semibold" for="mois">Mois</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.mois.$error }"
                    v-model="mois"
                    name="code"
                    class="form-control"
                    id="mois"
                    placeholder="Nombre de mois"

                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.mois.$error"
                  >
                    veuillez entrer le mois
                     </div>
                </div>
            </div>

         
           <div class="col-md-2">
                  <div class="form-group">
                  <label class="form-label semibold" for="jours">Jours</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.jours.$error }"
                    v-model="jours"
                    name="code"
                    class="form-control"
                    id="jours"
                    placeholder="Nombre de jours"

                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.jours.$error"
                  >
                    veuillez entrer la jours
                  </div>
                </div>
            </div>
              
              <div class="col-md-3">
                  <div class="form-group">
                  <label class="form-label semibold" for="deja_puge">Nombre de jours déja purgés</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.deja_puge.$error }"
                    v-model="formData.deja_puge"
                    name="code"
                    class="form-control"
                    id=" deja_puge"
                    placeholder="Peine déja purgés"
                    disabled
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.deja_puge.$error"
                  >
                    veuillez renseigner ce champs
                  </div>
                </div>
               </div>
                
              
              <div class="col-md-3">
                  <div class="form-group">
                  <label class="form-label semibold" for="peine">Nombres de jours de la peine</label>
                  <input
                    type="number"
                    :class="{ hasErroInpu: $v.formData.peine.$error }"
                    v-model="peine"
                    name="code"
                    class="form-control"
                    id=" peine"
                    placeholder="Nombres de jours de la peine"
                    disabled
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.peine.$error"
                  >
                    veuillez entrer la peine
                  </div>
                </div>
            </div>
            
<div class="col-md-3">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="date_fin">
                  Date de fin de la peine
                 </label>
                  <input
                    type="date"
                    
                    v-model="formData.date_fin"
                    name="code"
                    class="form-control"
                    id="date_fin"
                    placeholder="Date"
                    disabled
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.date_fin.$error"
                  >
                    veuillez entrer le date de fin
                  </div>
                </div>

            </div>
      <div class="col-md-3">
                  <div class="form-group">
                <label class="form-label" style="color:#fff">
                .<br>
              </label>
       <br>
                <v-btn class="btn btn-success"
                    :disabled="false"
                    :loading="false"
                    @click="calculeDateFin()"
                    text>
              Calculer la date de fin peine
               </v-btn>
                  </div>
                    
      </div>

      <div class="col-md-2"  v-if="decision_peine">
        
        <div class="form-group">
                    <label class="form-label semibold" for="annee_surcis">Année du sursis</label>
                    <input
                      type="number"
                      v-model="annee_surcis"
                      name="annee_surcis"
                      class="form-control"
                      id=""
                      placeholder="Année du sursis"
                    />
                    
          </div>
      </div>

   <div class="col-md-2"  v-if="decision_peine">
        
        <div class="form-group">
                    <label class="form-label semibold" for="moi_surcis">Mois du sursis</label>
                    <input
                      type="number"
                      v-model="moi_surcis"
                      name="annee_surcis"
                      class="form-control"
                      id=""
                      placeholder="Moi du sursis"
                    />
                    
          </div>
    </div>
   
  

<div class="col-md-2"  v-if="decision_peine">
        
        <div class="form-group">
                    <label class="form-label semibold" for="jours_surcis">Jour du sursis</label>
                    <input
                      type="number"
                      v-model="jours_surcis"
                      name="jours_surcis"
                      class="form-control"
                      id=""
                      placeholder="Jour du sursis"
                    />
                    
          </div>
    </div>

     <div class="col-md-2"  v-if="decision_peine">
        
        <div class="form-group">
                    <label class="form-label semibold" for="date_fin_surcis">Date de sortie</label>
                    <input
                      type="date"
                      v-model="date_fin_surcis"
                      name="jours_surcis"
                      class="form-control"
                      id=""
                      placeholder="Jour du sursis"
                      readonly
                    />
                    
          </div>
    </div>

    <div class="col-md-2" v-if="decision_peine">
                  <div class="form-group">
                <label class="form-label" style="color:#fff">
                .<br>
              </label>
       <br>
                <v-btn class="btn btn-success"
                    :disabled="false"
                    :loading="false"
                    @click="calculeDateSortie()"
                    text>
              Calculer la date de sortie
               </v-btn>
                  </div>
                    
      </div>

                   <div class="col-md-4">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="personne_suivie">Nom de la personne chargée du suivi</label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.personne_suivie.$error }"
                    v-model="formData.personne_suivie"
                    name="code"
                    class="form-control"
                    id="personne_suivie"
                    placeholder="Nom de la personne chargée du suivi"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.personne_suivie.$error"
                  >
                    veuillez renseigner ce champ
                  </div>
                </div>

                   </div>

<div class="col-md-4">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="contact">Contact</label>
                  <input
                    type="text"
                    :class="{ hasErroInpu: $v.formData.contact.$error }"
                    v-model="formData.contact"
                    name="code"
                    class="form-control"
                    id="contact"
                    placeholder="Contact"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.contact.$error"
                  >
                    veuillez entrer le contact
                  </div>
                </div>

                   </div>
                   <div class="col-md-4">
                     
                <div class="form-group">
                  <label class="form-label semibold" for="email">Email </label>
                  <input
                    type="email"
                    :class="{ hasErroInpu: $v.formData.email.$error }"
                    v-model="formData.email"
                    name="code"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.email.$error"
                  >
                    veuillez entrer le Mail
                  </div>
                </div>

                   </div>




               </div>


               <div class="row">
                 <div class="col-md-2">
                    <v-btn class="btn btn-primary"
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement()"
                >
                  Enregistrer
                </v-btn>
                 </div>

                  <div class="col-md-1" style="font-weght:blod">
                    OU
                  </div>
                  <div class="col-md-2">
                    <v-btn class="btn btn-primary"
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement1()"
                >
                  Enregistrer et quitter
                </v-btn>
                 </div>
               </div>








              </div>
            </div>
          </div>
        </div>

       
        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!---DIALOGUE DE SUPPRESION-->

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="headline"> Suppression !! </v-card-title>

            <v-card-text>
               Voulez-vous réellement effectuer cette action ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green" text @click="dialogDelete = false">
                Annuler
              </v-btn>

              <v-btn color="red" text @click="deleteItemConfirm(edite)">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogEdite">
          <template v-slot:default="dialogEdite">
            <v-card>
              <v-toolbar dark>Modification</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="code">Code</label>
                  <input
                    type="text"
                    v-model="edite.code"
                    name="code"
                    class="form-control"
                    id="code"
                    placeholder="Code"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Structure</label>
                  <select class="form-control" v-model="edite.structure_id">
                    <option></option>
                    <option
                      v-for="item in liste_structure"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libelle</label
                  >
                  <input
                    type="text"
                    v-model="edite.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Libelle"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Type Acteur</label>
                  <select class="form-control" v-model="edite.type_acteur_id">
                    <option></option>
                    <option
                      v-for="item in typeActeurJu"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Régions</label>
                  <select
                    class="form-control"
                    v-model="edite.localisation_geo_graphique_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdite.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="modification(edite)"
                >
                  Modification
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
  import { ModelListSelect } from "vue-search-select";

export default {
  name: "AjouterPeine",
  components: {
    ModelListSelect
  },
  data() {
    return {
      dialog: false,
      peineounon:"",
      test: true,
      peine:"",
      sursis:0,
      type_peine:"",
      date_fin_previsionnel:null,
      peine_suicie:"",
      formData: {
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: null,
        deja_puge: "",
        date_fin:null,
        jours:0,
        annee:0,
        mois:0,

        peine_suicie:0,
        date_fin_surcis:null,
        jours_surcis:0,
        annee_surcis:0,
        moi_surcis:0,
      },
      date_decision:"",
      objet_dossier12:'',
      objet_audiance12:'',
       jours:0,
        annee:0,
        mois:0,
      date_debut:"",
      edite: "",
      jours_surcis:0,
      moi_surcis:0,
      annee_surcis:0,
      date_fin_surcis:"",
     partie_requerante:[],
      dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: "Personne condamnée", value: "partie_requerente_id" },
        { text: "Durée de la peine", value: "peine" },
        { text: "Date de debut", value: "date_debut" },
        { text: "Date de Fin", value: "date_fin" },
        { text: "Peine restante à purger (Jour(s))", value: "subire" },
        { text: "Nom de la personne chargée du suivi", value: "personne_suivie" },
        { text: "Contact ", value: "contact" },
        { text: "Email ", value: "email" },
        { text: "Etat", value: "etat" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
      dossier_clinique_id:"",
      decision_peine:false,
      type_durre_succis:"",
    };
  },
  created() {
    if(this.$route.params.id){
     // console.log()
     this.dossier_clinique_id=this.$route.params.id
     this.dialog=true
     this.peineounon=this.$route.params.id1
    }
  },
  computed: {
     ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),
    ...mapGetters("Application", [
      "getterLocalisationGeographique",
      "getterStructure",
      "getterActeurJuridique",
      "getterLoadingActeurJurique",
      "getterErrorActeurJuridique",
      "getterTypeActeurJuridique",
       "getterDossiercliniquejuridique",
       "getterFicheEntretien",
       "getterAnalyseMJ",
       "getterLoadingPeine",
       "getterPeine",'getterMotifPartie',
       "getterPartieRequerant",
       "getterAudience",
       "getterVictimeprevenu"
    ]),

     id_ficheentretien() {
        return id => {
          if (id != null && id != "") {
            let Objet =  this.getterDossiercliniquejuridique.find(
              element => element.id == id
            );
            if(Objet){
              return Objet.fiche_analyse_cj_id
            }else{
              return ''
            }
          }
        };
      },

      return_id_motif(){
      return (id)=>{
        if(id!=null && id!=""){
          let Objet = this.getterFicheEntretien.find(item=>item.id==id);
          if(Objet){
            return Objet.motif_partie_id
          }return ""
        }
      }
    },

      return_libelle_motif(){
      return (id)=>{
        if(id!=null && id!=""){
          let Objet = this.getterMotifPartie.find(item=>item.id==id);
          if(Objet){
            return Objet.libelle
          }return ""
        }
      }
    },
    // this.return_libelle_motif(this.return_id_motif(this.id_ficheentretien))
    listeDesDossierAvecCondation(){
     
     // let objet=this.getterDossiercliniquejuridique.filter(item=>item.status==1)
      
     let objet =[];
          this.getterDossiercliniquejuridique.filter(item=>{
            if(item.status==1){
              let data ={
                id:item.id,
                numrequette:this.recup_numero_requete(item.fiche_analyse_cj_id)
              }
              objet.push(data)
            }
          });
          return objet

    },


    //fiche_analyse_cj_id

    listeDesDossierAvecCondation1(){

    },
   etatPeine(){
    return date_fin=>{
        let date=this.peineResteAsubir(date_fin)
        if(date<1) return true
        return false
    }
   },
   listePartieRequerant(){
      let arr=[]
       let vm=this 
      this.partie_requerante.forEach(value=>{
           let objet=this.getterPeine.find(item=>item.partie_requerente_id ==value.id)
           if(objet){
            if(objet.status!=0){
            arr.push(value)
           }
         }else{
 arr.push(value)
         }


           
      })
      return arr.filter(item=>item.decision_peine!=3)
   },
peineAcheveEncour(){
  return typ=>{
    if(!typ) return ""
       let encours=[]
      let acheve=[]
      let vm=this 
      this.getterPeine.forEach(value=>{
         let day=vm.peineResteAsubir(value.date_fin)
         if (day<1 && value.status==0) {
          acheve.push(value)
         }
         if (day>0 && value.status==0) {
          encours.push(value)
         }
      })

      if(typ==1) return encours
        return acheve
  }
 

},



  peineResteAsubir(){
   return date_fin=>{
    if(!date_fin) return null
       let date1=new Date()
     let date2= new Date(date_fin)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
   }
  },
  calcalEntre2Date(){
     return (d1,d2)=>{
      let date1=new Date(d1)
     let date2= new Date(d2)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
     }
  },
typeDuree(){
  return (type,peine)=>{
    if(!type) return null
      if (type==1) {
        if(peine<2) return "Jour"
           if(peine>1) return "Jours"
      }
    if (type==3) {
        if(peine<2) return "An"
           if(peine>1) return "Ans"
      }
    if (type==2) {
        if(peine<2) return "Moi"
           if(peine>1) return "Mois"
      }
           return null 
  }
},
     dateDayCalcule() {
        return (date, info)=>{

            var result = new Date(date);

              let rest= result.setDate(result.getDate() + parseInt(info));
              console.log("...date de jours")
              let test=new Date(rest)
            console.log(test)
            return this.formatDate(rest);
        }
     },

       soustrationDateDayCalcule() {
        return (date, info)=>{

            var result = new Date(date);

              let rest= result.setDate(result.getDate() - parseInt(info));
              console.log("...date de jours")
              let test=new Date(rest)
            console.log(test)
            return this.formatDate(rest);
        }
     },

     dateDayCalcule2() {
        return (date, info)=>{

            var result = new Date(date);

              let rest= result.setDate(result.getDate() - parseInt(info));
              console.log("...date de jours")
              let test=new Date(rest)
            console.log(test)
            return this.formatDate(rest);
        }
     },
     
     dateMoiCalcule() {
        return (date, info)=>{

            var result = new Date(date);
          let rest=  result.setMonth(result.getMonth() + parseInt(info));
            console.log(rest)
            return this.formatDate(rest);
        }
     },
     soustrationDateMoiCalcule() {
        return (date, info)=>{

            var result = new Date(date);
          let rest=  result.setMonth(result.getMonth() - parseInt(info));
            console.log(rest)
            return this.formatDate(rest);
        }
     },
     soustrationDateYearCalcule() {
        return (date, info)=>{

            var result = new Date(date);
          let rest= result.setFullYear(result.getFullYear() - parseInt(info));
           console.log(rest)

            return this.formatDate(result);
        }
     },
     dateYearCalcule() {
        return (date, info)=>{

            var result = new Date(date);
          let rest= result.setFullYear(result.getFullYear() + parseInt(info));
           console.log(rest)

            return this.formatDate(result);
        }
     },
      formatDate() {
        return date=>{
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
      },
      formatDateFr(){
          return date=>{
              var d = new Date(date),
                  month = '' + (d.getMonth() + 1),
                  day = '' + d.getDate(),
                  year = d.getFullYear();

              if (month.length < 2)
                  month = '0' + month;
              if (day.length < 2)
                  day = '0' + day;

              return [day,month,year].join('/');
          }
      },
    partieRequerente(){
    return id=>{
      if(!id) return ""
        let obj=this.getterPartieRequerant.find(item=>item.id==id)
      if(obj==undefined) return null
        return obj.nom+" "+obj.prenom
    }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingPeine;
    },
    typeActeurJu() {
      return this.getterTypeActeurJuridique.reverse();
    },

    liste_structure() {
      return this.getterStructure.reverse();
    },
    acteurJuridiquePasTypeActeur() {
      return (type_acteur_id) => {
        if (!type_acteur_id) return [];
        return this.getterActeurJuridique.filter(
          (item) => item.type_acteur_id == type_acteur_id
        );
      };
    },
    regionActeurJuridique() {
      return (localisation_geo_graphique_id) => {
        if (!localisation_geo_graphique_id) return "";

        let objet = this.getterLocalisationGeographique.find(
          (item) => item.id == localisation_geo_graphique_id
        );
        if (objet == undefined) return "";
        return objet.libelle;
      };
    },
     recup_numero_requete(){
        return(id)=>{
          if (id != null && id != "" && id != undefined) {
              const Objet=this.getterFicheEntretien.find((item)=>item.id==id)
              if(Objet){
             return Objet.numrequete;
           }
           return ''
          }
          return ""
        }
       
      },
  },
  validations: {
    formData: {
       type_peine: "",
        peine: { required },
        partie_requerente_id: { required },
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: { required },
        contact: { required },
        email: { required },
        status:"",
        date_fin:"",
        date_debut: "",
        deja_puge: { required },
        jours:{ required },
        mois:{ required },
        annee:{ required },
        peine_suicie:"",
        date_fin_surcis:"",
        jours_surcis:"",
        annee_surcis:"",
        moi_surcis:""
    },
  },
  methods: {
    ...mapActions("Application", [
      "getActeurJuridique",
      "ajouterActeurJuridique",
      "supprimerActeurJuridique",
      "modificationActeurJuridique",
      "ajouterPeine",
      "supprimerPeine",
      "modificationPeine"
    ]),
    gotoModale() {
      this.$router.push({name:"Peine"})
    //  this.dialog = true;
    },


//         enregistrement1() {

//       this.formData.peine=this.peine
//       this.formData.type_peine=this.type_peine
//       this.$v.formData.$touch();
//       if (this.$v.formData.$error) {
//         return;
//       }
//     let newObjet={
//       ...this.formData,
//       date_debut:this.date_debut,
//       dossier_clinique_id:this.dossier_clinique_id,
//       structure_id:this.getterUserStore.structure_id,
//       peine:this.peine,
//       type_peine:this.type_peine,
//       sursis:this.sursis,
//       annee:this.annee,
//       jours:this.jours,
//       mois:this.mois
//     }


//        console.log(newObjet)
//       this.ajouterPeine(newObjet).then(res=>{
//          this.$router.push({name:"Peine"})
//         console.log(res)
//         this.$v.formData.$reset();
//         this.date_debut=""
//         this.dossier_maisons_id=""
//         this.jours=""
//         this.mois=""
//         this.annee=""
//         this.sursis="",
// this.formData={
//         type_peine: "",
//         peine: "",
//         partie_requerente_id: "",
//         dossier_clinique_id: "",
//         structure_id: "",
//         personne_suivie: "",
//         contact: "",
//         email: "",
//         status: 0,
//         date_debut: "",
//         deja_puge: "",
//         date_fin:""
//       }
//        this.$router.push({name:"Peine"})
//       });
//       this.$router.push({name:'Peine'})
     
//     },


      enregistrement1() {

      this.formData.peine=this.peine
      this.formData.type_peine=this.type_peine
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }

      console.log(this.date_decision)

      if(this.return_libelle_motif(this.return_id_motif(this.id_ficheentretien(this.dossier_clinique_id)))=='Prévenu / accusé non détenu'){
        this.formData.date_debut=this.date_decision
        let newObjet={
      ...this.formData,
      date_debut:this.date_decision,
      dossier_clinique_id:this.dossier_clinique_id,
      structure_id:this.getterUserStore.structure_id,
      peine:this.peine,
      subire:this.subire,
      type_peine:this.type_peine,
      sursis:this.sursis,
      annee:this.annee,
      jours:this.jours,
      mois:this.mois
    }

    console.log(newObjet)


       console.log(newObjet)
      this.ajouterPeine(newObjet).then(res=>{
        this.$router.push({name:"Peine"})
        console.log(res)
        this.$v.formData.$reset();
        this.date_debut=""
        this.dossier_maisons_id=""
        this.jours=""
        this.mois=""
        this.annee=""
        this.sursis="",

this.formData={
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      }
      })
      }else{
         let newObjet={
      ...this.formData,
      date_debut:this.date_debut,
      dossier_clinique_id:this.dossier_clinique_id,
      structure_id:this.getterUserStore.structure_id,
      peine:this.peine,
      type_peine:this.type_peine,
      sursis:this.sursis,
      annee:this.annee,
      jours:this.jours,
      mois:this.mois
    }


       console.log(newObjet)
      this.ajouterPeine(newObjet).then(res=>{
        this.$router.push({name:"Peine"})
        console.log(res)
        this.$v.formData.$reset();
        this.date_debut=""
        this.dossier_maisons_id=""
        this.jours=""
        this.mois=""
        this.annee=""
        this.sursis="",

this.formData={
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      }
      })
   
      };
     
    },



//  this.$router.push({name:"Peine"})

    enregistrement() {

      this.formData.peine=this.peine
      this.formData.type_peine=this.type_peine
      
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }

      if(this.return_libelle_motif(this.return_id_motif(this.id_ficheentretien(this.dossier_clinique_id)))==''){
        this.formData.date_debut=this.date_decision
        let newObjet={
      ...this.formData,
      date_debut:this.date_decision,
      dossier_clinique_id:this.dossier_clinique_id,
      structure_id:this.getterUserStore.structure_id,
      peine:this.peine,
      subire:this.subire,
      type_peine:this.type_peine,
      sursis:this.sursis,
      annee:this.annee,
      jours:this.jours,
      mois:this.mois
    }

    console.log(newObjet)


       console.log(newObjet)
      this.ajouterPeine(newObjet).then(res=>{
        console.log(res)
        this.$v.formData.$reset();
        this.date_debut=""
        this.dossier_maisons_id=""
        this.jours=""
        this.mois=""
        this.annee=""
        this.sursis="",

this.formData={
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      }
      })
      }else{
         let newObjet={
      ...this.formData,
      date_debut:this.date_debut,
      dossier_clinique_id:this.dossier_clinique_id,
      structure_id:this.getterUserStore.structure_id,
      peine:this.peine,
      type_peine:this.type_peine,
      sursis:this.sursis,
      annee:this.annee,
      jours:this.jours,
      mois:this.mois
    }


       console.log(newObjet)
      this.ajouterPeine(newObjet).then(res=>{
        console.log(res)
        this.$v.formData.$reset();
        this.date_debut=""
        this.dossier_maisons_id=""
        this.jours=""
        this.mois=""
        this.annee=""
        this.sursis="",

this.formData={
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      }
      })
   
      };
     
    },
 determinesur(){
 let obj=this.getterPartieRequerant.find(item=>item.id==this.formData.partie_requerente_id)
 let _objet=this.getterVictimeprevenu.find(item=>item.partie_requerant_id==this.formData.partie_requerente_id)
  if(_objet!=undefined){
    this.date_debut=_objet.date_mandatdepot
  }
  if(obj.decision_peine==2){
    this.decision_peine=true
  }else{
     this.decision_peine=false
  }
 },
    modification(item) {
      this.modificationActeurJuridique(item);
    },
    editItem(item) {
      this.edite = item;
      this.dialogEdite = true;
    },

    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerPeine(item.id);
    },
    calculeDateFin(){
      if(this.return_libelle_motif(this.return_id_motif(this.id_ficheentretien(this.dossier_clinique_id)))=='Prévenu / accusé non détenu'){

        this.date_fin_previsionnel=this.date_decision
      // console.log(this.date_debut)
      if(this.jours!=""){
         this.date_fin_previsionnel=this.dateDayCalcule(this.date_fin_previsionnel,parseInt(this.jours))


      }
      if(this.mois!=""){
          this.date_fin_previsionnel=this.dateMoiCalcule(this.date_fin_previsionnel,parseInt(this.mois))
      }

      if(this.annee!=""){
          this.date_fin_previsionnel=this.dateYearCalcule(this.date_fin_previsionnel,parseInt(this.annee))
      }
        let puge=parseInt(this.formData.deja_puge) + parseInt(this.sursis)
      this.formData.date_fin=this.date_fin_previsionnel
     
      let date1 = new Date()
      this.formData.deja_puge=this.calcalEntre2Date(this.date_decision,date1)
      //this.formData.date_fin=this.dateDayCalculye2(this.date_fin_previsionnel,parseInt(puge))  subire

        console.log(this.formData.date_fin)
      this.sursis=parseInt(this.calcalEntre2Date(this.date_fin_previsionnel, this.date_decision))-parseInt(this.formData.deja_puge)
      this.peine=this.calcalEntre2Date(this.date_fin_previsionnel, this.date_decision)

      }else{
         this.date_fin_previsionnel=this.date_debut
      console.log(this.date_debut)
      if(this.jours!=""){
         this.date_fin_previsionnel=this.dateDayCalcule(this.date_fin_previsionnel,parseInt(this.jours))


      }
      if(this.mois!=""){
          this.date_fin_previsionnel=this.dateMoiCalcule(this.date_fin_previsionnel,parseInt(this.mois))
      }

      if(this.annee!=""){
          this.date_fin_previsionnel=this.dateYearCalcule(this.date_fin_previsionnel,parseInt(this.annee))
      }
      let puge=parseInt(this.formData.deja_puge) + parseInt(this.sursis)
      this.formData.date_fin=this.date_fin_previsionnel
      //this.formData.date_fin=this.dateDayCalcule2(this.date_fin_previsionnel,parseInt(puge))
         let cal =this.calcalEntre2Date(this.date_debut,this.date_decision)
        // this.formData.deja_puge= parseInt(this.formData.deja_puge) + parseInt(cal)-1
        console.log(this.formData.date_fin)
     // this.peine=this.calcalEntre2Date(this.date_fin_previsionnel, this.date_debut)

      this.sursis=parseInt(this.calcalEntre2Date(this.date_fin_previsionnel, this.date_debut))-parseInt(this.formData.deja_puge)
      this.peine=this.calcalEntre2Date(this.date_fin_previsionnel, this.date_debut)
      }
     
    },
    calculeDateSortie(){
      this.date_fin_surcis=this.date_fin_previsionnel
      console.log(this.date_debut)
      if(this.jours_surcis!=""){
         this.date_fin_surcis=this.soustrationDateDayCalcule(this.date_fin_surcis,parseInt(this.jours_surcis))


      }
      if(this.moi_surcis!=""){
        console.log(this.moi_surcis)
          this.date_fin_surcis=this.soustrationDateMoiCalcule(this.date_fin_surcis,parseInt(this.moi_surcis))
      }

      if(this.annee_surcis!=""){
          this.date_fin_surcis=this.soustrationDateYearCalcule(this.date_fin_surcis,parseInt(this.annee_surcis))
      }
       this.peine_suicie=this.calcalEntre2Date(this.date_fin_surcis, this.date_debut)
      this.formData.date_fin_surcis=this.date_fin_surcis

        
      
    }
  },
  watch: {
    /*jours:0,
        annee:0,
        mois:0,*/
    /*    jours:function(value){
     
 
    

        },
        annee:function(value){
 
 if(value){
      this.date_fin_previsionnel=this.dateYearCalcule(this.date_fin_previsionnel,parseInt(value))

   }else{
      this.date_fin_previsionnel=this.dateYearCalcule(this.date_fin_previsionnel,parseInt(0))

   }

        },
        mois:function(value){
      
if(value){
      this.date_fin_previsionnel=this.dateMoiCalcule(this.date_fin_previsionnel,parseInt(value))

   }else{
      this.date_fin_previsionnel=this.dateMoiCalcule(this.date_fin_previsionnel,parseInt(0))

   }

        },*/

    jours_surcis:function (value){
      console.log(value)
    },
    moi_surcis:function (value){
      console.log(value)
    },
    annee_surcis:function(value){
      console.log(value)
    },
    sursis:function (value) {
  
      let peine=parseInt(this.peine) - (parseInt(this.formData.deja_puge) + parseInt(value))
    if (this.type_peine==1) {
   
      this.formData.date_fin=this.dateDayCalcule(this.date_debut,this.peine)
    }
    if (this.type_peine==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(this.date_debut,this.peine)
    }

     if (this.type_peine==3) {
    
      this.formData.date_fin=this.dateYearCalcule(this.date_debut,this.peine)
     
    }
     
    },
    type_peine:function (value) {
  
      let peine=parseInt(this.peine) - (parseInt(this.formData.deja_puge) + parseInt(this.sursis))
    if (value==1) {
   
      this.formData.date_fin=this.dateDayCalcule(this.date_debut,this.peine)
    }
    if (value==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(this.date_debut,this.peine)
    }

     if (value==3) {
    
      this.formData.date_fin=this.dateYearCalcule(this.date_debut,this.peine)
     
    }
     
    },
    peine:function (value) {
  
      let peine=parseInt(this.peine) - (parseInt(this.formData.deja_puge) + parseInt(this.sursis))
    if (this.type_peine==1) {
   
      this.formData.date_fin=this.dateDayCalcule(this.date_debut,value)
    }
    if (this.type_peine==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(this.date_debut,value)
    }

     if (this.type_peine==3) {
    
      this.formData.date_fin=this.dateYearCalcule(this.date_debut,value)
     
    }
     
    },
    date_debut: function (value) {
      if (value) {
      console.log(value)
       console.log(this.date_decision)
          this.formData.deja_puge=this.calcalEntre2Date(value,this.date_decision)
      } 
    
     
    },
     dossier_clinique_id: function (value) {
  let objet_dossier=this.getterDossiercliniquejuridique.find(item=>item.id==value && item.status==1)
  this.objet_dossier12=this.getterDossiercliniquejuridique.find(item=>item.id==value && item.status==1)
  let objet_audiance=""
   if(objet_dossier!=undefined){
     //236baf51-5b94-45b5-b8c8-be7253314186
     if(this.peineounon==1){
      objet_audiance=this.getterAppel.find(item=>item.dossier_clinique_id.id==value && item.status==2 )
      this.objet_audiance12=this.getterAppel.find(item=>item.dossier_clinique_id.id==value && item.status==2 && item.decision_peine==1)
     }else{
      objet_audiance=this.getterAudience.find(item=>item.dossier_clinique.id==value && item.status==2 && item.decision_peine!=3)
      this.objet_audiance12=this.getterAudience.find(item=>item.dossier_clinique.id==value && item.status==2 && item.decision_peine==1)
     }
    
  
   
   }
    console.log(this.getterVictimeprevenu)
    if(this.return_libelle_motif(this.return_id_motif(this.id_ficheentretien(this.dossier_clinique_id)))=='Prévenu / accusé non détenu'){
    this.date_debut=objet_dossier.date_decision
    let date1 = new Date()
   
    this.formData.deja_puge=this.calcalEntre2Date(objet_dossier.date_decision,date1)

    }else{
        if(objet_dossier.date_mandatdepot){
    this.date_debut=objet_dossier.date_mandatdepot
   
    this.formData.deja_puge=this.calcalEntre2Date(objet_dossier.date_mandatdepot,objet_dossier.date_decision)
  }
    }
  
    console.log("***********************")
    console.log(objet_audiance)
      console.log(objet_dossier.date_mandatdepot)
     this.date_decision=objet_audiance.date_decision
     console.log("***********************")
    
    // this.date_debut=objet_dossier.date_mandatdepot
   //  this.date_fin_previsionnel=objet_dossier.date_mandatdepot
     //this.decision_peine=objet_dossier.decision
     if(objet_audiance.decision_peine==2){
      this.decision_peine=true
     }else{
      this.decision_peine=false
     }
      let vm = this;
      vm.partie_requerante = [];
      if (value != "") {
        let objt = this.getterFicheEntretien.find((item) => item.id == objet_dossier.fiche_analyse_cj_id);
        console.log(objt)
        objt.fiche_partie_requerant.forEach((element) => {

          vm.partie_requerante.push(element.partie_requerant);
          console.log('***test lega**************')
          console.log(vm.partie_requerante)
          console.log(objt.fiche_partie_requerant)
          console.log('*** fin test lega**************')
        });
      }
    },
  },
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
.icon:hover {
  cursor: pointer;
}
.muted {
  color: gray;
  font-size: 80%;
}
</style>



<style>
.vtl .vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}
.vtl .vtl-disabled {
  background-color: #d0cfcf;
}
</style> 
 
