
<template>
  <v-app>
    <notifications />
    <v-main>
      <div id="content" class="content">
        <!-- begin breadcrumb -->
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
          <li class="breadcrumb-item active">Gestion des appels</li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h5 class="page-header">
          Gestion  des appels
        </h5>
        <!-- end page-header -->
        <!-- begin panel -->
        <div class="panel panel-inverse">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-default"
                data-click="panel-expand"
                ><i class="fa fa-expand"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-success"
                data-click="panel-reload"
                ><i class="fa fa-redo"></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-warning"
                data-click="panel-collapse"
                ><i class="fa fa-minus"></i
              ></a>
            </div>
            <h4 class="panel-title">Liste des appels</h4>
          </div>
          <div class="panel-body">
           <!-- <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                <button
                  type="button"
                  @click="gotoModale()"
                  class="btn btn-inverse"
                >
                  NOUVEAU
                </button>
              </div>
            </div>-->

            <div>
              <div class="col-lg-12 ui-sortable" align="center">
                <!-- begin panel -->
                 <h1>EN CONSTRUCTION</h1>
              </div>
            </div>
          </div>
        </div>

        <v-dialog max-width="800" v-model="dialog" persistent>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar dark>Formulaire d'enregistrement</v-toolbar>
              <v-card-text>
                <br />
                <div class="form-group">
                  <label class="form-label semibold">Dossier </label>
                  <select
                    class="form-control"
                    :class="{ hasErroInpu: $v.formData.dossier_clinique_id.$error }"
                    v-model="formData.dossier_clinique_id" placeholder="veuillez selectionner le dossier"
                  >
                    <option></option>
                    <option
                      v-for="item in getterDossiercliniquejuridique"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                     {{recup_numero_requete(item.fiche_analyse_cj_id)}} - {{ item.numero_dossier_sys }}
                    </option>
                  </select>

                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.dossier_clinique_id.$error"
                  >
                    veuillez entrer le dossier
                  </div>
                </div>

                 <div class="form-group">
                  <label class="form-label semibold" for="date">Date de renvoi</label>
                  <input
                    type="date"
                    :class="{ hasErroInpu: $v.formData.date.$error }"
                    v-model="formData.date"
                    name="code"
                    class="form-control"
                    id="date"
                    placeholder="Date"
                    
                  />
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.date.$error"
                  >
                    veuillez entrer le date
                  </div>
                </div>

                 <div class="form-group">
                  <label class="form-label semibold" for="motif">Motif</label>
                  <textarea class="form-control"  :class="{ hasErroInpu: $v.formData.motif.$error }" rows="3"  v-model="formData.motif"></textarea>
                  
                  <div
                    style="color: red"
                    class=""
                    v-if="$v.formData.motif.$error"
                  >
                    veuillez entrer le motif
                  </div>
                </div>






              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="enregistrement()"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <!--DIALOG LOADING--->
        <v-dialog v-model="loading" persistent width="250" height="200">
          <v-card color="#2d353c" dark class="text-center">
            <v-card-text class="white--text">
              Requête en cours de traitement<br />
              <v-progress-circular
                indeterminate
                color="white"
                class="mb-0"
                size="50"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!---DIALOGUE DE SUPPRESION-->

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="headline"> Suppression !! </v-card-title>

            <v-card-text>
              Voulez vous réellement effectuer cette action
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green" text @click="dialogDelete = false">
                Annuler
              </v-btn>

              <v-btn color="red" text @click="deleteItemConfirm(edite)">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogEdite">
          <template v-slot:default="dialogEdite">
            <v-card>
              <v-toolbar dark>Modification</v-toolbar>
              <v-card-text>
                <br />

                <div class="form-group">
                  <label class="form-label semibold" for="code">Code</label>
                  <input
                    type="text"
                    v-model="edite.code"
                    name="code"
                    class="form-control"
                    id="code"
                    placeholder="Code"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Structure</label>
                  <select class="form-control" v-model="edite.structure_id">
                    <option></option>
                    <option
                      v-for="item in liste_structure"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold" for="libelle"
                    >Libelle</label
                  >
                  <input
                    type="text"
                    v-model="edite.libelle"
                    name="libelle"
                    class="form-control"
                    id="libelle"
                    placeholder="Libelle"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Type Acteur</label>
                  <select class="form-control" v-model="edite.type_acteur_id">
                    <option></option>
                    <option
                      v-for="item in typeActeurJu"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label semibold">Régions</label>
                  <select
                    class="form-control"
                    v-model="edite.localisation_geo_graphique_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'AZ' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialogEdite.value = false">Fermer</v-btn>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  text
                  @click="modification(edite)"
                >
                  Modification
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "ActeurJuridique",
  components: {},
  data() {
    return {
      dialog: false,
      test: true,
      formData: {
        type_peine: "",
        peine: "",
        partie_requerente_id: "",
        dossier_clinique_id: "",
        structure_id: "",
        personne_suivie: "",
        contact: "",
        email: "",
        status: 0,
        date_debut: "",
        deja_puge: "",
        date_fin:""
      },
      date_debut:"",
      edite: "",
    partie_requerante:[],
      dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: "Dossier", value: "id" },
        { text: "Date Renvoi", value: "date" },
        { text: "Motif", value: "motif" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        libelle: "",
      },
      defaultItem: {
        code: "",
        libelle: "",
      },
      dossier_clinique_id:"",
    };
  },
  created() {},
  computed: {
     ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),

    ...mapGetters("Application", [
      "getterLocalisationGeographique",
      "getterStructure",
      "getterActeurJuridique",
      "getterLoadingActeurJurique",
      "getterErrorActeurJuridique",
      "getterTypeActeurJuridique",
       "getterDossiercliniquejuridique",
       "getterFicheEntretien",
       "getterAnalyseMJ",
       "getterLoadingPeine",
       "getterPeine",
       "getterPartieRequerant",
       "getterRenvoi2",
       "getterLoadingRenvoi"
    ]),
   etatPeine(){
    return date_fin=>{
        let date=this.peineResteAsubir(date_fin)
        if(date<1) return true
        return false
    }
   },
   listePartieRequerant(){
      let arr=[]
       let vm=this 
      this.partie_requerante.forEach(value=>{
           let objet=this.getterPeine.find(item=>item.partie_requerente_id ==value.id)
           if(objet){
            if(objet.status!=0){
            arr.push(value)
           }
         }else{
 arr.push(value)
         }


           
      })
      return arr
   },
peineAcheveEncour(){
  return typ=>{
    if(!typ) return ""
       let encours=[]
      let acheve=[]
      let vm=this 
      this.getterPeine.forEach(value=>{
         let day=vm.peineResteAsubir(value.date_fin)
         if (day<1 && value.status==0) {
          acheve.push(value)
         }
         if (day>0 && value.status==0) {
          encours.push(value)
         }
      })

      if(typ==1) return encours
        return acheve
  }
 

},



  peineResteAsubir(){
   return date_fin=>{
    if(!date_fin) return null
       let date1=new Date()
     let date2= new Date(date_fin)
     let  diffTime = Math.abs(date2 - date1);
let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
return diffDays
   }
  },
typeDuree(){
  return (type,peine)=>{
    if(!type) return null
      if (type==1) {
        if(peine<2) return "Jour"
           if(peine>1) return "Jours"
      }
    if (type==3) {
        if(peine<2) return "An"
           if(peine>1) return "Ans"
      }
    if (type==2) {
        if(peine<2) return "Moi"
           if(peine>1) return "Mois"
      }
           return null 
  }
},
     dateDayCalcule() {
        return (date, info)=>{

            var result = new Date(date);

            result.setDate(result.getDate() + parseInt(info));
            //console.log(result)
            return this.formatDate(result);
        }
     },
     dateMoiCalcule() {
        return (date, info)=>{

            var result = new Date(date);
            result.setMonth(result.getMonth() + parseInt(info));
            //console.log(result)
            return this.formatDate(result);
        }
     },
     dateYearCalcule() {
        return (date, info)=>{

            var result = new Date(date);
            result.setFullYear(result.getFullYear() + parseInt(info));
            //console.log(result)
            return this.formatDate(result);
        }
     },
      formatDate() {
        return date=>{
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
      },
      formatDateFr(){
          return date=>{
              var d = new Date(date),
                  month = '' + (d.getMonth() + 1),
                  day = '' + d.getDate(),
                  year = d.getFullYear();

              if (month.length < 2)
                  month = '0' + month;
              if (day.length < 2)
                  day = '0' + day;

              return [day,month,year].join('/');
          }
      },
    partieRequerente(){
    return id=>{
      if(!id) return ""
        let obj=this.getterPartieRequerant.find(item=>item.id==id)
      if(obj==undefined) return null
        return obj.nom+" "+obj.prenom
    }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loading() {
      return this.getterLoadingRenvoi;
    },
    typeActeurJu() {
      return this.getterTypeActeurJuridique.reverse();
    },

    liste_structure() {
      return this.getterStructure.reverse();
    },
    acteurJuridiquePasTypeActeur() {
      return (type_acteur_id) => {
        if (!type_acteur_id) return [];
        return this.getterActeurJuridique.filter(
          (item) => item.type_acteur_id == type_acteur_id
        );
      };
    },
    regionActeurJuridique() {
      return (localisation_geo_graphique_id) => {
        if (!localisation_geo_graphique_id) return "";

        let objet = this.getterLocalisationGeographique.find(
          (item) => item.id == localisation_geo_graphique_id
        );
        if (objet == undefined) return "";
        return objet.libelle;
      };
    },
     recup_numero_requete(){
        return(id)=>{
          if (id != null && id != "" && id != undefined) {
              const Objet=this.getterFicheEntretien.find((item)=>item.id==id)
              if(Objet){
             return Objet.numrequete;
           }
           return ''
          }
          return ""
        }
       
      },
  },
  validations: {
    formData: {
        date: { required },
        motif: { required },
        dossier_clinique_id: { required },
    },
  },
  methods: {
    ...mapActions("Application", [
      "getActeurJuridique",
      "ajouterActeurJuridique",
      "supprimerActeurJuridique",
      "modificationActeurJuridique",
      "ajouterPeine",
      "supprimerPeine",
      "modificationPeine",
      "ajouterRenvoi",
      "supprimerRenvoi",
      "modificationRenvoi"
    ]),
    gotoModale() {
      this.dialog = true;
    },
    enregistrement() {
      this.$v.formData.$touch();
      if (this.$v.formData.$error) {
        return;
      }
  
   let newObjet={
      ...this.formData,
      structure_id:this.getterUserStore.structure_id
    }
      this.ajouterRenvoi(newObjet).then(res=>{
       
        this.$v.formData.$reset();
       
this.formData={
       date: "",
        motif:"",
        dossier_clinique_id:"",
      }
      });
     
    },

    modification(item) {
      this.modificationActeurJuridique(item);
    },
    editItem(item) {
      this.edite = item;
      this.dialogEdite = true;
    },

    deleteItem(item) {
      this.edite = item;
      console.log(this.edite);
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      this.dialogDelete = false;
      this.supprimerRenvoi(item.id);
    },
  },
  watch: {
    date_debut: function (value) {
      console.log(value)
      let peine=parseInt(this.formData.peine) - parseInt(this.formData.deja_puge)
    if (this.formData.type_peine==1) {
   
      this.formData.date_fin=this.dateDayCalcule(value,peine)
    }
    if (this.formData.type_peine==2) {
    
      this.formData.date_fin=this.dateMoiCalcule(value,peine)
    }

     if (this.formData.type_peine==3) {
    
      this.formData.date_fin=this.dateYearCalcule(value,peine)
     
    }
     
    },
     dossier_clinique_id: function (value) {
     
  let objet_dossier=this.getterDossiercliniquejuridique.find(item=>item.id==value)

    
      let vm = this;
      vm.partie_requerante = [];
      if (value != "") {
        let objt = this.getterFicheEntretien.find((item) => item.id == objet_dossier.fiche_analyse_cj_id);
        objt.fiche_partie_requerant.forEach((element) => {
          vm.partie_requerante.push(element.partie_requerant);
        });
      }
    },
  },
};
</script>

<style scoped>
.input {
  background-color: #f7f7f7;
  border: 1px solid #c7c7c7;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}
.hasError .input {
  border: red;
}
.hasErroInpu {
  border: 1px red solid;
}
.hasError label {
  color: red;
}
.icon:hover {
  cursor: pointer;
}
.muted {
  color: gray;
  font-size: 80%;
}
</style>



<style>
.vtl .vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}
.vtl .vtl-disabled {
  background-color: #d0cfcf;
}
</style> 
 
