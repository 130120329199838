import { render, staticRenderFns } from "./Tableaubord_des_requetes.vue?vue&type=template&id=165a2b9b&scoped=true&"
import script from "./Tableaubord_des_requetes.vue?vue&type=script&lang=js&"
export * from "./Tableaubord_des_requetes.vue?vue&type=script&lang=js&"
import style0 from "./Tableaubord_des_requetes.vue?vue&type=style&index=0&id=165a2b9b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165a2b9b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VMain})
